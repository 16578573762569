import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService, getUserType, isPermissionExist } from "../services";
import { Assignment } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEmpty from "../gComponents/gEmpty";
import QueryWith from "../Query";
import OrderStatuses from "../gComponents/gOrderStatus";
import { StoreFilter } from "../components/storeMultiFilterComponent";
import { CustomerFilter } from "../components/customerMultiFilterComponent";
import GSelectbox from "../gComponents/gSelectbox";
import GSorting from "../gComponents/gSorting";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import { createSocketConnection } from "../useLaravelPusher";
import { GMultiSelectCheckbox } from "../gComponents/gMultiSelectCheckbox";
import { ReactComponent as PickupDelivery } from "../assets/images/filters/filter.svg";
import { ReactComponent as ImmediateSchedule } from "../assets/images/filters/time.svg";
import { ReactComponent as Payment } from "../assets/images/filters/transactions.svg";
import { StringsContext } from "../DataFactory/useStrings";

export default function Orders(props) {
  const [strings, language] = useContext(StringsContext);
  const settings = useSettings(true);
  const [ordersData, setOrderData] = useState([]);
  const [orderPaginationData, setOrderPaginationData] = useState("");
  const [orderStatusData, setOrderStatusData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    order_status: params.get("order_status")
      ? params.get("order_status")
      : "active",
    stores: params.get("stores") ? params.get("stores") : "",
    customers: params.get("customers") ? params.get("customers") : "",
    delivery_types: params.get("delivery_types")
      ? params.get("delivery_types")
      : "",
    order_types: params.get("order_types") ? params.get("order_types") : "",
    orderby: params.get("orderby") ? params.get("orderby") : "created_at",
    order: params.get("order") ? params.get("order") : "DESC",
    pending_payment: params.get("pending_payment")
      ? params.get("pending_payment")
      : "",
    class: params.get("class") ? params.get("class") : "",
  });

  const columns = [
    { index: 1, id: "id", label: "Id", minWidth: 30, type: "order" },
    {
      index: 2,
      id: "customer",
      label: strings.w_customer,
      minWidth: 100,
      type: "customer_id",
      align: "center",
    },
    {
      index: 3,
      id: "store",
      label: strings.w_store,
      minWidth: 150,
      type: "store_id",
      align: "center",
    },
    {
      index: 4,
      id: "price",
      label: strings.w_price,
      minWidth: 80,
      type: "order",
      align: "center",
    },
    {
      index: 5,
      id: "pickup_time",
      label: strings.w_pickup_time,
      minWidth: 110,
      width: 160,
      type: "order",
      align: "center",
    },

    {
      index: 6,
      id: "delivery_time",
      label: strings.w_delivery_time,
      minWidth: 110,
      width: 160,
      type: "order",
      align: "center",
    },
    {
      index: 7,
      id: "status",
      label: strings.w_status,
      minWidth: 120,
      type: "order",
      align: "center",
    },
    {
      index: 8,
      id: "pending_amount",
      label: strings.w_pending_amount,
      minWidth: 120,
      type: "order",
      align: "center",
    },
    {
      index: 9,
      id: "created_at_formatted",
      label: strings.w_created,
      minWidth: 130,
      type: "order",
      align: "center",
    },
  ];

  const filterOrderTypes = [
    {
      title: strings.w_pickup,
      value: "store_pickup",
    },
    {
      title: strings.w_delivery,
      value: "home_delivery",
    },
  ];

  const filterDeliveryTypes = [
    {
      title: strings.w_immediate,
      value: "asap",
    },
    {
      title: strings.w_scheduled,
      value: "scheduled",
    },
  ];

  const filterPayment = [
    {
      title: strings.w_with_pending_amount,
      value: "true",
    },
  ];
  const orderSorting = [
    {
      title: strings.w_created_at,
      value: "created_at",
    },
    {
      title: strings.w_delivery_time,
      value: "delivery_to",
    },
  ];
  const classFilters = [
    {
      title: strings.w_regular,
      value: "regular",
    },
    {
      title: strings.w_courier,
      value: "courier",
    },
    {
      title: strings.w_atm,
      value: "atm",
    },
  ];

  useEffect(() => {
    if (props.type) {
      if (props.onChange) {
        props.onChange("orders");
      }
      var obj = {};
      obj["page"] = filters.page;
      obj[props.title] = props.value;
    } else {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/orders",
          state: "order",
          search: params[0],
        });
      }
    }
    var query = props.type ? obj : filters;
    ApiService({ method: "GET", route: "orders", body: query })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data.data);
          setOrderPaginationData(response.data.data);
          setOrderStatusData(response.data.statuses);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [history, filters, props.value, language]);

  const handleChangePage = (pageUrl) => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("admin-updates").listen("BCOrderUpdated", function (e) {
      console.log(e);
      let findInd = ordersData.findIndex((o) => o.id == e.order?.id);
      if (findInd > -1) {
        ordersData[findInd] = e.order;
        setOrderData([...ordersData] || []);
      }
    });
    return () => {
      window.Echo.leaveChannel(`admin-updates`);
    };
  }, [orderStatusData]);

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {!props.type ? (
        <React.Fragment>
          <h1>
            <Assignment /> {strings.w_orders}
          </h1>
          {settings?.add_order === true &&
          (getUserType() || isPermissionExist("order_store")) ? (
            <Link to={{ pathname: "/orderCreate", state: "order" }}>
              <GButton
                variant="condensed"
                style={{
                  marginTop: "-55px",
                  float: "right",
                }}
                children={strings.w_add_new_order}
              />
            </Link>
          ) : (
            ""
          )}
        </React.Fragment>
      ) : (
        ""
      )}

      {!props.type ? (
        <React.Fragment>
          <OrderStatuses
            statusList={orderStatusData}
            onClickStatus={(oStatus) => {
              setFilters({ ...filters, page: "1", order_status: oStatus });
            }}
            defaultStatus={filters.order_status}
            currencySymbol={settings.currency_symbol}
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "initial",
              gap: "7px",
              // width: "calc(100% - 300px)",
            }}
          >
            {getUserType() || isPermissionExist("store_index") ? (
              <StoreFilter
                value={filters.stores ? filters.stores.split(",") : []}
                handleStoreChange={(values) => {
                  setFilters({
                    ...filters,
                    page: 1,
                    stores: values.length > 0 ? values.toString() : "",
                  });
                }}
                style={{ width: "auto", minWidth: "auto" }}
              />
            ) : (
              ""
            )}
            {getUserType() || isPermissionExist("customer_index") ? (
              <CustomerFilter
                cvalue={filters.customers ? filters.customers.split(",") : []}
                handleCustomerChange={(values) => {
                  setFilters({
                    ...filters,
                    page: 1,
                    customers: values.length > 0 ? values.toString() : "",
                  });
                }}
                style={{ width: "auto", minWidth: "auto" }}
              />
            ) : (
              ""
            )}
            {settings.product !== "laundry" ? (
              <React.Fragment>
                <GMultiSelectCheckbox
                  data={filterOrderTypes}
                  title={strings.w_pickup + " / " + strings.w_delivery}
                  activeColor={"#BEE2B6"}
                  values={
                    filters.order_types ? filters.order_types.split(",") : []
                  }
                  option_key="title"
                  option_value="value"
                  icon={
                    <PickupDelivery
                      style={{ width: "20px", height: "20px", fill: "#4D4D4D" }}
                    />
                  }
                  handleChange={(v) => {
                    console.log(v);
                    setFilters({
                      ...filters,
                      order_types: v.length == 0 ? "" : v.toString(),
                    });
                  }}
                  style={{ minWidth: "auto", width: "auto" }}
                />
                <GMultiSelectCheckbox
                  data={filterDeliveryTypes}
                  title={strings.w_immediate + " / " + strings.w_scheduled}
                  values={
                    filters.delivery_types
                      ? filters.delivery_types.split(",")
                      : []
                  }
                  option_key="title"
                  option_value="value"
                  activeColor={"#BEE2B6"}
                  icon={
                    <ImmediateSchedule
                      style={{ width: "18px", height: "18px", fill: "#4D4D4D" }}
                    />
                  }
                  handleChange={(v) => {
                    console.log(v);
                    setFilters({
                      ...filters,
                      delivery_types: v.length == 0 ? "" : v.toString(),
                    });
                  }}
                  style={{ minWidth: "auto", width: "auto" }}
                />
              </React.Fragment>
            ) : (
              ""
            )}

            <GMultiSelectCheckbox
              data={filterPayment}
              title={strings.w_payment}
              activeColor={"#C4DEF9"}
              values={
                filters.pending_payment
                  ? filters.pending_payment.split(",")
                  : []
              }
              icon={
                <Payment
                  style={{ width: "18px", height: "18px", fill: "#4D4D4D" }}
                />
              }
              option_key="title"
              option_value="value"
              handleChange={(v) => {
                console.log(v);
                setFilters({
                  ...filters,
                  pending_payment: v.length == 0 ? "" : v.toString(),
                });
              }}
              style={{ minWidth: "auto", width: "auto" }}
            />

            <GSorting
              defaultvalue={filters.orderby}
              defaultOrdervalue={filters.order}
              data={orderSorting}
              id="sortBy"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                console.log(newVal);
                setFilters({ ...filters, page: "1", orderby: newVal });
              }}
              style={{
                height: "40px",
                minWidth: "150px",
              }}
              onOrderChange={(newVal) => {
                console.log(newVal);
                setFilters({ ...filters, page: "1", order: newVal });
              }}
            />
            <div>
              <GSelectbox
                placeholder={strings.w_all_orders}
                defaultvalue={filters.class}
                data={classFilters}
                id="d_class_filter"
                option_key="title"
                option_value="value"
                onChange={(newVal) => {
                  setFilters({ ...filters, class: newVal, page: 1 });
                }}
                style={{
                  border: "1px solid #e2e2e2",
                  padding: "7px 12px",
                  minWidth: "165px",
                }}
                iconStyle={{ marginLeft: "-25px" }}
              />
            </div>

            {isLoading == false && ordersData.length > 0 ? (
              <>
                <GPagination
                  style={{ display: "inline-block", marginLeft: "auto" }}
                  count={orderPaginationData.total}
                  from={orderPaginationData.from}
                  to={orderPaginationData.to}
                  onChangeNextPage={() =>
                    handleChangePage(orderPaginationData.next_page_url)
                  }
                  onChangePrevPage={() =>
                    handleChangePage(orderPaginationData.prev_page_url)
                  }
                  rowsPerPage={orderPaginationData.per_page}
                  prevDisabled={
                    orderPaginationData.prev_page_url ? false : true
                  }
                  nextDisabled={
                    orderPaginationData.next_page_url ? false : true
                  }
                ></GPagination>
                <GPaginationSelect
                  defaultvalue={filters.page}
                  totalPage={orderPaginationData.last_page}
                  onChangePage={(NewPageNo) =>
                    setFilters({ ...filters, page: "1", page: NewPageNo })
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      <div
        className="listPaper"
        style={{ marginTop: props.type ? "" : "10px" }}
      >
        {isLoading == false && ordersData.length !== 0 && props.type ? (
          <GPagination
            count={orderPaginationData.total}
            from={orderPaginationData.from}
            to={orderPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(orderPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(orderPaginationData.prev_page_url)
            }
            rowsPerPage={orderPaginationData.per_page}
            prevDisabled={orderPaginationData.prev_page_url ? false : true}
            nextDisabled={orderPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}

        {isLoading == true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {ordersData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {settings.product !== "laundry"
                      ? columns
                          .filter((f) => f.id !== "pickup_time")
                          .filter((column) => column.type !== props.title)
                          .map((c) => {
                            return (
                              <TableHeader
                                key={c.id}
                                style={{
                                  minWidth: c.minWidth,
                                  maxWidth: c.maxWidth,
                                  width: c.width,
                                  textAlign: c.align,
                                }}
                              >
                                {c.label}
                              </TableHeader>
                            );
                          })
                      : columns
                          .filter((column) => column.type !== props.title)
                          .map((c) => {
                            return (
                              <TableHeader
                                key={c.id}
                                style={{
                                  minWidth: c.minWidth,
                                  maxWidth: c.maxWidth,
                                  width: c.width,
                                  textAlign: c.align,
                                }}
                              >
                                {c.label}
                              </TableHeader>
                            );
                          })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData.map((s) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        <TableRowData>
                          {s.class == "courier" ? (
                            <Link
                              to={{
                                pathname: "/courier-order/" + s.id,
                                state: "order",
                              }}
                            >
                              #{s.id}
                            </Link>
                          ) : s.class == "atm" ? (
                            <Link
                              to={{
                                pathname: "/atm-order/" + s.id,
                                state: "order",
                              }}
                            >
                              #{s.id}
                            </Link>
                          ) : (
                            <Link
                              to={{
                                pathname: "/order/" + s.id,
                                state: "order",
                              }}
                            >
                              #{s.id}
                            </Link>
                          )}
                        </TableRowData>
                        {props.title !== "customer_id" && (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.customer ? (
                              <Link
                                to={{
                                  pathname:
                                    "/customer/" + s.customer.id + "/orders",
                                  state: "customer",
                                }}
                              >
                                {s.customer.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        {props.title !== "store_id" && (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.store ? (
                              <Link
                                to={{
                                  pathname: "/store/" + s.store.id + "/orders",
                                  state: "store",
                                }}
                              >
                                {s.store.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        <TableRowData style={{ textAlign: "center" }}>
                          {settings.currency_symbol}
                          {s.amount_display}
                        </TableRowData>
                        {settings.product === "laundry" && (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.pickup_time ? s.pickup_time : "-"}
                          </TableRowData>
                        )}
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.delivery_time ? s.delivery_time : "-"}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            className="orderStatus"
                            style={{
                              color: "#" + s.status.color1,
                              background: "#" + s.status.color2,
                            }}
                          >
                            {s.status.title}
                          </span>
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {settings.currency_symbol}
                          {s.pending_payment.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>
    </div>
  );
}
