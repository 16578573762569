import React from "react";
import { Grid } from "@material-ui/core";
import { Card } from "../styledComponents/cardStyles";
import GText from "../gComponents/gText";
import { Assignment, PeopleAlt, Store, LocalOffer } from "@material-ui/icons";

function GDashboardCounts(props) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: "2px 8px" }}>
      <Card>
        {props.type === "user" ? (
          <PeopleAlt fontSize="large" style={{ background: "#3097E7" }} />
        ) : props.type === "store" ? (
          <Store fontSize="large" style={{ background: "#F23937" }} />
        ) : props.type === "order" ? (
          <Assignment fontSize="large" style={{ background: "#1BAC57" }} />
        ) : props.type === "offer" ? (
          <LocalOffer fontSize="large" style={{ background: "#FFAF00" }} />
        ) : (
          ""
        )}
        <div
          style={{
            display: "inline-block",
            float: "right",
            textAlign: "right",
          }}
        >
          <GText g5 light text={props.title} style={{ margin: "0" }} />
          <GText
            bold
            med
            g1
            text={props.total_count}
            style={{ margin: "0 0 7px" }}
          />
        </div>
        <GText med text={props.subtitle} style={{ margin: "20px 0 0" }} />
      </Card>
    </Grid>
  );
}

export default GDashboardCounts;
