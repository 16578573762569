import React, { useEffect, useState, useContext } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ImgUrl, addDefaultSrc } from "../services";
import { NoteDiv } from "../styledComponents/notesStyles";
import GLightBox from "../gComponents/gLightBox";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const imgStyle = {
  cursor: "pointer",
  width: "80px",
  border: "1px solid #e2e2e2",
  padding: "5px",
};

export default function OrderDeliveryInfo(props) {
  const [strings] = useContext(StringsContext);
  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const [images, setImages] = useState([]);
  const [signatures, setSigns] = useState([]);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  const [selectedImgType, setSelectedImgType] = useState("");

  useEffect(() => {
    setNotes(props.notes || []);
    setImages(props.images || []);
    setSigns(props.signatures || []);
  }, [props]);

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setOdInfovisible(false);
  };

  if (props.odInfovisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.odInfovisible}
        onClick={handleClose}
      >
        <Paper
          className={`modalRight
              ${props.odInfovisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <div
            style={{ display: "flex", margin: "0 15px", alignItems: "center" }}
          >
            <GText g3 semi text={strings.w_delivery_info} />
            <GText
              g4
              semi
              theme
              text={"#" + props.linkedId}
              style={{
                fontStyle: "italic",
                marginLeft: "auto",
                textAlign: "right",
              }}
            />
          </div>
          <div
            style={{
              padding: "5px 20px 15px",
              marginBottom: "20px",
            }}
          >
            <GText g4 semi text={strings.w_notes + " (" + notes.length + ")"} />
            {notes && notes.length === 0 ? (
              <GText
                light
                text={strings.s_no_notes_found}
                style={{ textAlign: "center" }}
              />
            ) : (
              notes.map((p) => {
                return (
                  <div key={p.id}>
                    <NoteDiv className={"send"}>
                      <h5>{p.notes}</h5>
                      <p>{p.created}</p>
                    </NoteDiv>
                  </div>
                );
              })
            )}
          </div>
          <hr />
          <div
            style={{
              padding: "5px 10px 15px",
              marginBottom: "20px",
            }}
          >
            <GText
              g4
              semi
              text={strings.w_images + " (" + images.length + ")"}
            />
            {images && images.length === 0 ? (
              <GText
                light
                text={strings.s_no_images_found}
                style={{ textAlign: "center" }}
              />
            ) : (
              images.map((p, i) => {
                return (
                  <div
                    key={p.id}
                    style={{ display: "inline-block", margin: "5px 10px" }}
                  >
                    <img
                      alt=""
                      src={ImgUrl("dimage") + "/" + p.image_path}
                      onError={addDefaultSrc}
                      className="thumbnail"
                      style={imgStyle}
                      onClick={() => {
                        setSelectedImgPreview(p.image_path);
                        setImgPreview(true);
                        setSelectedImgType("dimage");
                      }}
                    />
                  </div>
                );
              })
            )}
          </div>
          <hr />
          <div
            style={{
              padding: "5px 10px 15px",
              marginBottom: "20px",
            }}
          >
            <GText
              g4
              semi
              text={strings.w_signatures + " (" + signatures.length + ")"}
            />

            {signatures && signatures.length === 0 ? (
              <GText
                light
                text={strings.s_no_signatures_found}
                style={{ textAlign: "center" }}
              />
            ) : (
              signatures.map((p) => {
                return (
                  <div
                    key={p.id}
                    style={{ display: "inline-block", margin: "5px 10px" }}
                  >
                    <img
                      alt=""
                      src={ImgUrl("dsignature") + "/" + p.signature_path}
                      onError={addDefaultSrc}
                      className="thumbnail"
                      style={imgStyle}
                      onClick={() => {
                        setSelectedImgPreview(p.signature_path);
                        setImgPreview(true);
                        setSelectedImgType("dsignature");
                      }}
                    />
                  </div>
                );
              })
            )}
          </div>
        </Paper>

        {isImgPreview === true && (
          <GLightBox
            images={selectedImgPreview}
            isImgPreview={isImgPreview}
            setImgPreview={setImgPreview}
            imgType={selectedImgType}
          ></GLightBox>
        )}
      </Backdrop>
    </div>
  );
}
