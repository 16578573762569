import React, { useState, useEffect, useContext } from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";
import GButton from "./gButton";
import { Edit, Delete, Settings } from "@material-ui/icons";
import GConfirmDialog from "./gConfirmDialog";
import useSettings from "../useSettings";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick.svg";
import { StringsContext } from "../DataFactory/useStrings";

const ListCard = styled.div`
  padding: 0.8em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  background: #f4f5f8;
  vertical-align: top;
  width: 230px;
  display: inline-block;
  margin: 10px;
  height: 136px;
  overflow: overlay;
  /*  
  &:hover{
    color:rgba(0, 0, 0, 0.26);
  }  */

  h4 {
    margin: 0px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    width: 70%;
  }
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #777;
    font-size: 0.9rem;
    padding: 5px 0;
  }
  p {
    margin: 0px;
    font-weight: 400;
    display: inline-block;
    width: 50%;
    font-size: 0.95rem;
    color: #818183;
  }

  label {
    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      vertical-align: middle;

      &.checked {
        path {
          fill: #d8ae36;
        }
      }
    }
  }
`;

function GPricingListCard(props) {
  const [strings] = useContext(StringsContext);
  const [pricingRulesData, setPricingRulesData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  const settings = useSettings(true);
  useEffect(() => {
    setPricingRulesData(props.rulesData);
  }, [props]);

  const handleDelete = (e) => {
    //setValue(e.target.value);
    console.log(selectedRuleId);
    props.onDelete(selectedRuleId);
  };
  const handleDefault = () => {
    props.onSelectDefault(selectedRuleId);
  };

  return (
    <div>
      {pricingRulesData.map((v) => {
        return (
          <ListCard key={v.id}>
            {props.default ? (
              <div
                className="radio"
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: "30px",
                  verticalAlign: "top",
                }}
              >
                <label
                  onClick={() => {
                    setSelectedRuleId(v.id);
                    setDefaultDialogOpen(true);
                  }}
                >
                  <input
                    type="radio"
                    value="cash"
                    checked={v.id === parseInt(props.default.key_value)}
                    onClick={() => {
                      setSelectedRuleId(v.id);
                      setDefaultDialogOpen(true);
                    }}
                    style={{
                      cursor: "pointer",
                      height: "17px",
                      width: "17px",
                      display: "none",
                    }}
                  />
                  {v.id === parseInt(props.default.key_value) ? (
                    <Check className="checked" />
                  ) : (
                    <UnCheck />
                  )}
                </label>
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                display: props.default ? "inline-block" : "block",
                width: props.default ? "calc(100% - 30px)" : "100%",
              }}
            >
              <h4>{v.title}</h4>

              {props.actions === true ? (
                <div style={{ float: "right" }}>
                  <GButton
                    variant="linkable"
                    onClick={() => {
                      props.onEdit(v);
                    }}
                  >
                    <Edit fontSize="small" />
                  </GButton>
                  <GButton
                    variant="linkable"
                    onClick={() => {
                      setSelectedRuleId(v.id);
                      setDialogOpen(true);
                    }}
                  >
                    <Delete fontSize="small" />
                  </GButton>
                </div>
              ) : (
                ""
              )}

              {v.rule_type === "1" ? (
                <div>
                  <h5>{strings.w_fixed_price}</h5>
                  <p>
                    {v.base_fare} {strings.w_per_delivery}
                  </p>
                </div>
              ) : v.rule_type === "2" ? (
                <div>
                  <h5>{strings.w_distance_and_time_based}</h5>
                  <div>
                    <p>BF: {v.base_fare}</p>
                    <p>MF: {v.minimum_fare}</p>
                    <p>
                      <span style={{ textTransform: "uppercase" }}>
                        {settings.distance_unit}
                      </span>
                      :{v.distance_fee}
                    </p>
                    <p>MIN: {v.duration_fee}</p>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "97px",
                    overflowY: "overlay",
                    width: "100%",
                  }}
                >
                  <h5>{strings.w_distance_slots_based}</h5>

                  {v.options.map((slots, i) => {
                    return (
                      <p key={i}>
                        {slots.upto} {settings.distance_unit}: {slots.cost}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </ListCard>
        );
      })}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_pricing_rule_title}
          text={strings.s_delete_pricing_rule_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDelete();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {defaultDialogOpen === true ? (
        <GConfirmDialog
          open={defaultDialogOpen}
          title={strings.s_set_default_rule_title}
          text={strings.s_set_default_rule_subtitle}
          handleClose={() => setDefaultDialogOpen(false)}
          onConfirmClose={() => {
            setDefaultDialogOpen(false);
            handleDefault();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}

export default GPricingListCard;
