import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { Delete } from "@material-ui/icons";
import GEditable from "../gComponents/gEditable";
import GButton from "../gComponents/gButton";
import CreateSocialLinkForm from "../forms/createSocialLinks";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useSnackbar } from "react-simple-snackbar";
import GText from "../gComponents/gText";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function SocialLinks(props) {
  const [strings] = useContext(StringsContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSocialData, setSelectedSocialData] = useState({});
  const [selectedSocialId, setSelectedSocialId] = useState("");
  const [csvisible, setCsvisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();
  const {
    loading,
    TableShimmer,
    data: linksData,
    Placeholder,
  } = useDataFctory("social", false);

  function updateField(newValue, selectedFieldId) {
    ApiService({
      method: "PUT",
      route: "social/" + selectedFieldId,
      body: {
        url: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = linksData.findIndex(
            (p) => p.id == selectedFieldId
          );
          if (selectedIndex > -1) {
            linksData[selectedIndex] = response.data.data;
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const deleteSocialLink = () => {
    ApiService({ method: "DELETE", route: "social/" + selectedSocialId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = linksData.findIndex(
            (p) => p.id == selectedSocialId
          );
          if (selectedIndex > -1) {
            linksData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedSocialData({});
          setIsEmpty(true);
          setCsvisible(true);
        }}
        style={{
          float: "right",
        }}
      />
      <GText
        g2
        bold
        text={strings.w_social_links}
        style={{ marginBottom: "15px" }}
      />
      <div className="listPaper">
        {loading == true ? (
          <TableShimmer />
        ) : linksData && linksData.length == 0 ? (
          <Placeholder />
        ) : (
          linksData.map((link, i) => (
            <div style={{ display: "inline-flex", width: "80%" }} key={i}>
              <GEditable
                key={link.id}
                type="text"
                title={true}
                defaultValue={link.url}
                className={props.title}
                placeholder={link.title}
                id={link.id}
                onSubmit={updateField}
                style={{
                  margin: "10px 0 20px",
                  minWidth: "70%",
                  paddingRight: "10px",
                  boxSizing: "border-box",
                }}
              />
              <GButton
                variant="linkable"
                onClick={(e) => {
                  setSelectedSocialId(link.id);
                  setDialogOpen(true);
                }}
                style={{ paddingTop: "20px" }}
              >
                <Delete fontSize="small" />
              </GButton>
            </div>
          ))
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_social_link_title}
          text={strings.s_delete_social_link_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteSocialLink();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={csvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCsvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Social Link Form Modal"
      >
        <CreateSocialLinkForm
          csvisible={csvisible}
          setCsvisible={setCsvisible}
          data={selectedSocialData}
          onSubmit={(data) => {
            linksData.push(data);
          }}
          isEmpty={isEmpty}
        />
      </Modal>
    </div>
  );
}
