import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GSwitch from "../gComponents/gSwitch";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function InvoiceSettings() {
  const [strings, language] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [assignData, setAssignData] = useState({
    delivery_create: {},
    delivery_autoassign: {},
    ten_min_free_delivery: {},
  });

  useEffect(() => {
    ApiService({ method: "GET", route: "setting/delivery" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setAssignData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [language]);

  function updateField(newValue, selectedFieldId, field) {
    console.log(newValue + "--" + selectedFieldId);
    // return;
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          assignData[field] = response.data.data;
        }
        openSnackbar(response.data.message);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_delivery_assign_settings}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "60%",
            minWidth: "540px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          {assignData.delivery_create?.id && (
            <div style={{ display: "flex", padding: "15px 0" }}>
              <label>{assignData.delivery_create.key_display_title}</label>
              <div style={{ width: "calc(100% - 320px)" }}>
                <GSwitch
                  id={assignData.delivery_create.id}
                  uId={assignData.delivery_create.id}
                  currentValue={assignData.delivery_create.key_value}
                  style={{ margin: "0 20px" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "delivery_create")
                  }
                />
              </div>
            </div>
          )}

          {assignData.delivery_autoassign?.id &&
            assignData.delivery_create.key_value == "1" && (
              <div style={{ display: "flex", padding: "15px 0" }}>
                <label>
                  {assignData.delivery_autoassign.key_display_title}
                </label>
                <div style={{ width: "calc(100% - 320px)" }}>
                  <GSwitch
                    id={assignData.delivery_autoassign.id}
                    uId={assignData.delivery_autoassign.id}
                    currentValue={assignData.delivery_autoassign.key_value}
                    style={{ margin: "0 20px" }}
                    onSwitchChange={(newVal, id) =>
                      updateField(newVal, id, "delivery_autoassign")
                    }
                  />
                </div>
              </div>
            )}

          {assignData.ten_min_free_delivery?.id && (
            <div style={{ display: "flex", padding: "15px 0" }}>
              <label>
                {assignData.ten_min_free_delivery.key_display_title}
              </label>
              <div style={{ width: "calc(100% - 320px)" }}>
                <GSwitch
                  id={assignData.ten_min_free_delivery.id}
                  uId={assignData.ten_min_free_delivery.id}
                  currentValue={assignData.ten_min_free_delivery.key_value}
                  style={{ margin: "0 20px" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "ten_min_free_delivery")
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
