import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import FeedbackTemplateForm from "../forms/feedbackTemplateForm";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Edit, Delete, Add } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import Modal from "react-modal";
import useDataFctory from "../useDataFactory";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { StringsContext } from "../DataFactory/useStrings";

export default function FeedbackTemplates() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isEmpty, setIsEmpty] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFeedbackTemplate, setSelectedFeedbackTemplate] = useState({});
  const [selectedFeedbackTemplateId, setSelectedFeedbackTemplateId] = useState(
    {}
  );
  const [feedbackTempVisible, setFeedbackTempVisible] = useState(false);
  const {
    loading,
    data: feedbackTemplatesData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("feedback_templates", false);

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 150 },
    { id: "star", label: strings.w_star, minWidth: 60, align: "center" },
    { id: "type", label: strings.w_type, minWidth: 80, align: "center" },
    { id: "actions", label: strings.w_actions, minWidth: 70, align: "center" },
  ];

  function deleteFeedbackTemplate(selectedId) {
    ApiService({ method: "DELETE", route: "chat-templates/" + selectedId })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          let selectedIndex = feedbackTemplatesData.findIndex(
            (p) => p.id == selectedId
          );
          if (selectedIndex > -1) {
            feedbackTemplatesData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>
        {strings.w_feedback_templates}
        <GButton
          variant="condensed"
          children={strings.w_add}
          onClick={() => {
            setSelectedFeedbackTemplate({});
            setIsEmpty(true);
            setFeedbackTempVisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      </h2>
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : (
          <TableContainer>
            {feedbackTemplatesData && feedbackTemplatesData?.length > 0 ? (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedbackTemplatesData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                            textAlign: "center",
                          }}
                        >
                          {c.star}
                        </TableRowData>{" "}
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                            textTransform: "capitalize",
                            textAlign: "center",
                          }}
                        >
                          {c.type}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            children="Edit"
                            onClick={() => {
                              setSelectedFeedbackTemplate(c);
                              setIsEmpty(false);
                              setFeedbackTempVisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>

                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedFeedbackTemplateId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Placeholder />
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_feedback_template_title}
          text={strings.s_delete_feedback_template_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteFeedbackTemplate();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={feedbackTempVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFeedbackTempVisible(false)}
        style={{
          content: {
            width: "380px",
          },
        }}
        contentLabel="Feedback Template Form Modal"
      >
        <FeedbackTemplateForm
          feedbackTempVisible={feedbackTempVisible}
          setFeedbackTempVisible={setFeedbackTempVisible}
          data={selectedFeedbackTemplate}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = feedbackTemplatesData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              feedbackTemplatesData[selectedIndex] = { ...data };
            } else {
              feedbackTemplatesData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
