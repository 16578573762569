import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { Edit, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import TaxClassForm from "../forms/taxClassesForm";
import TaxClassValForm from "../forms/taxClassesValForm";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GEmpty from "../gComponents/gEmpty";
import GListCard from "../gComponents/gListCards";
import GActions from "../gComponents/gActionsStyle";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function TaxClasses(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [taxClassesFormvisible, setTaxClassesFormvisible] = useState(false);
  const [taxClassValFormvisible, setTaxClassValFormvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTaxClassData, setSelectedTaxClassData] = useState({});
  const [selectedTaxClassId, setSelectedTaxClassId] = useState("");
  const [selectedTaxClassValData, setSelectedTaxClassValData] = useState({});
  const [selectedTaxClassValId, setSelectedTaxClassValId] = useState("");

  const deleteTaxClass = (id) => {
    var taxClassId = id ? id : selectedTaxClassId;
    ApiService({ method: "DELETE", route: "tax-classes/" + taxClassId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onDelete(taxClassId);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTaxClassValue = (id, classId) => {
    var taxValId = id ? id : setSelectedTaxClassValId;
    ApiService({ method: "DELETE", route: "tax-class-values/" + taxValId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onClassValueDelete(taxValId, classId);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <GText
            light
            text={strings.w_title}
            style={{
              margin: 0,
              width: "80px",
              paddingRight: "18px",
            }}
          />
          &nbsp;
          <GText
            light
            text={strings.w_options}
            style={{
              margin: 0,
              paddingLeft: "10px",
            }}
          />
        </div>
        {props.taxClassesData.map((c, pi) => {
          return (
            <React.Fragment key={c.id}>
              <div
                style={{
                  padding: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  borderBottom: "1px solid #e2e2e2",
                }}
              >
                <GText
                  text={c.title}
                  style={{
                    margin: 0,
                    width: "75px",
                    minWidth: "75px",
                    borderRight: "1px solid #aaa",
                    paddingRight: "18px",
                  }}
                />
                {/* <GText light text={"|"} style={{ margin: 0, width: "auto" }} /> */}
                &nbsp;
                {c.values?.map((sub, ind) => {
                  return (
                    <GListCard
                      setting={strings.w_tax_class_value}
                      key={ind}
                      id={sub.id}
                      title={props.taxData
                        .filter((f) => f.id == sub.tax_id)
                        .map((t) => {
                          return t.title;
                        })}
                      subtitle={
                        sub.type == "percentage"
                          ? `${sub.percentage}%`
                          : `${props.settings.currency_symbol}${sub.fixed}`
                      }
                      switch={false}
                      dropdownActions={true}
                      edit={true}
                      delete={true}
                      onEdit={() => {
                        setSelectedTaxClassValData(sub);
                        setIsEmpty(false);
                        setTaxClassValFormvisible(true);
                      }}
                      onDelete={(id) => {
                        deleteTaxClassValue(id, c.id);
                      }}
                      data={sub}
                      style={{
                        width: "110px",
                        minWidth: "100px",
                        margin: 0,
                        padding: "3px 8px 4px",
                      }}
                      titleStyle={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        height: "22px",
                        color: "#777",
                      }}
                      subTitleStyle={{
                        paddingTop: 0,
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                        fontWeight: "500",
                      }}
                    ></GListCard>
                  );
                })}
                <GButton
                  variant="linkable"
                  children="Add"
                  onClick={() => {
                    setSelectedTaxClassValData({});
                    setIsEmpty(true);
                    setSelectedTaxClassId(c.id);
                    setTaxClassValFormvisible(true);
                  }}
                  style={{
                    fontSize: "14px",
                  }}
                >
                  + {strings.w_add} {strings.w_option}
                </GButton>
                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "start",
                    marginTop: 3,
                  }}
                >
                  <GButton
                    type="button"
                    variant="linkable"
                    style={{ alignSelf: "start" }}
                    onClick={(e) => {
                      setSelectedTaxClassId(c.id);
                      setDialogOpen(true);
                    }}
                  >
                    <Delete fontSize="small" />
                  </GButton>
                  <GButton
                    type="button"
                    variant="linkable"
                    style={{ alignSelf: "start" }}
                    onClick={(e) => {
                      setSelectedTaxClassData(c);
                      setIsEmpty(false);
                      setTaxClassesFormvisible(true);
                    }}
                  >
                    <Edit fontSize="small" />
                  </GButton>
                </div>
                {/* <GActions
                  edit={true}
                  delete={true}
                  onEdit={() => {
                    setSelectedTaxClassData(c);
                    setIsEmpty(false);
                    setTaxClassesFormvisible(true);
                  }}
                  onDelete={() => {
                    setSelectedTaxClassId(c.id);
                    setDialogOpen(true);
                  }}
                  style={{ verticalAlign: "middle" }}
                  menuStyle={{ fontWeight: "400" }}
                /> */}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <GButton
        variant="linkable"
        onClick={() => {
          setSelectedTaxClassData({});
          setIsEmpty(true);
          setTaxClassesFormvisible(true);
        }}
      >
        + {strings.w_add} {strings.w_tax_class}
      </GButton>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_tax_class_title}
          text={strings.s_delete_tax_class_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteTaxClass();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={taxClassesFormvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setTaxClassesFormvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Tax Class Form Modal"
      >
        <TaxClassForm
          taxClassesFormvisible={taxClassesFormvisible}
          setTaxClassesFormvisible={setTaxClassesFormvisible}
          data={selectedTaxClassData}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            props.onSubmit(data);
          }}
        />
      </Modal>
      <Modal
        isOpen={taxClassValFormvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setTaxClassValFormvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Tax Class Value Form Modal"
      >
        <TaxClassValForm
          taxData={props.taxData}
          classId={selectedTaxClassId}
          taxClassValFormvisible={taxClassValFormvisible}
          setTaxClassValFormvisible={setTaxClassValFormvisible}
          data={selectedTaxClassValData}
          isEmpty={isEmpty}
          // onSubmit={() => refreshValData()}
          onSubmit={(data) => {
            props.onClassValueSubmit(data, selectedTaxClassId);
          }}
        />
      </Modal>
    </div>
  );
}
