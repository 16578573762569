import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GListCard from "../gComponents/gListCards";
import RolesForm from "../forms/rolesForm";
import RolePermissionForm from "../forms/rolesPermissionForm";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
import useDataFctory from "../useDataFactory";
import { useParams } from "react-router-dom";
import { StringsContext } from "../DataFactory/useStrings";

export default function Roles() {
  let { roleType } = useParams();
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isEmpty, setIsEmpty] = useState(true);
  const [selectedRole, setSelectedRole] = useState({});
  const [roleVisible, setRoleVisible] = useState(false);
  const [rolePerVisible, setRolePerVisible] = useState(false);
  const {
    loading,
    data: rolesData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("roles", false, { guard_name: roleType });

  useEffect(() => {
    refreshData({ guard_name: roleType });
  }, [roleType]);

  function deleteRole(selectedId) {
    ApiService({ method: "DELETE", route: "roles/" + selectedId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = rolesData.findIndex((p) => p.id == selectedId);
          if (selectedIndex > -1) {
            rolesData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>
        {strings.w_roles}
        <GButton
          variant="condensed"
          children={strings.w_add}
          onClick={() => {
            setSelectedRole({});
            setIsEmpty(true);
            setRoleVisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      </h2>
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : !rolesData || rolesData?.length === 0 ? (
          <Placeholder />
        ) : (
          <div>
            {rolesData &&
              rolesData.map((d) => {
                return (
                  <GListCard
                    setting={strings.w_role}
                    title={d.name}
                    listActions={true}
                    key={d.id}
                    id={d.id}
                    edit={true}
                    delete={true}
                    onEdit={(selectedRecord) => {
                      setSelectedRole(selectedRecord);
                      setIsEmpty(false);
                      setRoleVisible(true);
                    }}
                    onDelete={deleteRole}
                    onEditPermissions={(selectedRecord) => {
                      setSelectedRole(selectedRecord);
                      setRolePerVisible(true);
                    }}
                    data={d}
                  ></GListCard>
                );
              })}
          </div>
        )}
      </div>

      <Modal
        isOpen={roleVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setRoleVisible(false)}
        style={{
          content: {
            width: "380px",
          },
        }}
        contentLabel="Role Form Modal"
      >
        <RolesForm
          roleVisible={roleVisible}
          setRoleVisible={setRoleVisible}
          data={selectedRole}
          type={roleType}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = rolesData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              rolesData[selectedIndex] = { ...data };
            } else {
              rolesData.push(data);
            }
          }}
        />
      </Modal>

      <Modal
        isOpen={rolePerVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setRolePerVisible(false)}
        style={{
          content: {
            width: "380px",
          },
        }}
        contentLabel="Permissions Form Modal"
      >
        <RolePermissionForm
          rolePerVisible={rolePerVisible}
          setRolePerVisible={setRolePerVisible}
          roleId={selectedRole.id}
          onSubmit={(response) => {
            refreshData({ guard_name: roleType });
          }}
        />
      </Modal>
    </div>
  );
}
