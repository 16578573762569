import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import { Card } from "../styledComponents/cardStyles";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import { Link } from "react-router-dom";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function ReportsList({ match }) {
  const [strings] = useContext(StringsContext);
  const [reportsData, setReportData] = React.useState([]);

  function getReports() {
    ApiService({ method: "GET", route: "reports" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setReportData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getReports();
  }, []);

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_reports}
        style={{ marginBottom: "20px" }}
      />

      <div className="listPaper" style={{ padding: "30px" }}>
        <Grid container spacing={3}>
          {reportsData
            // .filter((r) => r.type === typeValue)
            .map((c) => {
              return (
                <Grid item lg={4} xs={6} key={c.key}>
                  <Card
                    style={{
                      minHeight: "160px",
                      padding: "18px",
                      border: "1px solid #f2f2f2",
                      marginBottom: "10px",
                    }}
                  >
                    <GText semi text={c.title} style={{ fontSize: "18px" }} />
                    <GText
                      med
                      text={c.desc}
                      style={{
                        fontSize: "15.5px",
                        height: "80px",
                        overflow: "overlay",
                      }}
                    />
                    <Link
                      to={{
                        pathname: "/report/details/" + c.key,
                        state: "report",
                      }}
                    >
                      <GButton
                        variant="linkable"
                        children={strings.w_generate}
                        style={{
                          float: "right",
                          padding: "12px 3px",
                          fontSize: "15px",
                        }}
                      />
                    </Link>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
}
