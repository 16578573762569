import React, { useState, useEffect, useContext } from "react";
import orderIcon from "../assets/images/sidebar/orders.svg";
import customerIcon from "../assets/images/sidebar/customers.svg";
import driverIcon from "../assets/images/sidebar/drivers.svg";
import mapIcon from "../assets/images/sidebar/map.svg";
import storeIcon from "../assets/images/sidebar/stores.svg";
import productIcon from "../assets/images/sidebar/products.svg";
import couponIcon from "../assets/images/sidebar/promotions.svg";
import withdrawalIcon from "../assets/images/sidebar/withdrawals.svg";
import transactionIcon from "../assets/images/sidebar/transactions.svg";
import reportIcon from "../assets/images/sidebar/reports.svg";
import notificationIcon from "../assets/images/sidebar/notifications.svg";
import { getUserType, isFeatureEnabled, isPermissionExist } from "../services";
import { StringsContext } from "../DataFactory/useStrings";
import { Link, useLocation } from "react-router-dom";
import { Add, SubdirectoryArrowRight } from "@material-ui/icons";

export default function SidebarRoutes(props) {
  const location = useLocation();
  const [strings] = useContext(StringsContext);
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [isActive, setIsActive] = useState(false);
  const menus = [
    {
      icon: orderIcon,
      title: strings.w_orders,
      path: "/orders",
      state: "order",
      isLink: true,
      isStaff: "order_index",
      children: [
        {
          icon: "📋",
          title: strings.w_support,
          path: "/order-chat-support",
          state: "order",
          isStaff: "order_chat",
          isLink: true,
        },
      ],
    },
    {
      icon: customerIcon,
      title: strings.w_customers,
      path: "/customers",
      state: "customer",
      isLink: true,
      isAdd: "customer_store",
      isStaff: "customer_index",
    },
    {
      icon: driverIcon,
      title: strings.w_drivers,
      path: "/drivers",
      state: "driver",
      isLink: true,
      isAdd: "agent_store",
      isStaff: "agent_index",
      children: [
        {
          icon: "📋",
          title: strings.w_reviews,
          path: "/reviews",
          state: "driver",
          isLink: true,
          isStaff: "agent_reviews",
        },
      ],
    },
    getUserType() && {
      icon: mapIcon,
      title: strings.w_map_view,
      path: "/map-view",
      state: "map",
      isLink: true,
      isAdd: false,
      isStaff: "agent_index",
    },
    {
      icon: storeIcon,
      title: strings.w_stores,
      path: "/stores",
      state: "store",
      isLink: true,
      isAdd: "store_store",
      isStaff: "store_index",
      children: [
        {
          icon: "📋",
          title: strings.w_reviews,
          path: "/reviews",
          state: "store",
          isLink: true,
          isStaff: "store_reviews",
        },
        getUserType() && isFeatureEnabled("store_search_log")
          ? {
              icon: "📋",
              title: strings.w_search_logs,
              path: "/store-logs",
              state: "store",
              isLink: true,
            }
          : "",
      ],
    },
    {
      icon: productIcon,
      title: strings.w_products,
      path: "/products",
      state: "product",
      isLink: true,
      isStaff: "product_index",
      children: [
        {
          icon: "📋",
          title: strings.w_categories,
          path: "/categories",
          state: "product",
          isLink: true,
          isStaff: "product_index",
        },
        {
          icon: "📋",
          title: strings.w_variant_types,
          path: "/variant-types",
          state: "product",
          isLink: true,
          isStaff: "variant_type_index",
        },
      ],
    },
    {
      icon: couponIcon,
      title: strings.w_promotions,
      path: "/promotions",
      state: "coupon",
      isLink: true,
      isAdd: "coupon_store",
      isStaff: "coupon_index",
      children: [
        {
          icon: "📋",
          title: strings.w_bulk_notifications,
          path: "/bulk-notifications/push",
          state: "coupon",
          isLink: true,
          isStaff: "promotion_bulk",
        },
        {
          icon: "📋",
          title: strings.w_automated_promotions,
          path: "/automated-promotions",
          state: "coupon",
          isLink: true,
        },
        {
          icon: "📋",
          title: strings.w_promotion_campaign,
          path: "/promotion-campaign/email",
          state: "coupon",
          isLink: true,
        },
        isFeatureEnabled("newsletter") && {
          icon: "📋",
          title: strings.w_email_newsletter,
          path: "/email-newsletter",
          state: "coupon",
          isLink: true,
          isStaff: "newsletter",
        },
      ],
    },
    {
      icon: withdrawalIcon,
      title: strings.w_withdrawals,
      state: "withdrawal",
      isLink: false,
      path: "",
      children: [
        {
          icon: "📋",
          title: strings.w_stores,
          path: "/withdrawals/store",
          state: "withdrawal",
          isLink: true,
        },
        {
          icon: "📋",
          title: strings.w_drivers,
          path: "/withdrawals/driver",
          state: "withdrawal",
          isLink: true,
        },
      ],
    },
    {
      icon: transactionIcon,
      title: strings.w_transactions,
      path: "/transactions",
      state: "transaction",
      isLink: true,
      children: [
        {
          icon: "📋",
          title: strings.w_failed_payments,
          path: "/failed-payments/pending",
          state: "transaction",
          isLink: true,
        },
      ],
    },
    {
      icon: reportIcon,
      title: strings.w_reports,
      path: "/reports",
      state: "report",
      isLink: true,
    },
  ];

  useEffect(() => {
    // console.log(location);
    let activeKey = "";
    const found = menus.some((el) => el.path === location.pathname);
    console.log(found);
    if (!found) {
      setIsMenuOpen({});
      setIsActive(false);
    }

    menus.map((s) => {
      s.children &&
        s.children.map((el) => {
          if (s.path === location.pathname && s.state === location.state) {
            setIsMenuOpen(s);
          }
          if (el.path === location.pathname && s.state === location.state) {
            activeKey = s;
            setIsMenuOpen(s);
          }
          if (s.state === location.state) {
            activeKey = s;
            setIsMenuOpen(s);
          }
        });
    });
  }, [location]);

  const handleToggle = (data) => {
    setIsMenuOpen(data);
    setIsActive(true);
  };

  function isPermissionExist(per) {
    let exist = props.settings?.permissions?.includes(per);
    console.log(exist);
    return exist;
  }

  function getUserType() {
    let uType =
      props.settings?.user?.user_type === "sa"
        ? true
        : props.settings?.user?.user_type === "admin"
        ? true
        : false;
    return uType;
  }

  const renderMenuItem = (menu, i) => {
    const { title, path, state, icon, children, isLink, isAdd } = menu;

    return (
      <React.Fragment key={i}>
        <li
          className={
            !state
              ? "pListItem"
              : state === location.state
              ? "pListItem active"
              : "pListItem"
          }
          onClick={() => {
            handleToggle(menu, i);
          }}
          href={"#" + menu.path}
        >
          {isLink === true ? (
            <Link className="pLink" to={{ pathname: path, state: state }}>
              <span className="pListIcon">
                <img src={icon} alt={title} />
              </span>{" "}
              {title}
            </Link>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "2px",
              }}
            >
              <span className="pListIcon">
                {" "}
                <img src={icon} alt={title} />
              </span>
              <span style={{ fontWeight: "550", letterSpacing: "0px" }}>
                {" "}
                &nbsp;{title}
              </span>
            </div>
          )}
          {isAdd &&
          (getUserType() == true ? true : isPermissionExist(isAdd)) === true ? (
            <span>
              {
                <Add
                  className="addIcon"
                  onClick={() => props.handleClick(state)}
                />
              }
            </span>
          ) : (
            ""
          )}
        </li>

        {children &&
        children
          .filter((t) => t.title)
          .filter((m) => getUserType() || isPermissionExist(m.isStaff) == true)
          .length > 0 ? (
          <>{renderSubMenuItem(menu)}</>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  const renderSubMenuItem = (menu) => {
    const { children } = menu;
    return (
      <ul
        key={menu.path}
        className={`cList ${
          isMenuOpen.state === menu?.state
            ? menu.path
              ? "collapse-in"
              : "collapse"
            : ""
        }`}
        id={menu.path}
      >
        {children &&
          children
            .filter((t) => t.title)
            .filter(
              (m) => getUserType() || isPermissionExist(m.isStaff) == true
            )
            .map((child, i) => {
              return (
                <li
                  className={
                    child.path === location.pathname &&
                    child.state == location.state
                      ? "cListItem active"
                      : "cListItem"
                  }
                  key={i}
                >
                  <Link
                    className="cLink"
                    to={{ pathname: child.path, state: child.state }}
                  >
                    <span className="cListIcon">
                      <SubdirectoryArrowRight />
                    </span>
                    {child.title}
                  </Link>
                </li>
              );
            })}
      </ul>
    );
  };

  return (
    <React.Fragment>
      {getUserType() ? (
        <>
          {menus
            .filter((f) => f.title)
            .map((menu, i) => renderMenuItem(menu, i))}
        </>
      ) : (
        <>
          {menus
            .filter((m) => isPermissionExist(m.isStaff) === true)
            .map((menu, i) => renderMenuItem(menu, i))}
        </>
      )}
    </React.Fragment>
  );
}
