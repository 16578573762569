import React, { useEffect, useState, useContext } from "react";
import { ApiService } from "../services";
import { Add, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import { StringsContext } from "../DataFactory/useStrings";

const fieldStyle = {
  border: "0px",
  borderBottom: "1px solid #d2d2d2",
  background: "transparent",
};
export default function ProductVariantForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [selectedProductVar, setSelectedProductVar] = useState({
    data: {},
    options: [],
  });

  const validationSchema = Yup.object({});

  useEffect(() => {
    // console.log(JSON.stringify(props.selectedVariantType));
    setSelectedProductVar({
      data: props.selectedVariantType.data,
      options: props.selectedVariantType.options,
    });
  }, [props]);

  const initialValues = {
    options: props.selectedVariantType.options,
  };

  const handleUpdateVariant = (values, ind) => {
    //return;
    ApiService({
      method: "PUT",
      route: "product/variants/" + values.options[ind].id,
      body: values.options[ind],
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        //setProductImgaes([...productImages, response.data]);
      } else {
        setSelectedProductVar({
          data: props.selectedVariantType.data,
          options: props.selectedVariantType.options,
        });
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  return (
    <div style={props.style}>
      <Formik
        initialValues={initialValues}
        //onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form>
            <FieldArray name="options">
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { options } = values;
                const handleAddVariant = (ind) => {
                  setIsBtnDisabled(true);
                  values.options[ind].product_id = props.productId;
                  values.options[ind].product_variant_type_id =
                    selectedProductVar.data.id;
                  ApiService({
                    method: "POST",
                    route: "product/variants",
                    body: values.options[ind],
                  }).then((response) => {
                    console.log("r" + response.data.status_code);
                    if (response.data.status_code === 1) {
                      setFieldValue(`options[${ind}]`, response.data.data);
                      props.onVariantAdd(response.data.data);
                    } else {
                      setSelectedProductVar({
                        data: props.selectedVariantType.data,
                        options: props.selectedVariantType.options,
                      });
                    }
                    setIsBtnDisabled(false);
                    openSnackbar(response.data.message);
                    return;
                  });
                };
                const handleDeleteVariant = (ind) => {
                  ApiService({
                    method: "DELETE",
                    route: "product/variants/" + values.options[ind].id,
                  }).then((response) => {
                    console.log("r" + response.data.status_code);
                    if (response.data.status_code === 1) {
                      props.onVariantRemove(ind);
                      // remove(ind);
                      //setProductImgaes([...productImages, response.data]);
                    }
                    openSnackbar(response.data.message);
                    return;
                  });
                };

                return (
                  <div>
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        padding: "0px 0 0 20px",
                        boxSizing: "border-box",
                      }}
                      className="arrayFields"
                    >
                      <label
                        style={{
                          width: props.isVarStockShow != "1" ? "37%" : "30%",
                        }}
                      >
                        {strings.w_title}
                      </label>
                      {props.selectedVariantType.data?.price_type == "0" ? (
                        ""
                      ) : (
                        <label
                          style={{
                            width: props.isVarStockShow != "1" ? "25%" : "20%",
                          }}
                        >
                          {strings.w_price}
                        </label>
                      )}
                      <label
                        style={{
                          width: props.isVarStockShow != "1" ? "25%" : "15%",
                        }}
                      >
                        {strings.w_status}
                      </label>
                      {props.isVarStockShow == "1" ? (
                        <label style={{ width: "40%" }}>
                          {strings.w_unlimited}
                        </label>
                      ) : (
                        ""
                      )}
                      <GButton
                        variant="linkable"
                        onClick={(e) => {
                          push({
                            title: "",
                            price:
                              props.selectedVariantType.data?.price_type == "0"
                                ? 0
                                : "",
                            status: "0",
                            unlimited: 1,
                            stock: "0",
                          });
                        }}
                      >
                        <Add />
                      </GButton>
                    </div>
                    {options.map((o, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            padding: "0px 0 0 15px",
                            boxSizing: "border-box",
                          }}
                          className="arrayFields"
                        >
                          <Field
                            type="text"
                            placeholder=""
                            name={`options[${index}].title`}
                            style={Object.assign({}, fieldStyle, {
                              width: "35%",
                            })}
                          />
                          {props.selectedVariantType.data?.price_type == "0" ? (
                            ""
                          ) : (
                            <Field
                              type="text"
                              placeholder=""
                              name={`options[${index}].price`}
                              style={Object.assign({}, fieldStyle, {
                                width: "20%",
                              })}
                            />
                          )}

                          <FormikControl
                            control="toggle"
                            isToggled={o.status === "1" ? 1 : 0}
                            name={`options[${index}].status`}
                            onToggle={() => {
                              let statusVal = o.status === "1" ? true : false;
                              statusVal = !statusVal;
                              statusVal = statusVal === true ? "1" : "0";
                              setFieldValue(
                                `options[${index}].status`,
                                statusVal
                              );
                            }}
                            style={{ width: "15%" }}
                          />

                          {props.isVarStockShow == "1" ? (
                            <div style={{ width: "40%" }}>
                              <FormikControl
                                control="toggle"
                                isToggled={
                                  o.unlimited === "1" ||
                                  o.unlimited === 1 ||
                                  o.unlimited === true
                                    ? 1
                                    : 0
                                }
                                name={`options[${index}].unlimited`}
                                onToggle={() => {
                                  let unlimitedVal =
                                    o.unlimited === "1" ? true : false;
                                  unlimitedVal = !unlimitedVal;
                                  unlimitedVal =
                                    unlimitedVal === true ? "1" : "0";
                                  setFieldValue(
                                    `options[${index}].unlimited`,
                                    unlimitedVal
                                  );
                                  console.log(!o.unlimited);
                                }}
                                style={{ width: "35%", padding: "4px 5px" }}
                              />

                              {props.isVarStockShow === "1" &&
                              !(o.unlimited === "1" || o.unlimited === 1) ? (
                                <Field
                                  type="text"
                                  placeholder=""
                                  name={`options[${index}].stock`}
                                  style={Object.assign({}, fieldStyle, {
                                    width: "40%",
                                    marginLeft: "10px",
                                  })}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {!options[index].id ? (
                            <GButton
                              disabled={isBtnDisabled}
                              variant="linkable"
                              onClick={(e) => {
                                handleAddVariant(index);
                              }}
                              style={{ width: "150px" }}
                            >
                              {strings.w_add}
                            </GButton>
                          ) : (
                            <span style={{ display: "inline-flex" }}>
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  handleUpdateVariant(values, index);
                                }}
                              >
                                {strings.w_update}
                              </GButton>
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  handleDeleteVariant(index);
                                }}
                              >
                                <Delete />
                              </GButton>
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
}
