import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import { Check } from "@material-ui/icons";

const radioLabelStyle = {
  display: "flex",
  alignItems: "center",
  padding: "0px 8px",
  color: "rgb(0, 0, 0)",
  justifyContent: "center",
  margin: 0,
  padding: 0,
  height: "28px",
  cursor: "pointer",
  fontSize: "14px",
};
function radioButton(props) {
  const {
    label,
    name,
    options,
    selected,
    key_title,
    style,
    icons,
    key_value,
    mainDivStyle,
    ...rest
  } = props;
  return (
    <div className="form-control" style={mainDivStyle}>
      {label ? (
        <label
          style={{
            fontSize: "0.98rem",
            color: "#000",
            marginBottom: "5px",
            fontWeight: "500",
          }}
        >
          {label}
        </label>
      ) : (
        ""
      )}
      <div style={Object.assign({ display: "flex" }, props.divStyle)}>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "inline-block",
                    padding: "5px 7px",
                    border: "1px solid #e2e2e2",
                    cursor: "pointer",
                    background:
                      field.value == option[key_value]
                        ? "#e5e5e5"
                        : "transparent",
                    color: field.value == option[key_value] ? "#fff" : "#000",
                    width: 100 / options.length + "%",
                    boxSizing: "border-box",
                    borderTopLeftRadius: index == 0 ? "20px" : "0",
                    borderBottomLeftRadius: index == 0 ? "20px" : "0",
                    borderTopRightRadius:
                      options.length == index + 1 ? "20px" : "0",
                    borderBottomRightRadius:
                      options.length == index + 1 ? "20px" : "0",
                  }}
                >
                  <input
                    type="radio"
                    id={name + "_option_" + option[key_value]}
                    {...field}
                    value={option[key_value]}
                    checked={field.value == option[key_value]}
                    style={{ display: "none" }}
                    onChange={() => props.onChange(option[key_value])}
                  />
                  <label
                    style={Object.assign(
                      {
                        fontWeight:
                          field.value == option[key_value] ? "500" : "400",
                      },
                      radioLabelStyle,
                      props.labelStyle
                    )}
                    htmlFor={name + "_option_" + option[key_value]}
                  >
                    {field.value == option[key_value] ? (
                      <Check
                        style={{ width: 20, height: 18, marginRight: 2 }}
                      />
                    ) : (
                      ""
                    )}
                    {option[key_title]}
                  </label>
                </div>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default radioButton;
