import React, { useState, useEffect, useContext } from "react";
import { Grid, makeStyles, TableContainer } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { Dashboard } from "@material-ui/icons";
import "./dashboard.css";
import {
  ApiService,
  isPermissionExist,
  getUserType,
  ImgUrl,
  addDefaultLogo,
} from "../services";
import { Card } from "../styledComponents/cardStyles";
import GDashboardCounts from "../gComponents/gDashboardCounts";
import GDahboardInfo from "../gComponents/gDahboardInfo";
import GCharts from "../gComponents/gCharts";
import GEmpty from "../gComponents/gEmpty";
import GText from "../gComponents/gText";
import { Link } from "react-router-dom";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

const paperStyle = {
  height: "420px",
  overflow: "auto",
  border: "1px solid #e8e8e8",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function DashboardData() {
  const classes = useStyles();
  const [strings, language] = useContext(StringsContext);
  const [dashboardData, setDashboardData] = useState({
    recent_customers: [],
    top_customers: [],
    recent_orders: [],
    graph_orders: [],
    graph_transactions: [],
    graph_deliveries: [],
    most_ordered_stores: [],
    least_ordered_stores: [],
    most_selling_products: [],
    least_selling_products: [],
    most_selling_classes: [],
    least_selling_classes: [],
  });
  const settings = useSettings(true);

  const columns = [
    { id: "id", label: strings.w_id },
    { id: "status", label: strings.w_status, minWidth: 40 },
    { id: "customer", label: strings.w_customer, minWidth: 80 },
    { id: "total", label: strings.w_total, minWidth: 40 },
    { id: "created_at_formatted", label: strings.w_created, minWidth: 110 },
  ];

  const itemColumns = [
    { id: "title", label: strings.w_title, minWidth: 70 },
    { id: "photo", label: strings.w_photo, minWidth: 50 },
    { id: "category", label: strings.w_category, minWidth: 60 },
    {
      id: "ranking_order",
      label: strings.w_count,
      minWidth: 70,
      align: "center",
    },
  ];

  useEffect(() => {
    ApiService({ method: "GET", route: "dashboard" })
      .then((response) => {
        console.log(response.data);
        setDashboardData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [language]);

  return (
    <div id="dashboard" className="mainInnerView">
      {getUserType() ||
      isPermissionExist("customer_index") ||
      isPermissionExist("store_index") ||
      isPermissionExist("order_index") ||
      isPermissionExist("coupon_index") ? (
        <>
          <h1>
            <Dashboard /> {strings.w_dashboard}
          </h1>
          <div style={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              {(getUserType() || isPermissionExist("customer_index")) &&
              dashboardData.customers ? (
                <GDashboardCounts
                  title={strings.w_total_users}
                  subtitle={strings.w_total_registered_users}
                  total_count={dashboardData.customers}
                  type="user"
                />
              ) : (
                ""
              )}
              {(getUserType() || isPermissionExist("order_index")) &&
              dashboardData.orders ? (
                <GDashboardCounts
                  title={strings.w_total_orders}
                  subtitle={strings.w_total_active_orders}
                  total_count={dashboardData.orders}
                  type="order"
                />
              ) : (
                ""
              )}
              {(getUserType() || isPermissionExist("store_index")) &&
              dashboardData.stores ? (
                <GDashboardCounts
                  title={strings.w_total_stores}
                  subtitle={strings.w_total_active_stores}
                  total_count={dashboardData?.stores}
                  type="store"
                />
              ) : (
                ""
              )}
              {(getUserType() || isPermissionExist("coupon_index")) &&
              dashboardData.coupons ? (
                <GDashboardCounts
                  title={strings.w_total_offers}
                  subtitle={strings.w_total_active_offers}
                  total_count={dashboardData?.coupons}
                  type="offer"
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid container spacing={2}>
              {getUserType() || isPermissionExist("order_index") ? (
                <>
                  <Grid item lg={6} xs={12}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_orders}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      <GText
                        light
                        text={strings.s_total_orders_for_this_year}
                        style={{ padding: "0 0 10px 8px" }}
                      />
                      <GCharts
                        labels={
                          dashboardData?.graph_orders &&
                          dashboardData?.graph_orders.map((l) => {
                            return l.label;
                          })
                        }
                        values={
                          dashboardData?.graph_orders &&
                          dashboardData?.graph_orders.map((l) => {
                            return l.count;
                          })
                        }
                        height="320"
                        color="#46ca7c"
                        type="areaspline"
                        name="Order"
                      />
                    </Card>
                  </Grid>{" "}
                  <Grid item lg={6} xs={12}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_revenue}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {/* <h1
                style={{
                  fontWeight: "500",
                  margin: "-7px 0 15px",
                  padding: "0 8px",
                  fontSize: "1.8rem",
                  color: "#777",
                }}
              >
                {transactionsData.total_amount}
              </h1> */}
                      <GText
                        light
                        text={strings.s_including_all_tranactions}
                        style={{ padding: "0 0 10px 8px" }}
                      />
                      <GCharts
                        labels={
                          dashboardData?.graph_transactions &&
                          dashboardData?.graph_transactions.map((l) => {
                            return l.label;
                          })
                        }
                        values={
                          dashboardData?.graph_transactions &&
                          dashboardData?.graph_transactions.map((l) => {
                            return l.count;
                          })
                        }
                        height="290"
                        color="#e56d87"
                        type="line"
                        name="Transaction"
                      />
                    </Card>
                  </Grid>{" "}
                  <Grid item lg={6} xs={12}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_recent_orders}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableHeader
                                  key={column.id}
                                  style={{
                                    minWidth: column.minWidth,
                                    textAlign: column.align,
                                  }}
                                >
                                  {column.label}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          {dashboardData?.recent_orders?.length !== 0 && (
                            <TableBody>
                              {dashboardData?.recent_orders &&
                                dashboardData?.recent_orders.map((o) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={o.id}
                                      className="bordered"
                                    >
                                      <TableRowData
                                        style={{
                                          BorderBottom: "1px solid #f4f5f9",
                                        }}
                                      >
                                        <Link
                                          to={{
                                            pathname: "/order/" + o.id,
                                            state: "order",
                                          }}
                                        >
                                          #{o.id}
                                        </Link>
                                      </TableRowData>
                                      <TableRowData>
                                        <span
                                          className="orderStatus"
                                          style={{
                                            color: o.status.color1
                                              ? "#" + o.status.color1
                                              : "#333",
                                            background: o.status.color2
                                              ? "#" + o.status.color2
                                              : "transparent",
                                            padding: "5px 11px 2px",
                                            fontSize: "0.9em",
                                            fontWeight: "550",
                                          }}
                                        >
                                          {o.status.title}
                                        </span>
                                      </TableRowData>
                                      <TableRowData>
                                        {o.customer.title}
                                      </TableRowData>
                                      <TableRowData>
                                        {settings.currency_symbol}
                                        {o.amount.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                        })}
                                      </TableRowData>
                                      <TableRowData className="created">
                                        {o.created}
                                      </TableRowData>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {dashboardData?.recent_orders.length === 0 ? (
                        <GEmpty />
                      ) : (
                        ""
                      )}
                    </Card>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {getUserType() || isPermissionExist("customer_index") ? (
                <>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_new_users}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData?.recent_customers &&
                      dashboardData.recent_customers.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData?.recent_customers.map((user, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={user.id}
                              title={user.title}
                              subtitle={user.email}
                              photo={user.thumb_photo}
                              imgType="user"
                              rightTopContent={user.created}
                              rightBottomContent=""
                              className={i % 2 ? "even" : "odd"}
                              rightTopStyle={{
                                color: "#b8b8b8",
                                fontWeight: "400",
                              }}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_top_users}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData.top_customers &&
                      dashboardData.top_customers.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData.top_customers.map((user, i) => (
                          <div
                            key={user.id}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={user.id}
                              title={user.title}
                              subtitle={user.email}
                              photo={user.thumb_photo}
                              imgType="user"
                              rightTopContent={
                                user.count + "  " + strings.w_orders
                              }
                              rightBottomContent={user.created}
                              rightTopStyle={{ color: "#333" }}
                              className={i % 2 ? "even" : "odd"}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {getUserType() || isPermissionExist("order_index") ? (
                <Grid item xs={12} lg={6}>
                  <Card style={paperStyle}>
                    <GText
                      g2
                      bold
                      text={strings.w_tasks}
                      style={{ color: "#333", paddingLeft: "8px" }}
                    />
                    <GText
                      light
                      text={strings.s_total_tasks_of_last_12_months}
                      style={{ padding: "0 0 10px 8px" }}
                    />
                    <GCharts
                      labels={dashboardData?.graph_deliveries.map((l) => {
                        return l.label;
                      })}
                      values={dashboardData?.graph_deliveries.map((l) => {
                        return l.count;
                      })}
                      height="320"
                      color="#3994ff"
                      type="line"
                      name="Task"
                    />
                  </Card>
                </Grid>
              ) : (
                ""
              )}

              {getUserType() || isPermissionExist("store_index") ? (
                <>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_most_selling_stores}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData?.most_ordered_stores &&
                      dashboardData.most_ordered_stores.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData?.most_ordered_stores.map((store, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={store.id}
                              title={store.title}
                              subtitle={store.address}
                              photo={store.thumb_photo}
                              imgType="store"
                              rightTopContent={
                                store.ordered + " " + strings.w_orders
                              }
                              rightBottomContent=""
                              className={i % 2 ? "even" : "odd"}
                              rightTopStyle={{
                                color: "#b8b8b8",
                                fontWeight: "400",
                              }}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_least_selling_stores}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData.least_ordered_stores &&
                      dashboardData.least_ordered_stores.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData.least_ordered_stores.map((store, i) => (
                          <div
                            key={store.id}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={store.id}
                              title={store.title}
                              subtitle={store.address}
                              photo={store.thumb_photo}
                              imgType="store"
                              rightTopContent={
                                store.ordered + "  " + strings.w_orders
                              }
                              rightTopStyle={{
                                color: "#b8b8b8",
                                fontWeight: "400",
                              }}
                              className={i % 2 ? "even" : "odd"}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                </>
              ) : (
                ""
              )}

              {getUserType() || isPermissionExist("product_index") ? (
                <>
                  <Grid item xs={12} lg={6}>
                    <Card
                      style={{
                        border: "1px solid #e8e8e8",
                      }}
                    >
                      <GText
                        g2
                        bold
                        text={strings.w_best_selling_products}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      <TableContainer
                        style={{ height: "380px", overflow: "auto" }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {itemColumns.map((column) => (
                                <TableHeader
                                  key={column.id}
                                  style={{
                                    minWidth: column.minWidth,
                                    textAlign: column.align,
                                  }}
                                >
                                  {column.label}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          {dashboardData?.most_selling_products &&
                          dashboardData.most_selling_products.length > 0 ? (
                            <TableBody>
                              {dashboardData?.most_selling_products.map((o) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={o.id}
                                    className="bordered"
                                  >
                                    <TableRowData>
                                      {o.title}
                                      {getUserType() ||
                                      isPermissionExist("store_index") ? (
                                        <>
                                          <br />
                                          <Link
                                            to={{
                                              pathname:
                                                "/store/" +
                                                o.store_id +
                                                "/orders",
                                              state: "store",
                                            }}
                                            target="_blank"
                                            style={{
                                              textDecoration: "underline",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {strings.w_view_store}
                                          </Link>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </TableRowData>
                                    <TableRowData>
                                      <img
                                        alt={o.title}
                                        src={
                                          ImgUrl("item") + "/" + o.thumb_photo
                                        }
                                        onError={addDefaultLogo}
                                        style={{
                                          height: "45px",
                                          width: "45px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </TableRowData>
                                    <TableRowData>
                                      {o.categories_title}
                                    </TableRowData>
                                    <TableRowData
                                      style={{ textAlign: "center" }}
                                    >
                                      {o.ranking_order}
                                    </TableRowData>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          ) : (
                            // dashboardData?.most_selling_products.map((item, i) => (
                            //   <div
                            //     key={i}
                            //     style={{
                            //       borderBottom: "1px solid rgb(226, 226, 226)",
                            //     }}
                            //   >
                            //     <GDahboardInfo
                            //       id={item.id}
                            //       title={item.title}
                            //       subtitle={item.price}
                            //       photo={item.thumb_photo}
                            //       imgType="item"
                            //       rightTopContent={""}
                            //       rightBottomContent=""
                            //       className={i % 2 ? "even" : "odd"}
                            //       rightTopStyle={{
                            //         color: "#b8b8b8",
                            //         fontWeight: "400",
                            //       }}
                            //     />
                            //   </div>
                            //)}
                            <GEmpty />
                          )}
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card
                      style={{
                        border: "1px solid #e8e8e8",
                      }}
                    >
                      <GText
                        g2
                        bold
                        text={strings.w_least_Selling_products}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      <TableContainer
                        style={{ height: "380px", overflow: "auto" }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {itemColumns.map((column) => (
                                <TableHeader
                                  key={column.id}
                                  style={{
                                    minWidth: column.minWidth,
                                    textAlign: column.align,
                                  }}
                                >
                                  {column.label}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          {dashboardData?.least_selling_products &&
                          dashboardData.least_selling_products.length > 0 ? (
                            <TableBody>
                              {dashboardData?.least_selling_products.map(
                                (o) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={o.id}
                                      className="bordered"
                                    >
                                      <TableRowData>
                                        {o.title}
                                        {getUserType() ||
                                        isPermissionExist("store_index") ? (
                                          <>
                                            <br />
                                            <Link
                                              to={{
                                                pathname:
                                                  "/store/" +
                                                  o.store_id +
                                                  "/orders",
                                                state: "store",
                                              }}
                                              target="_blank"
                                              style={{
                                                textDecoration: "underline",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {strings.w_view_store}
                                            </Link>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </TableRowData>
                                      <TableRowData>
                                        <img
                                          alt={o.title}
                                          src={
                                            ImgUrl("item") + "/" + o.thumb_photo
                                          }
                                          onError={addDefaultLogo}
                                          style={{
                                            height: "45px",
                                            width: "45px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </TableRowData>
                                      <TableRowData>
                                        {o.categories_title}
                                      </TableRowData>
                                      <TableRowData
                                        style={{ textAlign: "center" }}
                                      >
                                        {o.ranking_order}
                                      </TableRowData>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          ) : (
                            <GEmpty />
                          )}
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                </>
              ) : (
                ""
              )}

              {getUserType() ? (
                <>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_most_selling_classes}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData?.most_selling_classes &&
                      dashboardData.most_selling_classes.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData?.most_selling_classes.map((c, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={c.id}
                              title={c.title}
                              subtitle={`Index - ${c.index}`}
                              photo={c.icon}
                              imgType="store-class"
                              rightTopContent={
                                c.ordered + " " + strings.w_orders
                              }
                              rightBottomContent=""
                              className={i % 2 ? "even" : "odd"}
                              rightTopStyle={{
                                color: "#b8b8b8",
                                fontWeight: "400",
                              }}
                              isLink={false}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={strings.w_least_selling_classes}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData.least_selling_classes &&
                      dashboardData.least_selling_classes.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData.least_selling_classes.map((c, i) => (
                          <div
                            key={c.id}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={c.id}
                              title={c.title}
                              subtitle={`Index - ${c.index}`}
                              photo={c.icon}
                              imgType="store-class"
                              rightTopContent={
                                c.ordered + "  " + strings.w_orders
                              }
                              rightTopStyle={{
                                color: "#b8b8b8",
                                fontWeight: "400",
                              }}
                              className={i % 2 ? "even" : "odd"}
                              isLink={false}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </div>
        </>
      ) : (
        <GText
          light
          bold
          g1
          text={strings.s_welcome_to_dashboard}
          style={{
            display: "flex",
            height: "calc(100vh - 150px)",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "42px",
            textTransform: "uppercase",
          }}
        />
      )}
    </div>
  );
}

export default DashboardData;
