import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function StoreGroupForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    index: !props.isEmpty ? props.data?.index : "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "store/groups/" + props.data.id
      : "store/groups";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setGroupVisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    index: Yup.string().required(strings.w_required),
  });

  if (props.groupVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_store_group
          : strings.w_create_store_group}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_index}
                name="index"
              />
              <br />
              <GButton
                variant="condensed"
                disabled={isDisabled}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
