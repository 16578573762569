import React, { useState, useContext } from "react";
import { TableContainer, Avatar } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, ImgUrl } from "../services";
import { Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import StaffForm from "../forms/staffForm";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import AssignRoleForm from "../forms/assignRoleForm";
import { StringsContext } from "../DataFactory/useStrings";

export default function StaffMembers() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStaffData, setSelectedStaffData] = useState({});
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [sFormVisible, setSFormVisible] = useState(false);
  const [assignRoleVisible, setAssignRoleVisible] = useState(false);
  const {
    loading,
    data: staffData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("staff", false);

  const columns = [
    { id: "first_name", label: strings.w_name, minWidth: 130 },
    { id: "photo", label: strings.w_photo, minWidth: 50, type: "image" },
    { id: "email", label: strings.w_email, minWidth: 150 },
    { id: "role", label: strings.w_role, minWidth: 150, align: "center" },
    { id: "created_at_formatted", label: strings.w_created, minWidth: 150 },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "center" },
  ];

  const deleteStaff = () => {
    var hitUrl = "staff/" + selectedStaffId;
    ApiService({ method: "DELETE", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = staffData.findIndex(
            (p) => p.id == selectedStaffId
          );
          if (selectedIndex > -1) {
            staffData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <GButton
        variant="condensed"
        children={strings.w_create_staff}
        onClick={() => {
          setIsEmpty(true);
          setSelectedStaffData({});
          setSFormVisible(true);
        }}
        style={{ float: "right", marginTop: "5px" }}
      />

      <GText
        g2
        bold
        text={strings.w_staff_members}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        {loading === true ? (
          <TableShimmer />
        ) : (
          <TableContainer>
            {staffData && staffData.length === 0 ? (
              <Placeholder />
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.first_name} {c.last_name}
                        </TableRowData>
                        <TableRowData>
                          <Avatar
                            alt={c.first_name}
                            src={ImgUrl("user") + "/" + c.thumb_photo}
                          />
                        </TableRowData>
                        <TableRowData>{c.email}</TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.role?.name || "-"}
                        </TableRowData>
                        <TableRowData>{c.created}</TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setIsEmpty(false);
                              setSelectedStaffData(c);
                              setSFormVisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedStaffId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                          <GButton
                            variant="condensed"
                            onClick={(e) => {
                              setSelectedStaffData(c);
                              setAssignRoleVisible(true);
                            }}
                            style={{ padding: "0.65rem", width: "120px" }}
                          >
                            {c.role?.id
                              ? strings.w_re_assign
                              : strings.w_assign}{" "}
                            {strings.w_role}
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_Staff_title}
          text={strings.s_delete_Staff_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteStaff();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={sFormVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSFormVisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Staff Member Form Modal"
      >
        <StaffForm
          sFormVisible={sFormVisible}
          setSFormVisible={setSFormVisible}
          isEmpty={isEmpty}
          data={selectedStaffData}
          onSubmit={(data) => {
            let selectedIndex = staffData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              staffData[selectedIndex] = { ...data };
            } else {
              staffData.push(data);
            }
          }}
        />
      </Modal>

      <Modal
        isOpen={assignRoleVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAssignRoleVisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Assign Role Form Modal"
      >
        <AssignRoleForm
          assignRoleVisible={assignRoleVisible}
          setAssignRoleVisible={setAssignRoleVisible}
          staffData={selectedStaffData}
          onSubmit={() => refreshData()}
        />
      </Modal>
    </div>
  );
}
