import React, { useState, useContext } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService } from "../services";
import { Add } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import GEmpty from "../gComponents/gEmpty";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import ClassForm from "../forms/storeClassesForm";
import GListCard from "../gComponents/gListCards";
import GActions from "../gComponents/gActionsStyle";
import useDataFactory from "../useDataFactory";
import Modal from "react-modal";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function StoreClasses(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const settings = useSettings();
  const [classFormvisible, setClassFormvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedClassData, setSelectedClassData] = useState({});
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedParentTheme, setSelectedParentTheme] = useState("");

  const {
    loading,
    data: classesData,
    refreshData,
    CardShimmer,
    Placeholder,
  } = useDataFactory("store/classes", false);

  const deleteClass = (id) => {
    var catId = id ? id : selectedClassId;
    ApiService({ method: "DELETE", route: "store/classes/" + catId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          refreshData();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditClass = (data, parentData) => {
    setSelectedClassData(data);
    setIsEmpty(false);
    setSelectedClassId(data.parent_id);
    setSelectedParentTheme(parentData.list_theme);
    setClassFormvisible(true);
  };

  return (
    <div className="mainInnerView" style={{ padding: "20px 20px 0" }}>
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedClassData({});
          setIsEmpty(true);
          setSelectedClassId("");
          setClassFormvisible(true);
        }}
        style={{
          float: "right",
        }}
      />
      <GText
        g1
        bold
        text={strings.w_classes}
        style={{ margin: "0 0 20px", fontSize: "27px" }}
      />
      <div className="listPaper">
        <div
          style={{
            maxHeight: "calc(100vh - 270px)",
            overflowY: "auto",
            padding: "5px",
          }}
        >
          {loading ? (
            <CardShimmer />
          ) : classesData.length == 0 ? (
            <Placeholder />
          ) : (
            classesData.map((c, pi) => {
              return (
                <React.Fragment key={c.id}>
                  <h4 style={{ margin: "10px 0" }}>
                    {c.title} ({c.childs.length} {strings.w_subclasses})
                    <GActions
                      edit={true}
                      delete={true}
                      onEdit={() => {
                        setSelectedClassData(c);
                        setIsEmpty(false);
                        setSelectedClassId("");
                        setClassFormvisible(true);
                      }}
                      onDelete={() => {
                        setSelectedClassId(c.id);
                        setDialogOpen(true);
                      }}
                      style={{ verticalAlign: "middle" }}
                      menuStyle={{ fontWeight: "400" }}
                    />
                    <GButton
                      variant="linkable"
                      onClick={() => {
                        setSelectedClassData({});
                        setIsEmpty(true);
                        setSelectedClassId(c.id);
                        setClassFormvisible(true);
                        setSelectedParentTheme(c.list_theme);
                      }}
                      style={{
                        float: "right",
                      }}
                    >
                      <Add />
                    </GButton>
                  </h4>
                  <hr />

                  {c.childs.length === 0 ? (
                    <GEmpty></GEmpty>
                  ) : (
                    c.childs.map((sub, ind) => {
                      return (
                        <GListCard
                          setting={strings.w_subclass}
                          key={ind}
                          id={sub.id}
                          title={sub.title}
                          subtitle={`Index - ${sub.index}`}
                          switch={false}
                          dropdownActions={true}
                          edit={true}
                          delete={true}
                          onEdit={(data) => handleEditClass(data, c)}
                          onDelete={(id) => {
                            //setSelectedClassId(id);
                            deleteClass(id);
                          }}
                          data={sub}
                          style={{ width: "23%" }}
                          titleStyle={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            height: "22px",
                          }}
                        ></GListCard>
                      );
                    })
                  )}
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_class_title}
          text={strings.s_delete_class_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteClass();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={classFormvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setClassFormvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Class Form Modal"
      >
        <ClassForm
          classId={selectedClassId}
          classTheme={selectedParentTheme}
          classFormvisible={classFormvisible}
          setClassFormvisible={setClassFormvisible}
          data={selectedClassData}
          isEmpty={isEmpty}
          settings={settings}
          onSubmit={() => refreshData()}
        />
      </Modal>
    </div>
  );
}
