import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import earnPoints from "../assets/images/point-earn.png";
import withdrawPoints from "../assets/images/point-not-earn.png";
import GButton from "../gComponents/gButton";
import WalletForm from "../forms/walletPointForm";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import GText from "../gComponents/gText";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { StringsContext } from "../DataFactory/useStrings";

const DateFilterStyles = styled.div`
  .from,
  .to {
    border: 1px solid #e2e2e2;
    background: #fff;
    padding: 5px;
    margin: 5px 0 5px 10px;
    position: relative;
    display: inline-block;
    text-align: left;
    label {
      display: block;
      font-eight: 550;
      padding: 2px;
    }
    .react-datepicker__input-container {
      margin-right: 30px;
    }
    .from .react-datepicker__tab-loop .react-datepicker-popper {
      left: -30px !important;
    }
    .from
      .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle {
      left: 0 !important;
    }
    input {
      border: 0;
      outline: 0;
      box-shadow: none;
      font-weight: 450;
    }
  }
`;

const handleFormatChange = (d) => {
  console.log(d);
  var date = ("0" + new Date(d).getDate()).slice(-2); //Current Date
  var month = ("0" + (new Date(d).getMonth() + 1)).slice(-2); //Current Month
  var year = new Date(d).getFullYear(); //Current Year
  // var hour = ("0" + new Date(d).getHours()).slice(-2); //Current Year
  // var minute = ("0" + new Date(d).getMinutes()).slice(-2); //Current Year
  // var second = ("0" + new Date(d).getSeconds()).slice(-2); //Current Year
  console.log(year + "-" + month + "-" + date);
  return year + "-" + month + "-" + date;
};

export default function WalletPoints(props) {
  const [strings] = useContext(StringsContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const { title, value } = props;
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [currentPage, setCurrentPage] = useState("1");
  const [walletvisible, setWalletvisible] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    from: params.get("from") ? params.get("from") : "",
    to: params.get("to") ? params.get("to") : "",
  });

  const columns = [
    { id: "earn", label: "" },
    { id: "points", label: strings.w_points, minWidth: 60, align: "" },
    { id: "text", label: strings.w_text, minWidth: 130, align: "center" },
    { id: "date", label: strings.w_date, minWidth: 80, align: "center" },
  ];

  let obj = {};
  obj[title] = value;
  obj["page"] = currentPage;
  const {
    loading,
    data: walletData,
    pagination: walletPaginationData,
    Placeholder,
    refreshData,
    TableShimmer,
    extra,
  } = useDataFctory(
    props.type + "/wallet",
    true,
    props.type == "store" ? { ...obj, ...filters } : obj
  );

  useEffect(() => {
    console.log(props);
    if (props.type) {
      if (props.onChange) {
        props.onChange("wallet");
      }
    }
    setSelectedWalletId(props.value);
    if (initialLoading == false && props.value) {
      obj[title] = value;
      obj["page"] = currentPage;
      if (props.type == "store") {
        refreshData({ ...obj, ...filters });
      } else {
        refreshData(obj);
      }
    }
    setInitialLoading(false);
  }, [currentPage, selectedWalletId, filters]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {props.type ? "" : <h1>{strings.w_wallet_points}</h1>}

      {props.type === "customer" && (
        <GButton
          variant="linkable"
          children="Add"
          onClick={() => {
            setWalletvisible(true);
          }}
          style={{
            margin: "-40px 0px 10px",
            float: "right",
          }}
        >
          <Add fontSize="large" />
        </GButton>
      )}

      <div className="listPaper">
        {props.type === "store" && (
          <div style={{ display: "flex" }}>
            <DateFilterStyles>
              <div className="from">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    console.log(update);
                    setDateRange(update);
                    if (update[0] && update[1]) {
                      setFilters({
                        ...filters,
                        from: handleFormatChange(update[0]),
                        to: handleFormatChange(update[1]),
                      });
                    }

                    if (!update[0] && !update[1]) {
                      setFilters({
                        ...filters,
                        from: "",
                        to: "",
                      });
                    }
                  }}
                  isClearable={true}
                  placeholderText={strings.s_select_created_date}
                  dateFormat="dd MMM, yyyy"
                />
              </div>
            </DateFilterStyles>
            <GText
              semi
              med
              text={`${strings.w_available_balance} : ${
                extra?.balance_display || "-"
              }`}
              style={{
                fontStyle: "italic",
                margin: "0 0 0 auto",
                width: "auto",
              }}
            />
          </div>
        )}
        {walletPaginationData?.total != 0 ? (
          <GPagination
            count={walletPaginationData.total}
            from={walletPaginationData.from}
            to={walletPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(walletPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(walletPaginationData.prev_page_url)
            }
            rowsPerPage={walletPaginationData.per_page}
            prevDisabled={walletPaginationData.prev_page_url ? false : true}
            nextDisabled={walletPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : (
          <TableContainer>
            {walletData.length === 0 ? (
              <Placeholder />
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth:
                            walletData.length > 0 && !column.label
                              ? "30px"
                              : column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {walletData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          {c.amount < 0 ? (
                            <img
                              src={withdrawPoints}
                              alt=""
                              style={{ width: "15px" }}
                            />
                          ) : (
                            <img
                              src={earnPoints}
                              alt=""
                              style={{ width: "15px" }}
                            />
                          )}
                        </TableRowData>
                        <TableRowData>{c.amount}</TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {c.title}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {c.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      <Modal
        isOpen={walletvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWalletvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Wallet Point Form Modal"
      >
        <WalletForm
          walletvisible={walletvisible}
          setWalletvisible={setWalletvisible}
          onSubmit={() => {
            refreshData(obj);
          }}
          type={props.type}
          id={selectedWalletId}
        />
      </Modal>
    </div>
  );
}
