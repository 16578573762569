import React, { useState, useEffect, useContext } from "react";
import { makeStyles, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { CheckCircleOutline, Payment } from "@material-ui/icons";
import { useSnackbar } from "react-simple-snackbar";
import { Link, useHistory, Switch, Route, useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GButton from "../gComponents/gButton";
import GEmpty from "../gComponents/gEmpty";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GTableShimmer from "../gComponents/gTableShimmer";
import GPagination from "../gComponents/gPagination";
import useSettings from "../useSettings";
import { CustomerFilter } from "./customerFilterComponent";
import QueryWith from "../Query";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
  container: {
    maxHeight: "calc(100vh - 260px)",
    overflow: "auto",
    padding: "0 20px",
    boxSizing: "border-box",
  },
});

export default function FailedTransactions() {
  const [strings] = useContext(StringsContext);
  const [typeValue, setTypeValue] = useState("pending");

  const payRoutes = [
    {
      title: strings.w_pending,
      identifier: "pending",
      link: "/failed-payments/pending",
      state: "transaction",
    },
    {
      title: strings.w_resolved,
      identifier: "resolved",
      link: "/failed-payments/resolved",
      state: "transaction",
    },
  ];

  return (
    <div className="mainInnerView">
      <h1>
        <Payment /> {strings.w_failed_payments}
      </h1>

      <GTabs routes={payRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper" style={{ padding: "20px 0" }}>
          <Route path="/failed-payments/:type">
            <Child
              onChange={(newValue) => {
                console.log(newValue);
                setTypeValue(newValue);
              }}
            />
          </Route>
        </div>
      </Switch>
    </div>
  );
}

function Child(props) {
  const [strings] = useContext(StringsContext);
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  let { type } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [transactionPaginationData, setTransactionPaginationData] =
    useState("");
  const settings = useSettings(true);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
  });

  const columns = [
    { id: "method", label: strings.w_methodd, minWidth: 100, align: "" },
    { id: "trans_id", label: strings.w_trans_id, minWidth: 100 },
    { id: "customer_id", label: strings.w_customer, minWidth: 120 },
    { id: "amount", label: strings.w_amount, minWidth: 80 },
    { id: "status", label: strings.w_status, minWidth: 50, align: "center" },
    {
      id: "added",
      label: strings.w_added,
      minWidth: 60,
      align: "center",
      type: "resolved",
    },
    {
      id: "resolved",
      label: strings.w_resolved,
      minWidth: 60,
      align: "center",
      type: "pending",
    },
  ];

  function getTransactions() {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/failed-payments/" + type,
        state: "transaction",
        search: params[0],
      });
    }
    var query = { ...filters, status: type };

    ApiService({ method: "GET", route: "gateway/transactions", body: query })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setTransactionData(response.data.data.data);
          setTransactionPaginationData(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    props.onChange(type);
    getTransactions();
  }, [history, filters, type]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const resolvePayment = () => {
    ApiService({
      method: "POST",
      route: "gateway/transaction/resolve/" + selectedTransaction,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getTransactions();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ float: "right", marginTop: "-115px" }}>
        <CustomerFilter
          value={filters.customer_id}
          style={{
            marginRight: "10px",
            marginBottom: "20px",
          }}
          handleCustomerChange={(value) => {
            setFilters({
              customer_id: value || "",
              page: "1",
            });
          }}
        />
        <br />

        {transactionData.length > 0 && (
          <GPagination
            count={transactionPaginationData.total}
            from={transactionPaginationData.from}
            to={transactionPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(transactionPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(transactionPaginationData.prev_page_url)
            }
            rowsPerPage={transactionPaginationData.per_page}
            prevDisabled={
              transactionPaginationData.prev_page_url ? false : true
            }
            nextDisabled={
              transactionPaginationData.next_page_url ? false : true
            }
          ></GPagination>
        )}
      </div>

      {isLoading === true ? (
        <GTableShimmer></GTableShimmer>
      ) : (
        <TableContainer className={classes.container}>
          {transactionData.length === 0 ? (
            <GEmpty></GEmpty>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns
                    .filter((column) => column.type !== type)
                    .map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  {type === "pending" ? (
                    <TableHeader
                      style={{
                        minWidth: "60px",
                        textAlign: "center",
                      }}
                    >
                      {strings.w_actions}
                    </TableHeader>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {c.image && (
                          <img
                            src={c.image}
                            alt=""
                            style={{
                              verticalAlign: "middle",
                              display: "inline-block",
                              width: "40px",
                              padding: "0 7px 0 0",
                            }}
                          />
                        )}
                        {c.gateway_identifier}
                      </TableRowData>
                      <TableRowData>
                        {c.transaction_id ? c.transaction_id : "-"}
                      </TableRowData>
                      <TableRowData>
                        <Link
                          to={{
                            pathname: "/customer/" + c.customer.id + "/orders",
                            state: "customer",
                          }}
                        >
                          {c.customer.title}
                        </Link>
                      </TableRowData>
                      <TableRowData>
                        {settings.currency_symbol}
                        {c.amount}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        <GText
                          text={c.status}
                          style={{
                            textTransform: "uppercase",
                            width: "auto",
                          }}
                        />
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {type === "pending" ? c.created : c.updated}
                      </TableRowData>
                      {type === "pending" && (
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={() => {
                              setSelectedTransaction(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <CheckCircleOutline />
                          </GButton>
                        </TableRowData>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}
      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_resolve_payment}
          text={strings.s_are_you_sure_resolve_payment}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            resolvePayment();
          }}
        ></GConfirmDialog>
      )}
    </>
  );
}
