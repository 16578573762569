import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import { useSnackbar } from "react-simple-snackbar";
import GSwitch from "../gComponents/gSwitch";
import useSettings from "../useSettings";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function AppSettings(props) {
  const [strings, language] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const settings = useSettings(true);
  const [appData, setAppData] = useState({
    i_c_url: {},
    a_c_url: {},
    w_c_url: {},
    skip_selection: {},
    delivery_tip: {},
    signup_name: {},
  });

  useEffect(() => {
    getSocialSettings();
  }, [language]);

  const getSocialSettings = () => {
    ApiService({ method: "GET", route: "setting/app" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setAppData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, field) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          appData[field] = response.data.data;
        } else {
        }
        openSnackbar(response.data.message);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_app_settings}
        style={{ marginBottom: "20px" }}
      />

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "60%",
            minWidth: "540px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={appData.i_c_url.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={appData.i_c_url.key_value}
            className={props.display}
            placeholder={appData.i_c_url.key_display_title}
            id={appData.i_c_url.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "i_c_url")
            }
            style={{ margin: "10px 0 20px" }}
            required={false}
          />

          <GEditable
            key={appData.a_c_url.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={appData.a_c_url.key_value}
            className={props.display}
            placeholder={appData.a_c_url.key_display_title}
            id={appData.a_c_url.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "a_c_url")
            }
            style={{ margin: "10px 0 20px" }}
            required={false}
          />

          {appData?.w_c_url?.id && (
            <GEditable
              key={appData.w_c_url?.id}
              type="text"
              title={true}
              editable={false}
              defaultValue={appData.w_c_url?.key_value}
              className={props.display}
              placeholder={appData.w_c_url?.key_display_title}
              id={appData.w_c_url?.id}
              onSubmit={(newValue, selectedFieldId) =>
                updateField(newValue, selectedFieldId, "w_c_url")
              }
              style={{ margin: "10px 0 20px" }}
              required={false}
            />
          )}

          {appData.signup_name && (
            <div style={{ display: "flex", padding: "15px 0" }}>
              <label>{appData.signup_name.key_display_title}</label>
              <div style={{ width: "calc(100% - 320px)" }}>
                <GSwitch
                  id={appData.signup_name.id}
                  uId={appData.signup_name.id}
                  currentValue={appData.signup_name.key_value}
                  style={{ margin: "0 20px" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "signup_name")
                  }
                />
              </div>
            </div>
          )}

          {appData.skip_selection && settings?.product === "laundry" && (
            <div style={{ display: "flex", padding: "15px 0" }}>
              <label>{appData.skip_selection.key_display_title}</label>
              <div style={{ width: "calc(100% - 320px)" }}>
                <GSwitch
                  id={appData.skip_selection.id}
                  uId={appData.skip_selection.id}
                  currentValue={appData.skip_selection.key_value}
                  style={{ margin: "0 20px" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "skip_selection")
                  }
                />
              </div>
            </div>
          )}

          {appData.delivery_tip && (
            <div style={{ display: "flex", padding: "15px 0" }}>
              <label>{appData.delivery_tip.key_display_title}</label>
              <div style={{ width: "calc(100% - 320px)" }}>
                <GSwitch
                  id={appData.delivery_tip.id}
                  uId={appData.delivery_tip.id}
                  currentValue={appData.delivery_tip.key_value}
                  style={{ margin: "0 20px" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "delivery_tip")
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
