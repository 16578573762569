import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";
import useSettings from "../useSettings";
import ColorPicker from "../gComponents/gColorPicker";
import GText from "../gComponents/gText";
import GInput from "../gComponents/gInput";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function WebsiteSettings(props) {
  const [strings, language] = useContext(StringsContext);
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const [linksData, setLinksData] = useState({
    web_home_header: {},
    web_home_title: {},
    web_home_desc: {},
    web_color: {},
    web_footer_color: {},
    web_logo: {},
    web_favicon: {},
  });

  useEffect(() => {
    getWebSettings();
  }, [language]);

  const getWebSettings = () => {
    ApiService({ method: "GET", route: "setting/web" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, selectedKey) {
    console.log(newValue + selectedFieldId);
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          linksData[selectedKey] = response.data.data;
          setLinksData(linksData);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_website_settings}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <div
          style={{
            width: "40%",
            display: "inline-block",
            verticalAlign: "top",
            padding: "10px 20px",
          }}
        >
          <GText text={strings.w_logo} style={{ margin: "0 0 10px" }} />
          {linksData.web_logo?.id && (
            <>
              <GImageEditor
                image={ImgUrl("web") + "/" + linksData.web_logo.key_value}
                onSubmit={(newVal, fieldId) => {
                  updateField(newVal, fieldId, "web_logo");
                }}
                id={linksData.web_logo.id}
                type="web"
                isPreviewImg={true}
                imgStyle={{
                  width: "100px",
                  height: "auto",
                  maxHeight: "105px",
                  objectFit: "contain",
                  background: "transparent",
                  borderRadius: "0px",
                }}
                divStyle={{
                  display: "inline-flex",
                  alignItems: "center",
                  width: "130px",
                  background: "#f4f5f9",
                  padding: "15px",
                  height: "130px",
                }}
                IconStyle={{ right: "-10px", bottom: "-4px" }}
              />
            </>
          )}
        </div>
        <div
          style={{
            width: "40%",
            display: "inline-block",
            verticalAlign: "top",
            padding: "10px 20px",
          }}
        >
          <GText text={strings.w_favicon} style={{ margin: "0 0 10px" }} />
          <div
            style={{
              background: "#f4f5f9",
              padding: "8px 12px",
              display: "inline-block",
            }}
          >
            {linksData.web_favicon?.id && (
              <GImagePicker
                type="web"
                alt={settings?.project_name}
                height={100}
                width={100}
                radius={1}
                editor
                editorProps={{
                  height: 200,
                  width: 200,
                  radius: 100,
                }}
                serverProps={{
                  size: 200,
                }}
                onSuccess={(v) => {
                  console.log(v);
                  updateField(v.image, linksData.web_favicon.id, "web_favicon");
                }}
                isPreviewImg={true}
                default={ImgUrl("web") + "/" + linksData.web_favicon.key_value}
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: "70%",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ marginBottom: "20px", position: "relative" }}>
            <GText text={strings.w_color} style={{ margin: "5px 0" }} />
            <ColorPicker
              id={linksData.web_color.id}
              defaultcolor={linksData.web_color.key_value}
              onChange={(newVal, fieldId) => {
                updateField(newVal, fieldId, "web_color");
              }}
            />
          </div>

          <div style={{ marginBottom: "20px", position: "relative" }}>
            <GText text={strings.w_footer_color} style={{ margin: "5px 0" }} />
            <ColorPicker
              id={linksData.web_footer_color.id}
              defaultcolor={linksData.web_footer_color.key_value || "#fff"}
              onChange={(newVal, fieldId) => {
                updateField(newVal, fieldId, "web_footer_color");
              }}
            />
          </div>

          <GEditable
            key={linksData.web_home_title?.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.web_home_title?.key_value}
            placeholder={linksData.web_home_title?.key_display_title}
            className={props.display}
            id={linksData.web_home_title?.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "web_home_title");
            }}
            style={{ margin: "10px 0 20px" }}
          />

          <GEditable
            key={linksData.web_home_desc.id}
            type="textarea"
            title={true}
            editable={false}
            defaultValue={linksData.web_home_desc.key_value}
            placeholder={linksData.web_home_desc.key_display_title}
            className={props.display}
            id={linksData.web_home_desc.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "web_home_desc");
            }}
            style={{ margin: "10px 0 20px" }}
          />

          <GText text={strings.w_header_image} style={{ margin: "5px 0" }} />
          <div
            style={{
              background: "#f4f5f9",
              padding: "10px",
              display: "block",
            }}
          >
            <GImageEditor
              image={
                ImgUrl("website") + "/" + linksData.web_home_header.key_value
              }
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "web_home_header");
              }}
              id={linksData.web_home_header.id}
              type="website"
              isPreviewImg={true}
              imgStyle={{
                width: "100%",
                height: "auto",
                borderRadius: "0",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
