import React, { useEffect, useState, useRef, useContext } from "react";
import { Filter } from "./selectListFilter";
import { List } from "./selectListView";
import { ApiService, isPermissionExist, getUserType } from "../services";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import { LoadingBar } from "../gComponents/gLoadingBar";
import useDebounce from "../useDebounce";
import { StringsContext } from "../DataFactory/useStrings";

export default function SelectSearch(props) {
  const [strings] = useContext(StringsContext);
  const [word, setWord] = useState("");
  const debouncedSearchTerm = useDebounce(word, 500);
  const [records, setRecords] = useState([]);
  const wrapperRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showList, setShowList] = useDetectOutsideClick(wrapperRef, false);
  const [filterDisplay, setFilterDisplay] = useState([]);

  useEffect(() => {
    const getSearchList = () => {
      ApiService({ method: "GET", route: "search?keyword=" + word })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setRecords(response.data.data);
            setFilterDisplay(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getSearchList();
  }, [debouncedSearchTerm]);

  const handleChange = (e) => {
    setWord(e);
  };

  const getRecords = () => {
    let oldList = records.map((data) => {
      return data;
    });

    setFilterDisplay(oldList);
  };

  return (
    <div ref={wrapperRef}>
      {getUserType() ||
      isPermissionExist("customer_index") ||
      isPermissionExist("store_index") ||
      isPermissionExist("order_index") ? (
        <>
          <Filter
            value={word}
            handleChange={(e) => handleChange(e.target.value)}
            // handleBlur={() => setShowList(true)}
            handleFocus={() => {
              setShowLoader(true);
              setTimeout(function () {
                setShowLoader(false);
                setShowList(true);
              }, 1300);
            }}
            placeholder={
              !getUserType()
                ? isPermissionExist("order_index")
                  ? isPermissionExist("store_index")
                    ? isPermissionExist("customer_index")
                      ? strings.s_search_by_user_store_and_order
                      : "Search by Store, Order"
                    : isPermissionExist("customer_index")
                    ? isPermissionExist("store_index")
                      ? strings.s_search_by_user_store_and_order
                      : "Search by Customer, Order"
                    : "Search by Order"
                  : isPermissionExist("store_index")
                  ? isPermissionExist("customer_index")
                    ? "Search by User & Store "
                    : "Search by Store"
                  : isPermissionExist("customer_index")
                  ? "Search by User"
                  : ""
                : strings.s_search_by_user_store_and_order
            }
          />
          {showLoader === true ? (
            <LoadingBar width={400} percent={0.25} style={{ margin: "8px" }} />
          ) : (
            ""
          )}
          {/* {showList === true ? ( */}
          <List
            data={word.length < 1 ? records : filterDisplay}
            show={showList}
            handleClick={() => setShowList(false)}
          />
          {/* ) : (
        ""
      )} */}
        </>
      ) : (
        ""
      )}
    </div>
  );
}
