import React, { useEffect, useContext } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CancelReasons(props) {
  const classes = useStyles();
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const { data: orderCancelReasons } = useDataFctory("cancel-reasons", false, {
    user_type: 2,
  });

  useEffect(() => {
    orderCancelReasons.push({ title: "Other" });
  }, [orderCancelReasons]);

  const initialValues = {
    cancel_reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    console.log(values);
    let sendData = { ...values };
    if (sendData.cancel_reason !== "Other") {
      delete sendData.other_reason;
    } else {
      sendData.cancel_reason = sendData.other_reason;
    }

    ApiService({
      method: props.type == "courier" || props.type == "atm" ? "POST" : "PUT",
      route:
        props.type == "courier" || props.type == "atm"
          ? props.type + "/order/cancel/" + props.linkedId
          : "order/cancel/" + props.linkedId,
      body: sendData,
    }).then((response) => {
      console.log("r" + response.data);
      if (response.data.status_code === 1) {
        props.setOcrvisible(false);
        props.onSubmit();
      } else {
        if (values.other_reason) {
          values.cancel_reason = "Other";
        }
      }
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    cancel_reason: Yup.string().required(strings.w_required),
    other_reason: Yup.string().when("cancel_reason", {
      is: "Other",
      then: Yup.string().required(strings.w_required),
    }),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setOcrvisible(false);
  };

  if (props.ocrvisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.ocrvisible}
        onClick={handleClose}
      >
        <Paper
          className={`modalRight
              ${props.ocrvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "475px" }}
        >
          <h3>{strings.w_cancel_order}</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <label style={{ margin: "15px 15px 5px" }}>
                  {strings.s_please_choose_the_cancellation_reason_below}
                </label>
                {orderCancelReasons.length > 0 && (
                  <FormikControl
                    control="radio"
                    name="cancel_reason"
                    options={orderCancelReasons}
                    key_title="title"
                    key_value="title"
                    divstyle={{ display: "block", padding: "5px 0" }}
                  />
                )}
                {values.cancel_reason === "Other" ? (
                  <FormikControl
                    control="input"
                    as="textarea"
                    placeholder={strings.s_type_reason_here}
                    name="other_reason"
                  />
                ) : (
                  ""
                )}

                <GButton
                  type="submit"
                  variant="condensed"
                  children={strings.w_cancel_order}
                  style={{ width: "calc(100% - 30px)", margin: "20px 15px" }}
                />
              </Form>
            )}
          </Formik>
        </Paper>
      </Backdrop>
    </div>
  );
}
