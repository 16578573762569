import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GPricingListCard from "../gComponents/gPricingListCards";
import PricingRuleForm from "../forms/pricingRuleForm";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function PricingRules() {
  const [strings, language] = useContext(StringsContext);
  const [prvisible, setPrvisible] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const [defaultRule, setDefaultRule] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();
  const {
    loading,
    Placeholder,
    data: rulesData,
    CardShimmer,
  } = useDataFctory("task_rules", false);

  useEffect(() => {
    getDefaultRuleSetting();
  }, [language]);

  const getDefaultRuleSetting = () => {
    ApiService({ method: "GET", route: "setting/delivery" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setDefaultRule(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (ruleId) => {
    ApiService({ method: "DELETE", route: "task/rules/" + ruleId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = rulesData.findIndex((p) => p.id == ruleId);
          if (selectedIndex > -1) {
            rulesData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDefaultRule = (newVal) => {
    ApiService({
      method: "PUT",
      route: "setting/" + defaultRule.pricing_rule.id,
      body: {
        key_value: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDefaultRule({ pricing_rule: response.data.data });
        } else {
          getDefaultRuleSetting();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedRule({});
          setPrvisible(true);
          setIsEmpty(true);
        }}
        style={{
          margin: "10px 5px",
          float: "right",
        }}
      ></GButton>
      <GText g2 bold text={strings.w_pricing_rules} />

      {rulesData.length > 0 && (
        <GText
          g4
          text={
            strings.w_default +
            " - " +
            rulesData
              .filter(
                (rule) =>
                  rule.id == parseInt(defaultRule?.pricing_rule?.key_value)
              )
              .map((c) => {
                return c.title;
              })
          }
        />
      )}

      <div
        className="listPaper"
        style={{ padding: "20px 0", marginTop: "20px" }}
      >
        <div
          style={{ maxHeight: "70vh", overflow: "overlay", padding: "0 15px" }}
        >
          {loading === true ? (
            <CardShimmer />
          ) : rulesData.length === 0 ? (
            <Placeholder />
          ) : (
            <GPricingListCard
              actions={true}
              onEdit={(sRule) => {
                setSelectedRule(sRule);
                setPrvisible(true);
                setIsEmpty(false);
              }}
              onDelete={handleDelete}
              rulesData={rulesData}
              default={defaultRule?.pricing_rule}
              onSelectDefault={handleDefaultRule}
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={prvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPrvisible(false)}
        style={{
          content: {
            width: "580px",
          },
        }}
        contentLabel="Pricing Rule Form Modal"
      >
        <PricingRuleForm
          prvisible={prvisible}
          setPrvisible={setPrvisible}
          ruleData={selectedRule}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = rulesData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              rulesData[selectedIndex] = { ...data };
            } else {
              rulesData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
