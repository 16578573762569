import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import GSwitch from "../gComponents/gSwitch";
import GText from "../gComponents/gText";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function ProductSettings() {
  const [strings, language] = useContext(StringsContext);
  const { settings, setSettings } = useContext(SettingsContext);
  const [productSettingsData, setProductSettingsData] = useState({
    product_stock: {},
    product_barcode_enabled: {},
    brands_available: {},
  });
  const [openSnackbar] = useSnackbar();
  const [stockSwitch, setStockSwitchVal] = useState();

  const getProductSettings = () => {
    ApiService({ method: "GET", route: "setting/product" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setProductSettingsData(response.data.data);
          setStockSwitchVal(response.data.data.product_stock?.key_value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductSettings();
  }, [language]);

  function updateSetting(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            productSettingsData[keyTitle] = response.data.data;
            setProductSettingsData({ ...productSettingsData });
            console.log(productSettingsData);
            if (keyTitle == "brands_available") {
              settings.brands_available = response.data.data.key_value;
              setSettings({ ...settings });
            }
          }
        } else {
          getProductSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_product_settings}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        <Table>
          <TableBody>
            {productSettingsData.product_stock?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {productSettingsData.product_stock.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={productSettingsData.product_stock.id}
                    uId={productSettingsData.product_stock.id}
                    currentValue={
                      productSettingsData.product_stock.key_value !== "0"
                        ? "1"
                        : "0"
                    }
                    onSwitchChange={(newVal, id) => {
                      setStockSwitchVal(newVal);
                      updateSetting(newVal, id, "product_stock");
                    }}
                  />
                </TableRowData>
                <TableRowData
                  style={{ width: "200px", padding: "0" }}
                ></TableRowData>
              </TableRow>
            )}
            {productSettingsData.product_barcode_enabled?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {
                    productSettingsData.product_barcode_enabled
                      .key_display_title
                  }
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={productSettingsData.product_barcode_enabled.id}
                    uId={productSettingsData.product_barcode_enabled.id}
                    currentValue={
                      productSettingsData.product_barcode_enabled?.key_value
                    }
                    onSwitchChange={(newVal, id) => {
                      updateSetting(newVal, id, "product_barcode_enabled");
                    }}
                  />
                </TableRowData>
                <TableRowData
                  style={{ width: "200px", padding: "0" }}
                ></TableRowData>
              </TableRow>
            )}

            {productSettingsData.brands_available?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {productSettingsData.brands_available.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={productSettingsData.brands_available.id}
                    uId={productSettingsData.brands_available.id}
                    currentValue={
                      productSettingsData.brands_available?.key_value
                    }
                    onSwitchChange={(newVal, id) => {
                      updateSetting(newVal, id, "brands_available");
                    }}
                  />
                </TableRowData>
                <TableRowData
                  style={{ width: "200px", padding: "0" }}
                ></TableRowData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
