import React, { useState, useContext } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Child from "./orderCancelReasons";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderCancelReasons() {
  let { type } = useParams();
  const [strings] = useContext(StringsContext);
  const [typeValue, setTypeValue] = useState(type || "customer");

  const ReasonRoutes = [
    {
      title: strings.w_customer,
      identifier: "customer",
      link: "/settings/order-cancel-reasons/customer",
      state: "setting",
    },
    {
      title: strings.w_manager,
      identifier: "manager",
      link: "/settings/order-cancel-reasons/manager",
      state: "setting",
    },
  ];

  function handleChange(newValue) {
    console.log("n" + newValue);
    setTypeValue(newValue);
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_order + " " + strings.w_cancel_reasons}
        style={{ marginBottom: "20px" }}
      />
      <GTabs routes={ReasonRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/settings/order-cancel-reasons/:type">
            <Child onChange={handleChange} />
          </Route>
        </div>
      </Switch>
    </div>
  );
}
