import styled from "styled-components";

export const MessageBox = styled.div`
  display: inline-block;
  margin: 10px 0;
  position: relative;
  border: 2px solid #f3f5f7;
  border-radius: 4px;
  padding: 5px;
  text-align: left;

  & h5 {
    padding: 0px 8px;
    font-size: 15px;
    border-radius: 5px;
    margin: 0;
    position: relative;
    font-weight: 400;
  }
  & p {
    color: #acb3bb;
    font-size: 12px;
    margin: 0;
    padding: 2px;
  }

  &.received {
    max-width: 70%;
    background: #fff;

    h5 {
    }
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      left: -10px;
      transform: rotate(-90deg);
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
    }
  }
  &.send {
    background: #dfe6ee;
    max-width: 60%;
    h5 {
    }

    &:after {
      content: "";
      position: absolute;
      top: 10px;
      right: -7px;
      transform: rotate(-90deg);
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent #dfe6ee transparent transparent;
    }
    p {
      text-align: left;
    }
  }
`;
