import React, { useState, useEffect, useContext } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, getUserType, isPermissionExist } from "../services";
import { Add } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import GEmpty from "../gComponents/gEmpty";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useHistory } from "react-router-dom";
import CategoryForm from "../forms/productCategoryForm";
import QueryWith from "../Query";
import GListCard from "../gComponents/gListCards";
import GActions from "../gComponents/gActionsStyle";
import { StoreFilter } from "../components/storeFilterComponent";
import useDataFactory from "../useDataFactory";
import Modal from "react-modal";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function Categories() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const settings = useSettings();
  const [catFormvisible, setCatFormvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState({});
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [selectedStore, setSelectedStore] = useState(
    params.get("store_id") ? params.get("store_id") : ""
  );
  const {
    loading,
    data: categoriesData,
    refreshData,
    CardShimmer,
    Placeholder,
  } = useDataFactory("product/group", false, { store_id: selectedStore });

  useEffect(() => {
    var params = QueryWith(history.location, { store_id: selectedStore });
    if (params.length > 0) {
      history.push({
        pathname: "/categories",
        state: "product",
        search: params[0],
      });
    }
    if (selectedStore) {
      refreshData({ store_id: selectedStore });
    }
  }, [selectedStore, history]);

  const deleteCategory = (id) => {
    var catId = id;
    ApiService({ method: "DELETE", route: "product/categories/" + catId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          refreshData({ store_id: selectedStore });
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditCtaegory = (data) => {
    setSelectedCategoryData(data);
    setIsEmpty(false);
    setCatFormvisible(true);
  };

  const handleToggleStatus = (newVal, id, ind) => {
    console.log(newVal);
    ApiService({
      method: "PUT",
      route: "product/categories/" + id,
      body: {
        status: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          categoriesData[ind].status = newVal;
        } else {
          refreshData({ store_id: selectedStore });
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView" style={{ padding: "20px 20px 0" }}>
      {selectedStore &&
      (getUserType() || isPermissionExist("product_store")) ? (
        <GButton
          variant="condensed"
          children={strings.w_add}
          onClick={() => {
            setSelectedCategoryData({});
            setIsEmpty(true);
            setCatFormvisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      ) : (
        ""
      )}
      <GText
        g1
        bold
        text={strings.w_categories}
        style={{ margin: "0 0 20px", fontSize: "27px" }}
      />
      <StoreFilter
        value={selectedStore}
        isStored={true}
        handleStoreChange={(value) => {
          console.log(value);
          setSelectedStore(value);
        }}
        style={{
          marginBottom: "10px",
        }}
      />

      <div className="listPaper">
        {selectedStore ? (
          <div
            style={{
              maxHeight: "calc(100vh - 270px)",
              overflowY: "auto",
              padding: "5px",
            }}
          >
            {loading ? (
              <CardShimmer />
            ) : categoriesData.length == 0 ? (
              <Placeholder />
            ) : (
              categoriesData.map((c, pi) => {
                return (
                  <React.Fragment key={c.id}>
                    <GListCard
                      setting={strings.w_category}
                      id={c.id}
                      title={c.title}
                      subtitle={
                        c.item_count +
                        (c.item_count > 1
                          ? " " + strings.w_items
                          : " " + strings.w_item)
                      }
                      switch={
                        getUserType() || isPermissionExist("product_update")
                          ? true
                          : false
                      }
                      switchValue={c.status}
                      dropdownActions={
                        getUserType() ||
                        isPermissionExist("product_update") ||
                        isPermissionExist("product_destroy")
                          ? true
                          : false
                      }
                      edit={
                        getUserType() || isPermissionExist("product_update")
                          ? true
                          : false
                      }
                      delete={
                        getUserType() || isPermissionExist("product_destroy")
                          ? true
                          : false
                      }
                      onEdit={handleEditCtaegory}
                      onDelete={(id) => {
                        deleteCategory(id);
                      }}
                      data={c}
                      onSwitchToggle={(val, id) =>
                        handleToggleStatus(val, id, pi)
                      }
                      style={{ width: "23%" }}
                      titleStyle={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        height: "22px",
                      }}
                    ></GListCard>
                  </React.Fragment>
                );
              })
            )}
          </div>
        ) : (
          <GEmpty
            title={strings.w_categories}
            subtitle={strings.s_please_choose_store_to_fetch_categories}
          />
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_category_title}
          text={strings.s_delete_category_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCategory();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={catFormvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCatFormvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Category Form Modal"
      >
        <CategoryForm
          storeId={selectedStore}
          catFormvisible={catFormvisible}
          setCatFormvisible={setCatFormvisible}
          data={selectedCategoryData}
          isEmpty={isEmpty}
          settings={settings}
          onSubmit={() => refreshData({ store_id: selectedStore })}
          onLocalizationRemove={(ind) => {
            let findInd = categoriesData.findIndex(
              (nId) => nId?.id == selectedCategoryData.id
            );

            if (findInd > -1) {
              categoriesData[findInd].localizations.splice(ind, 1);
            }
          }}
          onLocalizationUpdate={(ind, data) => {
            let findInd = categoriesData.findIndex(
              (nId) => nId?.id == selectedCategoryData.id
            );

            if (findInd > -1) {
              categoriesData[findInd].localizations[ind] = data;
            }
          }}
        />
      </Modal>
    </div>
  );
}
