import React, { useContext, useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";

export default function DocumentForm(props) {
  const [strings] = useContext(StringsContext);
  const [isNoteShow, setIsNoteShow] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const { data: docTypesList } = useDataFctory("document/types", false, {
    user_type: props.userType,
  });

  const initialValues = {
    document_type_id: !props.isEmpty ? props.data.document_type_id : "",
    photo: !props.isEmpty ? props.data.photo : "",
    thumb_photo: !props.isEmpty ? props.data.thumb_photo : "",
    user_id: props.linkedId || "",
    user_type: props.userType || "",
    type: !props.isEmpty ? props.data.type : "",
    text: !props.isEmpty ? props.data.text : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "documents/" + props.data.id : "documents";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setDocvisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    document_type_id: Yup.string().required(strings.w_required),
  });

  if (props.docvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_document : strings.w_create_document}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {docTypesList ? (
                <React.Fragment>
                  <FormikControl
                    control="select"
                    as="select"
                    label={strings.w_document_types}
                    name="document_type_id"
                    options={docTypesList}
                    key_title="title"
                    key_value="id"
                    onChange={(e) => {
                      setFieldValue("document_type_id", e.target.value);
                      setFieldValue(
                        "type",
                        docTypesList.find((d) => d.id == e.target.value)?.type
                      );
                    }}
                  />
                  {isNoteShow && docTypesList.length === 0 ? (
                    <p
                      style={{
                        margin: "8px 15px",
                        fontStyle: "italic",
                        fontSize: "14px",
                        color: "#777",
                      }}
                    >
                      <strong>{strings.w_note} : </strong>{" "}
                      {strings.s_please_add_document_types_from_settings}
                    </p>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                ""
              )}

              <br />

              {values.type == "image" && (
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.photo = newValue;
                    values.thumb_photo = thumb_val;
                  }}
                  id="thumb_photo"
                  image={
                    values.thumb_photo
                      ? ImgUrl("document") + "/" + values.thumb_photo
                      : ""
                  }
                  type="document"
                  style={{ height: "160px", paddingTop: "60px" }}
                  divstyle={{ margin: "0 auto", display: "block" }}
                />
              )}

              {values.type == "text" && (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_document_number}
                  name="text"
                />
              )}

              <br />
              <GButton
                type="submit"
                variant="condensed"
                disabled={isBtnDisabled}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
