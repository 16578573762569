import React, { useEffect, useState, useContext } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import { TableContainer } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { ApiService } from "../services";
import { Edit, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import TimeslotsForm from "../forms/timeslotsForm";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

const timeRoutes = [
  {
    title: "mon",
    identifier: "mon",
    link: "/settings/timeslots/mon",
    state: "setting",
  },
  {
    title: "tue",
    identifier: "tue",
    link: "/settings/timeslots/tue",
    state: "setting",
  },
  {
    title: "wed",
    identifier: "wed",
    link: "/settings/timeslots/wed",
    state: "setting",
  },
  {
    title: "thu",
    identifier: "thu",
    link: "/settings/timeslots/thu",
    state: "setting",
  },
  {
    title: "fri",
    identifier: "fri",
    link: "/settings/timeslots/fri",
    state: "setting",
  },
  {
    title: "sat",
    identifier: "sat",
    link: "/settings/timeslots/sat",
    state: "setting",
  },
  {
    title: "sun",
    identifier: "sun",
    link: "/settings/timeslots/sun",
    state: "setting",
  },
];

export default function Timeslots() {
  const [strings] = useContext(StringsContext);
  let { weekday } = useParams();
  const [openSnackbar] = useSnackbar();
  const [typeValue, setTypeValue] = useState("mon");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTimeslotId, setSelectedTimeslotId] = useState("");
  const [tsvisible, setTsvisible] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const {
    loading,
    data: timeslotData,
    Placeholder,
    TableShimmer,
  } = useDataFctory("timeslots", false);

  const columns = [
    { id: "from_time", label: strings.w_from_time, minWidth: 150 },
    { id: "to_time", label: strings.w_valid_upto, minWidth: 150 },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "center" },
  ];

  useEffect(() => {
    console.log(typeValue);
    setTypeValue(weekday ? weekday : "mon");
  }, [weekday]);

  const deleteTimeslot = () => {
    ApiService({
      method: "DELETE",
      route: "timeslots/" + selectedTimeslotId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = timeslotData.findIndex(
            (p) => p.id == selectedTimeslotId
          );
          if (selectedIndex > -1) {
            timeslotData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setIsEmpty(true);
          setSelectedSlot({});
          setTsvisible(true);
        }}
        style={{
          float: "right",
        }}
      />
      <GText
        g2
        bold
        text={strings.w_timeslots}
        style={{ marginBottom: "15px" }}
      />

      <GTabs routes={timeRoutes} active={typeValue}></GTabs>

      <div className="listPaper">
        {loading === true ? (
          <TableShimmer />
        ) : timeslotData.filter((t) => t.weekday === typeValue).length === 0 ? (
          <Placeholder />
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {timeslotData
                  .filter((t) => t.weekday === typeValue)
                  .map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>{c.from_time}</TableRowData>
                        <TableRowData>{c.to_time}</TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedSlot(c);
                              setIsEmpty(false);
                              setTsvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedTimeslotId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_timeslot_title}
          text={strings.s_delete_timeslot_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteTimeslot();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={tsvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setTsvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Timeslot Form Modal"
      >
        <TimeslotsForm
          tsvisible={tsvisible}
          setTsvisible={setTsvisible}
          isEmpty={isEmpty}
          data={selectedSlot}
          weekday={typeValue}
          onSubmit={(data) => {
            let selectedIndex = timeslotData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              timeslotData[selectedIndex] = { ...data };
            } else {
              timeslotData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
