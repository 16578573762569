import React, { useRef, useState, useContext } from "react";
import styled from "styled-components";
import GDropdown from "./gDropdown";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "./gButton";
import GInfo from "./gInfo";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import { AddCircleOutline } from "@material-ui/icons";
import AssignDriverPopup from "../components/assignDriver";
import AssigningIcon from "../assets/images/wall-clock-white.svg";
import { ApiService } from "../services";
import { StringsContext } from "../DataFactory/useStrings";

const AgentDiv = styled.div`
  margin-bottom: 10px;
  small {
    color: #777;
    font-style: italic;
    display: block;
    padding: 5px 10px;
  }
`;

export default function GCancelBox(props) {
  const [strings] = useContext(StringsContext);
  const [advisible, setAdvisible] = useState(false);
  const AssignDropdownRef = useRef(null);
  const [openSnackbar] = useSnackbar();
  const [isDropdownActive, setIsDropdownActive] = useDetectOutsideClick(
    AssignDropdownRef,
    false
  );

  const assignList = [
    {
      title: strings.w_assign_manually,
      value: "manually",
    },
    {
      title: strings.w_assign_automatically,
      value: "automatically",
    },
  ];

  const handleSelectValue = (selectedValue) => {
    if (selectedValue === "manually") {
      setAdvisible(true);
    } else {
      ApiService({
        method: "POST",
        route: "order/delivery/auto-assign/" + props.deliveryId,
      }).then((response) => {
        console.log("r" + response.data);
        if (response.data?.status_code == 0) {
          openSnackbar(response.data?.message);
        } else {
          props.onAssignTask();
          openSnackbar(
            response.message || "Request sent to the driver successfully"
          );
        }
      });
    }
  };

  return (
    <AgentDiv>
      {props.isAssign === false ? (
        <React.Fragment>
          {props.assign ? (
            <GButton
              disabled={props.orderStatus === "Cancelled"}
              variant="linkable"
              onClick={() => {
                setIsDropdownActive(true);
              }}
              style={{
                color:
                  props.orderStatus === "Cancelled" ? "#a2a2a2" : "#D8AE36",
              }}
            >
              <AddCircleOutline style={{ verticalAlign: "middle" }} />{" "}
              {strings.w_assign} {strings.w_driver}
            </GButton>
          ) : (
            ""
          )}

          {isDropdownActive === true ? (
            <div ref={AssignDropdownRef}>
              <GDropdown
                id="assign"
                className={`menu ${isDropdownActive ? "active" : "inactive"}`}
                listData={assignList}
                onSelectValue={(value) => {
                  setIsDropdownActive(false);
                  handleSelectValue(value);
                }}
              />
            </div>
          ) : (
            ""
          )}
          <small>{strings.s_no_driver_assigned_yet}</small>
        </React.Fragment>
      ) : props.taskLog ? (
        <React.Fragment>
          <p
            style={{
              color: "#fff",
              fontStyle: "italic",
              background: "#D8AE36",
              padding: "5px",
              display: "inline-block",
              fontWeight: "550",
              margin: "10px 0 15px",
            }}
          >
            <img
              src={AssigningIcon}
              alt="Task"
              style={{ width: "20px", verticalAlign: "middle" }}
            />{" "}
            &nbsp;&nbsp;Assigning ({props.taskLog.current_count} of{" "}
            {props.taskLog.retries_count}) - {props.taskLog.expire_time}
            sec each
          </p>
          <GInfo
            title={props.driverData.title + " (current)"}
            key={props.driverData.id}
            subtitle={props.driverData.phone}
            photo={props.driverData.thumb_photo}
            imgType="user"
            link={"/driver/" + props.driverData.id + "/tasks"}
            linkState="driver"
          />
        </React.Fragment>
      ) : (
        <GInfo
          title={props.driverData.title}
          key={props.driverData.id}
          subtitle={props.driverData.phone}
          photo={props.driverData.thumb_photo}
          imgType="user"
          link={"/driver/" + props.driverData.id + "/tasks"}
          linkState="driver"
        />
      )}

      {props.isReAssign === true &&
      props.isAssign !== false &&
      !props.taskLog ? (
        <>
          {props.assign ? (
            <GButton
              disabled={props.orderStatus === "Cancelled"}
              variant="linkable"
              onClick={() => {
                setIsDropdownActive(true);
              }}
              style={{
                color:
                  props.orderStatus === "Cancelled" ? "#a2a2a2" : "#D8AE36",
              }}
            >
              <AddCircleOutline style={{ verticalAlign: "middle" }} />{" "}
              {strings.w_re_assign} {strings.w_driver}
            </GButton>
          ) : (
            ""
          )}
          {isDropdownActive === true ? (
            <div ref={AssignDropdownRef}>
              <GDropdown
                id="assign"
                className={`menu ${isDropdownActive ? "active" : "inactive"}`}
                listData={assignList}
                onSelectValue={(value) => {
                  setIsDropdownActive(false);
                  handleSelectValue(value);
                }}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {advisible === true && (
        <AssignDriverPopup
          advisible={advisible}
          setAdvisible={setAdvisible}
          linkedId={props.deliveryId}
          onSubmit={() => props.onAssignTask()}
        />
      )}
    </AgentDiv>
  );
}
