import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import TooltipComponent from "../gComponents/gTooltip";
import { Info } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function VariantTypeForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [switchVal, setSwitchVal] = useState(
    props.variantData.minimum > 0 ? true : false
  );
  const priceOptions = [
    {
      key: strings.w_none,
      value: "0",
      tooltipValue: strings.s_none_tooltip_value,
    },
    {
      key: strings.w_additional,
      value: "1",
      tooltipValue: strings.s_additional_tooltip_value,
    },
    {
      key: strings.w_choice,
      value: "2",
      tooltipValue: strings.s_choice_tooltip_value,
    },
  ];
  const initialValues = {
    title: props.variantData.title || "",
    price_type: props.variantData.price_type || "",
    minimum: props.variantData.minimum || 0,
    maximum: props.variantData.maximum || "",
    status: props.variantData.status || 1,
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);

    let sendData = { ...values };
    sendData.minimum = switchVal === true ? sendData.minimum : 0;
    if (sendData.price_type != "1") {
      sendData.maximum = 1;
      sendData.minimum = 0;
    }
    console.log(sendData);
    let method = !props.isEmpty ? "PUT" : "POST";
    let url = !props.isEmpty
      ? "product/variant/types/" + props.variantData.id
      : "product/variant/types";

    ApiService({ method: method, route: url, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setVtvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    price_type: Yup.string().required(strings.w_required),
    maximum: Yup.string().when("price_type", {
      is: "1",
      then: Yup.string().required(strings.w_required),
    }),
    minimum: switchVal == true ? Yup.string().required(strings.w_required) : "",
  });

  if (props.vtvisible === false) {
    return null;
  }

  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_variant_type
          : strings.w_add_variant_type}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />

              <FormikControl
                control="radio"
                label={strings.w_price_options}
                name="price_type"
                options={priceOptions}
                key_title="key"
                key_value="value"
                divstyle={{ display: "block", padding: "5px 0" }}
              />

              {values.price_type === "1" ? (
                <React.Fragment>
                  <h4 style={{ margin: "15px 15px 5px" }}>
                    {strings.s_apply_rules_on_variants}
                  </h4>

                  <div style={{ margin: "15px 15px 5px" }}>
                    <label style={{ display: "inline-block" }}>
                      {strings.w_selection_mandatory}
                      <TooltipComponent
                        title={strings.s_selection_mandatory_tooltip_value}
                        position="bottom"
                        id="twilio_number"
                      >
                        <Info fontSize="small" />
                      </TooltipComponent>
                    </label>
                    <FormikControl
                      control="toggle"
                      isToggled={switchVal}
                      name="minimum_s"
                      onToggle={() => {
                        setSwitchVal(!switchVal);
                        setFieldValue("minimum", "");
                      }}
                    />
                  </div>
                  {switchVal === true ? (
                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_minimum_selection}
                      name="minimum"
                      onChange={(event) => {
                        const re = /^[0-9+\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("minimum", event.target.value);
                        }
                      }}
                      style={{
                        display: "inline-block",
                        width: "70px",
                        marginLeft: "15px",
                        padding: "0.45rem",
                      }}
                      labelStyle={{
                        display: "inline-block",
                        padding: "0px 15px 0 0",
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <FormikControl
                    control="input"
                    type="text"
                    label={strings.w_maximum_selection}
                    name="maximum"
                    style={{
                      display: "inline-block",
                      width: "70px",
                      marginLeft: "15px",
                      padding: "0.45rem",
                    }}
                    labelStyle={{
                      display: "inline-block",
                      padding: "0px 15px 0 0",
                    }}
                  />
                </React.Fragment>
              ) : (
                ""
              )}

              <br />
              <GButton
                disabled={isBtnDisabled}
                type="submit"
                variant="condensed"
                children={props.isEmpty ? strings.w_create : strings.w_update}
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
