import React, { useState, useContext } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import styled from "styled-components";
import GButton from "../gComponents/gButton";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick.svg";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

const Div = styled.div`
  margin: 20px 15px;
  .radio {
    padding: 7px 5px;
    input {
      margin: 0 10px;
      vertical-align: middle;
    }
    label {
      svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        vertical-align: top;

        &.checked {
          path {
            fill: #d8ae36;
          }
        }
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ReciveOrderPayment(props) {
  const [strings] = useContext(StringsContext);
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [selectedGateway, setSelectedGateway] = useState("cash");

  const onRecievePayment = () => {
    if (!selectedGateway) {
      openSnackbar(strings.s_choose_payment_method);
      return;
    }
    var values = {
      amount: props.pendingAmount,
      gateway: selectedGateway,
    };
    console.log(values);
    //return;
    ApiService({
      method: "POST",
      route: "order/payment/" + props.linkedId,
      body: values,
    }).then((response) => {
      console.log("r" + response.status_code);
      if (response.data.status_code === 1) {
        props.setTransModalVisible(false);
        props.onSubmit();
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setTransModalVisible(false);
  };

  if (props.transModalVisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.transModalVisible}
        onClick={handleClose}
      >
        <Paper
          className={`modalRight
              ${props.transModalVisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <GText
            g3
            bold
            text={strings.w_receive_pending_payment}
            style={{ padding: "0 15px" }}
          />

          <Div>
            <GText g4 theme text={"#" + props.linkedId} />
            <GText
              g4
              med
              text={strings.w_pending_amount + " - " + props.amount}
              style={{ fontStyle: "italic" }}
            />
            <GText
              g4
              semi
              text={strings.s_choose_payment_method}
              style={{ marginTop: "25px" }}
            />
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedGateway === "cash"}
                  onChange={() => setSelectedGateway("cash")}
                  style={{ display: "none" }}
                />
                {selectedGateway === "cash" ? (
                  <Check className="checked" />
                ) : (
                  <UnCheck />
                )}
                {strings.w_cash_on_delivery}
              </label>
            </div>
            <br />
            <GButton
              variant="condensed"
              children={strings.w_received}
              onClick={() => {
                onRecievePayment();
              }}
              style={{
                margin: "10px 5px",
              }}
            ></GButton>
          </Div>
        </Paper>
      </Backdrop>
    </div>
  );
}
