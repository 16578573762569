import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import Modal from "react-modal";
import LocalizationForm from "../forms/localizationForm";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function NotificationsPUSHForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const settings = useSettings();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [selectedNofifyType, setSelectedNotifyType] = useState(props.data);
  const [selectionStart, setSelectionStart] = useState(0);
  const [localizationsArray, setLocalizationsArray] = useState(
    props.data?.localizations || []
  );
  const [localizationVisible, setLocalizationVisible] = useState(false);
  const initialValues = {
    medium: props.data ? props.data.medium : "push",
    user_type: props.userType,
    event_identifier: props.eventIdentifier,
    status: props.data ? props.data.status : "1",
    title: props.data ? selectedNofifyType.title : "",
    sub_title: props.data ? selectedNofifyType.sub_title : "",
    localizations: props.data ? props.data.localizations || [] : [],
  };

  useEffect(() => {
    console.log(props);
    setSelectedNotifyType(props.data);
    setLocalizationsArray(props.data?.localizations || []);
  }, [props]);

  const onSubmit = (values) => {
    if (props.data) {
      values.id = props.data.id;
    }

    if (props.module === "task") {
      values.notification_type = props.notificationType
        ? props.notificationType
        : "delivery";
    }

    values.localizations = localizationsArray;
    setIsBtnDisabled(true);
    console.log(values);
    // return;
    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "notification-settings" : "notification-settings";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (localizationVisible == true) {
            props.onLocalizationAdded(values);
          } else {
            props.onSubmit();
          }
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    sub_title: Yup.string().required(strings.w_required),
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => {
          const handleSelectChange = (newVal, fieldName) => {
            if (!newVal) {
              return;
            }
            let cursorPos2 = selectionStart;
            var v2 = values[fieldName];
            var var2Val = "**" + newVal + "**";
            var textBefore2 = v2.substring(0, cursorPos2);
            var textAfter2 = v2.substring(cursorPos2, v2.length);
            setFieldValue(fieldName, textBefore2 + var2Val + textAfter2);
          };
          return (
            <Form>
              <div>
                <FormikControl
                  control="select"
                  as="select"
                  name="variable"
                  options={props.variables}
                  key_title="title"
                  key_value="identifier"
                  onChange={(e) => handleSelectChange(e.target.value, "title")}
                  style={{ width: "170px", float: "right", border: "0px" }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_title}
                  name="title"
                  onBlur={(e) => setSelectionStart(e.target.selectionStart)}
                />
              </div>
              <div>
                <FormikControl
                  control="select"
                  as="select"
                  name="variable1"
                  options={props.variables}
                  key_title="title"
                  key_value="identifier"
                  onChange={(e) =>
                    handleSelectChange(e.target.value, "sub_title")
                  }
                  style={{ width: "170px", float: "right", border: "0px" }}
                />

                <FormikControl
                  control="input"
                  as="textarea"
                  type="text"
                  label={strings.w_subtitle}
                  name="sub_title"
                  onBlur={(e) => setSelectionStart(e.target.selectionStart)}
                  rows={4}
                />
              </div>
              {settings?.locales && settings?.locales.length > 0 && (
                <div style={{ margin: "15px 15px 0" }}>
                  <label>Localizations</label>

                  {localizationsArray.length > 0 ? (
                    <GButton
                      variant="linkable"
                      onClick={() => setLocalizationVisible(true)}
                    >
                      {localizationsArray.length}{" "}
                      {strings.w_localizations_added}
                    </GButton>
                  ) : (
                    <GButton
                      variant="linkable"
                      children={strings.w_add_localization}
                      onClick={() => setLocalizationVisible(true)}
                    />
                  )}
                </div>
              )}

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={props.data ? strings.w_update : strings.w_add}
                style={{ float: "right", margin: "15px" }}
              />
            </Form>
          );
        }}
      </Formik>

      <Modal
        isOpen={localizationVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setLocalizationVisible(false)}
        style={{
          content: {
            width: "680px",
          },
        }}
        contentLabel="Localization Form Modal"
      >
        <LocalizationForm
          localizationVisible={localizationVisible}
          setLocalizationVisible={setLocalizationVisible}
          data={localizationsArray}
          onSubmit={(data) => {
            setLocalizationsArray([...data]);
          }}
          keyArray={[
            { title: strings.w_title, value: "title" },
            { title: strings.w_subtitle, value: "sub_title" },
          ]}
          variables={props.variables || []}
          onLocalizationRemove={(ind) => {
            props.onLocalizationRemove(ind);
          }}
          onLocalizationUpdate={(ind, data) => {
            props.onLocalizationUpdate(ind, data);
          }}
        />
      </Modal>
    </div>
  );
}
