import React, { useEffect, useState, useContext } from "react";
import { ApiService } from "../services";
import GSwitch from "../gComponents/gSwitch";
import { useSnackbar } from "react-simple-snackbar";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEditable from "../gComponents/gEditable";
import useSettings from "../useSettings";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function TaskAllocation() {
  const [strings, language] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const settings = useSettings();
  const [taskAllocationData, setTaskAllocationData] = useState({
    retries: {},
    task_available_time: {},
    allocation_radius: {},
    stay: {},
  });

  useEffect(() => {
    ApiService({ method: "GET", route: "setting/task_allocation" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setTaskAllocationData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [language]);

  function updateField(newValue, selectedFieldId, stitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          taskAllocationData[stitle].key_value = newValue;
          setTaskAllocationData({ ...taskAllocationData });
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_task_allocation}
        style={{ marginBottom: "15px" }}
      />
      <div className="listPaper" style={{ paddingBottom: "40px" }}>
        <GText
          text={strings.s_set_parameters_to_change_task_time_and_retries}
        />

        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <React.Fragment>
            <div
              style={{
                display: "inline-block",
                minWidth: "200px",
                padding: "10px 15px 0 0",
              }}
            >
              <GEditable
                key={taskAllocationData.retries.id}
                type="text"
                title={true}
                editable={false}
                defaultValue={taskAllocationData.retries.key_value}
                placeholder={taskAllocationData.retries.key_display_title}
                id={taskAllocationData.retries.id}
                onSubmit={(val, id) => updateField(val, id, "retries")}
                style={{ margin: "10px 0 20px" }}
              />

              <GEditable
                key={taskAllocationData.task_available_time.id}
                type="text"
                title={true}
                editable={false}
                defaultValue={taskAllocationData.task_available_time.key_value}
                placeholder={
                  taskAllocationData.task_available_time.key_display_title +
                  " (in sec)"
                }
                id={taskAllocationData.task_available_time.id}
                onSubmit={(val, id) =>
                  updateField(val, id, "task_available_time")
                }
                style={{ margin: "10px 0 20px" }}
              />

              <GEditable
                key={taskAllocationData.allocation_radius.id}
                type="text"
                title={true}
                editable={false}
                defaultValue={taskAllocationData.allocation_radius.key_value}
                placeholder={
                  taskAllocationData.allocation_radius.key_display_title +
                  " (in " +
                  settings.distance_unit +
                  ")"
                }
                id={taskAllocationData.allocation_radius.id}
                onSubmit={(val, id) =>
                  updateField(val, id, "allocation_radius")
                }
                style={{ margin: "10px 0 20px" }}
              />
            </div>

            <hr />

            <GText
              text={strings.s_keep_task_with_last_available_driver}
              style={{ padding: "10px 0" }}
            />
            <GSwitch
              id={taskAllocationData.stay.id}
              uId={taskAllocationData.stay.id}
              currentValue={taskAllocationData.stay.key_value}
              onSwitchChange={(newValue) => {
                console.log(newValue);
                updateField(newValue, taskAllocationData.stay.id, "stay");
              }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
