import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService } from "../services";
import { RateReview, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GSwitch from "../gComponents/gSwitch";
import { Link, useHistory } from "react-router-dom";
import { StoreFilter } from "./storeFilterComponent";
import { CustomerFilter } from "./customerFilterComponent";
import { DriverFilter } from "./driverFilterComponent";
import QueryWith from "../Query";
import RatingStars from "../gComponents/gStarRating";
import { StringsContext } from "../DataFactory/useStrings";

export default function ReviewsComponent(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [reviewsData, setReviewsData] = useState([]);
  const [reviewsPaginationData, setReviewsPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState("");
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);

  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    store_id: params.get("store_id") ? params.get("store_id") : "",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
    driver_id: params.get("driver_id") ? params.get("driver_id") : "",
    published: params.get("published") ? params.get("published") : "",
  });

  const columns = [
    { id: "orders_id", label: strings.w_order, minWidth: 60 },
    { id: "store", label: strings.w_store, minWidth: 100, type: "store" },
    { id: "driver", label: strings.w_driver, minWidth: 100, type: "driver" },
    { id: "customer", label: strings.w_customer, minWidth: 120 },
    { id: "review", label: strings.w_review, minWidth: 160 },
    { id: "rating", label: strings.w_rating, minWidth: 100 },
    {
      id: "status",
      label: strings.w_status,
      minWidth: 60,
    },
    {
      id: "created_at_formatted",
      label: strings.w_created,
      minWidth: 120,
      align: "center",
    },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "right" },
  ];

  const getReviews = () => {
    var params = QueryWith(history.location, filters);

    if (params.length > 0) {
      history.push({
        pathname: "/reviews",
        search: params[0],
        state: props.location?.state || "review",
      });
    }
    ApiService({
      method: "GET",
      route: props.location?.state == "driver" ? "delivery-reviews" : "reviews",
      body: filters,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setReviewsData(response.data.data.data);
          setReviewsPaginationData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getReviews();
  }, [filters, history]);

  const deleteReview = () => {
    ApiService({
      method: "DELETE",
      route:
        props.location?.state == "driver"
          ? "delivery-reviews/" + selectedReviewId
          : "reviews/" + selectedReviewId,
    })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.data.status_code === 1) {
          getReviews();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const toggleValue = (newVal, id, ind) => {
    ApiService({
      method: "PUT",
      route:
        props.location?.state == "driver"
          ? "delivery-reviews/" + id
          : "reviews/" + id,
      body: {
        published: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          reviewsData[ind] = response.data.data;
          setReviewsData([...reviewsData]);
        } else {
          getReviews();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <RateReview /> {strings.w_reviews}
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {props.location?.state == "driver" ? (
          <DriverFilter
            value={filters.driver_id}
            handleDriverChange={(value) => {
              setFilters({ ...filters, driver_id: value || "", page: 1 });
            }}
          />
        ) : (
          <StoreFilter
            value={filters.store_id}
            handleStoreChange={(value) => {
              setFilters({ ...filters, store_id: value || "", page: 1 });
            }}
          />
        )}
        <CustomerFilter
          value={filters.customer_id}
          handleCustomerChange={(value) => {
            setFilters({ ...filters, customer_id: value || "", page: 1 });
          }}
        />

        {reviewsData.length > 0 && (
          <React.Fragment>
            <GPagination
              count={reviewsPaginationData.total}
              from={reviewsPaginationData.from}
              to={reviewsPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(reviewsPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(reviewsPaginationData.prev_page_url)
              }
              rowsPerPage={reviewsPaginationData.per_page}
              prevDisabled={reviewsPaginationData.prev_page_url ? false : true}
              nextDisabled={reviewsPaginationData.next_page_url ? false : true}
              style={{ marginLeft: "auto" }}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={reviewsPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </React.Fragment>
        )}
      </div>

      <div className="listPaper" style={{ marginTop: "12px" }}>
        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {reviewsData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((c) => {
                        if (props.location?.state != "review") {
                          return c.type == props.location?.state || !c.type;
                        } else {
                          return c.type == "store" || !c.type;
                        }
                      })
                      .map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewsData.map((c, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/order/" + c.order_id + "/orders",
                              state: "order",
                            }}
                          >
                            #{c.order_id}
                          </Link>
                        </TableRowData>
                        {props.location?.state === "driver" ? (
                          <TableRowData>
                            {c.driver ? (
                              <Link
                                to={{
                                  pathname: "/driver/" + c.driver.id + "/tasks",
                                  state: "driver",
                                }}
                              >
                                {c.driver.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        ) : (
                          <TableRowData>
                            <Link
                              to={{
                                pathname: "/store/" + c.store.id + "/orders",
                                state: "store",
                              }}
                            >
                              {c.store.title}
                            </Link>
                          </TableRowData>
                        )}
                        <TableRowData>
                          {c.customer ? (
                            <Link
                              to={{
                                pathname:
                                  "/customer/" + c.customer.id + "/orders",
                                state: "customer",
                              }}
                            >
                              {c.customer.title}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </TableRowData>
                        <TableRowData>{c.review || "-"}</TableRowData>
                        <TableRowData>
                          <RatingStars
                            totalStars={5}
                            selectedStars={c.rating}
                          />
                        </TableRowData>
                        <TableRowData>
                          <GSwitch
                            id={c.id}
                            uId={c.id}
                            currentValue={
                              c.published === "true" ||
                              c.published === "1" ||
                              c.published === 1
                                ? 1
                                : 0
                            }
                            onSwitchChange={(newVal, id) =>
                              toggleValue(newVal, id, i)
                            }
                          />
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedReviewId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_review_title}
          text={strings.s_delete_review_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteReview();
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}
