import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GPolygon from "../leaflet/gPolygonEdit";
import useDataFctory from "../useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";

export default function GeofencePopup(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [selectedPolygons, setSelectedPolygons] = useState(
    props.data.polygon || []
  );
  const { data: citiesData } = useDataFctory("cities", false);

  const initialValues = {
    title: props.data.title || "",
    city_id: props.selectedCity || "",
    type: "polygon",
    polygon: props.data.polygon || [],
    cod_available: props.data.cod_available || 0,
    atm_available: props.data.atm_available || 0,
  };

  const onSubmit = (values) => {
    console.log(values);
    values.polygon = selectedPolygons;
    console.log(JSON.stringify(values));
    if (values.polygon.length === 0) {
      openSnackbar(strings.s_polygon_area_is_required);
      return;
    }
    // return;

    ApiService({
      method: "PUT",
      route: "geofences/" + props.data.id,
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setGeofEditvisible(false);
        props.onSubmit(response);
      }
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setGeofEditvisible(false);
  };

  if (props.geofvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header" style={{ padding: "20px 25px" }}>
        {strings.w_edit_geofence}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GButton
                type="submit"
                variant="condensed"
                children={strings.w_update}
                style={{
                  position: "absolute",
                  width: "150px",
                  right: "30px",
                  top: "17px",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              {citiesData ? (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_city}
                  placeholder="Select city"
                  name="city_id"
                  options={citiesData}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
              ) : (
                ""
              )}

              <FormikControl
                control="toggle"
                label={strings.w_cod_available}
                isToggled={values.cod_available == "1" ? 1 : 0}
                name="cod_available"
                onToggle={() => {
                  let codAvailable = values.cod_available == "1" ? true : false;
                  codAvailable = !codAvailable;
                  codAvailable = codAvailable === true ? "1" : "0";
                  setFieldValue("cod_available", codAvailable);
                }}
                style={{ width: "30%", boxSizing: "border-box" }}
              />

              <FormikControl
                control="toggle"
                label={strings.w_atm_available}
                isToggled={values.atm_available == "1" ? 1 : 0}
                name="atm_available"
                onToggle={() => {
                  let atmAvailable = values.atm_available == "1" ? true : false;
                  atmAvailable = !atmAvailable;
                  atmAvailable = atmAvailable === true ? "1" : "0";
                  setFieldValue("atm_available", atmAvailable);
                }}
                style={{ width: "30%", boxSizing: "border-box" }}
              />

              <div style={{ margin: "15px" }}>
                <label>{strings.w_area}</label>

                <GPolygon
                  polygonData={values.polygon}
                  getGeoJSON={(jsonArray) => {
                    console.log(jsonArray);
                    setFieldValue("polygon", jsonArray);
                    setSelectedPolygons(jsonArray);
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
