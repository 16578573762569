import React, { useState, useEffect } from "react";
// import {useAsyncStorage} from '@react-native-community/async-storage';
import useAsyncStorage from "./useAsyncStorage";
// import {EventRegister} from 'react-native-event-listeners';
// import { Alert } from "react-native";

const UseCart = (refresh = false) => {
  const [cartStore, setCartStore] = useState("");
  const [cart, setCart] = useState([]);

  useEffect(() => {
    //EventRegister.emit('cartUpdated', cart);
    setCart(cart);
    console.log(cart);
  }, [cart]);

  const addProduct = (product, variants = []) => {
    console.log(cart);
    if (cart.length === 0) {
      product.quantity = 1;
      setCartStore(product.store_id);
      setCart([product]);
      return;
    }

    // CHECK FOR ANY ITEM FROM DIFFERENT STORE
    if (cartStore && product.store_id != cartStore) {
      if (
        window.confirm(
          "Your cart contains products from another store. Would you like to clear cart and add this product?"
        )
      ) {
        product.variants = variants;
        setCart([product]);
        setCartStore(product.store_id);
        return;
      }

      // Alert.alert(
      //   "Clear Cart",
      //   "Your cart contains products from another store. Would you like to clear cart and add this product?",
      //   [
      //     {
      //       text: "Cancel",
      //       onPress: () => console.log("Cancel Pressed"),
      //       style: "cancel",
      //     },
      //     {
      //       text: "OK",
      //       onPress: () => {
      //         product.variants = variants;
      //         setCart([product]);
      //         setCartStore(product.store_id);
      //         return;
      //       },
      //     },
      //   ],
      //   { cancelable: false }
      // );
      return;
    }

    // VALIDATE VARIANTS
    if (variants.length > 0) {
      // return;
    }

    console.log(cart);
    // CHECK FOR SIMILAR PRODUCT -> INCREASE QUANTITY
    // let existing = cart.findIndex((c) => c.id === product.id);
    // console.log(existing);
    // if (existing >= 0) {
    //   let existingVariant = cart.findIndex((c) => {
    //     isSameArray((c.variants = []), variants);
    //   });
    //   if (existingVariant >= 0) {
    //     let tempArray = [...cart];
    //     let temp = tempArray[existingVariant];
    //     temp.quantity = temp.quantity + 1;
    //     tempArray[existing] = temp;
    //     setCart(tempArray);
    //   } else {
    //     let tempArray = [...cart];
    //     let temp = tempArray[existing];
    //     temp.quantity = temp.quantity + 1;
    //     tempArray[existing] = temp;
    //     setCart(tempArray);
    //   }
    // } else {
    //   // ADD THE PRODUCT
    //   product.quantity = 1;
    //   product.variants = variants;
    //   setCart([...cart, product]);
    // }

    let existing = cart.findIndex(
      (c) => c.id == product.id && isSameArray(c.variants, variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity + 1;
      tempArray[existing] = temp;
      setCart(tempArray);
    } else {
      // ADD THE PRODUCT
      product.quantity = 1;
      product.variants = variants;
      setCart([...cart, product]);
    }
    setCartStore(product.store_id);
  };

  const decreaseQuantity = (product) => {
    //
    let existing = cart.findIndex(
      (c) => c.id === product.id && isSameArray(c.variants, product.variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity - 1;
      tempArray[existing] = temp;
      console.log(tempArray);
      tempArray = tempArray.filter((t) => t.quantity > 0);
      setCart(tempArray);
      //   writeItemToStorage(tempArray);

      if (tempArray.length == 0) {
        setCartStore(null);
      }
    } else {
      let tempArrayNew = [...cart];
      tempArrayNew = tempArrayNew.filter((t) => t.quantity > 0);
      setCart(tempArrayNew);
    }
    setCartStore(product.store_id);
    //return;
  };

  const decimalQuantity = (product) => {
    //
    let existing = cart.findIndex(
      (c) => c.id === product.id && isSameArray(c.variants, product.variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = product.quantity;
      tempArray[existing] = temp;
      console.log(tempArray);
      tempArray = tempArray.filter((t) => t.quantity > 0);
      setCart(tempArray);
      //   writeItemToStorage(tempArray);

      if (tempArray.length == 0) {
        setCartStore(null);
      }
    } else {
      let tempArrayNew = [...cart];
      tempArrayNew = tempArrayNew.filter((t) => t.quantity > 0);
      setCart(tempArrayNew);
    }
    setCartStore(product.store_id);
    //return;
  };

  const removeProduct = (product) => {
    //
    let existing = cart.findIndex(
      (c) =>
        c.id === product.id && isSameArray((c.variants = []), product.variants)
    );
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = 0;
      tempArray[existing] = temp;
      tempArray = tempArray.filter((t) => t.quantity > 0);
      setCart(tempArray);
      //   writeItemToStorage(tempArray);

      if (tempArray.length == 0) {
        setCartStore(null);
      }

      //return;
    }

    setCartStore(product.store_id);
  };

  return {
    cart: cart,
    addProduct: addProduct,
    removeProduct: removeProduct,
    cartStore: cartStore,
    setCartStore: setCartStore,
    setCart: setCart,
    decreaseQuantity: decreaseQuantity,
    decimalQuantity: decimalQuantity,
  };
};

export default UseCart;

const isSameArray = (first, second) => {
  console.log(first);
  console.log(second);
  // if ((first.length = second.length = 0)) {
  //   return true;
  // }
  if (first.length == second.length) {
    let firstIds = first.map((f) => f.id);
    let secondIds = second.map((s) => s.id);
    if (firstIds.every((f) => secondIds.includes(f))) {
      return true;
    }
  }
  return false;
};
