import React, { useEffect, useState, useContext } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useParams } from "react-router-dom";
import { ApiService } from "../services";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import TagsPopup from "../forms/tagsForm";
import GTags from "../gComponents/gTags";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

const tagButtonStyle = {
  background: "#e5e5e5",
  borderRadius: "20px",
  color: "#000",
  padding: "7px 15px",
  fontWeight: "450",
  fontSize: "15px",
  margin: "0 5px",
};

export default function Tags() {
  const [strings] = useContext(StringsContext);
  let { type } = useParams();
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState("");
  const [tagvisible, setTagvisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});
  const [isEmpty, setIsEmpty] = React.useState(true);
  const { data: tagsData, refreshData } = useDataFctory("tags", false, {
    type: type,
  });

  const deleteTag = () => {
    ApiService({ method: "DELETE", route: "tags/" + selectedTagId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = tagsData.findIndex((p) => p.id == selectedTagId);
          if (selectedIndex > -1) {
            tagsData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_tags_manageent}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <GText text={strings.w_tags} style={{ padding: "2px 8px" }} />
        {tagsData &&
          tagsData.map((tag, i) => {
            return (
              <GTags
                key={i}
                data={tag}
                onDelete={() => {
                  setSelectedTagId(tag.id);
                  setDialogOpen(true);
                }}
              />
            );
          })}

        <GButton
          variant="condensed"
          style={tagButtonStyle}
          onClick={() => {
            setIsEmpty(true);
            setSelectedTag({});
            setTagvisible(true);
          }}
        >
          + {strings.w_add}
        </GButton>
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_tag_title}
          text={strings.s_delete_tag_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteTag();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={tagvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setTagvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Tag Form Modal"
      >
        <TagsPopup
          tagvisible={tagvisible}
          setTagvisible={setTagvisible}
          isEmpty={isEmpty}
          data={selectedTag}
          type={type}
          onSubmit={(data) => {
            let selectedIndex = tagsData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              tagsData[selectedIndex] = { ...data };
            } else {
              tagsData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
