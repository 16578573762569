import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import { StoreFilter } from "../components/storeFilterComponent";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../gComponents/gText";
import { CustomerFilter } from "../components/customerFilterComponent";
import { Cancel } from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";
import { ProductFilter } from "../components/productFilterComponent";

export default function PromotionForm(props) {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [selectedCustomers, setSelectedCutomers] = useState(
    props.data?.selected_customers_display || []
  );

  const paidByOptions = [
    { title: strings.w_admin, value: "admin" },
    { title: strings.w_store, value: "store" },
    { title: strings.s_split, value: "split" },
  ];

  const initialValues = {
    title: !props.isEmpty ? props.data?.title : "",
    description: !props.isEmpty ? props.data.description : "",
    paid_by: !props.isEmpty ? props.data?.paid_by : "admin",
    status: !props.isEmpty ? props.data.status : 1,
    code: !props.isEmpty ? props.data.code : "",
    targeted_store: props.data?.store_id ? "selected" : "all",
    store_id: !props.isEmpty ? props.data.store_id : "",
    targeted_customers: !props.isEmpty
      ? props.data?.targeted_customers || "all"
      : "all",
    selected_customers: !props.isEmpty ? props.data?.selected_customers : [],
    promotion_type: !props.isEmpty ? props.data?.promotion_type : "",
    fixed_discount: !props.isEmpty ? props.data?.fixed_discount || "" : "",
    percentage_discount: !props.isEmpty
      ? props.data?.percentage_discount || ""
      : "",
    free_product: !props.isEmpty ? props.data.free_product || "" : "",
    free_product_count: !props.isEmpty
      ? props.data.free_product_count || ""
      : "",
    promo_product: !props.isEmpty ? props.data.promo_product || "" : "",
    promo_product_min_count: props.data
      ? props.data.promo_product_min_count || ""
      : "",
    valid_from: !props.isEmpty
      ? props.data?.valid_from
        ? new Date(props.data.valid_from)
        : null
      : null,
    valid_upto: !props.isEmpty
      ? props.data?.valid_upto
        ? new Date(props.data.valid_upto)
        : null
      : null,
    limit_total: !props.isEmpty ? props.data?.limit_total || "" : "",
    limit_user: !props.isEmpty ? props.data?.limit_user || "" : "",
    max_discount: !props.isEmpty ? props.data?.max_discount || "" : "",
    minimum_order_amount: !props.isEmpty
      ? props.data?.minimum_order_amount || ""
      : "",
    maximum_order_amount: !props.isEmpty
      ? props.data?.maximum_order_amount || ""
      : "",
  };

  const handleChange = (d) => {
    var date = ("0" + new Date(d).getDate()).slice(-2); //Current Date
    var month = ("0" + (new Date(d).getMonth() + 1)).slice(-2); //Current Month
    var year = new Date(d).getFullYear(); //Current Year
    console.log(year + "-" + month + "-" + date);
    return year + "-" + month + "-" + date;
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };

    sendData.code = sendData.code || "";
    sendData.store_id =
      sendData.targeted_store === "selected" ? sendData.store_id : "";
    let assignedCustomers = [];
    selectedCustomers.map((cus) => {
      assignedCustomers.push(cus.id);
    });
    sendData.selected_customers =
      sendData.targeted_customers == "selected" ? assignedCustomers : [];

    sendData.valid_from = sendData.valid_from
      ? handleChange(sendData.valid_from)
      : "";
    sendData.valid_upto = sendData.valid_upto
      ? handleChange(sendData.valid_upto)
      : "";
    sendData.limit_total = sendData.limit_total || "0";
    sendData.limit_user = sendData.limit_user || "0";
    sendData.max_discount = sendData.max_discount || "0";
    sendData.minimum_order_amount = sendData.minimum_order_amount || "0";
    sendData.maximum_order_amount = sendData.maximum_order_amount || "0";

    console.log(sendData);

    // return;
    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "coupons/" + props.data.id : "coupons";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setCcpnvisible(false);
          if (props.data) {
            props.onSubmit(response.data.data);
          } else {
            if (location.pathname === "/promotions") {
              window.location.reload(false);
            } else {
              window.location = "/promotions";
            }
          }
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    description: Yup.string().required(strings.w_required),
    promotion_type: Yup.string().required(strings.w_required),
    paid_by: Yup.string().when("promotion_type", {
      is: (promotion_type) => promotion_type !== "free_product",
      then: Yup.string().required(strings.w_required),
    }),
    store_id: Yup.string().when("targeted_store", {
      is: (targeted_store) => targeted_store === "selected",
      then: Yup.string().required(strings.w_required),
    }),
    fixed_discount: Yup.string().when("promotion_type", {
      is: (promotion_type) => promotion_type === "fixed_discount",
      then: Yup.string().required(strings.w_required),
    }),
    percentage_discount: Yup.string().when("promotion_type", {
      is: (promotion_type) => promotion_type === "percentage_discount",
      then: Yup.string().required(strings.w_required),
    }),
    free_product: Yup.string().when("promotion_type", {
      is: (promotion_type) => promotion_type === "free_product",
      then: Yup.string().required(strings.w_required),
    }),
    free_product_count: Yup.string().when("promotion_type", {
      is: (promotion_type) => promotion_type === "free_product",
      then: Yup.string().required(strings.w_required),
    }),
  });

  if (props.ccpnvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit : strings.w_add} {strings.w_promotion}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input-md"
                type="text"
                label={strings.w_title}
                name="title"
                placeholder={strings.s_get_discount_upto}
              />

              <FormikControl
                control="textarea-md"
                as="textarea"
                label={strings.w_description}
                name="description"
                rows="3"
                placeholder={strings.s_use_code_and_get_discount}
              />

              <FormikControl
                control="input-md"
                type="text"
                name="code"
                label={`${strings.w_coupon_code} (${strings.w_optional})`}
                placeholder="FLAT50"
                divstyle={{
                  display: "inline-block",
                  width: "40%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <div
                style={{
                  display: "inline-block",
                  width: "40%",
                  boxSizing: "border-box",
                  verticleAlign: "top",
                  padding: "4px 15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "30px",
                    border: "1px solid #e2e2e2",
                    borderRadius: "4px",
                    height: "43px",
                    padding: "4px 20px 5px",
                  }}
                >
                  <label style={{ margin: "0" }}>{strings.w_status}</label>
                  <FormikControl
                    control="toggle"
                    isToggled={values.status == "1" ? 1 : 0}
                    name="status"
                    onToggle={() => {
                      let statusVal = values.status === "1" ? true : false;
                      statusVal = !statusVal;
                      statusVal = statusVal === true ? "1" : "0";
                      setFieldValue("status", statusVal);
                    }}
                    style={{ padding: 0 }}
                  />
                </div>
              </div>

              <div style={{ padding: "12px 15px" }}>
                <GText g4 semi text={strings.w_targeted_store} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    paddingTop: "7px",
                  }}
                >
                  <FormikControl
                    control="radio-md"
                    name="targeted_store"
                    options={[
                      { title: strings.w_all_stores, value: "all" },
                      { title: strings.w_selected_store, value: "selected" },
                    ]}
                    key_title="title"
                    key_value="value"
                    mainDivStyle={{ padding: 0, margin: 0, width: "55%" }}
                    onChange={(value) => {
                      setFieldValue("targeted_store", value || "");
                      setFieldValue("store_id", "");
                      setFieldValue("free_product", "");
                      setFieldValue("free_product_count", "");
                      setFieldValue("promo_product", "");
                      setFieldValue("promo_product_min_count", "");
                    }}
                  />

                  {values.targeted_store === "selected" && (
                    <div
                      style={{
                        display: "inline-block",
                        width: "35%",
                        marginLeft: "auto",
                        paddingLeft: "10px",
                      }}
                    >
                      <div
                        style={{
                          margin: "0",
                          border: "1px solid #e2e2e2",
                          borderRadius: "4px",
                          padding: "4px 15px",
                        }}
                      >
                        <label
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: "12px",
                            color: "#aaa",
                          }}
                        >
                          {strings.w_select_store}
                        </label>
                        <StoreFilter
                          value={values.store_id}
                          title={props.data?.store_name || ""}
                          placeholder={strings.w_choose}
                          style={{
                            display: "block",
                            width: "100%",
                          }}
                          innerStyle={{ border: 0, padding: 0, height: "25px" }}
                          handleStoreChange={(value) => {
                            values.store_id = value || "";
                            setFieldValue("store_id", value || "");
                            if (!value) {
                              setFieldValue("free_product", "");
                              setFieldValue("free_product_count", "");
                              setFieldValue("promo_product", "");
                              setFieldValue("promo_product_min_count", "");
                            }
                          }}
                        />
                      </div>
                      <FormikControl
                        control="input"
                        type="text"
                        name="store_id"
                        divstyle={{ padding: 0, margin: 0 }}
                        style={{ display: "none" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div style={{ padding: "12px 15px" }}>
                <GText g4 semi text={strings.w_targeted_customers} />

                <div
                  style={{
                    paddingTop: "8px",
                  }}
                >
                  <FormikControl
                    control="radio-md"
                    name="targeted_customers"
                    options={[
                      { title: strings.w_all, value: "all" },
                      { title: strings.w_new, value: "new" },
                      { title: strings.w_lapsed, value: "lapsed" },
                      { title: strings.w_selected, value: "selected" },
                    ]}
                    key_title="title"
                    key_value="value"
                    mainDivStyle={{ padding: 0, margin: 0, width: "100%" }}
                    onChange={(value) => {
                      setFieldValue("targeted_customers", value || "");
                      setSelectedCutomers([]);
                      setCustomerId("");
                    }}
                  />
                </div>

                {values.targeted_customers === "selected" && (
                  <div>
                    <div
                      style={{
                        margin: "5px 0",
                        // border: "1px solid #e2e2e2",
                        // borderRadius: "4px",
                        padding: "10px 10px 0px",
                        display: "flex",
                        alignItems: "center",
                        width: "70%",
                      }}
                    >
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: "14px",
                          color: "#aaa",
                          minWidth: "135px",
                        }}
                      >
                        {strings.w_select_customers}
                      </label>
                      <CustomerFilter
                        value={customerId}
                        handleCustomerChange={(value, data) => {
                          setCustomerId(value || "");
                          if (value) {
                            let selectedInd = selectedCustomers.findIndex(
                              (s) => s.id == value
                            );
                            if (selectedInd == -1) {
                              selectedCustomers.push({
                                id: value,
                                title: data.title,
                                email: data.email,
                                phone: data.phone,
                              });
                              setSelectedCutomers([...selectedCustomers]);
                            }
                          }
                        }}
                        style={{ display: "block", width: "100%" }}
                        innerStyle={{
                          border: 0,
                          borderBottom: "1px solid #e2e2e2",
                          padding: 0,
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "15px",
                        }}
                      />
                    </div>
                    {selectedCustomers.length > 0 && (
                      <div
                        style={{
                          border: "1px solid #e2e2e2",
                          padding: "7px 5px",
                          marginTop: "15px",
                          borderRadius: "4px",
                        }}
                      >
                        {selectedCustomers.map((c, i) => {
                          return (
                            <TooltipComponent
                              key={i}
                              title={c.title}
                              position="top"
                              id={c.id}
                              subtitle={`${c.email} \n ${c.phone || ""}`}
                              theme={"white"}
                              tooltipStyle={{
                                textAlign: "left",
                                minWidth: "auto",
                                borderRadius: "4px",
                                left: "60%",
                              }}
                              divStyle={{ margin: 0 }}
                            >
                              <div
                                style={{
                                  margin: "5px",
                                  display: "inline-flex",
                                  background: "#f4f5f9",
                                  borderRadius: "4px",
                                  padding: "2px 10px",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <GText
                                    semi
                                    text={c.title}
                                    style={{
                                      margin: "2px 5px",
                                      fontSize: "14px",
                                    }}
                                  />
                                </div>
                                <Cancel
                                  onClick={() => {
                                    selectedCustomers.splice(i, 1);
                                    setSelectedCutomers([...selectedCustomers]);
                                  }}
                                  style={{ width: "18px", marginLeft: 10 }}
                                />
                              </div>
                            </TooltipComponent>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div style={{ padding: "15px" }}>
                <GText g4 semi text={strings.w_promotion_reward} />

                <div
                  style={{
                    padding: "8px 0",
                  }}
                >
                  <FormikControl
                    control="radio-md"
                    name="promotion_type"
                    options={
                      values.targeted_store == "selected"
                        ? [
                            {
                              title: "% " + strings.w_discount,
                              value: "percentage_discount",
                            },
                            {
                              title: strings.w_fixed_discount,
                              value: "fixed_discount",
                            },
                            {
                              title: strings.w_free_delivery,
                              value: "free_delivery",
                            },
                            {
                              title: strings.w_free_product,
                              value: "free_product",
                            },
                          ]
                        : [
                            {
                              title: "% " + strings.w_discount,
                              value: "percentage_discount",
                            },
                            {
                              title: strings.w_fixed_discount,
                              value: "fixed_discount",
                            },
                            {
                              title: strings.w_free_delivery,
                              value: "free_delivery",
                            },
                          ]
                    }
                    key_title="title"
                    key_value="value"
                    mainDivStyle={{ padding: 0, margin: 0, width: "100%" }}
                    onChange={(value) => {
                      setFieldValue("promotion_type", value || "");
                      setFieldValue("percentage_discount", "");
                      setFieldValue("fixed_discount", "");
                      setFieldValue("free_product", "");
                      setFieldValue("free_product_count", "");
                      setFieldValue(
                        "paid_by",
                        value === "free_product" ? "store" : values.paid_by
                      );
                    }}
                  />
                </div>

                {values.promotion_type === "fixed_discount" && (
                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_fixed_discount}
                    name="fixed_discount"
                    inputRightSymbol={settings.currency_symbol}
                    maxLength={3}
                    placeholder="15"
                    onChange={(event) => {
                      const re = /^[0-9.\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("fixed_discount", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: "0",
                      padding: "8px 4px",
                    }}
                  />
                )}

                {values.promotion_type === "percentage_discount" && (
                  <FormikControl
                    control="input-md"
                    type="text"
                    label={`% ${strings.w_discount}`}
                    inputRightSymbol={"%"}
                    name="percentage_discount"
                    maxLength={3}
                    placeholder="15"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue(
                          "percentage_discount",
                          event.target.value || ""
                        );
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: "0",
                      padding: "8px 4px",
                    }}
                  />
                )}

                {values.promotion_type === "free_product" && (
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "start",
                      gap: "20px",
                    }}
                  >
                    {values.store_id ? (
                      <>
                        <div
                          style={{
                            display: "inline-block",
                            width: "35%",
                          }}
                        >
                          <div
                            style={{
                              margin: "0",
                              border: "1px solid #e2e2e2",
                              borderRadius: "4px",
                              padding: "4px 10px",
                            }}
                          >
                            <label
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: "12px",
                                color: "#aaa",
                              }}
                            >
                              {strings.w_free_product}
                            </label>
                            <ProductFilter
                              value={values.free_product}
                              placeholder={strings.w_choose}
                              productTitle={
                                props.data?.free_product_display?.title || ""
                              }
                              style={{
                                margin: "0px",
                                width: "100%",
                                padding: "2px 0",
                              }}
                              status={1}
                              containerStyle={{ margin: "0", width: "100%" }}
                              innerStyle={{
                                border: 0,
                                padding: 0,
                                height: "25px",
                              }}
                              storeId={values.store_id}
                              isStored={values.free_product ? true : false}
                              perPage={50}
                              handleProductChange={(id, value) => {
                                setFieldValue("free_product", id ? id : "");
                                if (!id) {
                                  setFieldValue("free_product_count", "");
                                }
                              }}
                            />
                          </div>

                          <FormikControl
                            control="input"
                            type="text"
                            name="free_product"
                            divstyle={{ padding: 0, margin: 0 }}
                            style={{ display: "none" }}
                          />
                        </div>

                        {values.free_product && (
                          <FormikControl
                            control="input-md"
                            type="text"
                            label={strings.w_free_product_count}
                            name="free_product_count"
                            maxLength={2}
                            placeholder="15"
                            onChange={(event) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                event.target.value &&
                                !re.test(event.target.value)
                              ) {
                                return;
                              } else {
                                setFieldValue(
                                  "free_product_count",
                                  event.target.value
                                );
                              }
                            }}
                            divstyle={{
                              padding: 0,
                              width: "35%",
                              margin: 0,
                              display: "inline-block",
                            }}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {values.promotion_type !== "free_product" && (
                  <>
                    <hr />
                    <FormikControl
                      control="radio-md"
                      label={strings.w_paid_by}
                      name="paid_by"
                      options={paidByOptions}
                      key_title="title"
                      key_value="value"
                      mainDivStyle={{
                        padding: "0 4px",
                        margin: "15px 0 0",
                        display: "flex",
                        alignItems: "center",
                      }}
                      divStyle={{ width: "75%", marginLeft: "auto" }}
                      onChange={(value) => {
                        setFieldValue("paid_by", value || "");
                      }}
                    />
                  </>
                )}
              </div>

              <div style={{ padding: "15px" }}>
                <GText g4 semi text={strings.w_promotion_requirements} />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px 27px",
                    paddingTop: "8px",
                  }}
                >
                  <FormikControl
                    control="date-md"
                    label={strings.w_valid_from}
                    name="valid_from"
                    format="yyyy-MM-dd"
                    placeholderText={strings.w_choose}
                    divstyle={{
                      display: "inline-block",
                      position: "relative",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />

                  <FormikControl
                    control="date-md"
                    label={strings.w_valid_upto}
                    name="valid_upto"
                    format="yyyy-MM-dd"
                    placeholderText={strings.w_choose}
                    divstyle={{
                      display: "inline-block",
                      position: "relative",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />

                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_min_order}
                    name="minimum_order_amount"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue(
                          "minimum_order_amount",
                          event.target.value
                        );
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />

                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_max_order}
                    name="maximum_order_amount"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue(
                          "maximum_order_amount",
                          event.target.value
                        );
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                </div>
              </div>

              {values.targeted_store === "selected" && values.store_id ? (
                <div style={{ padding: "10px 15px" }}>
                  <GText g4 semi text={strings.w_cart_requirements} />

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "start",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        width: "35%",
                      }}
                    >
                      <div
                        style={{
                          margin: "0",
                          border: "1px solid #e2e2e2",
                          borderRadius: "4px",
                          padding: "4px 10px",
                        }}
                      >
                        <label
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: "12px",
                            color: "#aaa",
                          }}
                        >
                          {strings.w_required_product}
                        </label>
                        <ProductFilter
                          value={values.promo_product}
                          placeholder={strings.w_choose}
                          productTitle={
                            props.data?.promo_product_display?.title || ""
                          }
                          style={{
                            margin: "0px",
                            width: "100%",
                            padding: "2px 0",
                          }}
                          status={1}
                          containerStyle={{ margin: "0", width: "100%" }}
                          innerStyle={{
                            border: 0,
                            padding: 0,
                            height: "25px",
                          }}
                          storeId={values.store_id}
                          isStored={values.promo_product ? true : false}
                          perPage={50}
                          handleProductChange={(id, value) => {
                            setFieldValue("promo_product", id ? id : "");
                            if (!id) {
                              setFieldValue("promo_product_min_count", "");
                            }
                          }}
                        />
                      </div>

                      <FormikControl
                        control="input"
                        type="text"
                        name="promo_product"
                        divstyle={{ padding: 0, margin: 0 }}
                        style={{ display: "none" }}
                      />
                    </div>

                    {values.promo_product && (
                      <FormikControl
                        control="input-md"
                        type="text"
                        label={strings.w_required_product_count}
                        name="promo_product_min_count"
                        maxLength={2}
                        placeholder="15"
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue(
                              "promo_product_min_count",
                              event.target.value
                            );
                          }
                        }}
                        divstyle={{
                          padding: 0,
                          width: "35%",
                          margin: 0,
                          display: "inline-block",
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div style={{ padding: "15px" }}>
                <GText g4 semi text={strings.w_promotion_limits} />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px 27px",
                    paddingTop: "8px",
                  }}
                >
                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_total_usage_limit}
                    name="limit_total"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("limit_total", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />

                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_user_limit}
                    name="limit_user"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("limit_user", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                  {values.promotion_type === "percentage_discount" && (
                    <FormikControl
                      control="input-md"
                      type="text"
                      label={strings.w_max_discount}
                      name="max_discount"
                      placeholder="100"
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("max_discount", event.target.value);
                        }
                      }}
                      divstyle={{
                        display: "inline-block",
                        width: "30%",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                      }}
                    />
                  )}
                </div>
              </div>

              <GButton
                disabled={isBtnDisabled}
                type="submit"
                variant="condensed"
                children={props.data ? strings.w_update : strings.w_create}
                style={{ width: "calc(100% - 30px)", margin: "10px 15px 0" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
