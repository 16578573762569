import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";
import useSettings from "../useSettings";
import ColorPicker from "../gComponents/gColorPicker";
import GText from "../gComponents/gText";
import GInput from "../gComponents/gInput";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function WebsiteSeoSettings(props) {
  const [strings, language] = useContext(StringsContext);
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const [linksData, setLinksData] = useState({
    home_meta_title: {},
    home_meta_description: {},
    home_meta_keywords: {},
  });

  useEffect(() => {
    getWebSettings();
  }, [language]);

  const getWebSettings = () => {
    ApiService({ method: "GET", route: "setting/web" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, selectedKey) {
    console.log(newValue + selectedFieldId);
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          linksData[selectedKey] = response.data.data;
          setLinksData(linksData);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_seo_settings}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <div
          style={{
            width: "70%",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={linksData.home_meta_title?.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.home_meta_title?.key_value}
            placeholder={linksData.home_meta_title?.key_display_title}
            className={props.display}
            id={linksData.home_meta_title?.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "home_meta_title");
            }}
            style={{ margin: "10px 0 20px" }}
          />

          <GEditable
            key={linksData.home_meta_description.id}
            type="textarea"
            title={true}
            editable={false}
            defaultValue={linksData.home_meta_description.key_value}
            placeholder={linksData.home_meta_description.key_display_title}
            className={props.display}
            id={linksData.home_meta_description.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "home_meta_description");
            }}
            style={{ margin: "10px 0 20px" }}
          />

          <GEditable
            key={linksData.home_meta_keywords.id}
            type="textarea"
            title={true}
            editable={false}
            defaultValue={linksData.home_meta_keywords.key_value}
            placeholder={linksData.home_meta_keywords.key_display_title}
            className={props.display}
            id={linksData.home_meta_keywords.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "home_meta_keywords");
            }}
            style={{ margin: "10px 0 20px" }}
          />
        </div>
      </div>
    </div>
  );
}
