import React, { useState, useEffect, useContext } from "react";
import { TableContainer, Avatar } from "@material-ui/core";
import {
  ApiService,
  ImgUrl,
  getUserType,
  isPermissionExist,
} from "../services";
import { PeopleAlt, Edit, Delete, Block } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import { useSnackbar } from "react-simple-snackbar";
import DriverCreate from "../forms/driverForm";
import TooltipComponent from "../gComponents/gTooltip";
import QueryWith from "../Query";
import { GSearch } from "../gComponents/gSearch";
import GSelectbox from "../gComponents/gSelectbox";
import useSettings from "../useSettings";
import { ReactComponent as Unblock } from "../assets/images/unlock.svg";
import Modal from "react-modal";
import { StoreFilter } from "../components/storeFilterComponent";
import { StringsContext } from "../DataFactory/useStrings";

export default function Drivers() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [cdvisible, setCdvisible] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [driverPaginationData, setDriverPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [selectedStatusType, setSelectedStatusType] = useState();
  const [selectedDriverData, setSelectedDriverData] = useState({});
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);

  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    store_id: params.get("store_id") ? params.get("store_id") : "",
    driver_status: params.get("driver_status")
      ? params.get("driver_status")
      : "",
    status: params.get("status") ? params.get("status") : "active",
  });

  let statusFilters = [
    {
      title: strings.w_active_drivers,
      value: "0",
    },
    {
      title: strings.w_blocked_drivers,
      value: "1",
    },
  ];

  const driverStatusFilters = [
    {
      title: strings.w_available_only,
      value: "1",
    },
    {
      title: strings.w_unavailable_only,
      value: "0",
    },
  ];

  if (settings.signup_agent == true) {
    statusFilters.push({
      title: strings.w_pending_approval,
      value: "2",
    });
  }

  const columns = [
    { id: "first_name", label: strings.w_name, minWidth: 130 },
    { id: "photo", label: strings.w_photo, minWidth: 50, type: "image" },
    { id: "email", label: strings.w_email, minWidth: 150 },
    { id: "phone", label: strings.w_mobile, minWidth: 120 },
    {
      id: "wallet_points_value",
      label: strings.w_balance,
      minWidth: 90,
      // format: (value) => value.toLocaleString('en-US'),
      format: (value) => value.toFixed(2),
    },
    { id: "created_at_formatted", label: strings.w_created, minWidth: 150 },
    getUserType() == true ||
    isPermissionExist("agent_update") ||
    isPermissionExist("agent_destroy")
      ? {
          id: "actions",
          label: strings.w_actions,
          minWidth: 60,
          align: "center",
        }
      : "",
  ];

  const getDrivers = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/drivers",
        state: "driver",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "drivers", body: filters })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDriverData(response.data.data.data);
          setDriverPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDrivers();
  }, [filters, history]);

  const deleteDriver = () => {
    ApiService({ method: "DELETE", route: "drivers/" + selectedDriverId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getDrivers();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const blockDriver = () => {
    var hitUrl =
      selectedStatusType == "Block"
        ? "driver/block/" + selectedDriverId
        : "driver/unblock/" + selectedDriverId;

    ApiService({ method: "POST", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getDrivers();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const acceptRejectRequestedDriver = (action) => {
    ApiService({
      method: "POST",
      route: `driver/${action}/${selectedDriverId}`,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getDrivers();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <PeopleAlt /> {strings.w_drivers}
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <GSearch
          placeholder={strings.s_search_by_name_email_or_phone}
          value={filters.search}
          handleChange={(val) =>
            setFilters({ ...filters, search: val, page: 1 })
          }
        />
        <StoreFilter
          value={filters.store_id}
          handleStoreChange={(value) => {
            setFilters({ ...filters, store_id: value || "", page: 1 });
          }}
        />
        <GSelectbox
          showSelectOption={false}
          defaultvalue={filters.status}
          data={statusFilters}
          id="d_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        <GSelectbox
          placeholder={strings.w_all_drivers}
          defaultvalue={filters.driver_status}
          data={driverStatusFilters}
          id="driver_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, driver_status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />

        {driverData.length > 0 ? (
          <React.Fragment>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={driverPaginationData.total}
              from={driverPaginationData.from}
              to={driverPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(driverPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(driverPaginationData.prev_page_url)
              }
              rowsPerPage={driverPaginationData.per_page}
              prevDisabled={driverPaginationData.prev_page_url ? false : true}
              nextDisabled={driverPaginationData.next_page_url ? false : true}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={driverPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
      <div className="listPaper">
        {!driverPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {driverData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {driverData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          <Link
                            to={{
                              pathname: "/driver/" + c.id + "/tasks",
                              state: "driver",
                            }}
                          >
                            {c.first_name} {c.last_name}
                          </Link>
                        </TableRowData>
                        <TableRowData>
                          <Avatar
                            alt={c.first_name}
                            src={ImgUrl("user") + "/" + c.thumb_photo}
                          />
                        </TableRowData>
                        <TableRowData>{c.email}</TableRowData>
                        <TableRowData>{c.phone}</TableRowData>
                        <TableRowData>
                          {settings.currency_symbol + "" + c.balance}
                        </TableRowData>
                        <TableRowData>{c.created}</TableRowData>
                        {getUserType() == true ||
                        isPermissionExist("agent_update") ||
                        isPermissionExist("agent_destroy") ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {getUserType() == true && c.status === 0 && (
                              <TooltipComponent
                                title={strings.w_block}
                                position="top"
                                id="block_cutomer"
                                divStyle={{
                                  verticalAlign: "unset",
                                  margin: "0",
                                }}
                                tooltipStyle={{
                                  minWidth: "65px",
                                  padding: "5px",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedStatusType("Block");
                                    setSelectedDriverId(c.id);
                                    setBlockDialogOpen(true);
                                  }}
                                >
                                  <Block
                                    fontSize="small"
                                    style={{ color: "#D8AE36" }}
                                  />
                                </GButton>
                              </TooltipComponent>
                            )}
                            {getUserType() == true && c.status === 1 && (
                              <TooltipComponent
                                title={strings.w_unblock}
                                position="top"
                                id="unblock_cutomer"
                                divStyle={{
                                  verticalAlign: "unset",
                                  margin: "0",
                                }}
                                tooltipStyle={{
                                  minWidth: "65px",
                                  padding: "5px",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedStatusType("Unblock");
                                    setSelectedDriverId(c.id);
                                    setBlockDialogOpen(true);
                                  }}
                                >
                                  <Unblock
                                    fontSize="small"
                                    style={{
                                      fill: "#bbb",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                </GButton>
                              </TooltipComponent>
                            )}

                            {c.status == 2 ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "6px",
                                  justifyContent: "center",
                                }}
                              >
                                <GButton
                                  variant="condensed"
                                  onClick={(e) => {
                                    setSelectedDriverId(c.id);
                                    setAcceptDialogOpen(true);
                                  }}
                                  style={{ padding: "0.4em 1em" }}
                                >
                                  {strings.w_accept}
                                </GButton>
                                <GButton
                                  variant="condensed"
                                  onClick={(e) => {
                                    setSelectedDriverId(c.id);
                                    setRejectDialogOpen(true);
                                  }}
                                  style={{ padding: "0.4em 1em" }}
                                >
                                  {strings.w_reject}
                                </GButton>
                              </div>
                            ) : (
                              <>
                                {getUserType() == true ||
                                isPermissionExist("agent_update") ? (
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      setSelectedDriverData(c);
                                      setCdvisible(true);
                                    }}
                                  >
                                    <Edit fontSize="small" />
                                  </GButton>
                                ) : (
                                  ""
                                )}
                                {getUserType() == true ||
                                isPermissionExist("agent_destroy") ? (
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      setSelectedDriverId(c.id);
                                      setDialogOpen(true);
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </GButton>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_driver_title}
          text={strings.s_delete_driver_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteDriver();
          }}
        ></GConfirmDialog>
      )}

      {blockDialogOpen === true && (
        <GConfirmDialog
          open={blockDialogOpen}
          title={
            selectedStatusType == "Block"
              ? strings.w_block_driver
              : strings.w_unblock_driver
          }
          text={
            selectedStatusType == "Block"
              ? strings.s_are_you_sure_block_driver
              : strings.s_are_you_sure_unblock_driver
          }
          handleClose={() => setBlockDialogOpen(false)}
          onConfirmClose={() => {
            setBlockDialogOpen(false);
            blockDriver();
          }}
        ></GConfirmDialog>
      )}

      {acceptDialogOpen === true && (
        <GConfirmDialog
          open={acceptDialogOpen}
          title={strings.s_accpet_signup_request}
          text={strings.s_are_you_sure_accpet_driver_signup_request}
          handleClose={() => setAcceptDialogOpen(false)}
          onConfirmClose={() => {
            setAcceptDialogOpen(false);
            acceptRejectRequestedDriver("approve");
          }}
        ></GConfirmDialog>
      )}

      {rejectDialogOpen === true && (
        <GConfirmDialog
          open={rejectDialogOpen}
          title={strings.s_reject_signup_request}
          text={strings.s_are_you_sure_reject_driver_signup_request}
          handleClose={() => setRejectDialogOpen(false)}
          onConfirmClose={() => {
            setRejectDialogOpen(false);
            acceptRejectRequestedDriver("reject");
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={cdvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCdvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Driver Form Modal"
      >
        <DriverCreate
          cdvisible={cdvisible}
          setCdvisible={setCdvisible}
          data={selectedDriverData}
          onSubmit={getDrivers}
          settings={settings}
        />
      </Modal>
    </div>
  );
}
