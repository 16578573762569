import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import storePlaceholder from "../assets/images/placeholders/p-store.png";
import { useSnackbar } from "react-simple-snackbar";
import {
  ImgUrl,
  ApiService,
  getUserType,
  isPermissionExist,
} from "../services";
import { StorefrontSharp } from "@material-ui/icons";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import TooltipComponent from "../gComponents/gTooltip";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GSelectbox from "../gComponents/gSelectbox";
import QueryWith from "../Query";
import useSettings from "../useSettings";
import useDataFactory from "../useDataFactory";
import Modal from "react-modal";
import StoreEditForm from "../forms/storeEditForm";
import GSwitch from "../gComponents/gSwitch";
import CreateDuplicateStore from "../forms/storeDuplicateCreateForm";
import GActions from "../gComponents/gActionsStyle";
import { StringsContext } from "../DataFactory/useStrings";

export default function Stores() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [storesData, setStoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [storePaginationData, setStorePaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [selectedStatusType, setSelectedStatusType] = useState();
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [selectedStoreData, setSelectedStoreData] = useState("");
  const [sevisible, setSevisible] = useState(false);
  const [showStoreDupilcateForm, setShowStoreDupilcateForm] = useState(false);
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    store_status: params.get("store_status") ? params.get("store_status") : "",
    branch_id: params.get("branch_id") ? params.get("branch_id") : "",
    status: params.get("status") ? params.get("status") : "active",
    theme: params.get("theme") ? params.get("theme") : "restaurant",
  });
  const { data: branchesData } = useDataFactory("branches", false);

  const availabilityFilters = [
    {
      title: strings.w_open_only,
      value: "1",
    },
    {
      title: strings.w_closed_only,
      value: "0",
    },
  ];

  let statusFilters = [
    {
      title: strings.w_active_stores,
      value: "0",
    },
    {
      title: strings.w_blocked_stores,
      value: "1",
    },
  ];

  const themeFilters = [
    { title: strings.w_restaurant, value: "restaurant" },
    { title: strings.w_grocery, value: "grocery" },
  ];
  if (settings.signup_vendor == true) {
    statusFilters.push({
      title: strings.w_pending_approval,
      value: "2",
    });
  }

  const columns = [
    { id: "id", label: strings.w_id, minWidth: 30 },
    { id: "title", label: strings.w_title, minWidth: 100 },
    { id: "address", label: strings.w_address, minWidth: 180 },
    { id: "photo", label: strings.w_photo, minWidth: 70, type: "image" },
    {
      id: "manager",
      label: strings.w_store_manager,
      minWidth: 120,
      align: "center",
    },
    {
      id: "wallet_points_value",
      label: strings.w_balance,
      minWidth: 100,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "status",
      label: strings.w_availability,
      minWidth: 60,
      align: "right",
    },
    {
      id: "created_at_formatted",
      label: strings.w_created,
      minWidth: 150,
      align: "center",
    },
    getUserType() == true ||
    isPermissionExist("store_store") ||
    isPermissionExist("store_update") ||
    isPermissionExist("store_destroy")
      ? {
          id: "actions",
          label: strings.w_actions,
          minWidth: 60,
          align: "right",
        }
      : "",
  ];

  const getStores = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/stores",
        state: "store",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "stores", body: filters })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setStoreData(response.data.data.data);
          setStorePaginationData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStores();
  }, [filters]);

  function addDefaultLogo(e) {
    e.target.src = storePlaceholder;
  }

  const deleteStore = () => {
    ApiService({ method: "DELETE", route: "stores/" + selectedStoreId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getStores();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const blockStore = () => {
    var hitUrl =
      selectedStatusType === "Block"
        ? "store/block/" + selectedStoreId
        : "store/unblock/" + selectedStoreId;
    ApiService({ method: "POST", route: hitUrl })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getStores();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const handleToggleStatus = (newVal, id, ind) => {
    ApiService({
      method: "PUT",
      route: "stores/" + id,
      body: {
        store_status: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          storesData[ind].store_status = newVal;
          setStoreData([...storesData]);
        } else {
          getStores();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const acceptRejectRequestedStore = (action) => {
    ApiService({ method: "POST", route: `store/${action}/${selectedStoreId}` })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getStores();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <StorefrontSharp /> {strings.w_stores}
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <GSelectbox
          showSelectOption={false}
          defaultvalue={filters.status}
          data={statusFilters}
          id="s_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        <GSelectbox
          placeholder={strings.w_all_available}
          defaultvalue={filters.store_status}
          data={availabilityFilters}
          id="store_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, store_status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        {getUserType() ? (
          <GSelectbox
            placeholder={strings.w_all_branches}
            defaultvalue={filters.branch_id}
            data={branchesData}
            id="store_branch_filter"
            option_key="title"
            option_value="id"
            onChange={(newVal) => {
              setFilters({ ...filters, branch_id: newVal, page: 1 });
            }}
            style={{
              border: "1px solid #e2e2e2",
              height: "40px",
              minWidth: "150px",
            }}
          />
        ) : (
          ""
        )}

        <GSelectbox
          showSelectOption={false}
          // placeholder={strings.w_theme}
          defaultvalue={filters.theme}
          data={themeFilters}
          id="theme_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, theme: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />

        {storesData.length > 0 && (
          <React.Fragment>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={storePaginationData.total}
              from={storePaginationData.from}
              to={storePaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(storePaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(storePaginationData.prev_page_url)
              }
              rowsPerPage={storePaginationData.per_page}
              prevDisabled={storePaginationData.prev_page_url ? false : true}
              nextDisabled={storePaginationData.next_page_url ? false : true}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={storePaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </React.Fragment>
        )}
      </div>

      <div className="listPaper" style={{ marginTop: "15px" }}>
        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {storesData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((c) => {
                        if (
                          getUserType() == true ||
                          isPermissionExist("store_destroy")
                        ) {
                          return c;
                        } else {
                          return c.id !== "actions";
                        }
                      })
                      .map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storesData.map((s, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        <TableRowData>#{s.id}</TableRowData>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/store/" + s.id + "/orders",
                              state: "store",
                            }}
                          >
                            {s.title}
                          </Link>
                        </TableRowData>
                        <TableRowData>{s.address}</TableRowData>
                        <TableRowData>
                          <img
                            alt={s.title}
                            src={ImgUrl("store") + "/" + s.thumb_photo}
                            onError={addDefaultLogo}
                            style={{
                              height: "45px",
                              width: "45px",
                              borderRadius: "50%",
                            }}
                          />
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {s.manager.title ? s.manager.title : "NA"}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {settings.currency_symbol + "" + s.balance}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {getUserType() ||
                          isPermissionExist("store_update") ? (
                            <GSwitch
                              id={"store" + s.id}
                              uId={s.id}
                              currentValue={s.store_status == 1 ? 1 : 0}
                              onSwitchChange={(newVal, id) =>
                                handleToggleStatus(newVal, id, i)
                              }
                              style={{ display: "inline-block" }}
                            />
                          ) : s.store_status == 1 ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {s.created}
                        </TableRowData>
                        {getUserType() == true ||
                        isPermissionExist("store_update") ||
                        isPermissionExist("store_destroy") ||
                        isPermissionExist("store_store") ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {s.status == 2 ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                }}
                              >
                                <GButton
                                  variant="condensed"
                                  onClick={(e) => {
                                    setSelectedStoreId(s.id);
                                    setAcceptDialogOpen(true);
                                  }}
                                  style={{
                                    padding: "0.4em 1em",
                                  }}
                                >
                                  {strings.w_accept}
                                </GButton>
                                <GButton
                                  variant="condensed"
                                  onClick={(e) => {
                                    setSelectedStoreId(s.id);
                                    setRejectDialogOpen(true);
                                  }}
                                  style={{ padding: "0.4em 1em" }}
                                >
                                  {strings.w_reject}
                                </GButton>
                              </div>
                            ) : (
                              <div style={{ position: "relative" }}>
                                <GActions
                                  edit={
                                    getUserType() == true ||
                                    isPermissionExist("store_update")
                                      ? true
                                      : false
                                  }
                                  delete={
                                    getUserType() == true ||
                                    isPermissionExist("store_destroy")
                                      ? true
                                      : false
                                  }
                                  duplicate={
                                    getUserType() == true ||
                                    isPermissionExist("store_store")
                                      ? true
                                      : false
                                  }
                                  block={
                                    getUserType() == true && s.status === 0
                                      ? true
                                      : false
                                  }
                                  unblock={
                                    getUserType() == true && s.status === 1
                                      ? true
                                      : false
                                  }
                                  onEdit={() => {
                                    setSelectedStoreData(s);
                                    setSevisible(true);
                                  }}
                                  onDelete={() => {
                                    setSelectedStoreId(s.id);
                                    setDialogOpen(true);
                                  }}
                                  onDuplicate={() => {
                                    setSelectedStoreData(s);
                                    setShowStoreDupilcateForm(true);
                                  }}
                                  onBlock={() => {
                                    setSelectedStatusType("Block");
                                    setSelectedStoreId(s.id);
                                    setBlockDialogOpen(true);
                                  }}
                                  onUnblock={() => {
                                    setSelectedStatusType("Unblock");
                                    setSelectedStoreId(s.id);
                                    setBlockDialogOpen(true);
                                  }}
                                  style={{ verticalAlign: "middle" }}
                                  menuStyle={{ fontWeight: "400" }}
                                />
                              </div>
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>
      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_store_title}
          text={strings.s_delete_store_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteStore();
          }}
        ></GConfirmDialog>
      )}

      {blockDialogOpen === true && (
        <GConfirmDialog
          open={blockDialogOpen}
          title={
            selectedStatusType == "Block"
              ? strings.w_block_store
              : strings.w_unblock_store
          }
          text={
            selectedStatusType == "Block"
              ? strings.s_are_you_sure_block_store
              : strings.s_are_you_sure_unblock_store
          }
          handleClose={() => setBlockDialogOpen(false)}
          onConfirmClose={() => {
            setBlockDialogOpen(false);
            blockStore();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={sevisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSevisible(false)}
        style={{
          content: {
            width: "480px",
          },
        }}
        contentLabel="Store Edit Form Modal"
      >
        <StoreEditForm
          sevisible={sevisible}
          setSevisible={setSevisible}
          data={selectedStoreData}
          onSubmit={getStores}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={showStoreDupilcateForm}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowStoreDupilcateForm(false)}
        style={{
          content: {
            width: "480px",
          },
        }}
        contentLabel="Store Duplicate Create Form Modal"
      >
        <CreateDuplicateStore
          showStoreDupilcateForm={showStoreDupilcateForm}
          setShowStoreDupilcateForm={setShowStoreDupilcateForm}
          data={selectedStoreData}
          onSubmit={() => getStores()}
          settings={settings}
        />
      </Modal>

      {acceptDialogOpen === true && (
        <GConfirmDialog
          open={acceptDialogOpen}
          title={strings.s_accpet_signup_request}
          text={strings.s_are_you_sure_accpet_store_signup_request}
          handleClose={() => setAcceptDialogOpen(false)}
          onConfirmClose={() => {
            setAcceptDialogOpen(false);
            acceptRejectRequestedStore("approve");
          }}
        ></GConfirmDialog>
      )}
      {rejectDialogOpen === true && (
        <GConfirmDialog
          open={rejectDialogOpen}
          title={strings.s_reject_signup_request}
          text={strings.s_are_you_sure_reject_store_signup_request}
          handleClose={() => setRejectDialogOpen(false)}
          onConfirmClose={() => {
            setRejectDialogOpen(false);
            acceptRejectRequestedStore("reject");
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}
