import React, { useEffect, useState, useContext } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GPagination from "../gComponents/gPagination";
import styled from "styled-components";
import { FiberManualRecord } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GEmpty from "../gComponents/gEmpty";
import { StringsContext } from "../DataFactory/useStrings";

const DListCard = styled.div`
  padding: 0.8em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  background: #f4f5f8;
  vertical-align: top;
  width: 45%;
  box-sizing: border-box;
  display: inline-block;
  margin: 10px;
  cursor: pointer-events;

  &.active {
    background: #cbe1fc;
  }
  h4 {
    margin: 0px;
    font-weight: 400;
    display: inline-block;
    width: 70%;
    font-size: 15px;
    color: #d8ae36;
  }
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #777;
    font-size: 14px;
    padding: 3px 0;
    float: right;
    span {
      background: #fff;
      height: 24px;
      text-align: center;
      width: 25px;
      font-size: 16px;
      display: block;
      padding-top: 0px;
      border: 1px solid #e2e2e2;
      border-radius: 50%;
    }
  }
  p {
    margin: 0px;
    font-weight: 400;
    display: inline-block;
    width: 50%;
    font-size: 0.9rem;
    font-style: italic;
    color: #818183;
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AssignDriver(props) {
  const classes = useStyles();
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [assignDriversList, setAssignDrivers] = useState([]);
  const [driverPagination, setDriverPagination] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedPage, setSelectedPage] = useState("1");
  const [searchValue, setSerachVal] = useState("");

  useEffect(() => {
    function getActiveDrivers() {
      ApiService({
        method: "GET",
        route:
          "drivers?per_page=12&page=" +
          selectedPage +
          "&order_delivery_id=" +
          props.linkedId +
          "&search=" +
          searchValue,
      }).then((response) => {
        console.log(response?.data);
        setAssignDrivers(response?.data?.data?.data || []);
        setDriverPagination(response?.data?.data || {});
      });
    }
    getActiveDrivers();
  }, [props, selectedPage, searchValue]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setSelectedPage(NewPageNo);
  };

  const onSelectDriver = () => {
    var values = {
      agent_id: selectedDriver,
      order_delivery_id: props.linkedId,
    };
    console.log(values);
    //return;
    ApiService({
      method: "POST",
      route: "order/delivery/assign",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setAdvisible(false);
        props.onSubmit();
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setAdvisible(false);
  };

  if (props.advisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.advisible}
        onClick={handleClose}
      >
        <Paper
          className={`modalRight
              ${props.advisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>{strings.w_assign_driver}</h3>

          <div style={{ margin: "0 15px 20px" }}>
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSerachVal(e.target.value)}
              placeholder={strings.s_search_driver}
              style={{
                border: "1px solid #e2e2e2",
                float: "right",
                height: "32px",
                padding: "0 10px",
                marginTop: "-10px",
              }}
            />
            <label>
              {strings.w_select} ({assignDriversList.length})
            </label>
          </div>
          {assignDriversList && assignDriversList?.length > 0 ? (
            <GPagination
              count={driverPagination.total}
              from={driverPagination.from}
              to={driverPagination.to}
              onChangeNextPage={() =>
                handleChangePage(driverPagination.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(driverPagination.prev_page_url)
              }
              rowsPerPage={driverPagination.per_page}
              prevDisabled={driverPagination.prev_page_url ? false : true}
              nextDisabled={driverPagination.next_page_url ? false : true}
            ></GPagination>
          ) : (
            ""
          )}
          <div>
            {assignDriversList.map((d, i) => {
              return (
                <DListCard
                  key={i}
                  onClick={() => setSelectedDriver(d.id)}
                  className={selectedDriver === d.id ? "active" : ""}
                >
                  <h5>
                    <span>{d.active_task_count || 0}</span>
                    {strings.w_task}
                  </h5>
                  <h4>
                    {d.driver_status === 1 && (
                      <FiberManualRecord
                        fontSize="small"
                        style={{
                          color: "#2bac76",
                          fontSize: "12px",
                          paddingRight: "5px",
                        }}
                      />
                    )}
                    {d.title}
                  </h4>
                  <p>{d.phone}</p>
                </DListCard>
              );
            })}
          </div>

          {assignDriversList && assignDriversList?.length > 0 ? (
            <GButton
              variant="condensed"
              children={strings.w_assign_task}
              onClick={() => {
                onSelectDriver();
              }}
              style={{
                margin: "10px 15px",
                float: "right",
              }}
            ></GButton>
          ) : (
            <GEmpty />
          )}
        </Paper>
      </Backdrop>
    </div>
  );
}
