import React, { Component, useRef, useEffect, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Pickup from "../assets/images/stop-pickup.svg";
import Dropoff from "../assets/images/stop-dropoff.svg";
import driverIcon from "../assets/images/driver-circle.svg";
import driverOfflineIcon from "../assets/images/driver-circle-offline.svg";
import driverIdleIcon from "../assets/images/driver-circle-idle.svg";
import storeIcon from "../assets/images/marker-store.svg";
import MapStyles from "./mapStyles.json";

const GMap = (props) => {
  const googleMapRef = useRef(null);
  // list of icons
  var googleMap;
  var bounds;
  // list of the marker object along with icon
  // const [googleMap, setGoogleMap] = useState();
  const [dMarkerList, setDMarkerList] = useState([]);
  const [sMarkerList, setSMarkerList] = useState([]);
  const [rMarkerList, setRMarkerList] = useState([]);
  const [storeLocations, setStoreLocations] = useState(
    props.storesLocations || []
  );
  const [driverLocations, setDriverLocations] = useState(
    props.driversLocations || []
  );
  const [markers, setMarkers] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [routeMapMarkers, setRouteMapMarkers] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [locateDriver, setLocateDriver] = useState({});

  var numDeltas = 100;
  var delay = 20; //milliseconds
  var i = 0;
  var deltaLat;
  var deltaLng;

  // if (googleMap && typeof onMount === `function`) onMount(googleMap, onMountProps);

  useEffect(() => {
    // setGoogleMap(initGoogleMap());
    googleMap = initGoogleMap();
  }, []);

  function InitialMap() {
    console.log(props);
    if (props.markers.length == 0) {
      if (
        props.locationsData?.stores.length > 0 ||
        props.locationsData?.drivers.length > 0
      ) {
        // console.log("n empty");
        setDriverLocations(props.locationsData?.drivers || []);
        setStoreLocations(props.locationsData?.stores || []);
        setMarkers([]);
        setDMarkerList([]);
        setSMarkerList([]);
        setRouteMapMarkers([]);
        setRMarkerList([]);

        setInitialLoading(false);
      } else if (props.chooseDriver?.id) {
        setDriverLocations(props.locationsData?.drivers || []);
        setStoreLocations(props.locationsData?.stores || []);
        setMarkers([]);
        setDMarkerList([]);
        setSMarkerList([]);
        setRouteMapMarkers([]);
        setRMarkerList([]);
        let newMarkers = [];
        newMarkers.push({
          lat: parseFloat(props.chooseDriver?.latitude),
          lng: parseFloat(props.chooseDriver?.longitude),
          icon:
            props.chooseDriver.type == 1
              ? driverIcon
              : props.chooseDriver.type == 2
              ? driverIdleIcon
              : driverOfflineIcon,
          title: props.chooseDriver?.title,
          address: props.chooseDriver.phone,
          id: props.chooseDriver?.id,
          info: props.chooseDriver,
          type: "driver",
        });
        setMarkers([...newMarkers]);
      } else {
        // console.log("empty");
        googleMap = initGoogleMap();
        bounds = new window.google.maps.LatLngBounds();
        googleMap.setCenter({
          lat: process.env.REACT_APP_DEFAULT_LATITUDE,
          lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
        });
        return;
      }
    } else {
      if (props.chooseDriver?.id) {
        setDriverLocations(props.locationsData?.drivers || []);
        setStoreLocations(props.locationsData?.stores || []);
        setMarkers([]);
        setDMarkerList([]);
        setSMarkerList([]);
        setRouteMapMarkers([]);
        setRMarkerList([]);
        let newMarkers = [];
        newMarkers.push({
          lat: parseFloat(props.chooseDriver?.latitude),
          lng: parseFloat(props.chooseDriver?.longitude),
          icon:
            props.chooseDriver.type == 1
              ? driverIcon
              : props.chooseDriver.type == 2
              ? driverIdleIcon
              : driverOfflineIcon,
          title: props.chooseDriver?.title,
          address: props.chooseDriver.phone,
          id: props.chooseDriver?.id,
          info: props.chooseDriver,
          type: "driver",
        });
        setMarkers([...newMarkers]);
      } else {
        setRouteMapMarkers([]);
        setRMarkerList([]);
        setMarkers([]);
        setDMarkerList([]);
        setSMarkerList([]);
      }
    }
  }

  useEffect(() => {
    InitialMap();
  }, [props.locationsData, props.markers]);

  useEffect(() => {
    console.log(props.isLocateDriver);
    if (props.isLocateDriver == true) {
      setLocateDriver(props.chooseDriver);
      InitialMap();
      props.setIsLocateDriver(false);
    }
  }, [props.isLocateDriver]);

  useEffect(() => {
    console.log(markers);
    if (markers.length == 0) {
      //googleMap = initGoogleMap();
      // bounds = new window.google.maps.LatLngBounds();

      if (
        props.locationsData?.drivers.length == 0 &&
        props.locationsData?.stores.length == 0
      ) {
        setInitializeGoogle();
      } else {
        let newMarkers = [];
        if (props.locationsData?.drivers.length > 0) {
          props.locationsData.drivers.map((d, i) => {
            let findInd = markers.findIndex((m) => d.id == m.id);
            if (d?.latitude && findInd == -1) {
              newMarkers.push({
                lat: parseFloat(d?.latitude),
                lng: parseFloat(d?.longitude),
                icon:
                  d.type == 1
                    ? driverIcon
                    : d.type == 2
                    ? driverIdleIcon
                    : driverOfflineIcon,
                title: d?.title,
                address: d.phone,
                id: d?.id,
                info: d,
                type: "driver",
              });
            }
          });
        }

        if (props.locationsData?.stores.length > 0) {
          props.locationsData.stores.map((d, i) => {
            let findIndd = markers.findIndex((m) => d.id == m.id);
            if (d?.latitude && findIndd == -1) {
              if (d?.latitude) {
                newMarkers.push({
                  lat: parseFloat(d?.latitude),
                  lng: parseFloat(d?.longitude),
                  icon: storeIcon,
                  title: d?.title,
                  address: d.address,
                  id: d?.id,
                  info: d,
                  type: "store",
                });
              }
            }
          });
        }

        if (props.chooseDriver?.id) {
          let existInd = newMarkers.findIndex(
            (n) => n.id == props.chooseDriver?.id
          );
          if (existInd == -1) {
            newMarkers.push({
              lat: parseFloat(props.chooseDriver?.latitude),
              lng: parseFloat(props.chooseDriver?.longitude),
              icon:
                props.chooseDriver.type == 1
                  ? driverIcon
                  : props.chooseDriver.type == 2
                  ? driverIdleIcon
                  : driverOfflineIcon,
              title: props.chooseDriver?.title,
              address: props.chooseDriver.phone,
              id: props.chooseDriver?.id,
              info: props.chooseDriver,
              type: "driver",
            });
          }
        }
        setMarkers([...newMarkers]);
      }
    } else {
      setInitializeGoogle();
    }
  }, [markers]);

  function setInitializeGoogle() {
    setMarkerList([...markers]);
  }

  function render({ count, position }, stats) {
    // change color if this cluster has more markers than the mean cluster
    const color =
      count > Math.max(10, stats.clusters.markers.mean) ? "#F23937" : "#F23937";
    // create svg url with fill color
    const svg = window.btoa(`
<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
</svg>`);
    // create marker using svg icon
    return new window.google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new window.google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "12px",
      },
      title: `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
    });
  }

  const showMarkers = () => {
    if (routeMapMarkers.length > 0) {
      getRouteDirections();
    } else {
      googleMap = initGoogleMap();
      // console.log(googleMap);
      bounds = new window.google.maps.LatLngBounds();

      if (markerList.length > 0) {
        markerList.map((x) => {
          const marker = createMarker(x);
          const infoWindow = createInfoWindow(x);
          window.google.maps.event.addListener(marker, "click", function () {
            infoWindow.close(); // Close previously opened infowindow
            infoWindow.setContent(
              x.type == "driver"
                ? '<h4 class="infowindowstyle" style="margin:0">' +
                    (x?.info?.active_task_count > 0
                      ? x.title + " (" + x?.info?.active_task_count + ")"
                      : x.title) +
                    '</h4><p style="margin:2px 0 0">' +
                    (x.info?.driver_status == 1
                      ? x.info?.location_updated
                        ? "Active . Updated " + x.info?.location_updated
                        : "Active"
                      : x.info?.location_updated
                      ? "Offline . Updated " + x.info?.location_updated
                      : "Offline") +
                    "</p>"
                : '<h4 class="infowindowstyle" style="margin:2px 0">' +
                    (x?.info?.active_task_count > 0
                      ? x.title + " (" + x?.info?.active_task_count + ")"
                      : x.title) +
                    '</h4><p style="margin:2px 0">' +
                    x.address +
                    "</p>"
            );
            infoWindow.open(googleMap, marker);
          });

          if (x.type == "driver") {
            let fInd = dMarkerList.findIndex((m) => m.id == marker.id);
            if (fInd == -1) {
              dMarkerList.push(marker);
              setDMarkerList([...dMarkerList]);
            }
          }
          if (x.type == "store") {
            let fIndx = sMarkerList.findIndex((s) => s.id == marker.id);
            if (fIndx == -1) {
              sMarkerList.push(marker);
              setSMarkerList([...sMarkerList]);
            }
          }

          if (props.chooseDriver?.id) {
            if (x.id == props.chooseDriver?.id) {
              infoWindow.open(googleMap, marker);
            }
          }
          if (props.markers.length == 0 && routeMapMarkers.length == 0) {
            if (props.chooseDriver?.id) {
              if (x.id == props.chooseDriver?.id) {
                bounds.extend(marker.position);
              }
            } else {
              bounds.extend(marker.position);
            }
          }
        });

        //console.log(dMarkerList);
        if (dMarkerList.length > 0 || sMarkerList.length > 0) {
          const markerCluster = new MarkerClusterer(
            // { googleMap, dMarkerList },
            {
              map: googleMap,
              markers: dMarkerList.concat(sMarkerList),
              renderer: {
                render,
              },
            }
          );
          //  console.log(markerCluster);
        }

        //  console.log(props.markers);
        if (props.markers.length == 0 && routeMapMarkers.length == 0) {
          googleMap.setCenter(bounds.getCenter());
          googleMap.fitBounds(bounds); // the map to contain all markers

          googleMap.addListener("click", function (e) {
            var result = [e.latLng.lat(), e.latLng.lng()];
            //console.log("result" + result);
            //transition(marker, 0.5, marker.position, result, i);
          });

          var listener = window.google.maps.event.addListener(
            googleMap,
            "idle",
            function () {
              if (props.chooseDriver?.id) {
                googleMap.setZoom(15);
              }
              console.log(dMarkerList.concat(sMarkerList).length);
              if (dMarkerList.concat(sMarkerList).length == 1) {
                googleMap.setZoom(15);
              }
              window.google.maps.event.removeListener(listener);
            }
          );
        }
      } else {
        googleMap.setCenter({
          lat: process.env.REACT_APP_DEFAULT_LATITUDE,
          lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
        });
      }
    }
  };

  useEffect(() => {
    showMarkers();
  }, [markerList]);

  useEffect(() => {
    function transition(result) {
      i = 0;
      deltaLat = (result[0] - latitude) / numDeltas;
      deltaLng = (result[1] - longitude) / numDeltas;
      moveMarker();
    }

    function moveMarker() {
      if (dMarkerList[index]) {
        latitude += deltaLat;
        longitude += deltaLng;
        var latlng = new window.google.maps.LatLng(latitude, longitude);
        dMarkerList[index].setPosition(latlng);
        dMarkerList[index].info.location_updated = "just now";
        dMarkerList[index].latitude = latitude;
        dMarkerList[index].longitude = longitude;
        // setDMarkerList([...dMarkerList]);
        if (i != numDeltas) {
          i++;
          setTimeout(moveMarker, delay);
        }
      }
    }
    if (initialLoading === false) {
      var latitude = null;
      var longitude = null;
      var index = null;

      if (dMarkerList.length > 0) {
        dMarkerList.map((d, i) => {
          if (d?.id == props.updatedDriver.id) {
            latitude = d.position.lat();
            longitude = d.position.lng();
            index = i;
            var result = [
              props.updatedDriver.latitude,
              props.updatedDriver.longitude,
            ];
            transition(result);
            //  var latlng = new window.google.maps.LatLng(props.updatedDriver.latitude, props.updatedDriver.longitude );
            //  setTimeout(d.setPosition(latlng) , delay);
          }
        });
      }
    }
  }, [props.updatedDriver]);

  function showRoute(googleMap) {
    if (props.routeJsonData?.routes && props.routeJsonData?.routes.length > 0) {
      if (typeof window.google.maps.geometry !== "undefined") {
        var polyline = new window.google.maps.Polyline({
          path: window.google.maps.geometry?.encoding?.decodePath(
            props.routeJsonData?.routes[0].overview_polyline.points
          ),
          map: googleMap,
          strokeColor: "#6B6A78",
          strokeWeight: 6,
        });
      }
    }
  }

  function getRouteDirections() {
    if (routeMapMarkers.length == 2 && rMarkerList.length == 0) {
      let arr3 = markerList.concat(routeMapMarkers);

      // console.log(arr3);

      googleMap = initGoogleMap();
      bounds = new window.google.maps.LatLngBounds();

      arr3.map((x) => {
        const marker = createMarker(x);
        const infoWindow = createInfoWindow(x);
        //console.log(x);
        if (x.title == "Pickup" || x.title == "Dropoff") {
          window.google.maps.event.addListener(marker, "click", function () {
            infoWindow.close(); // Close previously opened infowindow
            infoWindow.setContent(
              "<p style='margin:2px 0'>#" +
                x.info?.orderId +
                " - " +
                x.title +
                '</p><p class="infowindowstyle" style="margin:0;color:#aaa">' +
                x.address +
                "</p>"
            );
            infoWindow.open(googleMap, marker);
          });
          // window.google.maps.event.addListener(marker, "mouseout", function () {
          //   infoWindow.close();
          // });
        } else {
          window.google.maps.event.addListener(marker, "click", function () {
            infoWindow.close(); // Close previously opened infowindow
            infoWindow.setContent(
              x.type == "driver"
                ? '<h4 class="infowindowstyle" style="margin:0">' +
                    (x?.info?.active_task_count > 0
                      ? x.title + " (" + x?.info?.active_task_count + ")"
                      : x.title) +
                    '</h4><p style="margin:2px 0 0">' +
                    (x.info?.driver_status == 1
                      ? x.info?.location_updated
                        ? "Active . Updated " + x.info?.location_updated
                        : "Active"
                      : x.info?.location_updated
                      ? "Offline . Updated " + x.info?.location_updated
                      : "Offline") +
                    "</p>"
                : '<h4 class="infowindowstyle" style="margin:0">' +
                    x.title +
                    '</h4><p style="margin:2px 0 0">' +
                    x.address +
                    "</p>"
            );
            infoWindow.open(googleMap, marker);
          });

          //infoWindow.open(googleMap, marker);
        }

        if (x.title == "Pickup" || x.title == "Dropoff") {
          console.log(routeMapMarkers);
          rMarkerList.push(marker);
          setRMarkerList([...rMarkerList]);
          if (!props.chooseDriver?.id) {
            bounds.extend(marker.position);
          }
        } else {
          if (x.type == "driver") {
            dMarkerList.push(marker);
            setDMarkerList([...dMarkerList]);
          }
          if (x.type == "store") {
            sMarkerList.push(marker);
            setSMarkerList([...sMarkerList]);
          }
        }

        if (props.chooseDriver?.id) {
          if (x.id == props.chooseDriver?.id) {
            infoWindow.open(googleMap, marker);
            bounds.extend(marker.position);
          }
        }

        marker.setMap(googleMap);
      });

      if (dMarkerList.length > 0 || sMarkerList.length > 0) {
        const markerCluster = new MarkerClusterer({
          map: googleMap,
          markers: dMarkerList.concat(sMarkerList),
          renderer: {
            render,
          },
        });
        // console.log(markerCluster);
      }

      // console.log(bounds);
      // console.log(bounds.getCenter());
      googleMap.setCenter(bounds.getCenter());
      googleMap.fitBounds(bounds); // the map to contain all markers

      googleMap.addListener("click", function (e) {
        var result = [e.latLng.lat(), e.latLng.lng()];
        //console.log("result" + result);
        // transition(marker, .5, marker.position, result, i);
      });

      var listener = window.google.maps.event.addListener(
        googleMap,
        "idle",
        function () {
          if (props.chooseDriver?.id) {
            googleMap.setZoom(15);
          }
          window.google.maps.event.removeListener(listener);
        }
      );
      showRoute(googleMap);
      setInitialLoading(false);
    }
  }

  useEffect(() => {
    if (props.markers.length > 0) {
      if (routeMapMarkers.length == 0) {
        //  console.log(routeMapMarkers);
        let route = [];
        props.markers.map((m, i) => {
          if (m?.latitude) {
            route.push({
              lat: parseFloat(m?.latitude),
              lng: parseFloat(m?.longitude),
              icon: i === 0 ? Pickup : Dropoff,
              title: i === 0 ? "Pickup" : "Dropoff",
              address: m.formatted,
              info: m,
              id: "",
              type: "location",
            });
          }
        });
        setRouteMapMarkers([...route]);
      } else {
        // getRouteDirections();
      }
    }
  }, [routeMapMarkers]);

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: {
        lat:
          props.driversLocations.length > 0
            ? props.driversLocations[0]?.latitude
              ? parseFloat(props.driversLocations[0].latitude)
              : process.env.REACT_APP_DEFAULT_LATITUDE
            : process.env.REACT_APP_DEFAULT_LATITUDE,
        lng:
          props.driversLocations.length > 0
            ? props.driversLocations[0]?.longitude
              ? parseFloat(props.driversLocations[0].longitude)
              : process.env.REACT_APP_DEFAULT_LONGITUDE
            : process.env.REACT_APP_DEFAULT_LONGITUDE,
      },
      zoom: 15,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: MapStyles,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) =>
    new window.google.maps.Marker({
      position: { lat: markerObj.lat, lng: markerObj.lng },
      map: googleMap,
      info: markerObj,
      title: markerObj.title,
      id: markerObj.id,
      draggable: false,
      // animation:
      //   markerObj.title == "Pickup" || markerObj.title == "Dropoff"
      //     ? window.google.maps.Animation.DROP
      //     : window.google.maps.Animation.DROP,
      icon: {
        url: markerObj.icon,
        // set marker width and height
        //  scaledSize: new window.google.maps.Size(40, 40),
      },
    });

  const createInfoWindow = (markerObj) =>
    new window.google.maps.InfoWindow({
      content:
        markerObj.title == "Pickup" || markerObj.title == "Dropoff"
          ? "<p style='margin:2px 0'>#" +
            markerObj.info?.orderId +
            " - " +
            markerObj.title +
            '</p><p class="infowindowstyle" style="margin:0;color:#aaa">' +
            markerObj.address +
            "</p>"
          : markerObj.type == "driver"
          ? '<h4 class="infowindowstyle" style="margin:0">' +
            (markerObj?.info?.active_task_count > 0
              ? markerObj.title +
                " (" +
                markerObj?.info?.active_task_count +
                ")"
              : markerObj.title) +
            '</h4><p style="margin:2px 0 0">' +
            (markerObj.info?.driver_status == 1
              ? markerObj.info?.location_updated
                ? "Active . Updated " + markerObj.info?.location_updated
                : "Active"
              : markerObj.info?.location_updated
              ? "Offline . Updated " + markerObj.info?.location_updated
              : "Offline") +
            "</p>"
          : '<h4 style="margin:2px 0;font-size:14px">' +
            markerObj.title +
            '</h4><p style="margin:2px 0 0">' +
            markerObj.address +
            "</p>",
      disableAutoPan: true,
    });

  return (
    <div
      ref={googleMapRef}
      id="mapView"
      style={{
        width: "100%",
        height: "calc(100vh - 116px)",
        background: "#151515",
      }}
    />
  );
};

export default GMap;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyAl8q9s5LY5qUCPybXG-FWIp717RMB8IqE'
// })(MapContainer)
