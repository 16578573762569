import React, { useState, useContext } from "react";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { TableContainer } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import OfferPopUpForm from "../forms/offerPopUpForm";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import GLightBox from "../gComponents/gLightBox";
import moment from "moment-timezone";
import { StringsContext } from "../DataFactory/useStrings";

export default function OfferPopUps() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPopupData, setSelectedPopupData] = useState({});
  const [selectedPopupId, setSelectedPopupId] = useState("");
  const [oPopupVisible, setOPopupVisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  const {
    loading,
    data: popupsData,
    Placeholder,
    TableShimmer,
  } = useDataFctory("popups", false);

  const columns = [
    { id: "image", label: strings.w_image, minWidth: 70 },
    { id: "type", label: strings.w_type, minWidth: 90, align: "center" },
    { id: "title", label: strings.w_title, minWidth: 120, align: "center" },
    { id: "value", label: strings.w_value, minWidth: 100, align: "center" },
    { id: "time_schedule", label: strings.w_time_schedule, minWidth: 150 },
    { id: "actions", label: strings.w_actions, minWidth: 80, align: "center" },
  ];

  const deletePopup = () => {
    ApiService({ method: "DELETE", route: "popups/" + selectedPopupId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = popupsData.findIndex(
            (p) => p.id == selectedPopupId
          );
          if (selectedIndex > -1) {
            popupsData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedPopupData({});
          setIsEmpty(true);
          setOPopupVisible(true);
        }}
        style={{
          float: "right",
        }}
      />
      <GText
        g2
        bold
        text={strings.w_offer_popups}
        style={{ marginBottom: "15px" }}
      />
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer />
        ) : popupsData.length === 0 ? (
          <Placeholder />
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {popupsData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{ BorderBottom: "1px solid #f4f5f9" }}
                      >
                        <img
                          alt=""
                          src={ImgUrl("popup") + "/" + c.image}
                          onError={addDefaultSrc}
                          className="thumbnail"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedImgPreview(c.image);
                            setImgPreview(true);
                          }}
                        />
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {c.type}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.title || "-"}
                      </TableRowData>{" "}
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.value || "-"}
                      </TableRowData>
                      <TableRowData>
                        {strings.w_valid_from} :{" "}
                        {c.starts ? (
                          moment(new Date(c.starts)).format(
                            "DD MMM, yyyy HH:mm A"
                          )
                        ) : (
                          <span style={{ color: "#a2a2a2" }}>NA</span>
                        )}
                        <br />
                        {strings.w_valid_upto} :{" "}
                        {c.ends ? (
                          moment(new Date(c.ends)).format(
                            "DD MMM, yyyy HH:mm A"
                          )
                        ) : (
                          <span style={{ color: "#a2a2a2" }}>NA</span>
                        )}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedPopupData(c);
                            setIsEmpty(false);
                            setOPopupVisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedPopupId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {isImgPreview === true && (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType="popup"
        ></GLightBox>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_offer_popup_title}
          text={strings.s_delete_offer_popup_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deletePopup();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={oPopupVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOPopupVisible(false)}
        style={{
          content: {
            width: "470px",
          },
        }}
        contentLabel="Offer Pop-Up Form Modal"
      >
        <OfferPopUpForm
          oPopupVisible={oPopupVisible}
          setOPopupVisible={setOPopupVisible}
          data={selectedPopupData}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = popupsData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              popupsData[selectedIndex] = { ...data };
            } else {
              popupsData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
