import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function CompensationAmountForm(props) {
  const [strings] = useContext(StringsContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    order_id: props.orderId || "",
    amount: "",
    reason: "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    ApiService({
      method: "POST",
      route:
        props.type === "manager"
          ? "order-compensation-merchant"
          : props.type === "driver"
          ? "order-compensation-driver"
          : "",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setCompAmountVisible(false);
        props.onSubmit(response.data.data);
      }
      setIsDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required(strings.w_required),
    reason: Yup.string().required(strings.w_required),
  });

  if (props.compAmountVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{strings.w_add_compensation_amount}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_amount}
                name="amount"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("amount", event.target.value);
                  }
                }}
              />

              <FormikControl
                control="input"
                as="textarea"
                label={strings.w_reason}
                name="reason"
                rows="4"
              />

              <br />
              <GButton
                children={strings.w_submit}
                variant="condensed"
                type="submit"
                disabled={isDisabled}
                style={{ width: "calc(100% - 30px", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
