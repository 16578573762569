import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiService } from "../services";
import "../css/tags.css";
import { Close } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GButton from "./gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function GTagsSearchInput(props) {
  const [strings] = useContext(StringsContext);
  const [tags, setTags] = useState([]);
  const tagsRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useDetectOutsideClick(
    tagsRef,
    false
  );
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const getTags = () => {
      ApiService({ method: "GET", route: "tags?type=store" })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setSuggestions(response.data.data);
            let oTags = [];
            let oldTags = response.data.data.filter((t, index) => {
              const found = props.data.some(
                (el) => el === JSON.stringify(t.id)
              );
              console.log(found);
              if (found) {
                oTags.push(JSON.stringify(t.id));
              }
            });
            console.log(oTags);
            setTags(oTags);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getTags();
  }, []);

  const handleDelete = (data, i) => {
    console.log(data);
    let addedTags = tags.filter((t, index) => {
      console.log(t);
      return t !== JSON.stringify(data.id);
    });
    setTags([...addedTags]);
  };

  const handleAddition = (event) => {
    if (event.key === "Enter") {
      setTags([...tags, { title: event.target.value }]);
      event.target.value = "";
    }
  };

  const handleAdd = (t, i) => {
    const found = tags.some((el) => el === JSON.stringify(t.id));
    if (!found) {
      setTags([...tags, JSON.stringify(t.id)]);
    }
  };

  return (
    <div ref={tagsRef}>
      {props.tagsEdit === true && (
        <GButton
          variant="linkable"
          style={{
            marginTop: "-35px",
            float: "right",
            textDecoration: "underline",
          }}
          children={strings.w_update}
          onClick={() => {
            setShowSuggestions(false);
            props.onSubmit(tags);
          }}
        />
      )}

      <div
        className="react-tags"
        style={{
          border: props.tagsEdit === true ? "1px solid #d1d1d1" : "0px",
        }}
      >
        <React.Fragment>
          {tags.length > 0 ? (
            <ul className="react-tags-list">
              {suggestions
                .filter((c) => {
                  return tags.some((el) => el === JSON.stringify(c.id));
                })
                .map((t, i) => {
                  return (
                    <li className="react-tags__selected-tag" key={i}>
                      {t.title}
                      {props.tagsEdit === true ? (
                        <Close onClick={() => handleDelete(t, i)} />
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })}
            </ul>
          ) : props.tagsEdit === false ? (
            <p
              style={{ textAlign: "center", padding: "10px 0", color: "#777" }}
            >
              {strings.s_no_tags_found}
            </p>
          ) : (
            ""
          )}

          {props.tagsEdit !== false && (
            <input
              type="text"
              className="react-tags-input"
              placeholder={strings.w_add_a_tag}
              readOnly={true}
              onFocus={() => {
                setShowSuggestions(true);
              }}
              onKeyUp={() => handleAddition}
            />
          )}
        </React.Fragment>
        {/* )} */}
      </div>
      <div className="react-tags-suggestions">
        {showSuggestions === true ? (
          <div className="react-tags__suggestions">
            <ul>
              {suggestions
                .filter((c) => {
                  return !tags.includes(JSON.stringify(c.id));
                })
                .map((s, i) => {
                  return (
                    <li
                      key={i}
                      className="react-tags__selected-tag"
                      onClick={() => {
                        setShowSuggestions(false);
                        handleAdd(s, i);
                      }}
                    >
                      {s.title}
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
