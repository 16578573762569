import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function ChangePassword(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    old: "",
    new: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    ApiService({
      method: "POST",
      route: "change-password",
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.setCpvisible(false);
      }
      setBtnLoading(false);
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    old: Yup.string().required(strings.s_old_password_is_required),
    new: Yup.string()
      .min(5, strings.s_new_password_is_too_short)
      .required(strings.s_new_password_is_required),
  });

  return (
    <>
      <div className="modal-header">{strings.w_change_password}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              type="text"
              label={strings.w_old_password}
              name="old"
            />
            <FormikControl
              control="input"
              type="text"
              label={strings.w_new_password}
              name="new"
            />

            <GButton
              type="submit"
              variant="condensed"
              loading={isBtnLoading}
              children={strings.w_update}
              style={{ width: "calc(100% - 30px)", margin: "15px" }}
            />
          </Form>
        </Formik>
      </div>
    </>
  );
}
