import React, { useContext } from "react";
import styled from "styled-components";
import GText from "./gText";
import placeholder from "../assets/images/placeholders/empty-placeholder.svg";
import { StringsContext } from "../DataFactory/useStrings";

const PlaceHolder = styled.div`
  padding: 40px 0;
`;

export default function GEmpty(props) {
  const [strings] = useContext(StringsContext);
  return (
    <PlaceHolder>
      <div id="noRecords" style={{ textAlign: "center" }}>
        <img
          src={placeholder}
          alt=""
          style={{ width: "100px", marginBottom: "10px" }}
        />
        <GText g3 semi text={props.title || strings.w_no_records} />
        <GText
          text={props.subtitle || strings.s_no_data_found_for_this_search}
        />
      </div>
    </PlaceHolder>
  );
}
