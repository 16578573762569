import React, { createContext, useEffect, useState, useContext } from "react";
import { ApiService } from "../services";
import useLocalStorage from "../useAsyncStorage";

const SettingsContext = createContext(null);

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useLocalStorage(
    "settings",
    localStorage.getItem("settings")
      ? JSON.parse(localStorage.getItem("settings"))
      : ""
  );

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      return;
    }
    refreshSettings();
  }, []);

  const refreshSettings = () => {
    var token = localStorage.getItem("aAccessToken");
    var expires = localStorage.getItem("aExpiresIn");
    if (token && expires && expires > 0) {
      ApiService({ method: "GET", route: "me" }).then((response) => {
        if (response.data) {
          setSettings(response.data.data);
          console.log(response.data.data);
        }
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
        refreshSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };

export default () => useContext(SettingsContext);
