import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { MyLocation, FiberManualRecord, MoreHoriz } from "@material-ui/icons";
import styled from "styled-components";
import { GHorCardShimmer } from "../gComponents/gCardShimmer";
import GPagination from "../gComponents/gPagination";
import GPopover from "../gComponents/gPopover";
import { useHistory } from "react-router-dom";
import { ReactComponent as DriverIcon } from "../assets/images/sidebar/drivers.svg";
import GText from "../gComponents/gText";
import { GSearch } from "../gComponents/gSearch";
import { StringsContext } from "../DataFactory/useStrings";

const DriverListView = styled.div`
  padding: 15px 12px;
  display: flex;
  align-items: center;

  &:hover {
    background: #212226;
  }

  .actionIcon:hover {
    background: #333;
  }
`;

const ContentDiv = styled.div`
  width: calc(100% - 80px);
  padding: 0 10px;
  p {
    margin: 6px 0;
  }
`;

const DriversView = (props) => {
  let history = useHistory();
  const [strings] = useContext(StringsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [driversData, setDriversData] = useState(props.driversData || []);
  const [searchWord, setSearchWord] = useState("");

  useEffect(() => {
    let findInd = driversData.findIndex((d) => d.id == props.updatedDriver?.id);
    if (findInd > -1) {
      driversData[findInd].location_updated =
        props.updatedDriver.location_updated;
      driversData[findInd].latitude = props.updatedDriver.latitude;
      driversData[findInd].longitude = props.updatedDriver.longitude;
      setDriversData([...driversData]);
    }
  }, [props.updatedDriver]);
  return (
    <div>
      {isLoading == true ? (
        <>
          <br />
          <GHorCardShimmer theme="dark" height={60} />
        </>
      ) : (
        <>
          {/* <GPagination
            style={{ paddingTop: "5px", color: "#aaa" }}
            theme={"dark"}
            count={driversPagination.total}
            from={driversPagination.from}
            to={driversPagination.to}
            onChangeNextPage={() =>
              handleChangePage(driversPagination.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(driversPagination.prev_page_url)
            }
            rowsPerPage={driversPagination.per_page}
            prevDisabled={driversPagination.prev_page_url ? false : true}
            nextDisabled={driversPagination.next_page_url ? false : true}
          ></GPagination> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <GSearch
              placeholder={strings.s_search_driver}
              value={searchWord}
              handleChange={(val) => {
                setSearchWord(val);
              }}
              style={{
                width: "200px",
                border: "0px",
                padding: "5px 8px ",
                background: "#222326",
                margin: "5px",
              }}
              inputStyle={{ color: "#e2e2e2" }}
              iconStyle={{ color: "#999" }}
            />
            <GText
              med
              text={
                driversData.filter((d) => {
                  if (searchWord) {
                    return d.title
                      .toLowerCase()
                      .includes(searchWord.toLowerCase());
                  } else {
                    return d;
                  }
                }).length +
                " of " +
                driversData.length +
                " Drivers"
              }
              style={{
                width: "auto",
                margin: "0 20px",
                fontStyle: "italic",
                marginLeft: "auto",
              }}
            />
          </div>
          <div
            style={{
              background: "transparent",
              height: "calc(100vh - 205px)",
              overflow: "overlay",
              paddingBottom: "70px",
            }}
          >
            {driversData.filter((d) => {
              if (searchWord) {
                return d.title.toLowerCase().includes(searchWord.toLowerCase());
              } else {
                return d;
              }
            }).length > 0 ? (
              driversData
                .filter((d) => {
                  if (searchWord) {
                    return d.title
                      .toLowerCase()
                      .includes(searchWord.toLowerCase());
                  } else {
                    return d;
                  }
                })
                .map((o, i) => {
                  return (
                    <DriverListView
                      key={i}
                      onClick={() => {
                        // console.log(o);
                        if (o.latitude) {
                          props.onClick(o);
                        } else {
                          return;
                        }
                      }}
                      style={{ cursor: o.latitude ? "pointer" : "auto" }}
                    >
                      <FiberManualRecord
                        style={{
                          fill:
                            o.driver_status == 1
                              ? o.active_task_count > 0
                                ? "#43829B"
                                : "#76A543"
                              : "#9D9E9D",
                          width: "20px",
                          height: "20px",
                          margin: "0 5px",
                        }}
                        // title={o.status?.title}
                      />

                      <ContentDiv>
                        <GText
                          g4
                          text={
                            o.active_task_count
                              ? o.title + " (" + o.active_task_count + ")"
                              : o.title
                          }
                          style={{ color: "#bbb", fontSize: "16px" }}
                        />
                        <GText
                          text={
                            o.driver_status == 1
                              ? o.location_updated
                                ? `${strings.w_active} . ${strings.w_updated}  ${o.location_updated}`
                                : strings.w_active
                              : o.location_updated
                              ? `${strings.w_offline} . ${strings.w_updated}  ${o.location_updated}`
                              : strings.w_offline
                          }
                          style={{ color: "#777", fontSize: "14px" }}
                        />
                      </ContentDiv>

                      <div
                        style={{
                          width: "40px",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "20px",
                          position: "relative",
                          minHeight: "50px",
                        }}
                      >
                        <MoreHoriz
                          style={{
                            fill: "#555",
                            margin: "0",
                            fontSize: "1.5rem",
                            cursor: "pointer",
                            borderRadius: "20px",
                            padding: "2px",
                          }}
                          className={"actionIcon"}
                          onClick={(e) => {
                            e.stopPropagation();
                            driversData.map((o) => {
                              o.showPopover = false;
                            });
                            driversData.filter((d) => {
                              if (searchWord) {
                                return d.title
                                  .toLowerCase()
                                  .includes(searchWord.toLowerCase());
                              } else {
                                return d;
                              }
                            })[i].showPopover = true;
                            setDriversData([...driversData]);
                          }}
                        />
                        {o.showPopover == true && (
                          <GPopover
                            placement={"right"}
                            buttons={[
                              {
                                title: strings.w_show_on_map,
                                icon: <MyLocation />,
                                link: false,
                                show: o.latitude ? true : false,
                                action: () => {
                                  props.onClick(o);
                                },
                              },
                              {
                                title: strings.w_view_driver_details,
                                icon: (
                                  <DriverIcon
                                    style={{
                                      fill: "#777",
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  />
                                ),
                                route: "/driver/",
                                state: "driver",
                                show: true,
                                action: () => {
                                  window.open(
                                    process.env.REACT_APP_BASE_URL +
                                      "/driver/" +
                                      o.id +
                                      "/tasks",
                                    "_blank" // <- This is what makes it open in a new window.
                                  );
                                  // history.push({
                                  //   pathname: "/driver/" + o.id + "/tasks",
                                  //   state: "driver",
                                  // });
                                },
                              },
                            ]}
                            show={o.showPopover}
                            onPress={() => {
                              driversData.filter((d) => {
                                if (searchWord) {
                                  return d.title
                                    .toLowerCase()
                                    .includes(searchWord.toLowerCase());
                                } else {
                                  return d;
                                }
                              })[i].showPopover = false;
                              setDriversData([...driversData]);
                            }}
                            onClick={() => props.onClick(o?.tasks || {})}
                          />
                        )}
                      </div>
                    </DriverListView>
                  );
                })
            ) : (
              <GText
                med
                text={strings.w_no_driver_found}
                style={{ padding: "30px 0", textAlign: "center" }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DriversView;
