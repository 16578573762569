import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function TimeslotsForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    weekday: props.weekday,
    from_time: props.data ? props.data.from_time : "",
    to_time: props.data ? props.data.to_time : "",
  };

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "timeslots/" + props.data.id : "timeslots";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setTsvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    from_time: Yup.string().nullable().required(strings.w_required),
    to_time: Yup.string().nullable().required(strings.w_required),
  });

  if (props.tsvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_timeslot : strings.w_add_timeslot}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div
                style={{
                  padding: "15px 15px 0px",
                }}
              >
                <label>{strings.w_start_time} </label>
                <TimePicker
                  defaultValue={
                    values.from_time
                      ? moment(values.from_time, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.from_time
                      ? moment(values.from_time, "HH:mm:ss")
                      : moment()
                  }
                  onChange={(value) => {
                    values.from_time = value && value.format(str);
                    setFieldValue("from_time", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="from_time"
                style={{ display: "none" }}
              />

              <div
                style={{
                  margin: "10px 15px 0px",
                }}
              >
                <label>{strings.w_end_time}</label>
                <TimePicker
                  defaultValue={
                    values.to_time
                      ? moment(values.to_time, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.to_time
                      ? moment(values.to_time, "HH:mm:ss")
                      : moment()
                  }
                  onChange={(value) => {
                    values.to_time = value && value.format(str);
                    setFieldValue("to_time", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="to_time"
                style={{ display: "none" }}
              />
              <br />
              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
