import React, { useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import { isFeatureEnabled } from "../services";
import { Switch, Route } from "react-router-dom";
import SettingRoutes from "./settingRoutes";
import BasicSettings from "./basicSettings";
import SocialLinks from "./socialLinks";
import InvoiceSettings from "./invoiceSettings";
import AtmSettings from "./atmSettings";
import AppSettings from "./appSettings";
import DocumentTypes from "./documentTypes";
import Subscriptions from "./subscriptions";
import OrderCancelReasons from "./orderCancelReasonsTypes";
import FaqTypes from "./faqTypes";
import Events from "./events";
import VariantTypes from "./variantTypes";
import Tags from "./tags";
import Brands from "./brands";
import Cities from "./cities";
import AssignSettings from "./deliveryAssignSettings";
import TaskActions from "./taskActions";
import TaskAllocation from "./taskAllocation";
import OrderSettings from "./orderSettings";
import TimeslotSettings from "./timeslotSettings";
import Timeslots from "./timeslots";
import NotificationTypes from "./notificationsType";
import PricingRules from "./pricingRules";
import TaxSettings from "./taxSettings";
import WalletSettings from "./walletSettings";
import Banners from "./banners";
import PrivacyPolicy from "./privacyPolicy";
import TermsConditions from "./termsConditions";
import OfferPopUps from "./offerPopUps";
import WebBanners from "./webBanners";
import WebsiteSettings from "./websiteSettings";
import WebPlugins from "./websitePlugins";
import BulkUpload from "./bulk-upload";
import EmailSettings from "./emailSettings";
import SmsSettings from "./smsSettings";
import SmsOtpSettings from "./smsOtpSettings";
import Roles from "../roles/roles";
import StaffMembers from "./staffMembers";
import Branches from "./branches";
import WebsiteSeoSettings from "./websiteSeoSettings";
import ProductSettings from "./productSettings";
import StoreClasses from "./classes";
import DriverCommissionCategories from "./driverCommissionCategories";
import DriverRewards from "./driverRewards";
import DriverBasicSettings from "./driverBasicSettings";
import AtmOptions from "./atmOptions";
import OtherSettings from "./otherSettings";
import ChatTemplates from "./chatTemplates";
import NoteTemplates from "./noteTemplates";
import StoreGroups from "./storeGroups";
import FeedbackTemplates from "./feedbackTemplates";
import OrderIssues from "./orderIssues";

const leftInnerStyle = {
  width: "23%",
  height: "calc(100vh - 55px)",
  overflow: "overlay",
  background: "#fff",
};
const rightInnerStyle = {
  width: "77%",
  height: "87vh",
  overflow: "overlay",
};

export default function SettingsView({ match }) {
  const { settings } = useContext(SettingsContext);
  console.log(match);
  return (
    <div style={{ display: "flex" }}>
      <div style={leftInnerStyle}>
        <SettingRoutes url={match.url} />
      </div>
      {/* match.path should be equal to '/home' */}
      <div style={rightInnerStyle}>
        <Switch>
          <Route exact path="/settings/basic" component={BasicSettings} />
          <Route exact path="/settings/social" component={SocialLinks} />
          <Route exact path="/settings/invoice" component={InvoiceSettings} />
          <Route exact path="/settings/atm" component={AtmSettings} />
          <Route exact path="/settings/app" component={AppSettings} />
          <Route
            exact
            path="/settings/chat-templates"
            component={ChatTemplates}
          />
          <Route
            exact
            path="/settings/note-templates"
            component={NoteTemplates}
          />
          <Route
            exact
            path="/settings/feedback-templates"
            component={FeedbackTemplates}
          />
          <Route
            exact
            path="/settings/other-settings"
            component={OtherSettings}
          />
          <Route
            exact
            path="/settings/document-types/:type"
            component={DocumentTypes}
          />
          <Route
            exact
            path="/settings/order-cancel-reasons/:type"
            component={OrderCancelReasons}
          />
          <Route exact path="/settings/order-issues" component={OrderIssues} />
          {isFeatureEnabled("subscriptions") && (
            <Route
              exact
              path="/settings/subscriptions"
              component={Subscriptions}
            />
          )}

          <Route exact path="/settings/atm-options" component={AtmOptions} />
          <Route exact path="/settings/faqs/:type" component={FaqTypes} />
          <Route exact path="/settings/events" component={Events} />
          <Route exact path="/settings/store-groups" component={StoreGroups} />

          <Route
            exact
            path="/settings/product-settings"
            component={ProductSettings}
          />
          <Route
            exact
            path="/settings/variant-types"
            component={VariantTypes}
          />
          {isFeatureEnabled("brands_available") && (
            <Route exact path="/settings/brands" component={Brands} />
          )}
          <Route exact path="/settings/bulk-upload" component={BulkUpload} />
          <Route exact path="/settings/cities" component={Cities} />
          <Route
            exact
            path="/settings/assign-settings"
            component={AssignSettings}
          />
          <Route exact path="/settings/task-actions" component={TaskActions} />
          <Route exact path="/settings/pricing-rule" component={PricingRules} />
          <Route
            exact
            path="/settings/order-settings"
            component={OrderSettings}
          />
          <Route exact path="/settings/classes" component={StoreClasses} />
          <Route
            exact
            path="/settings/timeslot-settings"
            component={TimeslotSettings}
          />
          <Route exact path="/settings/tags/:type" component={Tags} />
          <Route
            exact
            path="/settings/timeslots/:weekday"
            component={Timeslots}
          />
          <Route exact path="/settings/timeslots" component={Timeslots} />
          <Route exact path="/settings/timeslots/" component={Timeslots} />
          <Route
            exact
            path="/settings/:type-notifications/:userType"
            component={NotificationTypes}
          />
          <Route
            exact
            path="/settings/:type-notifications/:userType/:notifyType"
            component={NotificationTypes}
          />
          <Route exact path="/settings/tax" component={TaxSettings} />
          <Route
            exact
            path="/settings/task-allocation"
            component={TaskAllocation}
          />

          <Route exact path="/settings/wallet" component={WalletSettings} />

          <Route
            exact
            path="/settings/driver-basic-settings"
            component={DriverBasicSettings}
          />
          <Route
            exact
            path="/settings/driver-commission-categories"
            component={DriverCommissionCategories}
          />

          <Route
            exact
            path="/settings/driver-rewards/:weekday"
            component={DriverRewards}
          />
          <Route
            exact
            path="/settings/driver-rewards"
            component={DriverRewards}
          />
          <Route
            exact
            path="/settings/driver-rewards/"
            component={DriverRewards}
          />

          <Route exact path="/settings/banners" component={Banners} />
          <Route
            exact
            path="/settings/privacy-policy"
            component={PrivacyPolicy}
          />
          <Route
            exact
            path="/settings/terms-conditions"
            component={TermsConditions}
          />

          {isFeatureEnabled("popups") && (
            <Route
              exact
              path="/settings/offer-popups"
              component={OfferPopUps}
            />
          )}

          <Route
            exact
            path="/settings/website-settings"
            component={WebsiteSettings}
          />

          <Route exact path="/settings/web-banners" component={WebBanners} />
          <Route exact path="/settings/web-plugin" component={WebPlugins} />
          <Route
            exact
            path="/settings/web-seo-settings"
            component={WebsiteSeoSettings}
          />
          <Route
            exact
            path="/settings/email-notifications"
            component={EmailSettings}
          />
          <Route
            exact
            path="/settings/sms-notifications"
            component={SmsSettings}
          />
          <Route
            exact
            path="/settings/sms-otp-notifications"
            component={SmsOtpSettings}
          />
          <Route
            exact
            path="/settings/staff-members"
            component={StaffMembers}
          />
          <Route exact path="/settings/roles/:roleType" component={Roles} />
          <Route path="/settings/branches" component={Branches} />
        </Switch>
      </div>
    </div>
  );
}
