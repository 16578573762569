import React, { useState, useContext } from "react";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GGeoTagsInput from "../gComponents/gGeofencesInput";
import { useLocation } from "react-router-dom";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function CreateDuplicateStore(props) {
  const [strings] = useContext(StringsContext);
  const settings = useSettings(true);
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const [geofencesList, setGeofencesList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues = {
    title: props.data?.title || "",
    phone: props.data?.phone || "",
    address: props.data?.address || "",
    latitude: props.data?.latitude || 30.7046,
    longitude: props.data?.longitude || 76.7179,
    duplicate_products: 0,
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    let sendData = { ...values };
    console.log(sendData);
    //return;
    ApiService({
      method: "POST",
      route: "store/duplicate/" + props.data.id,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.onSubmit();
        props.setShowStoreDupilcateForm(false);
      }
      setIsDisabled(false);
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    phone: Yup.string().required(strings.w_required),
    address: Yup.string().required(strings.w_required),
  });

  if (props.showStoreDupilcateForm === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_create_duplicate_store} </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div style={{ display: "inline-flex" }}>
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_title}
                  name="title"
                  readOnly={true}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_phone}
                  name="phone"
                  onChange={(event) => {
                    const re = /^[0-9-+\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("phone", event.target.value);
                    }
                  }}
                />
              </div>

              <GMap
                centerLocation={{ lat: values.latitude, lng: values.longitude }}
                search={true}
                height="30vh"
                type="createStore"
                onChange={(address, loc) => {
                  values.latitude = loc.lat();
                  values.longitude = loc.lng();
                  setFieldValue("address", address.address);
                }}
                label={strings.w_location}
                padding={true}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_address}
                name="address"
              />

              <h4 style={{ margin: "15px 15px 10px" }}>
                {strings.w_additional_information}
              </h4>

              <div
                style={{
                  display: "flex",
                  margin: "0 15px",
                  minHeight: "55px",
                  alignItems: "center",
                }}
              >
                <label>{strings.w_duplicate_products}</label>
                <FormikControl
                  control="toggle"
                  label=""
                  isToggled={values.duplicate_products}
                  name="duplicate_products"
                  iconType="google"
                  onToggle={() => {
                    let duplicateProducts =
                      values.duplicate_products == 1 ? true : false;
                    duplicateProducts = !duplicateProducts;
                    duplicateProducts = duplicateProducts === true ? 1 : 0;
                    setFieldValue("duplicate_products", duplicateProducts);
                  }}
                />
              </div>

              <br />

              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
