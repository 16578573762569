import React, { useEffect, useState, useContext } from "react";
import { ApiService } from "../services";
import { Edit, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { Formik, Form, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { StringsContext } from "../DataFactory/useStrings";

function TaxForm(props) {
  const [strings] = useContext(StringsContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [selectedTax, setSelectedTax] = useState();
  const [taxData, setTaxData] = useState([]);

  const Types = [
    {
      title: strings.w_fixed,
      value: "fixed",
    },
    {
      title: strings.w_percentage,
      value: "percentage",
    },
  ];

  const initialValues = {
    options: props.data,
  };

  const validationSchema = Yup.object({});

  useEffect(() => {
    document.getElementById("resetButton").click();
    setTaxData(props.data);
    initialValues.options = props.data;
  }, [props]);

  const handleUpdateVariant = (values, ind) => {
    console.log(ind + values.options[ind]);
    let sendData = { ...values.options[ind] };
    if (sendData.type == "fixed") {
      delete sendData.percentage;
    }
    if (sendData.type == "percentage") {
      delete sendData.fixed;
    }
    console.log(sendData);

    ApiService({
      method: "PUT",
      route: "taxes/" + values.options[ind].id,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        taxData[ind] = { ...response.data.data, isDisabled: true };
        setTaxData(taxData);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const handleDeleteTax = () => {
    ApiService({
      method: "DELETE",
      route: "taxes/" + selectedTax,
    }).then((response) => {
      console.log(response.data.status_code);
      if (response.data.status_code === 1) {
        props.onTaxRemove(selectedTax);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  return (
    <div style={props.style}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <FieldArray name="options">
              {(fieldArrayProps) => {
                const { form } = fieldArrayProps;
                const { values } = form;
                const { options } = values;

                return (
                  <>
                    {options.map((o, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            boxSizing: "border-box",
                            gap: 15,
                            margin: "5px 0",
                          }}
                          className="arrayFields"
                        >
                          <FormikControl
                            control="input"
                            type="text"
                            label={strings.w_title}
                            name={`options[${index}].title`}
                            disabled={
                              options[index].isDisabled === false ? false : true
                            }
                            divstyle={{
                              padding: "0",
                              verticalAlign: "top",
                              width: "150px",
                            }}
                            innerDivStyle={{
                              boxSizing: "border-box",
                              border: "1px solid #e2e2e2",
                              borderRadius: "4px",
                              padding: "2px 4px",
                              background:
                                options[index].isDisabled == true
                                  ? "#f4f5f9"
                                  : "transparent",
                            }}
                            style={{
                              height: "auto",
                              border: 0,
                              padding: "2px 5px 0",
                              margin: 0,
                              color: "#000",
                              background: "transparent",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                            }}
                            labelStyle={{
                              fontSize: "13px",
                              margin: 0,
                              padding: "0 0 0 5px",
                              color: "#a2a2a2",
                            }}
                          />

                          <FormikControl
                            control="select"
                            as="select"
                            label={strings.w_type}
                            name={`options[${index}].type`}
                            options={Types}
                            key_title="title"
                            key_value="value"
                            disabled={
                              options[index].isDisabled === false ? false : true
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `options[${index}].type`,
                                e.target.value || ""
                              );
                              setFieldValue(`options[${index}].fixed`, "");
                              setFieldValue(`options[${index}].percentage`, "");
                            }}
                            divstyle={{
                              padding: "0",
                              verticalAlign: "top",
                              width: "150px",
                            }}
                            innerDivStyle={{
                              boxSizing: "border-box",
                              border: "1px solid #e2e2e2",
                              borderRadius: "4px",
                              padding: "2px 4px",
                              background:
                                options[index].isDisabled == true
                                  ? "#f4f5f9"
                                  : "transparent",
                            }}
                            labelStyle={{
                              fontSize: "13px",
                              margin: 0,
                              padding: "0 0 0 5px",
                              color: "#a2a2a2",
                            }}
                            style={{
                              height: "auto",
                              border: 0,
                              padding: "2px 2px 0",
                              margin: 0,
                              color: "#000",
                              background: "transparent",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                            }}
                          />
                          {options[index].type == "percentage" && (
                            <FormikControl
                              control="input"
                              type="text"
                              label={`${strings.w_default} %`}
                              name={`options[${index}].percentage`}
                              maxLength={3}
                              onChange={(event) => {
                                const re = /^[0-9.\b]+$/;
                                if (
                                  event.target.value &&
                                  !re.test(event.target.value)
                                ) {
                                  return;
                                } else {
                                  setFieldValue(
                                    `options[${index}].percentage`,
                                    event.target.value
                                  );
                                }
                              }}
                              disabled={
                                options[index].isDisabled === false
                                  ? false
                                  : true
                              }
                              divstyle={{
                                padding: "0",
                                verticalAlign: "top",
                                width: "125px",
                              }}
                              innerDivStyle={{
                                boxSizing: "border-box",
                                border: "1px solid #e2e2e2",
                                borderRadius: "4px",
                                padding: "2px 4px",
                                background:
                                  options[index].isDisabled == true
                                    ? "#f4f5f9"
                                    : "transparent",
                              }}
                              style={{
                                height: "auto",
                                border: 0,
                                padding: "2px 5px 0",
                                margin: 0,
                                color: "#000",
                                background: "transparent",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                              }}
                              labelStyle={{
                                fontSize: "13px",
                                margin: 0,
                                padding: "0 0 0 5px",
                                color: "#a2a2a2",
                              }}
                            />
                          )}

                          {options[index].type == "fixed" && (
                            <FormikControl
                              control="input"
                              type="text"
                              label={strings.w_default}
                              name={`options[${index}].fixed`}
                              maxLength={6}
                              disabled={
                                options[index].isDisabled === false
                                  ? false
                                  : true
                              }
                              onChange={(event) => {
                                const re = /^[0-9.\b]+$/;
                                if (
                                  event.target.value &&
                                  !re.test(event.target.value)
                                ) {
                                  return;
                                } else {
                                  setFieldValue(
                                    `options[${index}].fixed`,
                                    event.target.value
                                  );
                                }
                              }}
                              divstyle={{
                                padding: "0",
                                verticalAlign: "top",
                                width: "125px",
                              }}
                              innerDivStyle={{
                                boxSizing: "border-box",
                                border: "1px solid #e2e2e2",
                                borderRadius: "4px",
                                padding: "2px 4px",
                                background:
                                  options[index].isDisabled == true
                                    ? "#f4f5f9"
                                    : "transparent",
                              }}
                              style={{
                                height: "auto",
                                border: 0,
                                padding: "2px 5px 0",
                                margin: 0,
                                color: "#000",
                                background: "transparent",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                              }}
                              labelStyle={{
                                fontSize: "13px",
                                margin: 0,
                                padding: "0 0 0 5px",
                                color: "#a2a2a2",
                              }}
                            />
                          )}
                          {/* <FormikControl
                            control="input"
                            type="text"
                            label="Title"
                            name={`options[${index}].title`}
                            divstyle={{
                              padding: "10px 5px",
                              maxWidth: "250px",
                              minWidth: "250px",
                            }}
                            style={{ width: "92%", margin: "5px 0" }}
                            disabled={
                              options[index].isDisabled === false ? false : true
                            }
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            label="Percentage"
                            name={`options[${index}].percentage`}
                            divstyle={{
                              padding: "10px 5px",
                              maxWidth: "200px",
                            }}
                            style={{ width: "92%", margin: "5px 0" }}
                            disabled={
                              options[index].isDisabled === false ? false : true
                            }
                            maxLength={3}
                            onChange={(event) => {
                              const re = /^[0-9.\b]+$/;
                              if (
                                event.target.value &&
                                !re.test(event.target.value)
                              ) {
                                return;
                              } else {
                                setFieldValue(
                                  `options[${index}].percentage`,
                                  event.target.value
                                );
                              }
                            }}
                          /> */}
                          {options[index].isDisabled === false ? (
                            <span
                              style={{
                                textAlign: "right",
                                width: "calc(100% - 420px)",
                                display: "flex",
                                alignItems: "start",
                              }}
                            >
                              <GButton
                                type="button"
                                variant="linkable"
                                style={{ alignSelf: "auto" }}
                                onClick={(e) => {
                                  setFieldValue(
                                    `options[${index}].isDisabled`,
                                    true
                                  );
                                  setFieldValue(
                                    `options[${index}].title`,
                                    props.data[index].title
                                  );
                                  setFieldValue(
                                    `options[${index}].percentage`,
                                    props.data[index].percentage
                                  );
                                }}
                              >
                                <Delete fontSize="small" />
                              </GButton>
                              <GButton
                                type="button"
                                children={strings.w_update}
                                variant="linkable"
                                style={{
                                  padding: "10px 5px",
                                  alignSelf: "auto",
                                  textDecoration: "underline",
                                }}
                                onClick={(e) => {
                                  handleUpdateVariant(values, index);
                                }}
                              />
                              <GButton
                                type="button"
                                children={strings.w_cancel}
                                variant="linkable"
                                style={{
                                  padding: "10px 5px",
                                  color: "#777",
                                  alignSelf: "auto",
                                  textDecoration: "underline",
                                }}
                                onClick={(e) => {
                                  setFieldValue(
                                    `options[${index}].title`,
                                    props.data[index].title
                                  );
                                  setFieldValue(
                                    `options[${index}].percentage`,
                                    props.data[index].percentage
                                  );
                                  setFieldValue(
                                    `options[${index}].isDisabled`,
                                    true
                                  );
                                }}
                              />
                            </span>
                          ) : (
                            <span
                              style={{
                                textAlign: "right",
                                width: "calc(100% - 420px)",
                                display: "flex",
                                alignItems: "start",
                                marginTop: 3,
                              }}
                            >
                              <GButton
                                type="button"
                                variant="linkable"
                                style={{ alignSelf: "start" }}
                                onClick={(e) => {
                                  setSelectedTax(options[index].id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete fontSize="small" />
                              </GButton>
                              <GButton
                                type="button"
                                variant="linkable"
                                style={{ alignSelf: "start" }}
                                onClick={(e) => {
                                  setFieldValue(
                                    `options[${index}].isDisabled`,
                                    false
                                  );
                                }}
                              >
                                <Edit fontSize="small" />
                              </GButton>
                            </span>
                          )}
                          <button
                            type="button"
                            id="resetButton"
                            style={{ display: "none" }}
                            onClick={() => resetForm()}
                          ></button>
                        </div>
                      );
                    })}
                  </>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Tax"
          text="Are you sure you want to delete this tax?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDeleteTax();
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}
export default TaxForm;
