import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";
import ColorPicker from "../gComponents/gColorPicker";
import { StringsContext } from "../DataFactory/useStrings";

export default function StoreClassForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { data: classesList } = useDataFctory("store/classes", false);
  const [openSnackbar] = useSnackbar();

  const themeOptions = [
    {
      title: strings.w_card,
      value: "card",
    },
    {
      title: strings.w_row,
      value: "row",
    },
  ];

  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    icon: !props.isEmpty ? props.data.icon : "",
    parent_id: props.classId || "",
    index: !props.isEmpty ? props.data.index : "",
    color: !props.isEmpty ? props.data.color : "#ffffff",
    list_theme: props.classId
      ? props.classTheme
      : !props.isEmpty
      ? props.data.list_theme
      : "",
    status: props.data?.status || 0,
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "store/classes/" + props.data.id
      : "store/classes";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setClassFormvisible(false);
          props.onSubmit();
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    parent_id: props.classId ? Yup.string().required(strings.w_required) : "",
    list_theme: !props.classId ? Yup.string().required(strings.w_required) : "",
    // description: Yup.string().required(strings.w_required)
  });

  if (props.classFormvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit : strings.w_add}{" "}
        {!props.classId ? strings.w_class : strings.w_subclass}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  values.icon = newValue;
                  setFieldValue("icon", newValue);
                }}
                id="class_icon"
                image={
                  values.icon ? ImgUrl("store-class") + "/" + values.icon : ""
                }
                type="store-class"
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{ margin: "0 auto", display: "block" }}
              />
              <br />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />

              {props.classId ? (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_class}
                  name="parent_id"
                  options={classesList}
                  key_title="title"
                  key_value="id"
                />
              ) : (
                ""
              )}

              <FormikControl
                control="input"
                type="text"
                label={strings.w_sort_index}
                name="index"
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("index", event.target.value);
                  }
                }}
                divstyle={{
                  width: !props.classId ? "50%" : "100%",
                  display: "inline-block",
                  boxSizing: "border-box",
                  verticleAlign: "top",
                }}
              />
              {!props.classId && (
                <div
                  className="form-control"
                  style={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticleAlign: "top",
                  }}
                >
                  <label style={{ margin: "0" }}>{strings.w_status}</label>
                  <FormikControl
                    control="toggle"
                    isToggled={values.status == "1" ? 1 : 0}
                    name="status"
                    onToggle={() => {
                      let statusVal = values.status == "1" ? true : false;
                      statusVal = !statusVal;
                      statusVal = statusVal === true ? "1" : "0";
                      setFieldValue("status", statusVal);
                    }}
                    style={{ padding: 0 }}
                  />
                </div>
              )}
              {!props.classId && (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_list_theme}
                  name="list_theme"
                  options={themeOptions}
                  key_title="title"
                  key_value="value"
                />
              )}
              <div style={{ margin: "0 15px 20px" }}>
                <label style={{ margin: "5px 0", display: "block" }}>
                  {strings.w_color}
                </label>
                <ColorPicker
                  id={"classColor"}
                  defaultcolor={values.color}
                  onChange={(newVal, fieldId) => {
                    setFieldValue("color", newVal);
                  }}
                />
              </div>

              <br />
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
