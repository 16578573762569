import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import "../css/tooltip.css";

function Tooltip({
  title,
  position,
  children,
  id,
  divStyle,
  tooltipStyle,
  theme,
  subtitle,
}) {
  const node = useRef();
  const [isVisible, setState] = useState(false);
  const handleClick = ({ target }) => {
    if (node.current.contains(target)) {
      // inside click
      return;
    }
    // outside click
    setState(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div
      className="tooltipContainer"
      data-testid={"tooltip" + id}
      ref={node}
      // onClick={() => setState(!isVisible)}
      onMouseEnter={() => setState(true)}
      onMouseLeave={() => setState(false)}
      style={Object.assign(
        { display: "inline-block", verticalAlign: "middle" },
        divStyle
      )}
    >
      <div style={{ display: "flex" }} data-testid={"tooltip-placeholder" + id}>
        {children}
      </div>

      {isVisible && (
        <div
          className={cx("tooltipContent", [position], theme)}
          data-testid={"tooltip-content" + id}
          style={Object.assign({ minWidth: "200px" }, tooltipStyle)}
        >
          <span className="arrow"></span>
          {title}
          {subtitle && (
            <div style={{ color: "#555", margin: "2px 0", fontWeight: 450 }}>
              {subtitle}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

Tooltip.defaultProps = {
  position: "right",
};

export default Tooltip;
