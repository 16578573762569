import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import { useSnackbar } from "react-simple-snackbar";
import GSwitch from "../gComponents/gSwitch";
import styled from "styled-components";
import { Info } from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";
import useSettings from "../useSettings";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export const DIV = styled.div`
  display: inline-flex;
  width: 85%;
  padding: 5px;
  align-items: center;

  & .inputStyle {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    span {
      text-align: center;
      background: #f2f2f2;
      border: 1px solid #f2f2f2;
      color: #555;
      padding: 0.5rem 2px;
      font-size: 14px;
      width: 60px;
    }
  }
`;

export default function WalletSettings() {
  const [strings, language] = useContext(StringsContext);
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [walletData, setWalletsData] = useState({
    earn_order_max_coin_limits: {},
    earn_task_max_coin_limits: {},
    invite_text: {},
    order_cashback: {},
    order_complete_points_percentage: {},
    point_value: {},
    referral_rule: {},
    sender_referral_points: {},
    spend_order_points_amount_percentage: {},
    spend_task_points_amount_percentage: {},
    task_cashback: {},
    task_complete_points_percentage: {},
    user_referral_points: {},
    wallet_agent: {},
    wallet_refund: {},
    wallet_store: {},
    withdraw_agent: {},
    withdraw_store: {},
  });

  useEffect(() => {
    getWalletSettings();
  }, [language]);

  const getWalletSettings = () => {
    ApiService({ method: "GET", route: "setting/wallet" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setWalletsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            walletData[keyTitle].key_value = newValue;
            setWalletsData(walletData);
          }
        } else {
          getWalletSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={`${strings.w_wallet} ${strings.w_settings}`}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <GText
          semi
          g4
          text={strings.w_referral_points}
          style={{ marginBottom: "15px" }}
        />
        <DIV>
          <GText text={strings.w_friend_referral} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.sender_referral_points.id}
              defaultValue={walletData.sender_referral_points.key_value}
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "sender_referral_points")
              }
              style={{ margin: "0px", width: "calc(100% - 60px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span>{strings.w_points}</span>
          </div>
        </DIV>
        <DIV>
          <GText text={strings.w_new_user} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.user_referral_points.id}
              defaultValue={walletData.user_referral_points.key_value}
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "user_referral_points")
              }
              style={{ margin: "0px", width: "calc(100% - 60px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span>{strings.w_points}</span>
          </div>
        </DIV>
        <DIV>
          <GText
            text={strings.w_restrict_on_order_completion}
            style={{ display: "inline-block", width: "auto" }}
          />
          <TooltipComponent
            title={strings.s_friend_referral_tooltip}
            position="bottom"
            id={walletData.referral_rule.id}
          >
            <Info fontSize="small" />
          </TooltipComponent>
          <GSwitch
            id={walletData.referral_rule.id}
            uId={walletData.referral_rule.id}
            currentValue={walletData.referral_rule.key_value}
            style={{ marginLeft: "auto" }}
            onSwitchChange={(newVal, id) =>
              updateField(newVal, id, "referral_rule")
            }
          />
        </DIV>

        <hr />

        <GText
          semi
          g4
          text={strings.w_cashback_points}
          style={{ margin: "15px 0", display: "inline-block", width: "auto" }}
        />
        <GSwitch
          id={walletData.order_cashback.id}
          uId={walletData.order_cashback.id}
          currentValue={walletData.order_cashback.key_value}
          style={{
            display: "inline-block",
            paddingLeft: "20px",
            verticalAlign: "middle",
          }}
          onSwitchChange={(newVal, id) =>
            updateField(newVal, id, "order_cashback")
          }
        />
        <DIV>
          <GText text={strings.w_cashback_per_order} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.order_complete_points_percentage.id}
              defaultValue={
                walletData.order_complete_points_percentage.key_value
              }
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "order_complete_points_percentage")
              }
              style={{ margin: "0px", width: "calc(100% - 100px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span style={{ width: "100px" }}>% {strings.w_of_subtotal}</span>
          </div>
        </DIV>
        <DIV>
          <GText text={strings.w_max_points_earned_per_order} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.earn_order_max_coin_limits.id}
              defaultValue={walletData.earn_order_max_coin_limits.key_value}
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "earn_order_max_coin_limits")
              }
              style={{ margin: "0px", width: "calc(100% - 60px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span>{strings.w_points}</span>
          </div>
        </DIV>

        <hr />
        <GText
          semi
          g4
          text={strings.w_discount}
          style={{ marginBottom: "15px" }}
        />
        <DIV>
          <GText text={strings.w_max_discount} />
          <div className="inputStyle">
            <GEditable
              type="number"
              maxLength={3}
              editable={false}
              maxVal={100}
              title={strings.w_max_discount}
              id={walletData.spend_order_points_amount_percentage.id}
              defaultValue={
                walletData.spend_order_points_amount_percentage.key_value
              }
              onSubmit={(newVal, id) => {
                updateField(newVal, id, "spend_order_points_amount_percentage");
              }}
              style={{ margin: "0px", width: "calc(100% - 100px)" }}
              inputStyle={{
                minWidth: "75px",
                maxWidth: "100px",
                width: "45%",
                float: "right",
              }}
            />
            <span style={{ width: "100px" }}>% {strings.w_of_subtotal}</span>
          </div>
        </DIV>
        <DIV>
          <GText text={strings.w_points_conversion_rate} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.point_value.id}
              defaultValue={walletData.point_value.key_value}
              onSubmit={(newVal, id) => updateField(newVal, id, "point_value")}
              style={{ margin: "0px", width: "calc(100% - 75px)" }}
              inputStyle={{
                minWidth: "75px",
                maxWidth: "100px",
                width: "45%",
                float: "right",
              }}
            />
            <span style={{ width: "75px" }}>
              {strings.w_points} / {settings?.currency_symbol}
            </span>
          </div>
        </DIV>

        <hr />

        <GText
          semi
          g4
          text={`${strings.w_store} ${strings.w_wallet}`}
          style={{ margin: "15px 0", display: "inline-block", width: "auto" }}
        />
        <GSwitch
          id={walletData.wallet_store.id}
          uId={walletData.wallet_store.id}
          currentValue={walletData.wallet_store.key_value}
          style={{
            display: "inline-block",
            paddingLeft: "20px",
            verticalAlign: "middle",
          }}
          onSwitchChange={(newVal, id) =>
            updateField(newVal, id, "wallet_store")
          }
        />

        {walletData.wallet_store.key_value === "1" ? (
          <DIV style={{ display: "flex" }}>
            <GText text={strings.w_allow_withdraw} />
            <GSwitch
              id={walletData.withdraw_store.id}
              uId={walletData.withdraw_store.id}
              currentValue={walletData.withdraw_store.key_value}
              style={{ marginLeft: "auto" }}
              onSwitchChange={(newVal, id) =>
                updateField(newVal, id, "withdraw_store")
              }
            />
          </DIV>
        ) : (
          ""
        )}

        <hr />

        <GText
          semi
          g4
          text={`${strings.w_driver} ${strings.w_wallet}`}
          style={{ margin: "15px 0", display: "inline-block", width: "auto" }}
        />
        <GSwitch
          id={walletData.wallet_agent.id}
          uId={walletData.wallet_agent.id}
          currentValue={walletData.wallet_agent.key_value}
          style={{
            display: "inline-block",
            paddingLeft: "20px",
            verticalAlign: "middle",
          }}
          onSwitchChange={(newVal, id) =>
            updateField(newVal, id, "wallet_agent")
          }
        />

        {walletData.wallet_agent.key_value === "1" ? (
          <DIV style={{ display: "flex" }}>
            <GText text={strings.w_allow_withdraw} />
            <GSwitch
              id={walletData.withdraw_agent.id}
              uId={walletData.withdraw_agent.id}
              currentValue={walletData.withdraw_agent.key_value}
              style={{ marginLeft: "auto" }}
              onSwitchChange={(newVal, id) =>
                updateField(newVal, id, "withdraw_agent")
              }
            />
          </DIV>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
