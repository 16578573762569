/* global google */
import React, { Component } from "react";
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
const { compose, withProps } = require("recompose");

let latestCircle;

const PlotMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAl8q9s5LY5qUCPybXG-FWIp717RMB8IqE&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%`, width: `100%` }} />,
    containerElement: <div style={{ height: `400px`, width: `100%` }} />,
    mapElement: <div style={{ height: `100%`, width: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={12}
    defaultCenter={
      new google.maps.LatLng(
        process.env.REACT_APP_DEFAULT_LATITUDE,
        process.env.REACT_APP_DEFAULT_LONGITUDE
      )
    }
    mapTypeControl={false}
    streetViewControl={false}
    zoomControl={false}
    fullscreenControl={false}
  >
    <DrawingManager
      onOverlayComplete={(circle) => {
        console.log(circle);
      }}
      onCircleComplete={(circle) => {
        console.log(circle);
        console.log(circle.getRadius());
        let radius = circle.getRadius();
        let latlng = circle.getCenter();
        console.log(latlng);
        props.getRadiusValue(radius, latlng.lat(), latlng.lng());
        if (latestCircle) {
          latestCircle.setMap(null);
        }
        // eslint-disable-next-line no-undef
        //setDrawingMode(null);
        //circle.setMap(null);
        // circle.setDrawingMode(null);
        google.maps.event.addListener(circle, "mouseover", function () {
          circle.set("editable", true);
        });

        google.maps.event.addListener(circle, "mouseout", function () {
          circle.set("editable", false);
        });
        latestCircle = circle;
      }}
      defaultDrawingMode={google.maps.drawing.OverlayType.CIRCLE}
      defaultOptions={{
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.CIRCLE],
        },
        circleOptions: {
          strokeColor: "#D8AE36",
          fillColor: "#D8AE36",
          fillOpacity: 0.7,
          strokeWeight: 3,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      }}
    />
  </GoogleMap>
));
export default PlotMap;
