import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";
import { FiberManualRecord } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GText from "./gText";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { StringsContext } from "../DataFactory/useStrings";

const LI = styled.li`
  padding: 5px 8px;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f4f5f9;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const DIV = styled.div`
  background: #212223;
  &:hover {
    background: #2a2a2a;
  }
`;

export const GMultiSelectbox = ({
  icon,
  title,
  data,
  values,
  style,
  handleChange,
  countData,
}) => {
  const [strings] = useContext(StringsContext);
  const wrapperMultiSelectRef = useRef(null);
  const [selectedValues, setSelectedValues] = useState(values || [{}]);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperMultiSelectRef,
    false
  );

  return (
    <div
      ref={wrapperMultiSelectRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
        },
        style
      )}
    >
      <DIV
        style={{
          borderRadius: "20px",
          height: "40px",
          fontSize: "0.97rem",
          color: "#ccc",
          boxSizing: "border-box",
          padding: "7px 5px 10px 10px",
          fontWeight: "500",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowFilterList(!showFilterList);
        }}
      >
        {selectedValues.length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "12px",
            }}
          >
            {selectedValues.map((v, i) => {
              return (
                <span
                  key={i}
                  style={{
                    display: "inline-flex",
                    background: "#212223",
                    marginLeft: "-12px",
                    borderRadius: "20px",
                  }}
                >
                  <FiberManualRecord style={{ fill: v.color }} />
                </span>
              );
            })}{" "}
            &nbsp;
            {selectedValues.length == data.length ? (
              <span style={{}}>{strings.w_all_drivers}</span>
            ) : (
              selectedValues.map((v, i) => {
                return (
                  <span key={i} style={{}}>
                    {v.title}
                    {i + 1 < selectedValues.length && <span>,&nbsp;</span>}
                  </span>
                );
              })
            )}
          </div>
        ) : (
          <span
            style={{
              color: "#555",
              padding: "3px 5px 5px",
              display: "inline-block",
            }}
          >
            {strings.w_no_drivers}
          </span>
        )}
      </DIV>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "100%",
            padding: "8px 0px 2px",
            borderRadius: "4px",
            top: "-145px",
          }}
        >
          <GText
            light
            text={title}
            style={{ margin: "5px 12px", width: "auto" }}
          />
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              height: "95px",
              overflow: "auto",
            }}
          >
            {data.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    let ind = selectedValues.findIndex((s) => s.type == c.type);
                    if (ind > -1) {
                      selectedValues.splice(ind, 1);
                      setSelectedValues([...selectedValues]);
                    } else {
                      selectedValues.push(c);
                      setSelectedValues([...selectedValues]);
                    }
                    handleChange(selectedValues);
                  }}
                >
                  <FiberManualRecord style={{ fill: c.color }} /> &nbsp;
                  {c.title}{" "}
                  {countData && countData.length > 0 ? (
                    <span style={{ color: "#777", paddingLeft: "2px" }}>
                      (
                      {countData.filter((count) => count.type == c.type).length}
                      )
                    </span>
                  ) : (
                    ""
                  )}
                  {selectedValues
                    .filter((v) => v.type == c.type)
                    .map((s, ind) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            marginLeft: "auto",
                          }}
                        >
                          <Check
                            style={{
                              fill: "#2A95D3",
                              width: "18px",
                              height: "17px",
                            }}
                          />
                        </span>
                      );
                    })}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
