import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function DriverRewardForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    weekday: props.weekday,
    starts: !props.isEmpty ? props.data.starts : "",
    ends: !props.isEmpty ? props.data.ends : "",
    type: !props.isEmpty ? props.data.type : "",
    count: !props.isEmpty ? props.data.count : "",
    reward: !props.isEmpty ? props.data.reward : "",
  };

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "driver-rewards/" + props.data.id
      : "driver-rewards";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setRewardsVisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    starts: Yup.string().nullable().required(strings.w_required),
    ends: Yup.string().nullable().required(strings.w_required),
    type: Yup.string().required(strings.w_required),
  });

  if (props.rewardsVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_driver_reward
          : strings.w_add_driver_reward}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div
                style={{
                  padding: "15px 15px 0px",
                }}
              >
                <label>{strings.w_starts}</label>
                <TimePicker
                  defaultValue={
                    values.starts
                      ? moment(values.starts, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.starts ? moment(values.starts, "HH:mm:ss") : moment()
                  }
                  onChange={(value) => {
                    values.starts = value && value.format(str);
                    setFieldValue("starts", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="starts"
                style={{ display: "none" }}
              />

              <div
                style={{
                  margin: "10px 15px 0px",
                }}
              >
                <label>{strings.w_ends}</label>
                <TimePicker
                  defaultValue={
                    values.ends ? moment(values.ends, "HH:mm:ss") : undefined
                  }
                  defaultOpenValue={
                    values.ends ? moment(values.ends, "HH:mm:ss") : moment()
                  }
                  onChange={(value) => {
                    values.ends = value && value.format(str);
                    setFieldValue("ends", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="ends"
                style={{ display: "none" }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_type}
                name="type"
                options={[
                  { title: strings.w_hours, value: "hours" },
                  { title: strings.w_orders, value: "orders" },
                ]}
                key_title="title"
                key_value="value"
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_count}
                name="count"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("count", event.target.value);
                  }
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_reward}
                name="reward"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("reward", event.target.value);
                  }
                }}
              />
              <br />
              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
