import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FilterList, Check } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GText from "./gText";
// import { ReactComponent as Check } from "../assets/images/tick.svg";

const LI = styled.li`
  padding: 6px 12px;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  line-height: 19px;
  &:hover {
    background: #f4f5f9;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const DIV = styled.div`
  background: transparent;
  border-radius: 4px;
  user-select: none;

  &:hover {
    background: #e6e7eb !important;
  }
  &.active {
    background: #ecda9f;
    &:hover {
      background: #ecda9f;
    }
  }

  .titleIcon {
    svg {
      margin: 0 2px;
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

export const GMultiSelectCheckbox = ({
  icon,
  title,
  data,
  values,
  style,
  handleChange,
  countData,
  activeColor,
  option_key,
  option_value,
}) => {
  const wrapperMultiSelectRef = useRef(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperMultiSelectRef,
    false
  );

  useEffect(() => {
    console.log(values);
    values.map((v) => {
      let findInd = data.findIndex((f) => f[option_value] == v);
      if (findInd > -1) {
        let findInd2 = selectedValues.findIndex(
          (f) => f[option_value] == data[findInd][option_value]
        );
        if (findInd2 == -1) {
          selectedValues.push(data[findInd]);
        }
      }
    });
    setSelectedValues(selectedValues);
  }, [values]);

  return (
    <div
      ref={wrapperMultiSelectRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
        },
        style
      )}
    >
      <DIV
        style={{
          borderRadius: "4px",
          // height: "34px",
          fontSize: "0.97rem",
          color: "#555",
          boxSizing: "border-box",
          padding: "3px 7px",
          fontWeight: "500",
          cursor: "pointer",
          background: selectedValues.length > 0 ? activeColor : "transparent",
        }}
        onClick={() => {
          setShowFilterList(!showFilterList);
        }}
        className={selectedValues.length > 0 ? "active" : ""}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            className="titleIcon"
            style={{
              display: "inline-flex",
            }}
          >
            {icon ? icon : <FilterList style={{ fill: "#4D4D4D" }} />}
          </span>

          {selectedValues.length > 0 ? (
            <p
              style={{
                margin: "0",
                padding: "3px 3px 4px",
                textOverflow: "ellipsis",
                maxWidth: "160px",
                whiteSpace: "pre",
                overflow: "hidden",
                fontSize: "14px",
                fontWeight: "550",
                flex: "1",
                // display: "-webkit-box",
                // webkitLineClamp: "1",
                // webkitBoxOrient: "vertical",
              }}
            >
              {/* {selectedValues.length == data.length ? (
                <span style={{}}>All {title}</span>
              ) : ( */}
              {selectedValues.map((v, i) => {
                return (
                  <span key={i} style={{}}>
                    {v[option_key]}
                    {i + 1 < selectedValues.length && <span>,&nbsp;</span>}
                  </span>
                );
              })}
            </p>
          ) : (
            <span
              style={{
                color: "#555",
                padding: "3px 5px 5px",
                display: "inline-block",
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              {title}
            </span>
          )}
        </div>
      </DIV>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "100%",
            minWidth: "190px",
            padding: "5px",
            borderRadius: "4px",
            marginTop: "4px",
            border: "1px solid #e2e2e2",
            // top: "-145px",
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: "2px 0",
              margin: "0",
              maxHeight: "150px",
              overflow: "auto",
            }}
          >
            {data.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    let ind = selectedValues.findIndex(
                      (s) => s[option_value] == c[option_value]
                    );
                    if (ind > -1) {
                      selectedValues.splice(ind, 1);
                      setSelectedValues([...selectedValues]);
                    } else {
                      selectedValues.push(c);
                      setSelectedValues([...selectedValues]);
                    }

                    let newarray = [];
                    selectedValues.map((s) => {
                      newarray.push(s[option_value]);
                    });
                    handleChange(newarray);
                  }}
                >
                  {c[option_key]}{" "}
                  {countData && countData.length > 0 ? (
                    <span style={{ color: "#777", paddingLeft: "2px" }}>
                      (
                      {countData.filter((count) => count.type == c.type).length}
                      )
                    </span>
                  ) : (
                    ""
                  )}
                  {selectedValues
                    .filter((v) => v[option_value] == c[option_value])
                    .map((s, ind) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            marginLeft: "auto",
                          }}
                        >
                          <Check
                            style={{
                              // fill: "#D8AE36",
                              verticalAlign: "middle",
                              width: "15px",
                              height: "14px",
                            }}
                          />
                        </span>
                      );
                    })}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
