import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import GButton from "../gComponents/gButton";
import { StoreFilter } from "../components/storeFilterComponent";
import useDataFactory from "../useDataFactory";
import GMultiSTagsInput from "../gComponents/gMultiSerachTagsInput";
import { StringsContext } from "../DataFactory/useStrings";

export default function DriverForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { data: commissionCategoriesData } = useDataFactory(
    "driver_commission_categories",
    false
  );

  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: "",
    commission_category_id: props.data ? props.data.commission_category_id : "",
    store_assigned: props.data ? props.data?.store_assigned : "",
    dob: props.data?.dob ? new Date(props.data?.dob) : null,
  };

  const handleChange = (d) => {
    var date = (new Date(d).getDate() < 10 ? "0" : "") + new Date(d).getDate();
    var month =
      (new Date(d).getMonth() + 1 < 10 ? "0" : "") +
      (new Date(d).getMonth() + 1);
    var year = new Date(d).getFullYear(); //Current Year
    return year + "-" + month + "-" + date;
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };
    if (props.data) {
      if (!sendData.password) {
        delete sendData.password;
      }
    }
    sendData.dob = sendData.dob ? handleChange(sendData.dob) : "";
    console.log(sendData);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "drivers/" + props.data.id : "drivers";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (props.onSubmit) {
            props.onSubmit(response.data.data);
          }

          if (!props.data) {
            if (location.pathname === "/drivers") {
              window.location.reload(false);
            } else {
              window.location = "/drivers";
            }
          }
          props.setCdvisible(false);
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings.w_required),
    email: Yup.string()
      .email(strings.s_invalid_email_address)
      .required(strings.w_required),
    phone: Yup.string().required(strings.w_required),
    password: !props.data ? Yup.string().required(strings.w_required) : "",
  });

  if (props.cdvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_driver : strings.w_create_driver}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_first_name}
                name="first_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("first_name", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_last_name}
                name="last_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("last_name", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label={strings.w_email_id}
                name="email"
              />
              <FormikControl
                control="input"
                type="password"
                label={strings.w_password}
                name="password"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_phone}
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9-+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="date"
                label={strings.w_dob}
                name="dob"
                format="yyyy-MM-dd"
                onKeyDown={(e) => e.preventDefault()}
              />
              <div style={{ margin: "15px 15px" }}>
                <label>{strings.w_assigned_store}</label>
                <StoreFilter
                  value={values.store_assigned}
                  style={{
                    marginBottom: "10px",
                    display: "block",
                    width: "100%",
                  }}
                  handleStoreChange={(value) => {
                    console.log(value);
                    setFieldValue("store_assigned", value || "");
                  }}
                />
                {/* <GMultiSTagsInput
                  apiRoute={"stores"}
                  selectedData={values.store_assigned}
                  filters={{
                    inhouse_drivers: 0,
                    per_page: 50,
                    store_type: "regular",
                  }}
                  placeholder={"All Stores"}
                  onSubmit={(data) => {
                    console.log(data);
                    values.store_assigned = data || [];
                    setFieldValue("store_assigned ", data || []);
                  }}
                /> */}
              </div>

              <FormikControl
                control="select"
                as="select"
                disabled={true}
                placeholder={strings.w_no_category_allocated}
                label={strings.w_commission_category}
                name="commission_category_id"
                options={commissionCategoriesData}
                key_title="title"
                key_value="id"
              />

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={props.data ? strings.w_update : strings.w_create}
                style={{ width: "calc(100% - 30px", margin: "15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
