import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { SettingsContext } from "../DataFactory/useSettings";
import { isFeatureEnabled } from "../services";
import { StringsContext } from "../DataFactory/useStrings";

const useStyles = makeStyles((theme) => ({
  SettingRoute: {
    color: "#000",

    "& a": {
      color: "#000",
      cursor: "pointer",
      display: "block",
      padding: "6px 20px",
      fontSize: "13.5px",
      textDecoration: "none",
      fontWeight: "450",
      letterSpacing: "-0.2px",

      "&:hover": {
        color: "#D8AE36",
      },
    },
    "& a.active": {
      color: "#D8AE36",
    },
  },
}));
var headerStyle = {
  fontSize: "15px",
  margin: "0px",
  fontWeight: "550",
  padding: "0 20px",
  marginBottom: "10px",
};

var lineStyle = {
  width: "90%",
  marginTop: "10px",
  marginBottom: "15px",
  border: "0px",
  borderTop: "1px solid #eee",
};

export default function SettingRoutes(props) {
  const classes = useStyles();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);

  return (
    <div className={classes.SettingRoute}>
      <br />
      <h3 style={headerStyle}>{strings.w_general_settings}</h3>
      <Link
        to={{ pathname: "/settings/basic", state: "setting" }}
        className={props.url === "/settings/basic" ? "active" : ""}
      >
        {strings.w_basic_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/social",
          state: "setting",
        }}
        className={props.url === "/settings/social" ? "active" : ""}
      >
        {strings.w_social_links}
      </Link>
      <Link
        to={{
          pathname: "/settings/invoice",
          state: "setting",
        }}
        className={props.url === "/settings/invoice" ? "active" : ""}
      >
        {strings.w_invoice_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/atm",
          state: "setting",
        }}
        className={props.url === "/settings/atm" ? "active" : ""}
      >
        {strings.w_atm_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/other-settings",
          state: "setting",
        }}
        className={props.url === "/settings/other-settings" ? "active" : ""}
      >
        {strings.w_other_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/chat-templates",
          state: "setting",
        }}
        className={props.url === "/settings/chat-templates" ? "active" : ""}
      >
        {strings.w_chat_templates}
      </Link>
      <Link
        to={{
          pathname: "/settings/note-templates",
          state: "setting",
        }}
        className={props.url === "/settings/note-templates" ? "active" : ""}
      >
        {strings.w_note_templates}
      </Link>
      <Link
        to={{
          pathname: "/settings/feedback-templates",
          state: "setting",
        }}
        className={props.url === "/settings/feedback-templates" ? "active" : ""}
      >
        {strings.w_feedback_templates}
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_user_settings}</h3>
      <Link
        to={{
          pathname: "/settings/document-types/customer",
          state: "setting",
        }}
        className={props.url === "/settings/document-types" ? "active" : ""}
      >
        {strings.w_document_types}
      </Link>
      {(settings.signup_agent == true || settings.signup_vendor == true) && (
        <Link
          to={{
            pathname: "/settings/user-notifications/manager",
            state: "setting",
          }}
          className={
            props.url === "/settings/user-notifications" ? "active" : ""
          }
        >
          {strings.w_notifications}
        </Link>
      )}
      <Link
        to={{
          pathname: "/settings/app",
          state: "setting",
        }}
        className={props.url === "/settings/app" ? "active" : ""}
      >
        {strings.w_app_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/atm-options",
          state: "setting",
        }}
        className={props.url === "/settings/atm-options" ? "active" : ""}
      >
        {strings.w_atm_options}
      </Link>

      {isFeatureEnabled("subscriptions") && (
        <Link
          to={{
            pathname: "/settings/subscriptions",
            state: "setting",
          }}
          className={props.url === "/settings/subscriptions" ? "active" : ""}
        >
          {strings.w_subscriptions}
        </Link>
      )}
      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_order_settings}</h3>
      <Link
        to={{
          pathname: "/settings/order-notifications/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-notifications" ? "active" : ""
        }
      >
        {strings.w_notifications}
      </Link>
      <Link
        to={{
          pathname: "/settings/order-cancel-reasons/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-cancel-reasons" ? "active" : ""
        }
      >
        {strings.w_cancel_reasons}
      </Link>
      <Link
        to={{
          pathname: "/settings/order-issues",
          state: "setting",
        }}
        className={props.url === "/settings/order-issues" ? "active" : ""}
      >
        {strings.w_order_issues}
      </Link>
      <Link
        to={{
          pathname: "/settings/order-settings",
          state: "setting",
        }}
        className={props.url === "/settings/order-settings" ? "active" : ""}
      >
        {strings.w_order_settings}
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_delivery_settings}</h3>

      <Link
        to={{
          pathname: "/settings/assign-settings",
          state: "setting",
        }}
        className={props.url === "/settings/assign-settings" ? "active" : ""}
      >
        {strings.w_assign_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/pricing-rule",
          state: "setting",
        }}
        className={props.url === "/settings/pricing-rule" ? "active" : ""}
      >
        {strings.w_pricing_rules}
      </Link>
      <Link
        to={{
          pathname: "/settings/task-allocation",
          state: "setting",
        }}
        className={props.url === "/settings/task-allocation" ? "active" : ""}
      >
        {strings.w_task_allocation}
      </Link>
      <Link
        to={{
          pathname: "/settings/task-actions",
          state: "setting",
        }}
        className={props.url === "/settings/task-actions" ? "active" : ""}
      >
        {strings.w_task_actions}
      </Link>
      <Link
        to={{
          pathname: "/settings/task-notifications/customer/delivery",
          state: "setting",
        }}
        className={props.url === "/settings/task-notifications" ? "active" : ""}
      >
        {strings.w_notifications}
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_store_settings}</h3>
      <Link
        to={{
          pathname: "/settings/events",
          state: "setting",
        }}
        className={props.url === "/settings/events" ? "active" : ""}
      >
        {strings.w_events}
      </Link>
      <Link
        to={{
          pathname: "/settings/timeslot-settings",
          state: "setting",
        }}
        className={props.url === "/settings/timeslot-settings" ? "active" : ""}
      >
        {strings.w_timeslot_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/timeslots/mon",
          state: "setting",
        }}
        className={props.url === "/settings/timeslots" ? "active" : ""}
      >
        {strings.w_timeslots}
      </Link>
      <Link
        to={{
          pathname: "/settings/tags/store",
          state: "setting",
        }}
        className={props.url === "/settings/tags" ? "active" : ""}
      >
        {strings.w_tags}
      </Link>
      <Link
        to={{
          pathname: "/settings/classes",
          state: "setting",
        }}
        className={props.url === "/settings/classes" ? "active" : ""}
      >
        {strings.w_classes}
      </Link>
      {settings.brands_available == 1 && (
        <Link
          to={{
            pathname: "/settings/brands",
            state: "setting",
          }}
          className={props.url === "/settings/brands" ? "active" : ""}
        >
          {strings.w_brands}
        </Link>
      )}

      <Link
        to={{
          pathname: "/settings/store-groups",
          state: "setting",
        }}
        className={props.url === "/settings/store-groups" ? "active" : ""}
      >
        {strings.w_store_groups}
      </Link>
      <Link
        to={{
          pathname: "/settings/roles/manager",
          state: "setting",
        }}
        className={props.url === "/settings/roles/manager" ? "active" : ""}
      >
        {strings.w_roles}
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_product_settings}</h3>
      <Link
        to={{
          pathname: "/settings/product-settings",
          state: "setting",
        }}
        className={props.url === "/settings/product-settings" ? "active" : ""}
      >
        {strings.w_basic_settings}
      </Link>
      {/* <Link
        to={{
          pathname: "/settings/variant-types",
          state: "setting",
        }}
        className={props.url === "/settings/variant-types" ? "active" : ""}
      >
        {strings.w_variant_types}
      </Link> */}
      <Link
        to={{
          pathname: "/settings/bulk-upload",
          state: "setting",
        }}
        className={props.url === "/settings/bulk-upload" ? "active" : ""}
      >
        {strings.w_bulk_upload}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_driver_settings}</h3>

      <Link
        to={{
          pathname: "/settings/driver-basic-settings",
          state: "setting",
        }}
        className={
          props.url === "/settings/driver-basic-settings" ? "active" : ""
        }
      >
        {strings.w_basic_settings}
      </Link>

      <Link
        to={{
          pathname: "/settings/driver-commission-categories",
          state: "setting",
        }}
        className={
          props.url === "/settings/driver-commission-categories" ? "active" : ""
        }
      >
        {strings.w_commission_categories}
      </Link>
      <Link
        to={{
          pathname: "/settings/driver-rewards/mon",
          state: "setting",
        }}
        className={props.url === "/settings/driver-rewards" ? "active" : ""}
      >
        {strings.w_rewards}
      </Link>

      <hr style={lineStyle} />
      <h3 style={headerStyle}>{strings.w_payment_settings}</h3>
      <Link
        to={{
          pathname: "/settings/tax",
          state: "setting",
        }}
        className={props.url === "/settings/tax" ? "active" : ""}
      >
        {strings.w_tax}
      </Link>
      <Link
        to={{
          pathname: "/settings/wallet",
          state: "setting",
        }}
        className={props.url === "/settings/wallet" ? "active" : ""}
      >
        {strings.w_wallet}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_content_settings}</h3>
      <Link
        to={{
          pathname: "/settings/banners",
          state: "setting",
        }}
        className={props.url === "/settings/banners" ? "active" : ""}
      >
        {strings.w_banners}
      </Link>

      <Link
        to={{
          pathname: "/settings/faqs/customer",
          state: "setting",
        }}
        className={props.url === "/settings/faqs" ? "active" : ""}
      >
        {strings.w_faqs}
      </Link>
      <Link
        to={{
          pathname: "/settings/privacy-policy",
          state: "setting",
        }}
        className={props.url === "/settings/privacy-policy" ? "active" : ""}
      >
        {strings.w_privacy_policy}
      </Link>
      <Link
        to={{
          pathname: "/settings/terms-conditions",
          state: "setting",
        }}
        className={props.url === "/settings/terms-conditions" ? "active" : ""}
      >
        {strings.w_terms_conditions}
      </Link>
      {isFeatureEnabled("popups") && (
        <Link
          to={{
            pathname: "/settings/offer-popups",
            state: "setting",
          }}
          className={props.url === "/settings/offer-popups" ? "active" : ""}
        >
          {strings.w_offer_popups}
        </Link>
      )}
      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_website_settings}</h3>
      <Link
        to={{ pathname: "/settings/website-settings", state: "setting" }}
        className={props.url === "/settings/website-settings" ? "active" : ""}
      >
        {strings.w_basic_settings}
      </Link>
      <Link
        to={{
          pathname: "/settings/web-banners",
          state: "setting",
        }}
        className={props.url === "/settings/web-banners" ? "active" : ""}
      >
        {strings.w_web_banners}
      </Link>

      <Link
        to={{ pathname: "/settings/web-plugin", state: "setting" }}
        className={props.url === "/settings/web-plugin" ? "active" : ""}
      >
        {strings.w_homepage_plugins}
      </Link>
      <Link
        to={{
          pathname: "/settings/web-seo-settings",
          state: "setting",
        }}
        className={props.url === "/settings/web-seo-settings" ? "active" : ""}
      >
        {strings.w_seo_settings}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_geo_location_settings}</h3>

      <Link
        to={{
          pathname: "/settings/cities",
          state: "setting",
        }}
        className={props.url === "/settings/cities" ? "active" : ""}
      >
        {strings.w_cities}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_notification_settings}</h3>
      <Link
        to={{
          pathname: "/settings/email-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/email-notifications" ? "active" : ""
        }
      >
        {strings.w_email}
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-notifications",
          state: "setting",
        }}
        className={props.url === "/settings/sms-notifications" ? "active" : ""}
      >
        {strings.w_sms}
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-otp-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/sms-otp-notifications" ? "active" : ""
        }
      >
        {strings.w_sms_otp}
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>{strings.w_staff_settings}</h3>
      <Link
        to={{
          pathname: "/settings/roles/admin",
          state: "setting",
        }}
        className={props.url === "/settings/roles/admin" ? "active" : ""}
      >
        {strings.w_roles}
      </Link>
      <Link
        to={{
          pathname: "/settings/staff-members",
          state: "setting",
        }}
        className={props.url === "/settings/staff-members" ? "active" : ""}
      >
        {strings.w_staff_members}
      </Link>
      <Link
        to={{
          pathname: "/settings/branches",
          state: "setting",
        }}
        className={props.url === "/settings/branches" ? "active" : ""}
      >
        {strings.w_branches}
      </Link>
      <br />
    </div>
  );
}
