import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import {
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";

export const MyPicker = ({ onChange, defaultcolor, id }) => {
  const wrapperSRef = useRef(null);
  const [displayColorPicker, setDisplayColorPicker] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [initialoading, setInitialLoading] = useState(true);
  // const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(defaultcolor || "#000000");
  const styles = {
    hue: {
      height: 10,
      position: "relative",
      marginBottom: 10,
    },
    saturation: {
      width: 100,
      height: 100,
      position: "relative",
    },
    input: {
      height: 34,
      border: `1px solid #e2e2e2`,
      paddingLeft: 10,
      outline: 0,
    },
    swatch: {
      width: 54,
      height: 38,
      background: `${defaultcolor}`,
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      // position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };
  useEffect(() => {
    setColor(defaultcolor);
  }, [defaultcolor]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    onChange(color, id);
  };

  useEffect(() => {
    if (displayColorPicker === false && initialoading === false) {
      onChange(color, id);
    }
  }, [displayColorPicker]);

  const handleChange = (color) => {
    console.log(color);
    setColor(color.hex);
    setInitialLoading(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{ display: "flex" }}
        onClick={() => {
          setDisplayColorPicker(true);
          setInitialLoading(true);
        }}
      >
        <EditableInput
          style={{ input: styles.input }}
          value={color}
          onChange={onChange}
        />
        <div style={styles.swatch} />
      </div>

      {displayColorPicker ? (
        <div style={styles.popover} ref={wrapperSRef}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default MyPicker;
