import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { Search, Close, ArrowDropDown } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import { StringsContext } from "../DataFactory/useStrings";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #d8ae36;
    color: #fff;
  }
`;

const Input = styled.input`
  width: 82%;
  /* min-width: 350px; */
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const DriverFilter = ({
  value,
  handleDriverChange,
  handleBlur,
  handleFocus,
  placeholder,
  position,
  style,
}) => {
  const [strings] = useContext(StringsContext);
  const [searchWord, setSearchWord] = useState("");
  const wrapperSRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [driversFilterList, setDrivers] = useState([]);

  useEffect(() => {
    var hitUrl = "drivers?per_page=30&search=" + searchWord;
    ApiService({ method: "GET", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDrivers(response.data.data.data);
          response.data.data.data.map((values) => {
            if (values.id === value) {
              setSelectedTitle(values.title);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchWord]);

  return (
    <div
      ref={wrapperSRef}
      style={Object.assign({}, style, {
        width: "212px",
        display: "inline-block",
        position: "relative",
        // verticalAlign: "middle",
      })}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid rgb(226, 226, 226)",
          height: "40px",
          fontSize: "0.97rem",
          color: "#555",
          boxSizing: "border-box",
          padding: "10px",
          fontWeight: "500",
        }}
        onClick={() => {
          setShowFilterList(true);
          setSearchWord("");
        }}
      >
        {selectedTitle ? (
          <span
            style={{
              width: "calc(100% - 40px)",
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: "20px",
              verticalAlign: "top",
            }}
          >
            {selectedTitle}
          </span>
        ) : (
          <span style={{ color: "#555" }}>{strings.w_all_drivers}</span>
        )}
        <ArrowDropDown
          style={{
            float: "right",
            fontSize: "19px",
            color: "#555",
          }}
        />
        {selectedTitle ? (
          <Close
            fontSize="small"
            style={{
              float: "right",
              fontSize: "18px",
              color: "#777",
              cursor: "pointer",
            }}
            onClick={() => {
              handleDriverChange();
              setSelectedTitle();
            }}
          />
        ) : (
          ""
        )}
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            width: "200px",
            padding: "0 5px 5px",
            border: "1px solid rgb(226, 226, 226)",
            top: position == "top" ? "-275px" : "auto",
          }}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder={strings.w_search}
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              height: "220px",
              overflow: "auto",
            }}
          >
            {driversFilterList.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    setSelectedTitle(c.title);
                    setShowFilterList(false);
                    handleDriverChange(c.id);
                  }}
                >
                  {c.title}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
