import React, { useContext } from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import { StringsContext } from "../DataFactory/useStrings";

function Selectbox(props) {
  const [strings] = useContext(StringsContext);
  const { label, name, key_title, key_value, options, ...rest } = props;
  return (
    <div className="form-control" style={props.divstyle}>
      <div style={props.innerDivStyle}>
        {label ? (
          <label htmlFor={name} style={props.labelStyle}>
            {label}
          </label>
        ) : (
          ""
        )}
        <Field as="select" id={name} name={name} {...rest}>
          <option value="">
            {props.placeholder ? props.placeholder : strings.s_select_an_option}
          </option>
          {options.map((option, ind) => {
            return (
              <option key={ind} value={option[key_value]}>
                {option[key_title]}
              </option>
            );
          })}
        </Field>
      </div>
      {props.errorstyle ? (
        <div style={props.errorstyle}>
          <ErrorMessage name={name} component={TextErrors} />
        </div>
      ) : (
        <ErrorMessage name={name} component={TextErrors} />
      )}
    </div>
  );
}

export default Selectbox;
