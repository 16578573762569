import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GSelectbox from "../gComponents/gSelectbox";
import { useSnackbar } from "react-simple-snackbar";
import useSettings from "../useSettings";
import GSwitch from "../gComponents/gSwitch";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

const gapTimeslotValues = [
  {
    label: "Same Day",
    value: "0",
  },
  {
    label: "+1 day",
    value: "1",
  },
  {
    label: "+2 days",
    value: "2",
  },
  {
    label: "+3 days",
    value: "3",
  },
  {
    label: "+4 days",
    value: "4",
  },
  {
    label: "+5 days",
    value: "5",
  },
];

const timeslotValues = [
  {
    label: "Today",
    value: "0",
  },
  {
    label: "Tomorrow",
    value: "1",
  },
  {
    label: "+2 days",
    value: "2",
  },
  {
    label: "+3 days",
    value: "3",
  },
  {
    label: "+4 days",
    value: "4",
  },
  {
    label: "+5 days",
    value: "5",
  },
];

export default function TimeslotSettings() {
  const [strings, language] = useContext(StringsContext);
  const [timeslotSettingsData, setTimeslotSettingsData] = useState({
    delivery_starts_from: {},
    delivery_gap: {},
    delivery_pref: {},
    pickup_separate: {},
  });
  const [openSnackbar] = useSnackbar();
  const settings = useSettings();

  const getTimeslotSettings = () => {
    ApiService({ method: "GET", route: "setting/slot" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setTimeslotSettingsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTimeslotSettings();
  }, [language]);

  function updateField(newValue, selectedFieldId, selectedKey) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          timeslotSettingsData[selectedKey] = response.data.data;
          setTimeslotSettingsData({ ...timeslotSettingsData });
        } else {
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_timeslot_settings}
        style={{ marginBottom: "15px" }}
      />
      {Object.keys(timeslotSettingsData).length > 0 && (
        <div className="listPaper">
          {timeslotSettingsData?.delivery_starts_from?.id ? (
            <>
              <GText
                text={
                  timeslotSettingsData.delivery_starts_from.key_display_title
                }
              />
              <GSelectbox
                placeholder={`${strings.w_select} ${strings.w_value}`}
                defaultvalue={
                  timeslotSettingsData.delivery_starts_from.key_value
                }
                display="inline-flex"
                data={timeslotValues}
                id={timeslotSettingsData.delivery_starts_from.id}
                onChange={(newval) =>
                  updateField(
                    newval,
                    timeslotSettingsData.delivery_starts_from.id,
                    "delivery_starts_from"
                  )
                }
                option_key="label"
                option_value="value"
              />
            </>
          ) : (
            ""
          )}

          {settings.product === "laundry" && (
            <React.Fragment>
              {timeslotSettingsData?.delivery_pref && (
                <>
                  <GText
                    text={timeslotSettingsData.delivery_pref.key_display_title}
                    style={{ marginTop: "25px" }}
                  />
                  <GSwitch
                    id={timeslotSettingsData.delivery_pref.id}
                    uId={timeslotSettingsData.delivery_pref.id}
                    currentValue={timeslotSettingsData.delivery_pref.key_value}
                    onSwitchChange={(newVal, id) =>
                      updateField(newVal, id, "delivery_pref")
                    }
                  />
                </>
              )}

              {timeslotSettingsData.delivery_gap &&
              timeslotSettingsData.delivery_pref.key_value === "1" ? (
                <>
                  <GText
                    text={timeslotSettingsData.delivery_gap.key_display_title}
                    style={{ marginTop: "25px" }}
                  />
                  <GSelectbox
                    placeholder={`${strings.w_select} ${strings.w_value}`}
                    defaultvalue={timeslotSettingsData.delivery_gap.key_value}
                    display="inline-flex"
                    data={gapTimeslotValues}
                    id={timeslotSettingsData.delivery_gap.id}
                    onChange={(newval) =>
                      updateField(
                        newval,
                        timeslotSettingsData.delivery_gap.id,
                        "delivery_gap"
                      )
                    }
                    option_key="label"
                    option_value="value"
                  />
                </>
              ) : (
                ""
              )}

              {timeslotSettingsData?.pickup_separate && (
                <>
                  <GText
                    text={
                      timeslotSettingsData.pickup_separate.key_display_title
                    }
                    style={{ marginTop: "25px" }}
                  />
                  <GSwitch
                    id={timeslotSettingsData.pickup_separate.id}
                    uId={timeslotSettingsData.pickup_separate.id}
                    currentValue={
                      timeslotSettingsData.pickup_separate.key_value
                    }
                    onSwitchChange={(newVal, id) =>
                      updateField(newVal, id, "pickup_separate")
                    }
                  />
                </>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}
