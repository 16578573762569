import React, { useState, useEffect } from "react";
import useCart from "../useCart";
import { ApiService } from "../services";
import styled from "styled-components";
import CustomerAddress from "../components/orderCustomerAddress";
import { useSnackbar } from "react-simple-snackbar";
import { CustomerFilter } from "../components/customerFilterComponent";
import AddressView from "../gComponents/gAddressView";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import OrderTimeslots from "../components/orderTimeslots";
import Modal from "react-modal";

const CheckoutDiv = styled.div`
  label {
    display: block;
  }
`;

const Section = styled.div`
  padding: 10px 0;
  border-top: 5px solid #f2f2f2;
  label {
    display: block;
    margin: 10px 0;
  }
  .inputDiv {
    display: inline-block;
    padding: 5px;
    color: #555;
    cursor: pointer;

    label {
      font-weight: 550;
      font-size: 15.5px;
      display: inline-block;
      margin: 0;
      vertical-align: top;
      span {
        font-weight: 450;
        padding: 5px 0;
      }
    }
  }
`;
export default function Checkout(props) {
  const { cartStore } = useCart();
  const [openSnackbar] = useSnackbar();
  const [CheckoutData, setCheckoutData] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [ocAddvisible, setOcAddVisible] = useState(false);
  const [otslotsvisible, setOTslotsVisible] = useState(false);
  const [selectedAddressType, setSelectedAddressType] = useState("");
  const [deliveryAddressVisible, setDeliveryAddressVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDateType, setSelectedDateType] = useState("");

  useEffect(() => {
    console.log(cartStore);
    if (!(cartStore || props.store?.id)) {
      return;
    }
    ApiService({
      method: "GET",
      route: `stores/${cartStore || props.store?.id}`,
    }).then((response) => {
      console.log(response.data);
      setCheckoutData({
        store: response.data.data,
        order_type: response.data.data.order_type || "home_delivery",
        order_type_visible: response.data.data.order_type === "",
        delivery_type:
          response.data.data.delivery_type !== "scheduled"
            ? response.data.data.delivery_type === "pickup_delivery"
              ? response.data.data.delivery_type
              : "asap"
            : "",
        asap_visible:
          response.data.data.delivery_type !== "scheduled" &&
          response.data.data.delivery_type !== "pickup_delivery",
        delivery_type_visible:
          response.data.data.delivery_type !== "asap" &&
          response.data.data.delivery_type !== "pickup_delivery",
        pickup_delivery_visible:
          response.data.data.delivery_type === "pickup_delivery",
        delivery_address:
          response.data.data.order_type !== "store_pickup" ? "" || "" : "",
        pickup_from: "",
        pickup_to: "",
        formatted_pickup_time: "",
        delivery_from: "",
        delivery_to: "",
        formatted_schedule_time: "",
        instructions: "",
      });

      setDeliveryAddressVisible(
        response.data.order_type === "store_pickup" ? false : true
      );
      setTimeout(function () {
        console.log(CheckoutData);
      }, 1000);

      if (response.data.status_code === 0) {
        // openSna(response.data.message);
      }
    });
  }, [cartStore, props.store]);

  return (
    <CheckoutDiv>
      <label>Customer</label>
      <CustomerFilter
        value={selectedCustomer?.id}
        style={{
          margin: "5px 0 20px",
        }}
        handleCustomerChange={(value) => {
          if (!value) {
            CheckoutData.delivery_address = "";
          }
          setSelectedCustomer(value);
        }}
      />

      {CheckoutData.order_type_visible === true ? (
        <Section>
          <label>Order Type</label>
          <div>
            <div
              className="inputDiv"
              onClick={() => {
                CheckoutData.order_type = "home_delivery";
                setDeliveryAddressVisible(true);
              }}
            >
              <input
                name="option_order_type"
                type="radio"
                id="v_option_home_delivery"
                value="home_delivery"
                defaultChecked={CheckoutData.order_type === "home_delivery"}
              />
              &nbsp;&nbsp;
              <label htmlFor="v_option_home_delivery">Home Delivery</label>
            </div>
            <br />
            <div
              className="inputDiv"
              onClick={() => {
                CheckoutData.order_type = "store_pickup";
                setDeliveryAddressVisible(false);
                CheckoutData.delivery_address = "";
                console.log(CheckoutData);
              }}
            >
              <input
                name="option_order_type"
                type="radio"
                id="v_option_store_pickup"
                value="store_pickup"
                defaultChecked={CheckoutData.order_type === "store_pickup"}
              />
              &nbsp;&nbsp;
              <label htmlFor="v_option_store_pickup">Store Pickup</label>
            </div>
          </div>
        </Section>
      ) : (
        ""
      )}

      {props.settings?.pickup_separate == 1 ? (
        <Section>
          <label>Pickup Address</label>
          <div style={{ flex: "1" }}>
            {!CheckoutData?.pickup_address ? (
              <GButton
                style={{ marginBottom: "10px" }}
                onClick={() => {
                  if (!selectedCustomer) {
                    openSnackbar("Please choose Customer");
                    return;
                  }
                  setSelectedAddressType("pickup");
                  setOcAddVisible(true);
                }}
                variant="linkable"
                children={"Choose Address"}
              />
            ) : (
              <div
                onClick={() => {
                  setSelectedAddressType("pickup");
                  setOcAddVisible(true);
                }}
              >
                <AddressView
                  dropdownActions={false}
                  addressData={CheckoutData.pickup_address}
                  default={false}
                />
              </div>
            )}
          </div>
        </Section>
      ) : (
        ""
      )}

      {deliveryAddressVisible === true ? (
        <Section>
          <label>Delivery Address</label>
          {!CheckoutData.delivery_address ? (
            <GButton
              style={{ margiBottom: "10px" }}
              onClick={() => {
                if (!selectedCustomer) {
                  openSnackbar("Please choose Customer");
                  return;
                }
                setSelectedAddressType("delivery");
                setOcAddVisible(true);
              }}
              variant="linkable"
              children="Choose Address"
            />
          ) : (
            <div
              onClick={() => {
                setSelectedAddressType("delivery");
                setOcAddVisible(true);
              }}
            >
              <AddressView
                dropdownActions={false}
                addressData={CheckoutData.delivery_address}
                default={false}
              />
            </div>
          )}
        </Section>
      ) : (
        ""
      )}

      <Section>
        {CheckoutData?.pickup_delivery_visible === true ? (
          <React.Fragment>
            <label>Choose Timeslot</label>
            <div style={{ padding: "10px 0" }}>
              <div
                style={{ display: "inline-block", cursor: "pointer" }}
                onClick={() => {
                  setSelectedDateType("pickup");
                  setOTslotsVisible(true);
                }}
              >
                <p style={{ margin: "0", color: "#777" }}>Pickup Date</p>
                <h4 style={{ margin: "5px 0" }}>
                  {CheckoutData?.formatted_pickup_time || "--"}
                </h4>
              </div>
              {props.settings?.delivery_pref === "1" && (
                <>
                  <hr
                    style={{
                      margin: "15px 0",
                      border: "0",
                      borderBottom: "1px solid #f2f2f2",
                    }}
                  />
                  <div
                    style={{ display: "inline-block", cursor: "pointer" }}
                    onClick={() => {
                      if (!CheckoutData?.formatted_pickup_time) {
                        openSnackbar("Please Choose pickup date first.");
                        return;
                      }
                      setSelectedDateType("delivery");
                      setOTslotsVisible(true);
                    }}
                  >
                    <p style={{ margin: "0", color: "#777" }}>Delivery Date</p>
                    <h4 style={{ margin: "5px 0" }}>
                      {CheckoutData?.formatted_schedule_time || "--"}
                    </h4>
                  </div>
                </>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <label>Delivery Type</label>
            <div>
              {CheckoutData.asap_visible === true ? (
                <React.Fragment>
                  <div
                    className="inputDiv"
                    onClick={() => {
                      CheckoutData.delivery_type = "asap";
                      CheckoutData.delivery_from = "";
                      CheckoutData.delivery_to = "";
                      CheckoutData.formatted_schedule_time = "";
                      setSelectedDay("");
                    }}
                  >
                    <input
                      name="option_delivery_type"
                      type="radio"
                      id="v_option_asap"
                      value="asap"
                      defaultChecked={CheckoutData.delivery_type === "asap"}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="v_option_asap">ASAP</label>
                  </div>
                  <br />
                </React.Fragment>
              ) : (
                ""
              )}

              {CheckoutData.delivery_type_visible === true ? (
                <div
                  className="inputDiv"
                  onClick={() => {
                    CheckoutData.delivery_type = "scheduled";
                    setOTslotsVisible(true);
                  }}
                >
                  <input
                    name="option_delivery_type"
                    type="radio"
                    id="v_option_scheduled"
                    value="scheduled"
                    defaultChecked={CheckoutData.delivery_type === "scheduled"}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="v_option_scheduled">
                    Later
                    <br />
                    <span>
                      {selectedDay
                        ? CheckoutData.formatted_schedule_time
                        : "Choose Timeslot"}
                    </span>
                  </label>
                </div>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        )}{" "}
      </Section>
      <Section>
        <label>Delivery Instructions</label>
        <GInput
          type="text"
          placeholder=""
          name="service_url"
          id="service_url"
          value={CheckoutData.instructions}
          display="block"
          onChange={(e) => {
            CheckoutData.instructions = e.target.value;
            setCheckoutData({ ...CheckoutData });
          }}
          onBlur={(e) => {
            CheckoutData.instructions = e;
          }}
        />
      </Section>

      <GButton
        variant="condensed"
        children="Place order"
        style={{ width: "100%" }}
        onClick={() => props.onOrderPlace(CheckoutData, selectedCustomer)}
      />

      <Modal
        isOpen={ocAddvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcAddVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order Customer Address Modal"
      >
        <CustomerAddress
          ocAddvisible={ocAddvisible}
          setOcAddVisible={setOcAddVisible}
          customerId={selectedCustomer}
          defaultAddress={
            selectedAddressType === "pickup"
              ? CheckoutData?.pickup_address
              : CheckoutData?.delivery_address
          }
          onSelectAddress={(address) => {
            if (selectedAddressType === "pickup") {
              CheckoutData.pickup_address = address;
            } else {
              CheckoutData.delivery_address = address;
            }
          }}
        />
      </Modal>

      <Modal
        isOpen={otslotsvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOTslotsVisible(false)}
        style={{
          content: {
            width: "550px",
          },
        }}
        contentLabel="Order Timeslots Modal"
      >
        <OrderTimeslots
          fromDate={
            selectedDateType !== "pickup" ? CheckoutData?.pickup_from : ""
          }
          selectedDateType={selectedDateType}
          selectedDate={selectedDay}
          otslotsvisible={otslotsvisible}
          setOTslotsVisible={setOTslotsVisible}
          onChooseTimeslot={(slotDate, slotTime) => {
            console.log(slotDate);
            if (CheckoutData?.delivery_type === "pickup_delivery") {
              if (selectedDateType === "pickup") {
                CheckoutData.pickup_from =
                  slotDate.date + " " + slotTime.from_time;
                CheckoutData.pickup_to = slotDate.date + " " + slotTime.to_time;
                CheckoutData.formatted_pickup_time =
                  slotDate.display +
                  " " +
                  slotTime.from_time +
                  "-" +
                  slotTime.to_time;

                CheckoutData.delivery_from = "";
                CheckoutData.delivery_to = "";
                CheckoutData.formatted_schedule_time = "";
              } else {
                CheckoutData.delivery_from =
                  slotDate.date + " " + slotTime.from_time;
                CheckoutData.delivery_to =
                  slotDate.date + " " + slotTime.to_time;
                CheckoutData.formatted_schedule_time =
                  slotDate.display +
                  " " +
                  slotTime.from_time +
                  "-" +
                  slotTime.to_time;
              }
            } else {
              CheckoutData.delivery_type = "scheduled";
              setSelectedDay(slotDate.display);
              CheckoutData.delivery_from =
                slotDate.date + " " + slotTime.from_time;
              CheckoutData.delivery_to = slotDate.date + " " + slotTime.to_time;
              CheckoutData.formatted_schedule_time =
                slotDate.display +
                " " +
                slotTime.from_time +
                "-" +
                slotTime.to_time;
            }
          }}
        />
      </Modal>
    </CheckoutDiv>
  );
}
