import React, { useState, useEffect, useContext } from "react";
import "../css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo } from "../services";
import useSettings from "../useSettings";
import { useParams } from "react-router-dom";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderThermalInvoice() {
  const setting = useSettings();
  const [strings] = useContext(StringsContext);
  let { orderId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [linksData, setLinksData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });

  const getInvoiceSettings = () => {
    ApiService({ method: "GET", route: "setting/invoice" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getOrderDetail = () => {
      ApiService({ method: "GET", route: "orders/" + orderId })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setInvoiceData(response.data.data);
            getInvoiceSettings();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    console.log(localStorage.getItem("aAccessToken"));
    console.log(setting);
    if (localStorage.getItem("aAccessToken")) {
      getOrderDetail();
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <div className="thermalInvoiceDiv">
      <img
        src={ImgUrl("logo") + "/" + linksData.invoice_logo.key_value}
        alt="logo"
        onError={addDefaultLogo}
      />
      <hr />

      <div>
        <p>{linksData.invoice_header_text.key_value}</p>
        <h4>{strings.w_order_number}</h4>
        <p>{"#" + orderId}</p>
        <p>{invoiceData.store ? invoiceData.created_full : ""}</p>
        <br />
        <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
        <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
        <p>
          {invoiceData.delivery ? invoiceData.delivery.delivery_address : ""}
        </p>
        <hr />
        <p>{invoiceData.store ? invoiceData.store.title : ""}</p>
        <p>{invoiceData.store ? invoiceData.store.address : ""}</p>
        {setting.product === "laundry" ? (
          ""
        ) : (
          <>
            <hr />
            <p>
              {strings.w_delivery_type} -{" "}
              {invoiceData.delivery_type ? invoiceData.delivery_type : ""}
            </p>
          </>
        )}
        <hr />
        <h4>
          {strings.w_items} (
          {invoiceData.order_products ? invoiceData.order_products.length : ""})
        </h4>
      </div>

      <div>
        <table>
          {invoiceData.order_products ? (
            <tbody>
              {invoiceData.order_products.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{item.quantity}X</td>
                    <td>
                      {item.title}
                      <br />({item.price}
                      {item.unit ? "/" + item.unit : ""})
                      <br />
                      {item.variants.map((v, $index) => {
                        return (
                          <small
                            key={v.id}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              display: "inline-block",
                              paddingRight: "5px",
                              lineHeight: "20px",
                              color: "#333",
                            }}
                          >
                            {v.value}
                            {item.variants.length > $index + 1 ? (
                              <span>, </span>
                            ) : (
                              ""
                            )}
                          </small>
                        );
                      })}
                    </td>
                    <td style={{ textAlign: "right", verticalAlign: "bottom" }}>

                    {item.price == 0 ?   <span
                          style={{
                            color: "green",
                            background: "#BAE1BD",
                            borderRadius: "3px",
                            padding: "3px 10px",
                            fontSize: "13PX",
                          }}
                        >
                          {strings.w_free}
                        </span>:
                        <>{setting?.currency_symbol}
                      {(item.quantity * item.price).toFixed(2)}
                      </>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            ""
          )}
        </table>

        <table style={{ borderTop: "0.5px solid #ccc" }}>
          {invoiceData.payment_summary ? (
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td
                  style={{ textAlign: "right", verticalAlign: "bottom" }}
                ></td>
              </tr>
              {invoiceData.payment_summary.data.map((payment, ind) => {
                return payment.title !== "line" ? (
                  <tr className={payment.title + " payment"} key={ind}>
                    <td>{payment.title}</td>
                    <td></td>
                    <td style={{ textAlign: "right" }}>{payment.value}</td>
                  </tr>
                ) : (
                  <tr style={{ borderBottom: "1px solid #e2e2e2" }}>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                  </tr>
                );
              })}

              <tr
                className="payment"
                style={{ borderTop: "1px solid #e2e2e2" }}
              >
                <td>
                  {strings.w_total} ({invoiceData.gateway_display})
                </td>
                <td></td>
                <td style={{ textAlign: "right" }}>
                  {setting?.currency_symbol}
                  {invoiceData.payment_summary.total_display}
                  
                </td>
              </tr>
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
      <br />

      <p>
        {strings.s_thankyou_for_using_the_services_of} {setting?.project_name}.
      </p>
      <p>{strings.s_powered_by} Ambar</p>
      <br />
      <p>{linksData.invoice_footer_text.key_value}</p>
    </div>
  );
}
