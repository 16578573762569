import React, { useEffect, useState } from "react";
import styled from "styled-components";
import VariantForm from "../components/productVariantsForm";
import AddVariantForm from "../components/productAddVariantsForm";
import useDataFctory from "../useDataFactory";
import VariantTypeForm from "../forms/variantTypesForm";
import Modal from "react-modal";
import GButton from "../gComponents/gButton";
import { getUserType, isPermissionExist } from "../services";

const Tab = styled.a`
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.6;
  border: 0;
  display: block;
  border-radius: 0px;
  border-left: 2px solid transparent;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-left: 2px solid #D8AE36;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: block;
  /* border-bottom: 1px solid #e2e2e2; */
  margin-bottom: 15px;
  width: 130px;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #98989c;
    background: #d8ae36;
    border-radius: 8px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background: #d9e5f4;
  }
`;

export default function ProductVariantsView(props) {
  const { data, refreshData } = useDataFctory("variant_types", false, {
    status: 1,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [productVar, setProductVar] = useState(props.productVariants);
  const [selectedTab, setSelectedTab] = useState(props.selectedIndex);
  const [formVisisble, setFormVisible] = useState(false);
  const [vtvisible, setVtvisible] = useState(false);
  const [selectedVariantType, setSelectedVariantType] = useState({
    data: {},
    options: [],
  });

  useEffect(() => {
    if (!props.productId && data) {
      console.log(data);
      setProductVar(data);
      console.log(props.selectedIndex);
      data.map((k, v) => {
        data[v].options = data[v]?.options || [];
        if (props.selectedIndex === v) {
          setSelectedVariantType({
            data: k,
            options: k?.options || [],
          });
          setFormVisible(true);
        }
      });
      setIsLoading(false);
    }
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (props.productId) {
      console.log(props);
      setProductVar(props.productVariants);
      console.log(props.selectedIndex);
      props.productVariants.map((k, v) => {
        if (props.selectedIndex === v) {
          setSelectedVariantType({
            data: k,
            options: k.options,
          });
          setFormVisible(true);
        }
      });
      setIsLoading(false);
    }
  }, [props]);

  return (
    <>
      <div style={{ display: "flex" }}>
        {getUserType() || isPermissionExist("variant_type_index") ? (
          <GButton
            variant="linkable"
            type="button"
            style={{ marginLeft: "auto", marginTop: "-40px" }}
            onClick={() => setVtvisible(true)}
          >
            Add New Variant Type
          </GButton>
        ) : (
          ""
        )}
      </div>

      <div style={{ background: "#f8f9fd", padding: "15px 5px" }}>
        <div style={{ display: "flex" }}>
          {isLoading == true ? (
            ""
          ) : (
            <>
              <ButtonGroup>
                {productVar.map((type, i) => (
                  <Tab
                    key={i}
                    active={selectedTab === i}
                    onClick={() => {
                      setFormVisible(false);
                      props.setVariantTypeIndex(i);
                      setSelectedVariantType({
                        data: type,
                        options: type.options,
                      });
                      setSelectedTab(i);
                      setTimeout(function () {
                        setFormVisible(true);
                      }, 10);
                    }}
                  >
                    {type.title}
                  </Tab>
                ))}
              </ButtonGroup>

              <div style={{ width: "80%" }}>
                {formVisisble === true ? (
                  props.productId ? (
                    <VariantForm
                      selectedVariantType={selectedVariantType}
                      selectedVariant={selectedTab}
                      productId={props.productId}
                      onVariantRemove={(ind) =>
                        props.handleVariantRemove(selectedTab, ind)
                      }
                      onVariantAdd={(data) => {
                        props.handleVariantAdd(selectedTab, data);
                      }}
                      isVarStockShow={props.isVarStockShow}
                    />
                  ) : (
                    <AddVariantForm
                      selectedVariantType={selectedVariantType}
                      selectedVariant={selectedTab}
                      onVariantRemove={(ind) => {
                        props.handleVariantRemove(selectedTab, ind);
                        if (!props.productId) {
                          data[selectedTab].options.splice(ind, 1);
                        }
                      }}
                      onVariantAdd={(ndata) => {
                        props.handleVariantAdd(selectedTab, ndata);
                        if (!props.productId) {
                          data[selectedTab].options.push(ndata);
                        }
                      }}
                      onVariantEdit={(ndata, i) => {
                        props.handleVariantEdit(selectedTab, ndata, i);
                        if (!props.productId) {
                          data[selectedTab].options[i] = ndata;
                        }
                      }}
                      isVarStockShow={props.isVarStockShow}
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={vtvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVtvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Variant Type Form Modal"
      >
        <VariantTypeForm
          vtvisible={vtvisible}
          setVtvisible={setVtvisible}
          variantData={{}}
          isEmpty={true}
          onSubmit={(data) => {
            if (props.productId) {
              props.onVariantTypeAdd();
            } else {
              refreshData({ status: 1 });
            }
          }}
        />
      </Modal>
    </>
  );
}
