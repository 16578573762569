/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Add, SubdirectoryArrowRight } from "@material-ui/icons";
import settingIcon from "../assets/images/sidebar/settings.svg";
import supportIcon from "../assets/images/sidebar/chat.svg";
import { StringsContext } from "../DataFactory/useStrings";
import SidebarRoutes from "../sidebar/sidebarRoutes";

const ParentDiv = styled.div`
  height: calc(100vh - 320px);
  overflow: overlay;

  .pList {
    margin: 0;
  }
  .pListItem {
    font-size: 13px;
    font-weight: 550;
    letter-spacing: 0.2px;
    padding: 6px 15px;
    color: #bbb;
    display: flex;
    margin: 5px 0 0;

    &.active,
    &.active:hover {
      background: #d8ae36;
      border-radius: 5px;
      color: #fff;
      span img {
        opacity: 1;
      }
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
        span img {
          opacity: 1;
        }
      }
      .addIcon {
        color: #fff;
      }
    }
    &:hover {
      background: #3a393e;
      border-radius: 5px;
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
      }
    }
    .pListIcon {
      margin-right: 15px;
      vertical-align: middle;
      img {
        width: 15px;
        height: 15px;
        opacity: 0.7;
      }
    }
    .pLink {
      color: #bbb;
      text-decoration: none;
      display: inline-block;
      width: 90%;
      font-weight: 550;
      letter-spacing: -0.2px;
      padding: 2px 0 0 0;
    }
    .addIcon {
      color: #b2b2b2;
      font-size: 17px;
      padding: 0;
      padding-top: 2px;
      cursor: pointer;
    }
  }

  .cList {
    background: transparent;
    list-style-type: none;
    padding: 0 0 2px 10px;
    height: 0px;
    display: none;
    opacity: 0;
    margin: 0;
    &.collapse-in {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.5s;
      animation-name: slideHight;
      transition: height 0.5s;
    }
    &.collapse {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.3s;
      animation-name: slideMaxHight;
      transition: height 0.3s;
    }
    .cListItem {
      height: auto;
      padding: 0 10px;
      font-size: 14px;
      &.active,
      &:hover {
        background: transparent;
        .cLink {
          color: #fff;
        }
      }
      .cListIcon {
        vertical-align: middle;
        svg {
          margin-right: 10px;
          float: none;
          padding: 0px;
        }
      }
      .cLink {
        color: #b2b2b2;
        display: block;
        padding: 3px 0;
        font-size: 14px;
        font-weight: 450;
      }
    }
  }
`;

function GNestedMenu(props) {
  const location = useLocation();
  const [strings] = useContext(StringsContext);

  function isPermissionExist(per) {
    let exist = props.settings?.permissions?.includes(per);
    console.log(exist);
    return exist;
  }

  function getUserType() {
    let uType =
      props.settings?.user?.user_type === "sa"
        ? true
        : props.settings?.user?.user_type === "admin"
        ? true
        : false;
    return uType;
  }

  return (
    <React.Fragment>
      <ParentDiv mode="inline" theme="dark">
        <ul className="pList">
          <SidebarRoutes
            settings={props.settings}
            handleClick={(title) => props.handleClick(title)}
          />
        </ul>
      </ParentDiv>

      <h4>{strings.w_other_information}</h4>
      <ul className="pList">
        {props.settings?.user?.user_type === "sa" && (
          <li className={location.state === "setting" ? "active" : ""}>
            <Link to={{ pathname: "/settings/basic", state: "setting" }}>
              <span role="img" aria-label="Order" className="icon">
                {/* 🛠️ */}
                <img src={settingIcon} alt="Settings" />
              </span>
              <span>{strings.w_settings}</span>
            </Link>
          </li>
        )}

        {(getUserType() == true || isPermissionExist("support_chat")) &&
        props.settings?.support_chat == true ? (
          <li className={location.state === "support" ? "active" : ""}>
            <Link to={{ pathname: "/chat-support", state: "support" }}>
              <span role="img" aria-label="Order" className="icon">
                <img
                  src={supportIcon}
                  alt="Support"
                  style={{ width: "15px" }}
                />
              </span>
              <span>{strings.w_chat_support}</span>
            </Link>
          </li>
        ) : (
          ""
        )}
      </ul>
    </React.Fragment>
  );
}

export default GNestedMenu;
