import React, { useState, useContext, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { CustomerFilter } from "./customerFilterComponent";
import GText from "../gComponents/gText";
import { Cancel } from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";
import Modal from "react-modal";
import LocalizationForm from "../forms/localizationForm";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import GTabs from "../gComponents/gTabs";
import { Switch, Route, useParams } from "react-router-dom";
import { StoreFilter } from "../components/storeFilterComponent";
import { ProductFilter } from "../components/productFilterComponent";

export default function BulkNotifications() {
  const [typeValue, setTypeValue] = React.useState("push");
  const [strings] = useContext(StringsContext);

  const notifyRoutes = [
    {
      title: strings.w_push,
      identifier: "push",
      link: "/bulk-notifications/push",
      state: "coupon",
    },
    {
      title: strings.w_email,
      identifier: "email",
      link: "/bulk-notifications/email",
      state: "coupon",
    },
    {
      title: strings.w_sms,
      identifier: "sms",
      link: "/bulk-notifications/sms",
      state: "coupon",
    },
  ];

  return (
    <div className="mainInnerView">
      <h1>{strings.w_bulk_notifications}</h1>
      <GTabs routes={notifyRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/bulk-notifications/:type">
            <Child onChange={(newValue) => setTypeValue(newValue)} />
          </Route>
        </div>
      </Switch>
    </div>
  );
}

function Child(props) {
  let { type } = useParams();
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const settings = useSettings();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [selectedStores, setSelectedStores] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [switchCustomersVal, setSwitchCustomersVal] = useState(false);
  const [selectedCustomers, setSelectedCutomers] = useState([]);
  const [localizationsArray, setLocalizationsArray] = useState([]);
  const [localizationVisible, setLocalizationVisible] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const initialValues = {
    title: "",
    subtitle: "",
    subject: "",
    message: "",
    notification_type: "",
    template_id: "",
    product_id: "",
    customers: [],
  };

  useEffect(() => {
    console.log(type);
    document.getElementById("resetButton").click();
    setSelectedCutomers([]);
    setLocalizationsArray([]);
    setCustomerId("");
    props.onChange(type);
  }, [type]);

  const onSubmit = (values, { setFieldValue, resetForm }) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };

    let assignedCustomers = [];
    selectedCustomers.map((cus) => {
      assignedCustomers.push(cus.id);
    });
    sendData.customers = switchCustomersVal == true ? [] : assignedCustomers;

    if (type == "email") {
      delete sendData.title;
      delete sendData.subtitle;
      delete sendData.message;
      delete sendData.product_id;
      delete sendData.notification_type;
    }
    if (type == "push") {
      sendData.localizations = localizationsArray || [];
      delete sendData.subject;
      delete sendData.template_id;
      delete sendData.message;
      let assignedStores = [];
      selectedStores.map((s) => {
        assignedStores.push(s.id);
      });
      sendData.stores = assignedStores;
    }
    if (type == "sms") {
      delete sendData.title;
      delete sendData.subtitle;
      delete sendData.subject;
      delete sendData.template_id;
      delete sendData.product_id;
      delete sendData.notification_type;
    }
    console.log(sendData);
    ApiService({
      method: "POST",
      route: "promotion/" + type,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        resetForm(initialValues);
        setSelectedCutomers([]);
        setLocalizationsArray([]);
        setCustomerId("");
      }
      setIsBtnDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    title: type == "push" ? Yup.string().required(strings.w_required) : "",
    subtitle: type == "push" ? Yup.string().required(strings.w_required) : "",
    subject: type == "email" ? Yup.string().required(strings.w_required) : "",
    template_id:
      type == "email" ? Yup.string().required(strings.w_required) : "",
    message: type == "sms" ? Yup.string().required(strings.w_required) : "",
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form style={{ width: "90%" }}>
            {type === "push" && (
              <>
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_title}
                  name="title"
                  divstyle={{ width: "500px" }}
                />
                <FormikControl
                  control="input"
                  as="textarea"
                  type="text"
                  label={strings.w_subtitle}
                  name="subtitle"
                  rows="5"
                  divstyle={{ width: "500px" }}
                />

                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_notiification_type}
                  name="notification_type"
                  options={[
                    { title: strings.w_regular, value: "regular" },
                    { title: strings.w_product, value: "product" },
                    { title: strings.w_stores, value: "stores" },
                  ]}
                  key_title="title"
                  key_value="value"
                  onChange={(e) => {
                    setFieldValue("notification_type", e.target.value || "");
                    setFieldValue("product_id", "");
                    setSelectedStore("");
                    setStoreId("");
                    setSelectedStores([]);
                  }}
                  divstyle={{ width: "500px" }}
                />

                {values.notification_type === "product" && (
                  <>
                    <div style={{ padding: "4px 15px", width: "500px" }}>
                      <label>{strings.w_store}</label>
                      <StoreFilter
                        value={selectedStore || ""}
                        storeType={"regular"}
                        style={{
                          marginBottom: "10px",
                          display: "block",
                          width: "100%",
                        }}
                        handleStoreChange={(value) => {
                          console.log(value);
                          setSelectedStore(value || "");
                          setFieldValue("product_id", "");
                        }}
                      />
                    </div>
                    {selectedStore ? (
                      <div style={{ padding: "4px 15px", width: "500px" }}>
                        <label>{strings.w_product}</label>
                        <ProductFilter
                          value={values.product_id}
                          style={{
                            margin: "0px 0 20px",
                            width: "100%",
                          }}
                          status={1}
                          containerStyle={{ margin: "0", width: "100%" }}
                          storeId={selectedStore}
                          perPage={50}
                          handleProductChange={(id, value) => {
                            setFieldValue("product_id", id ? id : "");
                          }}
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {values.notification_type == "stores" && (
                  <div style={{ padding: "4px 15px 15px" }}>
                    <div>
                      <label>{strings.w_stores}</label>
                      <StoreFilter
                        value={storeId}
                        handleStoreChange={(value, data) => {
                          setStoreId(value || "");
                          if (value) {
                            let selectedInd = selectedStores.findIndex(
                              (s) => s.id == value
                            );
                            if (selectedInd == -1) {
                              selectedStores.push({
                                id: value,
                                title: data.title,
                                phone: data.phone,
                              });
                              setSelectedStores([...selectedStores]);
                            }
                          }
                        }}
                        style={{ width: "500px" }}
                      />
                    </div>
                    {selectedStores.length > 0 && (
                      <div
                        style={{
                          border: "1px solid #e2e2e2",
                          padding: "7px 5px",
                          marginTop: "15px",
                        }}
                      >
                        {selectedStores.map((c, i) => {
                          return (
                            <TooltipComponent
                              key={i}
                              title={c.title}
                              position="top"
                              id={c.id}
                              subtitle={`${c.phone || ""}`}
                              theme={"white"}
                              tooltipStyle={{
                                textAlign: "left",
                                minWidth: "auto",
                                borderRadius: "4px",
                                left: "60%",
                              }}
                              divStyle={{ margin: 0 }}
                            >
                              <div
                                style={{
                                  margin: "5px",
                                  display: "inline-flex",
                                  background: "#f4f5f9",
                                  borderRadius: "4px",
                                  padding: "2px 10px",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <GText
                                    semi
                                    text={c.title}
                                    style={{
                                      margin: "2px 5px",
                                      fontSize: "14px",
                                    }}
                                  />
                                </div>
                                <Cancel
                                  onClick={() => {
                                    selectedStores.splice(i, 1);
                                    setSelectedStores([...selectedStores]);
                                  }}
                                  style={{ width: "18px", marginLeft: 10 }}
                                />
                              </div>
                            </TooltipComponent>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {type === "email" && (
              <>
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_subject}
                  name="subject"
                  divstyle={{ width: "500px" }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_template_id}
                  name="template_id"
                  divstyle={{ width: "500px" }}
                />
              </>
            )}

            {type === "sms" && (
              <>
                <FormikControl
                  control="input"
                  as="textarea"
                  type="text"
                  label={strings.w_message}
                  name="message"
                  rows="5"
                  divstyle={{ width: "500px" }}
                />
              </>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 15px",
              }}
            >
              <label
                style={{
                  display: "inline-block",
                  fontSize: "0.98rem",
                  color: "#333",
                  width: "170px",
                  margin: 0,
                  fontWeight: 500,
                }}
              >
                {strings.s_send_to_all_customers}
              </label>
              <FormikControl
                control="toggle"
                isToggled={switchCustomersVal}
                name="is_customer"
                onToggle={() => {
                  setSwitchCustomersVal(!switchCustomersVal);
                }}
              />
            </div>
            {switchCustomersVal != true && (
              <div>
                <div
                  style={{
                    margin: "15px 15px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: "0.98rem",
                      color: "#333",
                      width: "170px",
                      margin: 0,
                      fontWeight: 500,
                    }}
                  >
                    {strings.w_select_customers}
                  </label>
                  <CustomerFilter
                    value={customerId}
                    handleCustomerChange={(value, data) => {
                      setCustomerId(value || "");
                      if (value) {
                        let selectedInd = selectedCustomers.findIndex(
                          (s) => s.id == value
                        );
                        if (selectedInd == -1) {
                          selectedCustomers.push({
                            id: value,
                            title: data.title,
                            email: data.email,
                            phone: data.phone,
                          });
                          setSelectedCutomers([...selectedCustomers]);
                        }
                      }
                    }}
                    style={{ width: "300px" }}
                  />
                </div>
                {selectedCustomers.length > 0 && (
                  <div
                    style={{
                      border: "1px solid #e2e2e2",
                      padding: "7px 5px",
                      marginTop: "15px",
                    }}
                  >
                    {selectedCustomers.map((c, i) => {
                      return (
                        <TooltipComponent
                          key={i}
                          title={c.title}
                          position="top"
                          id={c.id}
                          subtitle={`${c.email} \n ${c.phone || ""}`}
                          theme={"white"}
                          tooltipStyle={{
                            textAlign: "left",
                            minWidth: "auto",
                            borderRadius: "4px",
                            left: "60%",
                          }}
                          divStyle={{ margin: 0 }}
                        >
                          <div
                            style={{
                              margin: "5px",
                              display: "inline-flex",
                              background: "#f4f5f9",
                              borderRadius: "4px",
                              padding: "2px 10px",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <div>
                              <GText
                                semi
                                text={c.title}
                                style={{
                                  margin: "2px 5px",
                                  fontSize: "14px",
                                }}
                              />
                            </div>
                            <Cancel
                              onClick={() => {
                                selectedCustomers.splice(i, 1);
                                setSelectedCutomers([...selectedCustomers]);
                              }}
                              style={{ width: "18px", marginLeft: 10 }}
                            />
                          </div>
                        </TooltipComponent>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            {type === "push" && (
              <>
                {settings?.locales && settings?.locales.length > 0 && (
                  <div style={{ margin: "15px 15px 0" }}>
                    <label>{strings.w_localizations}</label>

                    {localizationsArray.length > 0 ? (
                      <GButton
                        variant="linkable"
                        onClick={() => setLocalizationVisible(true)}
                      >
                        {localizationsArray.length}{" "}
                        {strings.w_localizations_added}
                      </GButton>
                    ) : (
                      <GButton
                        variant="linkable"
                        children={strings.w_add_localization}
                        onClick={() => setLocalizationVisible(true)}
                      />
                    )}
                  </div>
                )}
              </>
            )}
            <div style={{ display: "flex", margin: "25px 15px 20px 15px" }}>
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={strings.w_send_notification}
              />
              <GText
                semi
                text={
                  switchCustomersVal == true
                    ? `(${strings.w_all_customers})`
                    : `(${selectedCustomers.length} ${strings.w_customers})`
                }
                style={{ width: "auto", marginLeft: 15 }}
              />
            </div>

            <button
              type="button"
              id="resetButton"
              style={{ display: "none" }}
              onClick={() => resetForm()}
            ></button>
          </Form>
        )}
      </Formik>

      <Modal
        isOpen={localizationVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setLocalizationVisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
        contentLabel="Localization Form Modal"
      >
        <LocalizationForm
          localizationVisible={localizationVisible}
          setLocalizationVisible={setLocalizationVisible}
          data={localizationsArray}
          onSubmit={(data) => {
            setLocalizationsArray([...data]);
          }}
          keyArray={[
            { title: strings.w_title, value: "title" },
            { title: strings.w_subtitle, value: "sub_title" },
          ]}
          variables={[]}
        />
      </Modal>
    </div>
  );
}
