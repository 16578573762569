import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { Formik, Form, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import TaxForm from "../components/taxForm";
import { StringsContext } from "../DataFactory/useStrings";

export default function TaxTypes(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();

  const Types = [
    {
      title: strings.w_fixed,
      value: "fixed",
    },
    {
      title: strings.w_percentage,
      value: "percentage",
    },
  ];

  useEffect(() => {
    props.taxData.map((c) => {
      c.isDisabled = true;
    });
  }, [props.taxData]);

  const initialValues = {
    options: [],
  };

  const onSubmit = (values, { resetForm }) => {
    setIsBtnDisabled(true);
    let sendData = { ...values.options[0] };
    if (sendData.type == "fixed") {
      delete sendData.percentage;
    }
    if (sendData.type == "percentage") {
      delete sendData.fixed;
    }
    console.log(sendData);
    ApiService({ method: "POST", route: "taxes", body: sendData })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          response.data.data.isDisabled = true;
          props.onSubmit(response.data.data);
          resetForm(initialValues);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    options: Yup.array()
      .of(
        Yup.object({
          title: Yup.string().required(strings.w_required), // these constraints take precedence
          type: Yup.string().required(strings.w_required), // these constraints take precedence
          fixed: Yup.string().when("type", {
            is: "fixed",
            then: Yup.string().required(strings.w_required),
          }),
          percentage: Yup.string().when("type", {
            is: "percentage",
            then: Yup.string().required(strings.w_required),
          }),
        })
      )
      .required(strings.w_required),
  });

  return (
    <React.Fragment>
      {props.taxData.length > 0 ? (
        <TaxForm
          data={props.taxData}
          onTaxRemove={(id) => {
            props.onTaxRemove(id);
          }}
          onTaxUpdate={(data) => {
            props.onTaxUpdate(data);
          }}
        />
      ) : (
        ""
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="options">
              {(fieldArrayProps) => {
                // console.log(fieldArrayProps);
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { options } = values;
                return (
                  <React.Fragment>
                    {options.map((o, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            boxSizing: "border-box",
                            gap: 15,
                            margin: "5px 0",
                          }}
                          className="arrayFields"
                        >
                          <FormikControl
                            control="input"
                            type="text"
                            label={strings.w_title}
                            name={`options[${index}].title`}
                            divstyle={{
                              padding: "0",
                              verticalAlign: "top",
                              width: "150px",
                            }}
                            innerDivStyle={{
                              boxSizing: "border-box",
                              border: "1px solid #e2e2e2",
                              borderRadius: "4px",
                              padding: "2px 4px",
                              background: "transparent",
                            }}
                            style={{
                              height: "auto",
                              border: 0,
                              padding: "2px 5px 0",
                              margin: 0,
                              color: "#000",
                              background: "transparent",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                            }}
                            labelStyle={{
                              fontSize: "13px",
                              margin: 0,
                              padding: "0 0 0 5px",
                              color: "#a2a2a2",
                            }}
                          />

                          <FormikControl
                            control="select"
                            as="select"
                            label={strings.w_type}
                            name={`options[${index}].type`}
                            options={Types}
                            key_title="title"
                            key_value="value"
                            onChange={(e) => {
                              setFieldValue(
                                `options[${index}].type`,
                                e.target.value || ""
                              );
                              setFieldValue(`options[${index}].fixed`, "");
                              setFieldValue(`options[${index}].percentage`, "");
                            }}
                            divstyle={{
                              padding: "0",
                              verticalAlign: "top",
                              width: "150px",
                            }}
                            innerDivStyle={{
                              boxSizing: "border-box",
                              border: "1px solid #e2e2e2",
                              borderRadius: "4px",
                              padding: "2px 4px",
                              background: "transparent",
                            }}
                            labelStyle={{
                              fontSize: "13px",
                              margin: 0,
                              padding: "0 0 0 5px",
                              color: "#a2a2a2",
                            }}
                            style={{
                              height: "auto",
                              border: 0,
                              padding: "2px 2px 0",
                              margin: 0,
                              color: "#000",
                              background: "transparent",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                            }}
                          />
                          {o.type == "percentage" && (
                            <FormikControl
                              control="input"
                              type="text"
                              label={`${strings.w_default} %`}
                              name={`options[${index}].percentage`}
                              maxLength={3}
                              onChange={(event) => {
                                const re = /^[0-9.\b]+$/;
                                if (
                                  event.target.value &&
                                  !re.test(event.target.value)
                                ) {
                                  return;
                                } else {
                                  setFieldValue(
                                    `options[${index}].percentage`,
                                    event.target.value
                                  );
                                }
                              }}
                              divstyle={{
                                padding: "0",
                                verticalAlign: "top",
                                width: "125px",
                              }}
                              innerDivStyle={{
                                boxSizing: "border-box",
                                border: "1px solid #e2e2e2",
                                borderRadius: "4px",
                                padding: "2px 4px",
                                background: "transparent",
                              }}
                              style={{
                                height: "auto",
                                border: 0,
                                padding: "2px 5px 0",
                                margin: 0,
                                color: "#000",
                                background: "transparent",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                              }}
                              labelStyle={{
                                fontSize: "13px",
                                margin: 0,
                                padding: "0 0 0 5px",
                                color: "#a2a2a2",
                              }}
                            />
                          )}
                          {o.type == "fixed" && (
                            <FormikControl
                              control="input"
                              type="text"
                              label={strings.w_default}
                              name={`options[${index}].fixed`}
                              maxLength={6}
                              onChange={(event) => {
                                const re = /^[0-9.\b]+$/;
                                if (
                                  event.target.value &&
                                  !re.test(event.target.value)
                                ) {
                                  return;
                                } else {
                                  setFieldValue(
                                    `options[${index}].fixed`,
                                    event.target.value
                                  );
                                }
                              }}
                              divstyle={{
                                padding: "0",
                                verticalAlign: "top",
                                width: "125px",
                              }}
                              innerDivStyle={{
                                boxSizing: "border-box",
                                border: "1px solid #e2e2e2",
                                borderRadius: "4px",
                                padding: "2px 4px",
                                background: "transparent",
                              }}
                              style={{
                                height: "auto",
                                border: 0,
                                padding: "2px 5px 0",
                                margin: 0,
                                color: "#000",
                                background: "transparent",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                              }}
                              labelStyle={{
                                fontSize: "13px",
                                margin: 0,
                                padding: "0 0 0 5px",
                                color: "#a2a2a2",
                              }}
                            />
                          )}
                          {/* <div
                            style={{
                              padding: "10px 5px",
                              maxWidth: "250px",
                              minWidth: "250px",
                            }}
                          >
                            <FormikControl
                              control="input"
                              type="text"
                              label="Title"
                              name={`options[${index}].title`}
                              divstyle={{ padding: "0" }}
                              style={{ width: "92%", margin: "5px 0" }}
                            />
                          </div>
                          <div
                            style={{
                              padding: "10px 5px",
                              maxWidth: "200px",
                            }}
                          >
                            <FormikControl
                              control="input"
                              type="text"
                              label="Percentage"
                              name={`options[${index}].percentage`}
                              divstyle={{ padding: "0" }}
                              style={{ width: "92%", margin: "5px 0" }}
                              maxLength={3}
                              onChange={(event) => {
                                const re = /^[0-9.\b]+$/;
                                if (
                                  event.target.value &&
                                  !re.test(event.target.value)
                                ) {
                                  return;
                                } else {
                                  setFieldValue(
                                    `options[${index}].percentage`,
                                    event.target.value
                                  );
                                }
                              }}
                            />
                          </div> */}

                          <span
                            style={{
                              textAlign: "right",
                              width: "calc(100% - 420px)",
                              display: "flex",
                              alignItems: "start",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                remove(index);
                              }}
                              style={{ alignSelf: "auto" }}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                            <GButton
                              type="submit"
                              variant="linkable"
                              disabled={isBtnDisabled}
                              style={{
                                padding: "10px 5px",
                                textDecoration: "underline",
                                alignSelf: "auto",
                              }}
                            >
                              {strings.w_submit}
                            </GButton>
                          </span>
                        </div>
                      );
                    })}

                    <div>
                      <GButton
                        type="button"
                        variant="linkable"
                        onClick={(e) => {
                          push({
                            title: "",
                            type: "percentage",
                            percentage: "",
                            fixed: "",
                          });
                        }}
                      >
                        {strings.w_add_type}
                      </GButton>
                    </div>
                  </React.Fragment>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
