import React, { useState, useRef, useContext } from "react";
import { GetApp } from "@material-ui/icons";
import { csvFileApiService, ApiDownloadService } from "../services";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import sampleFile from "../assets/files/sample-products-csv.csv";
import sampleLocaleFile from "../assets/files/sample-products-locale-csv.csv";
import { StoreFilter } from "../components/storeFilterComponent";
import GText from "../gComponents/gText";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function BulkUpload() {
  const [strings] = useContext(StringsContext);
  const fileInput = useRef(null);
  const fileEditInput = useRef(null);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedItemStore, setSelectedItemStore] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedEditItemFile, setSelectedEditItemFile] = useState({});
  const [openSnackbar] = useSnackbar();
  const settings = useSettings();

  function uploadBukItems() {
    var hitUrl = "bulk/products";
    console.log(hitUrl);
    console.log(selectedFile);
    var sendData = {
      csv: selectedFile,
      store_id: selectedStore,
    };
    console.log(sendData);
    csvFileApiService("POST", hitUrl, sendData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          fileInput.current.value = "";
          setSelectedFile({});
          window.location.reload(false);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateItems() {
    // return;
    var hitUrl = "bulk/product/update";
    console.log(hitUrl);
    console.log(selectedEditItemFile);
    var sendData = {
      csv: selectedEditItemFile,
      store_id: selectedStore,
    };
    console.log(sendData);
    csvFileApiService("POST", hitUrl, sendData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          window.location.reload(false);
        }
        console.log(response.data);
        openSnackbar(response.message);
        fileEditInput.current.value = "";
        setSelectedEditItemFile({});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function downloadItems() {
    console.log(selectedItemStore);
    // return;
    var hitUrl = "bulk/product/download";
    console.log(hitUrl);
    var defaultFileName = "products.csv";
    ApiDownloadService("POST", hitUrl, { store_id: selectedItemStore })
      .then((response) => {
        console.log(JSON.stringify(response));
        var blob = new Blob([response]);
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.style = "display:none";
        a.href = objectUrl;
        a.download = defaultFileName;
        a.click();
        setSelectedItemStore("");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>{strings.w_bulk_upload}</h2>
      <div className="listPaper">
        <>
          <a
            href={
              settings?.locales && settings?.locales.length > 0
                ? sampleLocaleFile
                : sampleFile
            }
            download
            style={{
              float: "right",
              textDecoration: "underline",
              color: "#D8AE36",
              fontSize: "14px",
              marginTop: "7px",
            }}
          >
            {StringsContext.s_download_sample_csv_file}
          </a>
          <GText
            g4
            text={strings.w_upload_bulk_items}
            style={{ marginBottom: "30px" }}
          />
          <GText med text={strings.w_choose_file} />
          <GButton
            variant="outlined"
            onClick={() => document.getElementById("itemFile").click()}
            style={{
              padding: "5px 15px",
              borderRadius: "0",
            }}
          >
            {strings.w_choose_a_file}
          </GButton>
          &nbsp;
          <GText
            med
            text={
              fileInput?.current?.value
                ? selectedFile?.name
                : strings.w_no_file_choosen
            }
            style={{ display: "inline", width: "auto" }}
          />
          <input
            type="file"
            id="itemFile"
            ref={fileInput}
            onChange={(e) => {
              console.log(e.target.files[0]);
              setSelectedFile(e.target.files[0]);
            }}
            style={{ margin: "10px 0 20px", display: "none" }}
          />
          <GText
            med
            text={strings.w_select_store}
            style={{ marginTop: "30px" }}
          />
          <StoreFilter
            value={selectedStore}
            displayValue=""
            style={{
              marginBottom: "25px",
              display: "block",
            }}
            handleStoreChange={(value) => {
              setSelectedStore(value);
            }}
          />
          <GButton
            variant="condensed"
            children={strings.w_upload}
            onClick={uploadBukItems}
          ></GButton>
        </>
        <br /> <br />
        <hr />
        <React.Fragment>
          <p style={{ marginBottom: "20px", fontWeight: "550" }}>
            {strings.w_download_items}
          </p>
          <div>
            <label
              style={{
                color: "#333",
                display: "block",
                margin: "8px 0",
                fontSize: "14px",
              }}
            >
              {strings.w_select + " " + strings.w_store}
            </label>

            <StoreFilter
              value={selectedItemStore}
              displayValue=""
              style={{
                marginBottom: "10px",
              }}
              handleStoreChange={(value) => {
                console.log(value);
                setSelectedItemStore(value);
              }}
            />
          </div>
          <GButton
            variant="outlined"
            children={strings.w_download}
            onClick={downloadItems}
            style={{
              padding: "0.6em 2em",
              margin: "10px 0 15px",
              display: "inline-flex",
              alignItems: "center",
              fontWeight: "550",
            }}
          >
            <GetApp style={{ width: "20px", height: "20px" }} /> &nbsp; Download
          </GButton>
        </React.Fragment>
        <br />
        <hr />
        <React.Fragment>
          <GText
            g4
            text={strings.w_update_items}
            style={{ margin: "20px 0" }}
          />
          <GText med text={strings.w_choose_file} />
          <GButton
            variant="outlined"
            onClick={() => document.getElementById("itemEditFile").click()}
            style={{
              padding: "5px 15px",
              borderRadius: "0",
            }}
          >
            {strings.w_choose_a_file}
          </GButton>{" "}
          &nbsp;
          <GText
            med
            text={
              fileEditInput?.current?.value
                ? selectedEditItemFile?.name
                : strings.w_no_file_choosen
            }
            style={{ display: "inline", width: "auto" }}
          />
          <input
            type="file"
            id="itemEditFile"
            ref={fileEditInput}
            onChange={(e) => {
              console.log(e.target.files[0]);
              setSelectedEditItemFile(e.target.files[0]);
            }}
            style={{ margin: "10px 0 20px", display: "none" }}
          />
          <br />
          <br />
          <GButton
            variant="condensed"
            children={strings.w_update}
            onClick={updateItems}
          />
        </React.Fragment>
      </div>
    </div>
  );
}
