import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import GListCard from "../gComponents/gListCards";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import BranchFormPopup from "../forms/branchform";
import GText from "../gComponents/gText";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function Branches() {
  const [strings] = useContext(StringsContext);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [branchfvisible, setBranchfvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();
  const {
    loading,
    data: branchesData,
    Placeholder,
    CardShimmer,
  } = useDataFctory("branches", false);

  function deleteBranch(selectedId) {
    var hitUrl = "branches/" + selectedId;
    ApiService({ method: "DELETE", route: hitUrl })
      .then((response) => {
        if (response.data.status_code === 1) {
          let selectedIndex = branchesData.findIndex((p) => p.id == selectedId);
          if (selectedIndex > -1) {
            branchesData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedBranch({});
          setIsEmpty(true);
          setBranchfvisible(true);
        }}
        style={{
          float: "right",
          marginTop: "5px",
        }}
      />
      <GText
        g2
        bold
        text={strings.w_branches}
        style={{ marginBottom: "15px" }}
      />
      <div className="listPaper">
        {loading === true ? (
          <CardShimmer />
        ) : branchesData.length === 0 ? (
          <Placeholder />
        ) : (
          branchesData.map((d) => {
            return (
              <GListCard
                setting={strings.w_branch}
                title={d.title}
                listActions={true}
                id={d.id}
                key={d.id}
                onEdit={(selectedRecord) => {
                  setSelectedBranch(selectedRecord);
                  setIsEmpty(false);
                  setBranchfvisible(true);
                }}
                onDelete={deleteBranch}
                data={d}
              ></GListCard>
            );
          })
        )}
      </div>

      <Modal
        isOpen={branchfvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setBranchfvisible(false)}
        style={{
          content: {
            width: "380px",
          },
        }}
        contentLabel="Branch Form Modal"
      >
        <BranchFormPopup
          branchfvisible={branchfvisible}
          setBranchfvisible={setBranchfvisible}
          data={selectedBranch}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = branchesData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              branchesData[selectedIndex] = { ...data };
            } else {
              branchesData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
