import React, { useState, useContext } from "react";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function AddressForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [addressLocation, setAddressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
      : {
          lat: process.env.REACT_APP_DEFAULT_LATITUDE,
          lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
        }
  );

  const addressTitle = [
    {
      key: strings.w_home,
      value: "home",
    },
    {
      key: strings.w_work,
      value: "work",
    },
    {
      key: strings.w_other,
      value: "other",
    },
  ];

  const initialValues = {
    type: "saved",
    customer_id: props.id,
    title: !props.isEmpty ? props.data.title : "home",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    phone: !props.isEmpty ? props.data.phone : "",
    latitude: !props.isEmpty ? props.data.latitude : "",
    longitude: !props.isEmpty ? props.data.longitude : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "addresses/" + props.data.id : "addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        openSnackbar(response.data.message);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setAfvisible(false);
        }
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required(strings.w_required),
    city: Yup.string().required(strings.w_required),
    state: Yup.string().required(strings.w_required),
    country: Yup.string().required(strings.w_required),
    zipcode: Yup.string().required(strings.w_required).min(4).max(10),
    phone: Yup.string().required(strings.w_required),
  });

  const handleClose = (e) => {
    if (e.target.id != 2721) {
      return;
    }
    props.setAfvisible(false);
  };

  if (props.afvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {props.isEmpty === true
          ? strings.w_create_address
          : strings.w_edit_address}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={addressLocation}
                search={true}
                height="30vh"
                type="createAddress"
                onChange={(address, loc) => {
                  values.latitude = loc.lat();
                  values.longitude = loc.lng();
                  values.line1 = address.address;
                  values.city = address.city;
                  values.state = address.state;
                  values.country = address.country;
                  values.zipcode = address.zipcode;
                  setFieldValue("line1", address.address);
                }}
                padding={true}
              />

              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  width: "100%",
                  boxSizing: "border-box",
                  margin: "3px 0",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                placeholder={strings.s_address_line1}
                name="line1"
                className="noBorder"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder={strings.s_address_line2}
                name="line2"
                className="noBorder"
              />

              <h4 style={{ padding: "15px 15px 10px" }}>
                {strings.w_other_details}
              </h4>
              <div style={{ display: "inline-flex" }}>
                <FormikControl
                  control="input"
                  type="text"
                  placeholder={strings.w_city}
                  name="city"
                  maxLength={30}
                  style={{ height: "40px" }}
                  onChange={(event) => {
                    const re = /^[A-Za-z\s\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("city", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  placeholder={strings.w_state}
                  name="state"
                  maxLength={15}
                  style={{ height: "40px" }}
                  onChange={(event) => {
                    const re = /^[A-Za-z\s\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("state", event.target.value);
                    }
                  }}
                />
              </div>
              <div style={{ display: "inline-flex" }}>
                <FormikControl
                  control="input"
                  type="text"
                  placeholder={strings.w_country}
                  name="country"
                  style={{ height: "40px" }}
                  maxLength={51}
                  onChange={(event) => {
                    const re = /^[A-Za-z\s\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("country", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  placeholder={strings.w_zipcode}
                  name="zipcode"
                  style={{ height: "40px" }}
                  maxLength={10}
                  onChange={(event) => {
                    const re = /^[0-9\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("zipcode", event.target.value);
                    }
                  }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                placeholder={strings.w_phone}
                name="phone"
                style={{ height: "40px" }}
                maxLength={13}
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <br />
              <GButton
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
