import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiService } from "../services";
import "../css/tags.css";
import { Close } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GButton from "./gButton";
import { StringsContext } from "../DataFactory/useStrings";

function GTagsInput(props) {
  const [strings] = useContext(StringsContext);
  const [selectedTags, setSelectedTags] = useState([]);
  const tagsRef = useRef(null);
  const [showTagSuggestions, setShowTagSuggestions] = useDetectOutsideClick(
    tagsRef,
    false
  );
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    console.log(props);
    if (props.data.length > 0) {
      setSuggestions(props.data);
      let oTags = [];
      let oldTags = props.data.filter((t, index) => {
        // console.log(t);
        const found = props.selectedData.some(
          (el) => el === JSON.stringify(t.id)
        );
        // console.log(found);
        if (found) {
          oTags.push(JSON.stringify(t.id));
        }
      });
      //console.log(oTags);
      setSelectedTags(oTags);
      console.log(selectedTags);
    }
  }, [props]);

  const handleDelete = (data, i) => {
    let addedTags = selectedTags.filter((t, index) => {
      console.log(t);
      return t !== JSON.stringify(data.id);
    });
    console.log([...addedTags]);
    setSelectedTags([...addedTags]);
    if (props.showButton === false) {
      props.onSubmit(addedTags);
    }
  };

  const handleAddition = (event) => {
    if (event.key === "Enter") {
      setSelectedTags([...selectedTags, { title: event.target.value }]);
      event.target.value = "";
    }
  };

  const handleAdd = (t, i) => {
    const found = selectedTags.some((el) => el === JSON.stringify(t.id));
    console.log(selectedTags);
    if (!found) {
      //selectedTags.push(t);
      setSelectedTags([...selectedTags, JSON.stringify(t.id)]);
    }

    console.log([...selectedTags, JSON.stringify(t.id)]);
    //setShowSuggestions(true);
    if (props.showButton === false) {
      props.onSubmit([...selectedTags, JSON.stringify(t.id)]);
    }
  };

  return (
    <div ref={tagsRef}>
      {props.showButton !== false ? (
        <GButton
          variant="linkable"
          style={{
            marginTop: "-35px",
            float: "right",
            textDecoration: "underline",
          }}
          children="Update"
          onClick={() => props.onSubmit(selectedTags)}
        />
      ) : (
        ""
      )}
      <div className="react-tags">
        <ul className="react-tags-list">
          {suggestions
            .filter((c) => {
              return selectedTags.some((el) => el === JSON.stringify(c.id));
            })
            .map((t, i) => {
              return (
                <li className="react-tags__selected-tag" key={i}>
                  {t.title}
                  <Close onClick={() => handleDelete(t, i)} />
                </li>
              );
            })}
        </ul>

        <input
          type="text"
          className="react-tags-input"
          placeholder={props.placeholder || strings.w_add + " ..."}
          readOnly={true}
          onFocus={() => {
            // handleSuggestions();
            setShowTagSuggestions(true);
          }}
          onKeyUp={() => handleAddition}
          style={{ fontSize: "14px", minWidth: "175px" }}
        />
      </div>
      <div className="react-tags-suggestions">
        {showTagSuggestions === true ? (
          <div className="react-tags__suggestions">
            <ul>
              {suggestions.filter((c) => {
                return !selectedTags.includes(JSON.stringify(c.id));
              }).length > 0 ? (
                suggestions
                  .filter((c) => {
                    return !selectedTags.includes(JSON.stringify(c.id));
                  })
                  .map((s, i) => {
                    return (
                      <li
                        key={i}
                        className="react-tags__selected-tag"
                        onClick={() => {
                          setShowTagSuggestions(false);
                          handleAdd(s, i);
                        }}
                      >
                        {s.title}
                      </li>
                    );
                  })
              ) : (
                <p style={{ color: "#777" }}>
                  &nbsp;&nbsp;{strings.s_no_data_found}
                </p>
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default GTagsInput;
