import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function RolesForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const [permissionsData, setPermissionsData] = useState([]);

  const initialValues = {
    permissions: "",
  };

  useEffect(() => {
    ApiService({ method: "GET", route: "role/permissions/" + props.roleId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let assignedData =
            response.data.data.assigned.length > 0
              ? response.data.data.assigned.map((a) => {
                  return (a = JSON.stringify(a));
                })
              : [];
          console.log(assignedData);
          initialValues.permissions = [...assignedData] || [];
          setPermissionsData(response.data.data.all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    ApiService({
      method: "PUT",
      route: "role/permissions/" + props.roleId,
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.setRolePerVisible(false);
        props.onSubmit(response.data);
      }
      openSnackbar(response.data.message);
      setIsDisabled(false);
      return;
    });
  };

  const validationSchema = Yup.object({
    // name: Yup.string().required(strings.w_required),
  });

  if (props.rolePerVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{strings.w_manage_role_permissions}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {permissionsData.length >= 0 ? (
                <FormikControl
                  control="checkbox"
                  label={strings.w_assign_permissions}
                  name="permissions"
                  options={permissionsData}
                  key_title="title"
                  key_value="id"
                />
              ) : (
                ""
              )}
              <br />
              <GButton
                variant="condensed"
                disabled={isDisabled}
                children={strings.w_update}
                type="submit"
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
