import React, { useContext, useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import GGeoTagsInput from "../gComponents/gGeofencesInput";
import moment from "moment-timezone";
import { StringsContext } from "../DataFactory/useStrings";

export default function OfferPopUpForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    image: !props.isEmpty ? props.data.image : "",
    description: !props.isEmpty ? props.data.description : "",
    type: !props.isEmpty ? props.data.type : "",
    value: !props.isEmpty ? props.data.value : "",
    geofences: !props.isEmpty ? props.data.geofences : [],
    starts: !props.isEmpty
      ? props.data.starts
        ? new Date(props.data.starts)
        : null
      : null,
    ends: !props.isEmpty
      ? props.data.ends
        ? new Date(props.data.ends)
        : null
      : null,
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);

    let sendData = { ...values };
    sendData.starts = sendData.starts
      ? moment(new Date(sendData.starts)).format("yyyy-MM-DD hh:mm:ss")
      : sendData.starts;
    sendData.ends = sendData.ends
      ? moment(new Date(sendData.ends)).format("yyyy-MM-DD hh:mm:ss")
      : sendData.ends;

    console.log(sendData);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "popups/" + props.data.id : "popups";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setOPopupVisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    type: Yup.string().required(strings.w_required),
    image: Yup.string().required(strings.w_required),
  });

  if (props.oPopupVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_offer_popup
          : strings.w_create_offer_popup}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  values.image = newValue;
                  setFieldValue("image", newValue);
                }}
                id="image"
                image={values.image ? ImgUrl("popup") + "/" + values.image : ""}
                type="popup"
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{ margin: "0 auto", display: "block" }}
              />
              <FormikControl
                control="input"
                type="text"
                name="image"
                style={{ display: "none" }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_type}
                name="type"
                options={[
                  { title: strings.w_default, value: "default" },
                  { title: strings.w_coupon, value: "coupon" },
                ]}
                key_title="title"
                key_value="value"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width:
                    values.type && values.type != "default" ? "50%" : "100%",
                }}
                onChange={(e) => {
                  values.type = e.target.value || "";
                  setFieldValue("type", e.target.value || "");
                  setFieldValue("value", "");
                }}
              />

              {values.type && values.type != "default" ? (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_value}
                  name="value"
                  divstyle={{
                    display: "inline-block",
                    verticalAlign: "top",
                    boxSizing: "border-box",
                    width: "50%",
                  }}
                />
              ) : (
                ""
              )}

              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />

              <div style={{ margin: "15px" }}>
                <label>{strings.w_geofences}</label>
                <GGeoTagsInput
                  data={values.geofences}
                  onSubmit={(tags) => {
                    console.log(tags);
                    values.geofences = tags.map((d) => d.id);
                  }}
                  showButton={false}
                />
              </div>

              <FormikControl
                control="date"
                label={strings.w_starts}
                name="starts"
                show
                format="dd MMM, yyyy HH:mm a"
                placeholderText="Choose"
                showTimeSelect={true}
              />
              <FormikControl
                control="date"
                label={strings.w_ends}
                name="ends"
                format="dd MMM, yyyy HH:mm a"
                placeholderText="Choose"
                showTimeSelect={true}
              />

              <FormikControl
                control="input"
                as="textarea"
                label={strings.w_description}
                name="description"
                rows="3"
              />

              <GButton
                type="submit"
                variant="condensed"
                disabled={isBtnDisabled}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
