import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GImageEditor from "../gComponents/gImageEditor";
import { StringsContext } from "../DataFactory/useStrings";

export default function BrandsForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    map_icon: !props.isEmpty ? props.data?.map_icon : "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "brands/" + props.data.id : "brands";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setBrandVisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
  });

  if (props.brandVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_brand : strings.w_create_brand}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div
                style={{
                  padding: "0 15px 10px",
                }}
              >
                <label
                  style={{
                    fontSize: "15px",
                    fontWeight: "450",
                  }}
                >
                  {strings.w_icon}
                </label>
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.map_icon = newValue;
                    setFieldValue("map_icon", newValue);
                  }}
                  remove={true}
                  onRemove={() => {
                    values.map_icon = "";
                    setFieldValue("map_icon", "");
                  }}
                  id="map_icon"
                  skipThumb={true}
                  image={
                    values.map_icon
                      ? ImgUrl("brand") + "/" + values.map_icon
                      : ""
                  }
                  type="brand"
                  style={{ height: "155px", paddingTop: "60px" }}
                  divstyle={{
                    margin: "0 auto",
                    display: "block",
                    minWidth: "60%",
                    padding: "5px 0",
                  }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />
              <br />
              <GButton
                variant="condensed"
                disabled={isDisabled}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
