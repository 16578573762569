import React, { useState, useContext } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import NotificationsSetting from "./notificationSettings";
import GText from "../gComponents/gText";
import { isFeatureEnabled } from "../services";
import { StringsContext } from "../DataFactory/useStrings";

export default function NotificationTypes() {
  const [strings] = useContext(StringsContext);
  let { type } = useParams();
  let { userType } = useParams();
  let { notifyType } = useParams();

  const [notificationTypeValue, setNotificationTypeValue] = useState(
    type === "task" ? notifyType || "delivery" : ""
  );

  const notificationRoutes =
    type === "order"
      ? [
          {
            title: strings.w_customer,
            identifier: "customer",
            link: "/settings/order-notifications/customer",
            state: "setting",
          },
          {
            title: strings.w_manager,
            identifier: "manager",
            link: "/settings/order-notifications/manager",
            state: "setting",
          },
          {
            title: strings.w_admin,
            identifier: "admin",
            link: "/settings/order-notifications/admin",
            state: "setting",
          },
        ]
      : type === "task"
      ? [
          {
            title: strings.w_customer,
            identifier: "customer",
            link:
              "/settings/task-notifications/customer/" + notificationTypeValue,
            state: "setting",
          },
          {
            title: strings.w_driver,
            identifier: "driver",
            link:
              "/settings/task-notifications/driver/" + notificationTypeValue,
            state: "setting",
          },
          {
            title: strings.w_admin,
            identifier: "admin",
            link: "/settings/task-notifications/admin/" + notificationTypeValue,
            state: "setting",
          },
        ]
      : type === "user"
      ? [
          isFeatureEnabled("signup_vendor")
            ? {
                title: strings.w_manager,
                identifier: "manager",
                link: "/settings/user-notifications/manager",
                state: "setting",
              }
            : null,
          isFeatureEnabled("signup_agent")
            ? {
                title: strings.w_driver,
                identifier: "driver",
                link: "/settings/user-notifications/driver",
                state: "setting",
              }
            : null,
        ]
      : [];

  function handleChange(nTypeVal) {
    setNotificationTypeValue(nTypeVal ? nTypeVal : "delivery");
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={type + " " + strings.w_notifications}
        style={{ marginBottom: "15px", textTransform: "capitalize" }}
      />
      <GTabs
        routes={notificationRoutes.filter((t) => t?.title)}
        active={userType}
      ></GTabs>
      <div className="listPaper">
        <div>
          <Switch>
            <Route path="/settings/:type-notifications/:userType">
              <NotificationsSetting
                onChange={handleChange}
                type={type}
                userType={userType}
                nType={notifyType || ""}
              />
            </Route>
            <Route path="/settings/:type-notifications/:type/:userType/:notifyType">
              <NotificationsSetting
                onChange={handleChange}
                type={type}
                userType={userType}
                nType={notifyType || notificationTypeValue}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
