import React, { useState, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Child from "./faqs";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function FaqTypes() {
  const [strings] = useContext(StringsContext);
  const [typeValue, setTypeValue] = useState("customer");

  const faqRoutes = [
    {
      title: strings.w_customer,
      identifier: "customer",
      link: "/settings/faqs/customer",
      state: "setting",
    },
    {
      title: strings.w_manager,
      identifier: "manager",
      link: "/settings/faqs/manager",
      state: "setting",
    },
    {
      title: strings.w_driver,
      identifier: "driver",
      link: "/settings/faqs/driver",
      state: "setting",
    },
  ];

  return (
    <div className="rightInnerView">
      <GText g2 bold text={strings.w_faqs} style={{ marginBottom: "20px" }} />
      <GTabs routes={faqRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/settings/faqs/:type">
            <Child
              onChange={(newValue) => {
                console.log(newValue);
                setTypeValue(newValue);
              }}
            />
          </Route>
        </div>
      </Switch>
    </div>
  );
}
