import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GListCard from "../gComponents/gListCards";
import BrandsForm from "../forms/brandsForm";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
import useDataFctory from "../useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";

export default function Brands() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isEmpty, setIsEmpty] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [brandVisible, setBrandVisible] = useState(false);
  const {
    loading,
    data: brandsData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("brands", false);

  function deleteBrand(selectedId) {
    ApiService({ method: "DELETE", route: "brands/" + selectedId })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.data.status_code === 1) {
          let selectedIndex = brandsData.findIndex((p) => p.id == selectedId);
          if (selectedIndex > -1) {
            brandsData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>
        {strings.w_brands}
        <GButton
          variant="condensed"
          children={strings.w_add}
          onClick={() => {
            setSelectedBrand({});
            setIsEmpty(true);
            setBrandVisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      </h2>
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : !brandsData || brandsData?.length === 0 ? (
          <Placeholder />
        ) : (
          <div>
            {brandsData &&
              brandsData.map((d) => {
                return (
                  <GListCard
                    setting={strings.w_brand}
                    title={d.title}
                    listActions={true}
                    key={d.id}
                    id={d.id}
                    edit={true}
                    delete={true}
                    onEdit={(selectedRecord) => {
                      setSelectedBrand(selectedRecord);
                      setIsEmpty(false);
                      setBrandVisible(true);
                    }}
                    onDelete={deleteBrand}
                    data={d}
                  ></GListCard>
                );
              })}
          </div>
        )}
      </div>

      <Modal
        isOpen={brandVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setBrandVisible(false)}
        style={{
          content: {
            width: "380px",
          },
        }}
        contentLabel="Brand Form Modal"
      >
        <BrandsForm
          brandVisible={brandVisible}
          setBrandVisible={setBrandVisible}
          data={selectedBrand}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = brandsData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              brandsData[selectedIndex] = { ...data };
            } else {
              brandsData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
