import React, { useContext } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import useSettings from "../useSettings";
import { Link } from "react-router-dom";
import { StringsContext } from "../DataFactory/useStrings";

require("dotenv").config();

const Div = styled.div`
  position: absolute;
  overflow-y: auto;
  z-index: 9;
  padding: 0 10px 10px;
  max-height: 500px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  margin: 10px;
  box-sizing: border-box;
  max-width: 450px;
  width: 60%;

  h3 {
    font-weight: 650;
    font-size: 19px;
    margin: 15px 10px;
    text-transform: uppercase;
    color: #485574;
    padding-top: 10px;
  }
  ul {
    list-style-type: none;
    padding: 0px;

    li {
      padding: 10px;
      .MuiAvatar-root {
        height: 50px;
        width: 50px;
        display: inline-flex;
        margin-right: 15px;
      }
      &:nth-of-type(odd) .MuiAvatar-root {
        background: #80cac3;
        color: #fff;
      }
      &:hover {
        background: #f4f5f9;
      }
      & .altStyle {
        background: rgb(128, 202, 195);
        color: rgb(255, 255, 255);
        height: 36px;
        width: 50px;
        padding-top: 14px;
        text-align: center;
        border-radius: 50%;
        font-size: 20px;
        margin: 0;
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle;
        text-transform: uppercase;
      }
      & .imgStyle {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 20px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.05),
          0 1px 1px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
      }
      & .listContent {
        width: calc(100% - 70px);
        display: inline-block;
        vertical-align: middle;

        .listTitle {
          margin: 5px 0;
          font-weight: 500;
          font-size: 15px;
          color: #000;
        }
        .listSubtitle {
          margin: 0px;
          color: #59698e;
          font-size: 13.5px;
          line-height: 21px;
        }
      }
    }
  }
`;

export const List = ({ data, handleClick, show }) => {
  const [strings] = useContext(StringsContext);
  const settingsData = useSettings(true);

  return (
    <React.Fragment>
      <Div style={{ display: show === true ? "block" : "none" }}>
        {data
          .filter((c) => {
            if (settingsData?.user?.user_type === "staff") {
              return c.type === "order";
            } else {
              return c;
            }
          })
          .map((d, i) => {
            return (
              <div key={i} style={{ borderTop: "1px solid #e2e2e2" }}>
                <h3>
                  {d.type} ({d.data.length})
                </h3>
                <ul>
                  {d.data.map((values, ind) => {
                    return (
                      <Link
                        key={ind}
                        to={
                          d.type === "order"
                            ? values.class == "courier"
                              ? "/courier-order/" + values.id
                              : values.class == "atm"
                              ? "/atm-order/" + values.id
                              : "/" + d.type + "/" + values.id
                            : d.type === "driver"
                            ? "/" + d.type + "/" + values.id + "/wallet"
                            : "/" + d.type + "/" + values.id + "/orders"
                        }
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={handleClick}
                      >
                        <li>
                          {d.type !== "order" ? (
                            values.thumb_photo ? (
                              <img
                                alt=""
                                src={
                                  ImgUrl(
                                    d.type === "customer" || "driver"
                                      ? "user"
                                      : d.type === "store"
                                      ? "store"
                                      : ""
                                  ) +
                                  "/" +
                                  values.thumb_photo
                                }
                                onError={addDefaultSrc}
                                className="imgStyle"
                              />
                            ) : (
                              <h4 className="altStyle">
                                {values.title.charAt(0)}
                              </h4>
                            )
                          ) : (
                            ""
                          )}
                          <div className="listContent">
                            <h4 className="listTitle">{values.title}</h4>
                            {values.order_status ? (
                              <h4 className="listTitle">
                                #{values.id} - {values.order_status}
                              </h4>
                            ) : (
                              ""
                            )}
                            {values.email ? (
                              <p className="listSubtitle">
                                {strings.w_email} : {values.email}
                              </p>
                            ) : (
                              ""
                            )}
                            {values.phone ? (
                              <p className="listSubtitle">
                                {strings.w_mobile} : {values.phone}
                              </p>
                            ) : (
                              ""
                            )}

                            {values.amount ? (
                              <p className="listSubtitle">
                                {strings.w_amount} -{" "}
                                {settingsData.currency_symbol}
                                {values.amount}
                              </p>
                            ) : (
                              ""
                            )}
                            {values.created ? (
                              <p className="listSubtitle">({values.created})</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </Div>
    </React.Fragment>
  );
};
