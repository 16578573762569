import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService, getUserType, isPermissionExist } from "../services";
import { Redeem, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import QueryWith from "../Query";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
import AutomatedPromotionForm from "../forms/automatedPromotionForm";
import { StringsContext } from "../DataFactory/useStrings";

export default function AutomatedPromotions() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [automatedPromotionData, setAutomatedPromotionData] = useState([]);
  const [
    automatedPromotionPaginationData,
    setAutomatedPromotionPaginationData,
  ] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAutomatedPromotionData, setSelectedAutomatedPromotionData] =
    useState({});
  const [selectedAutomatedPromotionId, setSelectedAutomatedPromotionId] =
    useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [autoPromoVisible, setAutoPromoVisible] = useState(false);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
  });

  const WeekDays = [
    { title: strings.w_monday, value: "mon" },
    { title: strings.w_tuesday, value: "tue" },
    { title: strings.w_wednesday, value: "wed" },
    { title: strings.w_thursday, value: "thu" },
    { title: strings.w_friday, value: "fri" },
    { title: strings.w_saturday, value: "sat" },
    { title: strings.w_sunday, value: "sun" },
  ];

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 120 },
    { id: "weekday", label: strings.w_weekday, minWidth: 80, align: "center" },
    { id: "time", label: strings.w_time, minWidth: 80, align: "center" },
    {
      id: "inactive_days",
      label: strings.w_inactive_days,
      minWidth: 80,
      align: "center",
    },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "center" },
  ];

  const getAutomatedPromotions = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/automated-promotions",
        state: "coupon",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "automated-promotions", body: filters })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setAutomatedPromotionData(response.data.data.data);
          setAutomatedPromotionPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAutomatedPromotions();
  }, [history, filters]);

  const deleteAutomatedPromotion = () => {
    ApiService({
      method: "DELETE",
      route: "automated-promotions/" + selectedAutomatedPromotionId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getAutomatedPromotions();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        {strings.w_automated_promotions}
        <GButton
          variant="condensed"
          children={strings.w_add}
          onClick={() => {
            setIsEmpty(true);
            setSelectedAutomatedPromotionData();
            setAutoPromoVisible(true);
          }}
          style={{
            marginLeft: "auto",
            marginTop: "-5px",
            padding: "0.6rem 2rem",
          }}
        />
      </h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        {automatedPromotionData.length > 0 && (
          <>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={automatedPromotionPaginationData.total}
              from={automatedPromotionPaginationData.from || 0}
              to={automatedPromotionPaginationData.to || 0}
              onChangeNextPage={() =>
                handleChangePage(automatedPromotionPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(automatedPromotionPaginationData.prev_page_url)
              }
              rowsPerPage={automatedPromotionPaginationData.per_page}
              prevDisabled={
                automatedPromotionPaginationData.prev_page_url ? false : true
              }
              nextDisabled={
                automatedPromotionPaginationData.next_page_url ? false : true
              }
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={automatedPromotionPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </>
        )}
      </div>

      <div className="listPaper">
        {!automatedPromotionPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {automatedPromotionData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {automatedPromotionData.map((c, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {c.weekday
                            ? WeekDays.filter(
                                (day) => day.value == c.weekday
                              ).map((v) => {
                                return v.title;
                              })
                            : "All"}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {c.time}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {c.inactive_days}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setIsEmpty(false);
                              setSelectedAutomatedPromotionData(c);
                              setAutoPromoVisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>

                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedAutomatedPromotionId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_automated_promotion_title}
          text={strings.s_delete_automated_promotion_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteAutomatedPromotion();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={autoPromoVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAutoPromoVisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Automated Promotion Form Modal"
      >
        <AutomatedPromotionForm
          autoPromoVisible={autoPromoVisible}
          setAutoPromoVisible={setAutoPromoVisible}
          isEmpty={isEmpty}
          data={selectedAutomatedPromotionData}
          weekdays={WeekDays}
          onSubmit={(data) => {
            let index = automatedPromotionData.findIndex(
              (f) => f.id == data.id
            );
            if (index > -1) {
              automatedPromotionData[index] = data;
              setAutomatedPromotionData([...automatedPromotionData]);
            } else {
              getAutomatedPromotions();
            }
          }}
        />
      </Modal>
    </div>
  );
}
