import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiService, getUserType, isPermissionExist } from "../services";
import CustomSeparator from "../gComponents/gBreadCrumbs";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import GItemTable from "../gComponents/gCartItems";
import { GTableContent } from "../gComponents/gContent";
import { AccountBalanceWallet, Info, Send } from "@material-ui/icons";
import { NoteDiv } from "../styledComponents/notesStyles";
import { MessageBox } from "../styledComponents/messageStyles";
import OrderTransactionPopup from "../components/OrderTransactionReceives";
import OrderDeliveryInfo from "../components/orderDeliveryInfo";
import TaskView from "../gComponents/gTaskView";
import TaskStatusTiming from "../gComponents/gTaskStatusView";
import CancelReasonsView from "../components/orderCancelReasons";
import CancelBox from "../gComponents/gCancelBox";
import GAgentView from "../gComponents/gAssignAgentView";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GDropdown from "../gComponents/gDropdown";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import { GContent } from "../gComponents/gContent";
import OrderEdit from "./orderEdit";
import { RepeatOrder } from "../models/RepeatOrder";
import useCart from "../useCart";
import { useParams, useHistory } from "react-router-dom";
import GText from "../gComponents/gText";
import { createSocketConnection } from "../useLaravelPusher";
import { StringsContext } from "../DataFactory/useStrings";

const fixedDivStyle = {
  display: "flex",
  bottom: "5px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "9",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function OrderDetail(props) {
  let { orderId } = useParams();
  const [openSnackbar] = useSnackbar();
  const [strings] = useContext(StringsContext);
  const [ocrvisible, setOcrvisible] = useState(false);
  const [odInfovisible, setOdInfovisible] = useState(false);
  const [opInfovisible, setOpInfovisible] = useState(false);
  const [transModalVisible, setTransModalVisible] = useState(false);
  const [noteVal, setNoteValue] = useState("");
  const [messageVal, setMessageValue] = useState("");
  let history = useHistory();
  const [orderData, setOrderData] = useState({
    customer: {},
    store: {},
    order_products: [],
    delivery: {},
    pickup: {},
    payment_summary: {
      data: [],
    },
    cancelled_log: {},
    order_transactions: {},
  });
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [ordernotes, setOrderNotes] = useState([]);
  const [orderMessages, setOrderMessages] = useState([]);
  const [msgBtnDisabled, setMsgBtnDisabled] = useState(false);
  const [noteBtnDisabled, setNoteBtnDisabled] = useState(false);
  const [orderAgent, setOrderAgent] = useState({});
  const settings = useSettings(true);
  const ViewDropdownRef = useRef(null);
  const [isViewDropdownActive, setIsViewDropdownActive] = useDetectOutsideClick(
    ViewDropdownRef,
    false
  );
  const { cart, setCart, cartStore, setCartStore } = useCart();
  const [editOrder, setEditOrder] = useState(false);

  const invoiceList = [
    {
      title: strings.w_thermal_invoice,
      link: "/order-thermal-invoice/" + props.match.params.orderId,
    },
    {
      title: strings.w_a4_invoice,
      link: "/order-invoice/" + props.match.params.orderId,
    },
  ];

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "orders/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderAgent({});
          setOrderStatuses(response.data?.options);
          setOrderStatus(response.data.data.status.title);
          setOrderNotes(response.data.data.order_notes);
          setOrderMessages(response.data.data.order_messages);
        } else {
          setOrderData({
            available: false,
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("order-updated." + orderId).listen(
      "BCOrderUpdated",
      function (e) {
        console.log(e);
        getOrderDetail();
        // console.log(orderStatuses);
        // setOrderData({ ...e?.order });
        // setOrderStatus(e.order.status.title);
        // setOrderNotes(e.order.order_notes);
        // setOrderMessages(e.order.order_messages);
      }
    );
    history.push({
      pathname: "/order/" + orderId,
      state: "order",
    });
    getOrderDetail();

    return () => {
      window.Echo.leaveChannel(`order-updated.${orderId}`);
    };
  }, [orderId]);

  useEffect(() => {
    if (orderStatuses && orderStatuses.length > 0 && orderStatus) {
      var keepGoing = true;
      const listCopy = [...orderStatuses];
      //Logic to update your list here

      listCopy.forEach((user, index) => {
        if (keepGoing === true) {
          user["color"] = "colored";
          if (user.title === orderStatus) {
            keepGoing = false;
          }
        } else {
          user["color"] = "";
        }
      });
      setOrderStatusList(listCopy);
    }
  }, [orderStatuses, orderStatus]);

  function updateField(selectedFieldId, newValue) {
    if (selectedFieldId === "notes") {
      setNoteBtnDisabled(true);
    }
    if (selectedFieldId === "messages") {
      setMsgBtnDisabled(true);
    }
    var post_note = {
      order_id: orderId,
      user_type: "4",
      user_id: "1",
      text:
        selectedFieldId === "notes"
          ? noteVal
          : selectedFieldId === "messages"
          ? messageVal
          : newValue,
    };
    ApiService({
      method: "POST",
      route: "order/" + selectedFieldId,
      body: post_note,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (selectedFieldId === "notes") {
            setNoteValue("");
            setOrderNotes([...ordernotes, response.data.data]);
          } else {
            setMessageValue("");
            setOrderMessages([...orderMessages, response.data.data]);
          }
        }
        openSnackbar(response.data.message);
        setNoteBtnDisabled(false);
        setMsgBtnDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCompleteOrder() {
    ApiService({ method: "POST", route: "order/complete/f/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatus(response.data.data?.status?.title);
          //getOrderDetail();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateOrderStatus(status) {
    ApiService({ method: "POST", route: "order/" + status + "/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatus(response.data.data?.status?.title);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (orderData?.available == false) {
    return (
      <div style={{ padding: 20 }}>
        <h1>{`#${orderId} -  ${orderData?.message}`}</h1>{" "}
      </div>
    );
  }

  return (
    <div className="mainInnerView" style={{ padding: "20px 30px" }}>
      <h1 style={{ display: "inline-block" }}>
        #{orderId} &nbsp;&nbsp;
        {orderStatus !== "Cancelled" && (
          <small
            onClick={() => setIsViewDropdownActive(true)}
            style={{
              color: "#D8AE36",
              textDecoration: "underline",
              fontStyle: "italic",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            {strings.w_view_inoice}
            {isViewDropdownActive === true ? (
              <div ref={ViewDropdownRef}>
                <GDropdown
                  id="viewInvoice"
                  className={`menu ${
                    isViewDropdownActive ? "active" : "inactive"
                  }`}
                  listData={invoiceList}
                  onSelectValue={() => {
                    setIsViewDropdownActive(false);
                  }}
                />
              </div>
            ) : (
              ""
            )}{" "}
          </small>
        )}
      </h1>

      {orderStatus &&
      (orderStatus === "Pending" ||
        orderStatus === "Processing" ||
        orderStatus === "Accepted" ||
        orderStatus === "Picked" ||
        orderStatus === "Ready") ? (
        getUserType() || isPermissionExist("order_update_status") ? (
          <CancelBox
            handleClick={() => setOcrvisible(true)}
            handleCompleteForcefully={() => handleCompleteOrder()}
          />
        ) : (
          ""
        )
      ) : orderStatus === "Cancelled" ? (
        <CancelBox data={orderData.cancelled_log} />
      ) : (
        ""
      )}

      <CustomSeparator
        statusList={orderStatusList}
        class={orderStatus}
        updateStatus={
          getUserType() || isPermissionExist("order_update_status")
            ? true
            : false
        }
        onUpdateStatus={(status) => updateOrderStatus(status)}
      />

      <div
        style={{
          width: "67%",
          display: "inline-block",
          paddingRight: "15px",
          boxSizing: "border-box",
        }}
      >
        {editOrder === true ? (
          <OrderEdit
            orderId={orderId}
            cartStore={orderData.store?.id}
            cart={cart}
            onUpdate={() => {
              setEditOrder(false);
              getOrderDetail();
            }}
            onCancel={() => setEditOrder(false)}
          />
        ) : (
          <Card>
            <CardHead>
              {orderData.order_products.length}{" "}
              {orderData.order_products.length === 1
                ? strings.w_item
                : strings.w_items}
              {(settings.product === "laundry" &&
                orderData.order_products.length == 0) ||
              settings?.update_items == true ? (
                orderStatus &&
                (orderStatus === "Pending" ||
                  orderStatus === "Processing" ||
                  orderStatus === "Picked" ||
                  orderStatus === "Accepted" ||
                  orderStatus === "Ready") ? (
                  getUserType() || isPermissionExist("order_products") ? (
                    <span
                      style={{
                        float: "right",
                        fontSize: "15px",
                        color: "#D8AE36",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCartStore(orderData.store?.id);
                        setCart(
                          orderData.order_products.map(
                            (o) => new RepeatOrder(o)
                          )
                        );
                        setTimeout(function () {
                          setEditOrder(true);
                        }, 1000);
                      }}
                    >
                      {strings.w_edit_items}
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </CardHead>
            <div style={{ maxHeight: "230px", overflow: "auto" }}>
              <GItemTable
                items={orderData?.order_products}
                currencySymbol={settings.currency_symbol}
              />
            </div>

            <div style={{ textAlign: "right", marginTop: "20px" }}>
              {orderData.payment_summary.data.length > 0 && (
                <div style={{ width: "250px", display: "inline-block" }}>
                  {orderData.payment_summary.data.map((p) => {
                    return (
                      <GTableContent
                        key={p.title}
                        title={p.title}
                        value={settings.currency_symbol + "" + p.value}
                        align="right"
                        lAlign="right"
                        color="#777"
                      />
                    );
                  })}
                  <hr />

                  <GTableContent
                    title={strings.w_total}
                    value={
                      settings.currency_symbol +
                        "" +
                        orderData.payment_summary?.total_display || ""
                    }
                    align="right"
                    lAlign="right"
                    bold="550"
                  />
                </div>
              )}
            </div>
          </Card>
        )}

        {settings.product === "laundry" && orderData.pickup?.status ? (
          <div
            style={{
              width: "100%",
              display: "inline-block",
              boxSizing: "border-box",
            }}
          >
            <Card>
              <CardHead>
                {strings.w_pickup_task} &nbsp;&nbsp;
                {orderData.pickup.status === "completed" ? (
                  <span
                    onClick={() => setOpInfovisible(true)}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#D8AE36",
                      fontSize: "14px",
                    }}
                  >
                    {strings.w_more_info}
                  </span>
                ) : (
                  ""
                )}
                <span
                  style={{
                    float: "right",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    padding: "5px 8px",
                    background: "",
                  }}
                >
                  {orderData.pickup.status}
                </span>
              </CardHead>
              <TaskStatusTiming
                data={orderData.pickup}
                updateStatus={
                  getUserType() || isPermissionExist("order_update_deliveries")
                    ? true
                    : false
                }
                onStatusUpdate={getOrderDetail}
                orderStatus={orderStatus}
              />
              <hr />
              <br />
              <div>
                <div
                  style={{
                    width: "230px",
                    display: "inline-block",
                    verticalAlign: "top",
                    position: "relative",
                  }}
                >
                  <CardHead>{strings.w_driver_details}</CardHead>
                  <GAgentView
                    orderStatus={orderStatus}
                    orderId={props.orderId}
                    taskLog={orderData.pickup.log}
                    isReAssign={
                      orderData.pickup.status === "pending" ? true : false
                    }
                    assign={
                      getUserType() || isPermissionExist("order_assign_agent")
                        ? true
                        : false
                    }
                    isAssign={!orderData.pickup.agent ? false : true}
                    driverData={orderData.pickup.agent}
                    deliveryId={orderData.pickup.id}
                    onAssignTask={() => getOrderDetail()}
                  />
                </div>
                <div
                  style={{
                    width: "calc(100% - 230px)",
                    display: "inline-block",
                    marginTop: "15px",
                  }}
                >
                  <TaskView
                    type="pickup"
                    task={orderData.pickup}
                    storeTitle={orderData.store?.title}
                  />
                </div>
              </div>
              <br />
            </Card>
          </div>
        ) : (
          ""
        )}

        {orderData.delivery?.status ? (
          <div
            style={{
              width: "60%",
              display: "inline-block",
              paddingRight: "15px",
              boxSizing: "border-box",
            }}
          >
            <Card>
              <CardHead>
                {strings.w_delivery_task} &nbsp;&nbsp;
                {orderData.delivery.status === "completed" ? (
                  <span
                    onClick={() => setOdInfovisible(true)}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#D8AE36",
                      fontSize: "14px",
                    }}
                  >
                    {strings.w_more_info}
                  </span>
                ) : (
                  ""
                )}
                <span
                  style={{
                    float: "right",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    padding: "5px 8px",
                    background: "",
                  }}
                >
                  {orderData.delivery.status}
                </span>
              </CardHead>
              <TaskStatusTiming
                data={orderData.delivery}
                updateStatus={
                  getUserType() || isPermissionExist("order_update_deliveries")
                    ? true
                    : false
                }
                onStatusUpdate={getOrderDetail}
                orderStatus={orderStatus}
              />
              <hr />
              <br />
              <div
                style={{
                  verticalAlign: "top",
                  position: "relative",
                }}
              >
                <CardHead>{strings.w_driver_details}</CardHead>
                <GAgentView
                  orderStatus={orderStatus}
                  orderId={props.orderId}
                  taskLog={orderData.delivery.log}
                  isReAssign={
                    orderData.delivery.status === "pending" ? true : false
                  }
                  assign={
                    getUserType() || isPermissionExist("order_assign_agent")
                      ? true
                      : false
                  }
                  isAssign={!orderData.delivery.agent ? false : true}
                  driverData={orderData.delivery.agent}
                  deliveryId={orderData.delivery.id}
                  onAssignTask={() => getOrderDetail()}
                />
              </div>
              <div
                style={{
                  width: "95%",
                  marginTop: "15px",
                }}
              >
                <TaskView
                  task={orderData.delivery}
                  storeTitle={orderData.store?.title}
                />
              </div>
              <br />
            </Card>
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            width: "40%",
            display: "inline-block",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
        >
          <Card>
            <GText g4 semi text={strings.w_order_summary} />
            {settings.product === "laundry" ? (
              ""
            ) : (
              <React.Fragment>
                <GContent
                  title={strings.w_order_type}
                  value={
                    orderData.order_type &&
                    orderData.order_type
                      .replace("_", " ")
                      .replace(/\b\w/g, (l) => l.toUpperCase())
                  }
                />
                {orderData.delivery_type && (
                  <GContent
                    title={strings.w_delivery_type}
                    value={
                      orderData.delivery_type === "asap"
                        ? "Deliver Immediately"
                        : orderData?.delivery_type
                            .replace("_", " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase())
                    }
                  />
                )}
              </React.Fragment>
            )}
            {orderData.pickup_time && (
              <GContent
                title={strings.w_pickup_time}
                value={orderData.pickup_time}
              />
            )}
            {orderData.delivery_time && (
              <GContent
                title={strings.w_delivery_time}
                value={orderData.delivery_time}
              />
            )}
            {orderData.instructions && (
              <GContent
                title={strings.w_instructions}
                value={orderData.instructions}
              />
            )}
            {orderData.delivery_address && !orderData.delivery ? (
              <GContent
                title={strings.w_delivery_address}
                value={orderData.delivery_address}
              />
            ) : (
              ""
            )}
            {orderData.delivery?.distance && (
              <GContent
                title={strings.w_delivery_distance}
                value={
                  orderData.delivery?.distance + " " + settings?.distance_unit
                }
              />
            )}
            <GContent
              title={strings.w_age_restricted}
              value={orderData.age_restricted == 1 ? "YES" : "NO"}
            />
            <br />
          </Card>
        </div>
      </div>
      <div
        style={{
          width: "33%",
          display: "inline-block",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ padding: "20px 15px 25px" }}>
          <GText g4 semi text={StringsContext.w_customer_details} />
          <GInfo
            title={orderData.customer?.title}
            key={orderData.customer?.id}
            subtitle={orderData.customer?.phone}
            photo={orderData.customer?.thumb_photo}
            imgType="user"
            link={"/customer/" + orderData.customer?.id + "/orders"}
          />

          {orderData.store?.id && (
            <>
              <hr style={{ margin: "18px 0" }} />
              <GText g4 semi text={strings.w_store_details} />
              <GInfo
                title={orderData.store?.title}
                subtitle={orderData.store?.address}
                subtitle2={orderData.store?.phone}
                photo={orderData.store?.thumb_photo}
                imgType="store"
                link={"/store/" + orderData.store?.id + "/orders"}
              />
            </>
          )}

          <hr style={{ margin: "18px 0" }} />

          {orderData.pending_payment > 0 && orderStatus !== "Cancelled" ? (
            getUserType() || isPermissionExist("order_payments") ? (
              <GButton
                variant="linkable"
                onClick={() => setTransModalVisible(true)}
                style={{
                  float: "right",
                  textDecoration: "underline",
                  marginTop: "-3px",
                  color: orderStatus === "Cancelled" ? "#a2a2a2" : "#D8AE36",
                }}
              >
                {strings.w_receive_payment}
              </GButton>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <GText g4 semi text={strings.w_payment_details} />

          {orderData.order_transactions.length > 0 ? (
            orderData.order_transactions.map((s) => {
              return (
                <div key={s.id} style={{ borderBottom: "1px solid #e2e2e2" }}>
                  <AccountBalanceWallet
                    style={{
                      float: "right",
                      color: "#777",
                    }}
                  />
                  <p
                    style={{
                      textTransform: "capitalize",
                      margin: "15px 0 10px",
                    }}
                  >
                    {settings.currency_symbol +
                      s.amount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}{" "}
                    {s.gateway && (
                      <span style={{ color: "#b2b2b2", fontStyle: "italic" }}>
                        ({s.gateway})
                      </span>
                    )}
                  </p>

                  <span
                    style={{
                      background: "#" + s.background,
                      padding: "2px 5px",
                      fontSize: "14px",
                    }}
                  >
                    {s.status_label}
                  </span>
                  <p
                    style={{
                      color: s.status === "pending" ? "#a2a2a2" : "#333",
                      fontSize: "14px",
                      margin: "12px 0 15px 0",
                    }}
                  >
                    {s.label}
                  </p>
                </div>
              );
            })
          ) : (
            <React.Fragment>
              <p
                style={{
                  margin: "20px 0 5px",
                }}
              >
                {strings.w_pending_amount} -{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0",
                    color: "#777",
                  }}
                >
                  {settings.currency_symbol}
                  {orderData?.pending_payment?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                  <span style={{ fontStyle: "italic" }}>
                    ({orderData.gateway})
                  </span>
                </span>
              </p>
              <GText light text={strings.s_no_transaction_yet} />
            </React.Fragment>
          )}
        </Card>
        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              {strings.w_notes} ({ordernotes.length})
            </CardHead>
          </div>
          {ordernotes && ordernotes.length >= 0 ? (
            <div
              style={{
                padding: "5px 15px",
                maxHeight: "340px",
                overflow: "overlay",
                marginBottom:
                  getUserType() || isPermissionExist("order_notes")
                    ? "40px"
                    : "10px",
              }}
            >
              {ordernotes.map((p) => {
                return (
                  <NoteDiv key={p.id}>
                    <h5>{p.text}</h5>

                    <p>{p.created}</p>
                  </NoteDiv>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {getUserType() || isPermissionExist("order_notes") ? (
            <div style={fixedDivStyle}>
              <input
                type="text"
                value={noteVal}
                id="notes"
                placeholder={strings.w_add_a_note}
                onChange={(e) => setNoteValue(e.target.value)}
                style={formInputStyle}
              />
              <GButton
                variant="linkable"
                disabled={noteBtnDisabled}
                onClick={() => {
                  updateField("notes");
                }}
                style={{
                  borderRadius: "50%",
                  color: "#fff",
                  background: "#D8AE36",
                  marginTop: "7px",
                  height: "30px",
                  padding: "3px 5px 0 7px",
                }}
              >
                <Send style={{ fontSize: "1.2rem", paddingTop: "2px" }} />
              </GButton>
            </div>
          ) : !ordernotes || ordernotes.length <= 0 ? (
            <p style={{ textAlign: "center", color: "#a2a2a2" }}>
              {strings.s_no_notes_added_yet}
            </p>
          ) : (
            ""
          )}
        </Card>

        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
            marginBottom: "50px",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              {strings.w_messages} ({orderMessages.length})
            </CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px 15px",
              maxHeight: "350px",
              overflow: "overlay",
              marginBottom:
                getUserType() || isPermissionExist("order_chat")
                  ? "40px"
                  : "10px",
            }}
          >
            {orderMessages.map((p) => {
              return (
                <div
                  key={p.id}
                  style={{ textAlign: p.user_type === 4 ? "right" : "left" }}
                >
                  <MessageBox
                    className={p.user_type === 4 ? "send" : "received"}
                  >
                    <h5>{p.text}</h5>

                    <p>
                      {p.user_type === 4 ? "" : p.user_title + ", "}
                      {p.created}
                    </p>
                  </MessageBox>
                </div>
              );
            })}
          </div>
          {getUserType() || isPermissionExist("order_chat") ? (
            <div style={fixedDivStyle}>
              <input
                type="text"
                value={messageVal}
                id="messages"
                placeholder={strings.w_add_a_message}
                onChange={(e) => setMessageValue(e.target.value)}
                style={formInputStyle}
              />
              <GButton
                variant="linkable"
                disabled={msgBtnDisabled}
                onClick={() => {
                  updateField("messages");
                }}
                style={{
                  borderRadius: "50%",
                  color: "#fff",
                  background: "#D8AE36",
                  marginTop: "7px",
                  height: "30px",
                  padding: "3px 5px 0 7px",
                }}
              >
                <Send style={{ fontSize: "1.2rem", paddingTop: "2px" }} />
              </GButton>
            </div>
          ) : !orderMessages || orderMessages.length <= 0 ? (
            <p style={{ textAlign: "center", color: "#a2a2a2" }}>
              {strings.s_no_messages_added_yet}
            </p>
          ) : (
            ""
          )}
        </Card>
      </div>

      {ocrvisible === true && (
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrvisible={setOcrvisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
          type={orderData.class}
        />
      )}

      {transModalVisible === true && (
        <OrderTransactionPopup
          transModalVisible={transModalVisible}
          setTransModalVisible={setTransModalVisible}
          linkedId={orderId}
          amount={
            settings.currency_symbol +
            orderData.pending_payment.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          }
          pendingAmount={orderData.pending_payment}
          onSubmit={getOrderDetail}
        />
      )}

      {odInfovisible === true && (
        <OrderDeliveryInfo
          odInfovisible={odInfovisible}
          setOdInfovisible={setOdInfovisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
          notes={orderData.delivery?.delivery_notes}
          images={orderData.delivery?.delivery_images}
          signatures={orderData.delivery?.delivery_signatures}
        />
      )}
      {opInfovisible === true && (
        <OrderDeliveryInfo
          odInfovisible={opInfovisible}
          setOdInfovisible={setOpInfovisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
          notes={orderData?.pickup?.delivery_notes}
          images={orderData?.pickup?.delivery_images}
          signatures={orderData?.pickup?.delivery_signatures}
        />
      )}
    </div>
  );
}
