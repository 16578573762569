import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MyLocation } from "@material-ui/icons";
import { Map, FeatureGroup } from "react-leaflet";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import { EditControl } from "react-leaflet-draw";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import useGeoLocation from "../useGeoLocation";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

const LocateButton = styled.span`
  border: 2px solid #ccc;
  color: #000;
  padding: 5px 5px 0;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  position: absolute;
  left: 8px;
  bottom: 8px;
  z-index: 9999;
  background: #fff;

  svg {
    width: 0.8em;
    height: 0.8em;
  }
`;

export default function DrawMap(props) {
  const [center, setCenter] = useState({
    lat: process.env.REACT_APP_DEFAULT_LATITUDE,
    lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
  });
  const ZOOM_LEVEL = 12;
  const mapRef = useRef();
  const [mapLayers, setMapLayers] = useState([]);
  const [editableFG, setEditableFG] = useState(null);
  const location = useGeoLocation();

  const showMyLocation = () => {
    if (location.loaded && !location.error) {
      mapRef.current.leafletElement.flyTo(
        [location.coordinates.lat, location.coordinates.lng],
        ZOOM_LEVEL,
        { animate: true }
      );
    } else {
      alert(location.error.message);
    }
  };

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    if (!map) return;
    const control = GeoSearchControl({
      style: "button",
      showMarker: false,
      provider: new GoogleProvider({
        params: {
          key: process.env.REACT_APP_GOOGLE_KEY,
        },
      }),
    });
    control.addTo(map);
  }, []);

  useEffect(() => {
    console.log(mapLayers);
    let coordinates = [];
    for (let l of mapLayers) {
      let latlng = [];
      for (let l1 of l.latlngs) {
        latlng.push([l1.lng, l1.lat]);
      }
      coordinates.push(latlng);
    }
    console.log(coordinates);
    props.getGeoJSON(coordinates);
  }, [mapLayers]);

  const onFeatureGroupReady = (reactFGref) => {
    // store the featureGroup ref for future access to content
    setEditableFG(reactFGref);
  };

  const _onCreate = (e) => {
    console.log(e);
    console.log(editableFG);

    // here you have all the stored layers
    const drawnItems = editableFG.leafletElement._layers;
    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index > 0) return;
        const layer = drawnItems[layerid];
        editableFG.leafletElement.removeLayer(layer);
      });
      console.log(drawnItems); // here you will get only the last one
    }
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        // ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    }
  };
  const _onEdit = (e) => {
    console.log(e);
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      console.log(editing.latlngs[0]);
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id ? { ...l, latlngs: editing.latlngs[0][0] } : l
        )
      );
    });
  };
  const _onDelete = (e) => {
    console.log(e);
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="col text-center">
        <div className="col">
          <Map center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
            <FeatureGroup
              ref={(featureGroupRef) => {
                onFeatureGroupReady(featureGroupRef);
              }}
            >
              <EditControl
                position="topright"
                onCreated={_onCreate}
                onEdited={_onEdit}
                onDeleted={_onDelete}
                draw={{
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polyline: false,
                  polygon: {
                    shapeOptions: { color: "#D8AE36" },
                  },
                }}
              />
            </FeatureGroup>

            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{ KEY: process.env.REACT_APP_GOOGLE_KEY }}
            />
          </Map>
        </div>
      </div>

      <LocateButton onClick={showMyLocation}>
        <MyLocation />
      </LocateButton>
    </div>
  );
}
