import React, { useState, useEffect, useContext } from "react";
import { TableContainer, Avatar } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import {
  ImgUrl,
  ApiService,
  isPermissionExist,
  getUserType,
} from "../services";
import { PeopleAlt, Edit, Delete, Block } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import TooltipComponent from "../gComponents/gTooltip";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import { Link, useHistory } from "react-router-dom";
import CustomerCreate from "../forms/customerForm";
import QueryWith from "../Query";
import { GSearch } from "../gComponents/gSearch";
import GSelectbox from "../gComponents/gSelectbox";
import useSettings from "../useSettings";
import { ReactComponent as Unblock } from "../assets/images/unlock.svg";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function Customers() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [ccvisible, setCcvisible] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [customerPaginationData, setCustomerPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  const [selectedStatusType, setSelectedStatusType] = useState();
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [hitBackUrl, setHitBackUrl] = useState(false);
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);

  const [currentPage, setCurrentPage] = useState(
    params.get("page") ? params.get("page") : "1"
  );
  const [searchWord, setSearchWord] = useState(
    params.get("search") ? params.get("search") : ""
  );
  const [status, setStatus] = useState(
    params.get("status") ? params.get("status") : "active"
  );

  const columns = [
    { id: "first_name", label: strings.w_name, minWidth: 130 },
    { id: "photo", label: strings.w_photo, minWidth: 50, type: "image" },
    { id: "email", label: strings.w_email, minWidth: 150 },
    { id: "phone", label: strings.w_mobile, minWidth: 120 },
    {
      id: "wallet_points_value",
      label: strings.w_balance,
      minWidth: 90,
      // format: (value) => value.toLocaleString('en-US'),
      format: (value) => value.toFixed(2),
    },
    { id: "created_at_formatted", label: strings.w_created, minWidth: 150 },
    getUserType() == true ||
    isPermissionExist("customer_update") ||
    isPermissionExist("customer_destroy")
      ? {
          id: "actions",
          label: strings.w_actions,
          minWidth: 60,
          align: "center",
        }
      : "",
  ];

  const statusFilters = [
    {
      title: strings.w_active_customers,
      value: "active",
    },
    {
      title: strings.w_blocked_customers,
      value: "inactive",
    },
  ];

  const getCustomers = () => {
    var additional = {
      page: currentPage,
      search: searchWord,
      status: status,
    };

    var params = QueryWith(history.location, additional);

    if (params.length > 0) {
      history.push({
        pathname: "/customers",
        state: "customer",
        search: params[0],
      });
    }

    ApiService({ method: "GET", route: "customers", body: additional })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setCustomerData(response.data.data.data);
          setCustomerPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    console.log("refresh");
    getCustomers();
  }, [currentPage, history, searchWord, status]);

  const deleteCustomer = () => {
    ApiService({
      method: "DELETE",
      route: "customers/" + selectedCustomerId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getCustomers();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const blockCustomer = () => {
    var hitUrl =
      selectedStatusType == "Block"
        ? "customer/block/" + selectedCustomerId
        : "customer/unblock/" + selectedCustomerId;

    ApiService({ method: "POST", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getCustomers();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
    setHitBackUrl(false);
  };

  return (
    <div className="mainInnerView">
      <h1>
        <PeopleAlt /> {strings.w_customers}
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <GSearch
          placeholder={strings.s_search_by_name_email_or_phone}
          value={searchWord}
          handleChange={(val) => {
            setSearchWord(val);
            setCurrentPage(1);
            setHitBackUrl(false);
          }}
        />
        <GSelectbox
          showSelectOption={false}
          defaultvalue={status}
          data={statusFilters}
          id="customer_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setStatus(newVal);
            setCurrentPage(1);
            setHitBackUrl(false);
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />

        {customerData.length !== 0 ? (
          <React.Fragment>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={customerPaginationData.total}
              from={customerPaginationData.from}
              to={customerPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(customerPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(customerPaginationData.prev_page_url)
              }
              rowsPerPage={customerPaginationData.per_page}
              prevDisabled={customerPaginationData.prev_page_url ? false : true}
              nextDisabled={customerPaginationData.next_page_url ? false : true}
            ></GPagination>

            <GPaginationSelect
              defaultvalue={currentPage}
              totalPage={customerPaginationData.last_page}
              onChangePage={(NewPageNo) => setCurrentPage(NewPageNo)}
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      <div className="listPaper">
        {!customerPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {customerData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns &&
                      columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          <Link
                            to={{
                              pathname: "/customer/" + c.id + "/orders",
                              state: "customer",
                            }}
                          >
                            {c.title}
                          </Link>
                        </TableRowData>
                        <TableRowData>
                          <Avatar
                            alt={c.title}
                            src={ImgUrl("user") + "/" + c.thumb_photo}
                          />
                        </TableRowData>
                        <TableRowData>{c.email || "-"}</TableRowData>
                        <TableRowData>{c.phone || "-"}</TableRowData>
                        <TableRowData>
                          {c.wallet}({settings.currency_symbol + "" + c.balance}
                          )
                        </TableRowData>
                        <TableRowData>{c.created}</TableRowData>
                        {getUserType() == true ||
                        isPermissionExist("customer_update") ||
                        isPermissionExist("customer_destroy") ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {getUserType() == true && c.status === 0 && (
                              <TooltipComponent
                                title={strings.w_block}
                                position="top"
                                id="block_cutomer"
                                divStyle={{
                                  verticalAlign: "unset",
                                  margin: "0",
                                }}
                                tooltipStyle={{
                                  minWidth: "65px",
                                  padding: "5px",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedStatusType("Block");
                                    setSelectedCustomerId(c.id);
                                    setBlockDialogOpen(true);
                                  }}
                                >
                                  <Block
                                    fontSize="small"
                                    style={{ color: "#D8AE36" }}
                                  />
                                </GButton>
                              </TooltipComponent>
                            )}

                            {getUserType() == true && c.status === 1 && (
                              <TooltipComponent
                                title={strings.w_unblock}
                                position="top"
                                id="unblock_cutomer"
                                divStyle={{
                                  verticalAlign: "unset",
                                  margin: "0",
                                }}
                                tooltipStyle={{
                                  minWidth: "65px",
                                  padding: "5px",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedStatusType("Unblock");
                                    setSelectedCustomerId(c.id);
                                    setBlockDialogOpen(true);
                                  }}
                                >
                                  <Unblock
                                    fontSize="small"
                                    style={{
                                      fill: "#bbb",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                </GButton>
                              </TooltipComponent>
                            )}
                            {getUserType() == true ||
                            isPermissionExist("customer_update") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCustomerData(c);
                                  setCcvisible(true);
                                }}
                              >
                                <Edit fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}
                            {getUserType() == true ||
                            isPermissionExist("customer_destroy") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCustomerId(c.id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_customer_title}
          text={strings.s_delete_customer_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCustomer();
          }}
        ></GConfirmDialog>
      )}

      {blockDialogOpen === true && (
        <GConfirmDialog
          open={blockDialogOpen}
          title={
            selectedStatusType == "Block"
              ? strings.w_block_customer
              : strings.w_unblock_customer
          }
          text={
            selectedStatusType == "Block"
              ? strings.s_are_you_sure_block_customer
              : strings.s_are_you_sure_unblock_customer
          }
          handleClose={() => setBlockDialogOpen(false)}
          onConfirmClose={() => {
            setBlockDialogOpen(false);
            blockCustomer();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={ccvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcvisible(false)}
        style={{
          content: {
            width: "420px",
          },
        }}
        contentLabel="Customer Form Modal"
      >
        <CustomerCreate
          ccvisible={ccvisible}
          setCcvisible={setCcvisible}
          data={selectedCustomerData}
          onSubmit={getCustomers}
        />
      </Modal>
    </div>
  );
}
