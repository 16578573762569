import React, { useState, useEffect, useContext } from "react";
import { Edit } from "@material-ui/icons";
import { ApiService, getUserType, isPermissionExist } from "../services";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent, GTableContent } from "../gComponents/gContent";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import GSwitch from "../gComponents/gSwitch";
import Tasks from "../tasks/tasksList";
import WalletPoints from "../components/walletPointsList";
import Documents from "../components/documentsList";
import { useSnackbar } from "react-simple-snackbar";
import DriverCreate from "../forms/driverForm";
import useSettings from "../useSettings";
import GRouting from "../gComponents/gRouteBreadCrumbs";
import GMap from "../gComponents/gMaps";
import useDataFactory from "../useDataFactory";
import Modal from "react-modal";
import GNoFound from "../gComponents/gNoFound";
import { StringsContext } from "../DataFactory/useStrings";

export default function DriverDetail() {
  const [strings] = useContext(StringsContext);
  let { driverId, detailType } = useParams();
  const [openSnackbar] = useSnackbar();
  const [cdvisible, setCdvisible] = useState(false);
  const settings = useSettings(true);
  const { data: driverData, refreshData } = useDataFactory(
    "drivers/" + driverId,
    false
  );
  const { data: commissionCategoriesData } = useDataFactory(
    "driver_commission_categories",
    false
  );

  const RoutLinks = [
    {
      title: strings.w_drivers,
      link: "/drivers",
      state: "driver",
    },
  ];

  const driverRoutes = [
    {
      title: strings.w_tasks,
      identifier: "tasks",
      link: "/driver/" + driverId + "/tasks",
      state: "driver",
    },
    getUserType() || isPermissionExist("agent_wallet")
      ? {
          title: strings.w_wallet,
          identifier: "wallet",
          link: "/driver/" + driverId + "/wallet",
          state: "driver",
        }
      : "",
    getUserType()
      ? {
          title: strings.w_documents,
          identifier: "documents",
          link: "/driver/" + driverId + "/documents",
          state: "driver",
        }
      : "",
  ];

  function toggleValue(newValue, selectedFieldId) {
    ApiService({
      method: "PUT",
      route: "drivers/" + selectedFieldId,
      body: {
        driver_status: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          driverData.driver_status = newValue;
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleUpdateImage(imgValue, id, thumbImg) {
    console.log(imgValue);
    ApiService({
      method: "PUT",
      route: "drivers/" + driverId,
      body: { photo: imgValue, thumb_photo: thumbImg },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        driverData.photo = response.data.data.photo;
        driverData.thumb_photo = response.data.data.thumb_photo;
      }
      openSnackbar(response.data.message);
      return;
    });
  }
  return (
    <div className="mainInnerView">
      <GRouting links={RoutLinks} currentPage={driverData.title} />
      {driverData ? (
        <GInfo
          id={driverData.id}
          title={driverData.title}
          key={driverData.id}
          className="headerInfo"
          imgType="user"
          photo={driverData?.photo}
          imgEdit={
            getUserType() || isPermissionExist("agent_update") ? true : false
          }
          uploadImage={handleUpdateImage}
          style={{ width: "auto" }}
        />
      ) : (
        ""
      )}
      {getUserType() || isPermissionExist("agent_update") ? (
        <GSwitch
          id={driverData.id}
          uId={driverData.id}
          currentValue={driverData.driver_status}
          disabled={
            getUserType() || isPermissionExist("agent_update") ? false : true
          }
          style={{
            margin: "8px 0 0 25px",
            display: "inline-block",
            verticalAlign: "top",
          }}
          onSwitchChange={toggleValue}
        />
      ) : (
        ""
      )}

      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ minHeight: "200px" }}>
          <CardHead>
            {strings.w_contact_details}
            {getUserType() || isPermissionExist("agent_update") ? (
              <Edit onClick={() => setCdvisible(true)} />
            ) : (
              ""
            )}
          </CardHead>
          <GContent title={strings.w_email} value={driverData.email} />
          <GContent title={strings.w_phone} value={driverData.phone} />
        </Card>

        <Card style={{ minHeight: "200px" }}>
          <CardHead>{strings.w_other_information}</CardHead>
          <GTableContent
            title={strings.w_balance}
            value={settings.currency_symbol + "" + driverData.balance}
            align="right"
          />
          <GTableContent
            title={strings.w_commission_category}
            value={
              driverData.commission_category_id
                ? commissionCategoriesData
                    .filter((f) => f.id == driverData.commission_category_id)
                    .map((cat) => {
                      return cat.title;
                    }) + "%"
                : "-"
            }
            align="right"
          />
          <GTableContent
            title={strings.w_last_location_update}
            value={
              driverData.location_updated ? driverData.location_updated : "-"
            }
            align="right"
          />

          {driverData.latitude ? (
            <div style={{ margin: "10px" }}>
              <GMap
                centerLocation={{
                  lat: driverData.latitude,
                  lng: driverData.longitude,
                }}
                height="200px"
                type="viewDriverLoc"
              />
            </div>
          ) : (
            ""
          )}
        </Card>
        <Modal
          isOpen={cdvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setCdvisible(false)}
          style={{
            content: {
              width: "400px",
            },
          }}
          contentLabel="Driver Form Modal"
        >
          <DriverCreate
            cdvisible={cdvisible}
            setCdvisible={setCdvisible}
            data={driverData}
            onSubmit={(data) => {
              driverData.title = data.title;
              driverData.first_name = data.first_name;
              driverData.last_name = data.last_name;
              driverData.email = data.email;
              driverData.phone = data.phone;
            }}
            settings={settings}
          />
        </Modal>
      </div>

      <div
        style={{
          width: "65%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
        }}
      >
        <GTabs routes={driverRoutes} active={detailType}></GTabs>

        <Switch>
          <Route exact path={"/driver/" + driverId + "/tasks"}>
            <Tasks type="driver" value={driverId} title="agent_id" />
          </Route>
          {getUserType() || isPermissionExist("agent_wallet") ? (
            <Route exact path={"/driver/" + driverId + "/wallet"}>
              <WalletPoints type="driver" value={driverId} title="driver_id" />
            </Route>
          ) : (
            ""
          )}
          {getUserType() ? (
            <Route exact path={"/driver/" + driverId + "/documents"}>
              <Documents type="driver" value={driverId} title="driver_id" />
            </Route>
          ) : (
            ""
          )}
          <Route exact path={"/driver/" + driverId + "/not"}>
            <GNoFound />
          </Route>

          <Redirect
            to={"/driver/" + driverId + "/tasks"}
            from={"/driver/" + driverId + "/wallet"}
          />
        </Switch>
      </div>
    </div>
  );
}
