import React, { useState, useContext } from "react";
import { AccountCircle, ExitToApp, Security } from "@material-ui/icons";
import {
  Button,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  Divider,
  Avatar,
} from "@material-ui/core";
import { LogoutService, ImgUrl } from "../services";
import { makeStyles } from "@material-ui/core/styles";
import "./header.css";
import ChangePassword from "./../components/ChangePassword";
import EditProfile from "./../components/editProfile";
import SearchComponent from "../components/selectSearchComponent";
import GInfo from "../gComponents/gInfo";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import Modal from "react-modal";
import ChangeLanguage from "../Localisation/ChangeLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    float: "right",
    alignItems: "center",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition() {
  const settings = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useSettings();
  // console.log(settings)
  const classes = useStyles();
  const [strings] = useContext(StringsContext);
  const [open, setOpen] = React.useState(false);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  // console.log(settings);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }
    // setAnchorEl(null);
    setOpen(false);
    setPlacement();
  };

  const handleLogout = () => {
    LogoutService("a/logout")
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.clear();
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <header>
      <div
        style={{
          display: "inline-block",
          width: "60%",
          margin: "0 20px",
          position: "relative",
        }}
      >
        <SearchComponent settings={settings} />
      </div>
      <div className={classes.root}>
        <ChangeLanguage />
        <Button
          aria-controls={open ? "menu-list-grow" : undefined}
          onClick={handleClick("bottom-end")}
          aria-haspopup="true"
          // onClick={handleToggle}
          className="dropdownMenu"
        >
          {/* <AccountCircle fontSize="small" /> */}
          <Avatar
            alt={settings?.user?.first_name}
            src={ImgUrl("admin") + "/" + settings?.user?.photo}
          />
        </Button>

        <Popper
          style={{ zIndex: "99" }}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          role={undefined}
          transition
          //    disablePortal
        >
          {({ TransitionProps }) => {
            //const  settings  = useSettings();
            return (
              <Grow
                {...TransitionProps}
                // style={{ transformOrigin:placement==='bottom'? 'bottom right':  'top right' , anchorOrigin : 'bottom right'}}
              >
                <Paper>
                  <p className="paperAdminInfo">
                    <GInfo
                      title={
                        settings?.user?.first_name +
                        " " +
                        settings?.user?.last_name
                      }
                      key={settings?.user.id}
                      subtitle={settings?.user?.email}
                      photo={settings?.user?.photo}
                      imgType="admin"
                    />
                  </p>

                  <Divider />
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => {
                          setEpvisible(true);
                          setAnchorEl(null);
                          setOpen(false);
                        }}
                      >
                        <AccountCircle fontSize="small" />{" "}
                        {strings.w_edit_profile}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCpvisible(true);
                          setAnchorEl(null);
                          setOpen(false);
                        }}
                      >
                        <Security fontSize="small" />{" "}
                        {strings.w_change_password}
                      </MenuItem>
                      <MenuItem onClick={() => setDialogOpen(true)}>
                        <ExitToApp fontSize="small" /> {strings.w_logout}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            );
          }}
        </Popper>
      </div>

      <Modal
        isOpen={cpvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "340px",
          },
        }}
        contentLabel="Change Password Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>

      <Modal
        isOpen={epvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "390px",
          },
        }}
        contentLabel="Edit Profile Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.w_logout}
          text={strings.s_are_you_sure_logout}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleLogout();
          }}
        ></GConfirmDialog>
      )}
    </header>
  );
}
