import React, { useState } from "react";
import "./sidebar.css";
import CustomerCreate from "../forms/customerForm";
import DriverCreate from "../forms/driverForm";
import StoreCreate from "../forms/storeCreateForm";
import PromotionCreate from "../forms/promotionForm";
import { Link } from "react-router-dom";
import useSettings from "../useSettings";
import { addDefaultLogo, ImgUrl } from "../services";
import GNestedMenu from "../gComponents/gNestedMenu";
import Modal from "react-modal";

export default function Sidebar(props) {
  const [ccvisible, setCcvisible] = useState(false);
  const [cdvisible, setCdvisible] = useState(false);
  const [csvisible, setCsvisible] = useState(false);
  const [ccpnvisible, setCcpnvisible] = useState(false);
  const settings = useSettings(true);

  return (
    <div className="Sidebar" style={props.style}>
      <div className="App-header">
        <Link to="/dashboard">
          <img
            src={ImgUrl("logo") + "/" + settings?.admin_logo}
            className="App-logo"
            alt="logo"
            onError={addDefaultLogo}
          />
        </Link>
        <span>
          <h3>{settings.project_name}</h3>
        </span>
      </div>

      <GNestedMenu
        settings={settings}
        handleClick={(title) => {
          console.log(title);
          // eslint-disable-next-line no-unused-expressions
          title === "customer"
            ? setCcvisible(true)
            : title === "driver"
            ? setCdvisible(true)
            : title === "store"
            ? setCsvisible(true)
            : title === "coupon"
            ? setCcpnvisible(true)
            : "";
        }}
      />

      <div
        className="Sidebar-footer"
        style={{ position: props.toggle == true ? "inherit" : "fixed" }}
      >
        <hr />
        <p>
          Powered by{" "}
          <a
          // target="_blank"
          // rel="noopener noreferrer"
          // href="https://www.ordefy.com"
          >
            Ambar
          </a>
        </p>
      </div>

      <Modal
        isOpen={cdvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCdvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Driver Form Modal"
      >
        <DriverCreate
          cdvisible={cdvisible}
          setCdvisible={setCdvisible}
          isEmpty={true}
        />
      </Modal>

      <Modal
        isOpen={csvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCsvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Store Create Form Modal"
      >
        <StoreCreate
          csvisible={csvisible}
          setCsvisible={setCsvisible}
          isEmpty={true}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={ccvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcvisible(false)}
        style={{
          content: {
            width: "420px",
          },
        }}
        contentLabel="Customer Form Modal"
      >
        <CustomerCreate
          isEmpty={true}
          ccvisible={ccvisible}
          setCcvisible={setCcvisible}
        />
      </Modal>

      <Modal
        isOpen={ccpnvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcpnvisible(false)}
        style={{
          content: {
            width: "620px",
          },
        }}
        contentLabel="Coupon Form Modal"
      >
        <PromotionCreate
          isEmpty={true}
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
        />
      </Modal>
    </div>
  );
}
