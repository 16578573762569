import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GTabs from "../gComponents/gTabs";
import { Switch, Route, useParams } from "react-router-dom";
import GText from "../gComponents/gText";
import { StoreFilter } from "../components/storeFilterComponent";
import { StringsContext } from "../DataFactory/useStrings";

export default function PromotionCampaign() {
  const [strings] = useContext(StringsContext);
  const [typeValue, setTypeValue] = useState("email");

  const notifyRoutes = [
    {
      title: strings.w_email,
      identifier: "email",
      link: "/promotion-campaign/email",
      state: "coupon",
    },
    {
      title: strings.w_sms,
      identifier: "sms",
      link: "/promotion-campaign/sms",
      state: "coupon",
    },
  ];

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_promotion_campaign}
        style={{ marginBottom: "20px" }}
      />
      <GTabs routes={notifyRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/promotion-campaign/:type">
            <Child onChange={(newValue) => setTypeValue(newValue)} />
          </Route>
        </div>
      </Switch>
    </div>
  );
}

function Child(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  let { type } = useParams();

  const initialValues = {
    subject: "",
    body: "",
    type: props.type,
    store_id: "",
  };

  useEffect(() => {
    console.log(type);
    document.getElementById("resetButton").click();
    props.onChange(type);
  }, [type]);

  const onSubmit = (values, { setFieldValue, resetForm }) => {
    setIsBtnDisabled(true);

    let sendData = { ...values };
    sendData.type = type;
    if (type == "sms") {
      delete sendData.subject;
    }
    console.log(sendData);
    // return;
    ApiService({
      method: "POST",
      route: "promotion/campaign",
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setFieldValue("body", "");
        setFieldValue("subject", "");
        resetForm(initialValues);
      }
      setIsBtnDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    subject: type == "email" ? Yup.string().required(strings.w_required) : "",
    body: Yup.string().required(strings.w_required),
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ resetForm, setFieldValue, values }) => (
          <Form style={{ width: "500px" }}>
            {type == "email" && (
              <>
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_subject}
                  name="subject"
                />
                <FormikControl
                  control="input"
                  as="textarea"
                  type="text"
                  label={strings.w_body}
                  name="body"
                  rows="5"
                />
                <div className="form-control">
                  <GText med text={strings.w_select_store} />
                  <StoreFilter
                    value={values.store_id}
                    displayValue=""
                    style={{
                      marginBottom: "25px",
                      display: "block",
                      width: "100%",
                    }}
                    handleStoreChange={(value) => {
                      setFieldValue("store_id", value || "");
                    }}
                  />
                </div>
              </>
            )}

            {type == "sms" && (
              <>
                <FormikControl
                  control="input"
                  as="textarea"
                  type="text"
                  label={strings.w_body}
                  name="body"
                  rows="5"
                />
                <div className="form-control">
                  <GText med text={strings.w_select_store} />
                  <StoreFilter
                    value={values.store_id}
                    displayValue=""
                    style={{
                      marginBottom: "25px",
                      display: "block",
                      width: "100%",
                    }}
                    handleStoreChange={(value) => {
                      setFieldValue("store_id", value || "");
                    }}
                  />
                </div>
              </>
            )}
            <GButton
              type="submit"
              disabled={isBtnDisabled}
              variant="condensed"
              children={strings.w_submit}
              style={{ display: "flex", margin: "15px 15px 20px auto" }}
            />
            <button
              type="button"
              id="resetButton"
              style={{ display: "none" }}
              onClick={() => resetForm()}
            ></button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
