import React, { useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function StoreManagerForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    store_id: props.storeId,
    first_name: !props.isEmpty ? props.data.first_name : "",
    last_name: !props.isEmpty ? props.data.last_name : "",
    email: !props.isEmpty ? props.data.email : "",
    phone: !props.isEmpty ? props.data.phone : "",
    password: "",
  };

  const onSubmit = (values) => {
    console.log(values);

    if (!props.isEmpty) {
      if (!values.password) {
        delete values.password;
      }
    }

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "store/manager/" + props.data.id
      : "store/manager";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setCsmvisible(false);
        }
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings.w_required),
    email: Yup.string()
      .email(strings.s_invalid_email_address)
      .required(strings.w_required),
    phone: Yup.string().required(strings.w_required),
    password: props.isEmpty ? Yup.string().required(strings.w_required) : "",
  });

  if (props.csmvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_manager : strings.w_create_manager}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_first_name}
                name="first_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("first_name", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_last_name}
                name="last_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("last_name", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label={strings.w_email_id}
                name="email"
              />

              <FormikControl
                control="input"
                type="password"
                label={strings.w_password}
                name="password"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_phone}
                name="phone"
              />
              <br />
              <GButton
                type="submit"
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
