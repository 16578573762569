import React, { useState, useEffect, useContext } from "react";
import {
  Close,
  Directions,
  WorkOutlineOutlined,
  MyLocation,
  MoreHoriz,
} from "@material-ui/icons";
import { ReactComponent as AutoIcon } from "../assets/images/magic.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search.svg";
// import { ReactComponent as HexagonIcon } from "../assets/images/hexagon.svg";
import { ReactComponent as OrderIcon } from "../assets/images/box.svg";
import { ReactComponent as HexagonIcon } from "../assets/images/stop-pickup.svg";
import { ImgUrl, ApiService, ApiLoginService } from "../services";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import GPagination from "../gComponents/gPagination";
import GButton from "../gComponents/gButton";
import { GTaskShimmer, GSingleShimmer } from "../gComponents/gCardShimmer";
import TooltipComponent from "../gComponents/gTooltip";
import GPopover from "../gComponents/gPopover";
import { useSnackbar } from "react-simple-snackbar";
import AssignDriverPopup from "../components/assignDriver";
import { Avatar } from "@material-ui/core";
import GText from "../gComponents/gText";
import { createSocketConnection } from "../useLaravelPusher";
import { GSearch } from "../gComponents/gSearch";
import useDebounce from "../useDebounce";
import { ReadMore } from "../gComponents/gReadMore";
import { StringsContext } from "../DataFactory/useStrings";

const TaskListView = styled.div`
  // border-top: 4px solid #212226;
  padding: 15px 12px;
  // cursor: pointer;
  color: #bbb;

  &:hover {
    background: #212226;
  }

  .topStatus {
    cursor: pointer;
    display: flex;
    // align-items: center;

    h4 {
      // display: inline-block;
      font-size: 16px;
      margin: 0;
      a {
        color: #aaa;
      }
    }
    svg {
      font-size: 2rem;
      margin-right: 10px;
      fill: #f7b500;
      cursor: pointer;
    }
  }

  .actionIcon:hover {
    background: #333;
  }
`;

const TaskDiv = styled.div`
  position: relative;
  margin-top: 15px;
  & h4 {
    font-size: 15px !important;
    line-height: 22px;
    margin: 2px 0 !important;
    font-weight: 400;
    color: #bbb;
  }
  & p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #999;
    line-height: 20px;
  }
`;
const PickupDiv = styled.div`
  display: inline-flex;
  vertical-align: top;
  svg {
    width: 50px;
  }
  width: 100%;
  div {
    display: inline-block;
    padding-left: 15px;
    border-left: 3px solid #333;
    padding-bottom: 10px;
    vertical-align: top;
    box-sizing: border-box;
  }
`;
const DeliveryDiv = styled.div`
  display: inline-flex;
  width: 100%;
  padding-bottom: 10px;
  svg {
    width: 50px;
  }
  div {
    display: inline-block;
    padding-left: 15px;
    border-left: 3px solid #333;
    vertical-align: top;
  }
`;

export default function OrderTasks(props) {
  let history = useHistory();
  const [strings] = useContext(StringsContext);
  const [isLoading, setIsLoading] = useState(props.initialLoading);
  const [currentPage, setCurrentPage] = useState("1");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [openSnackbar] = useSnackbar();
  const [advisible, setAdvisible] = useState(false);
  const [ordersData, setOrdersData] = useState(
    props.taskData || {
      data: [],
    }
  );
  const [searchWord, setSearchWord] = useState("");
  const debouncedSearchTerm = useDebounce(searchWord, 500);

  useEffect(() => {
    createSocketConnection();
    console.log(props);
    setOrdersData(props.taskData || {});
    setTimeout(function () {
      setIsLoading(false);
      props.setInitialLoading(false);
    }, 1000);
  }, [props.taskData]);

  const getordersList = () => {
    var hitUrl =
      "order/deliveries?status=active&page=" +
      currentPage +
      "&search=" +
      searchWord;
    console.log(hitUrl);
    ApiService({ method: "GET", route: hitUrl })
      .then((response) => {
        console.log(response.data);

        response.data.data.data.map((o) => {
          o.showPopover = false;
        });
        setOrdersData({ ...response.data.data } || {});
        setTimeout(function () {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.initialLoading == false) {
      getordersList();
    }
  }, [currentPage, props.filters, debouncedSearchTerm]);

  useEffect(() => {
    window.Echo.channel("admin-updates").listen("BCOrderUpdated", function (e) {
      console.log(e);
      console.log(ordersData);
      let newOrderData = { ...ordersData };
      console.log(ordersData);
      if (newOrderData?.data && newOrderData?.data.length > 0) {
        let findInd = newOrderData.data
          .filter((n) => n.status != "completed")
          .findIndex((o) => o.order_id == e.order.id);
        console.log(findInd);
        if (findInd > -1) {
          let newData =
            newOrderData.data[findInd].delivery_type == "pickup"
              ? e.order.pickup
              : e.order.delivery;
          newOrderData.data[findInd] = newData;
          setOrdersData({
            ...newOrderData,
          });
        }
      }
    });
    return () => {
      window.Echo.leaveChannel(`admin-updates`);
    };
  }, [isLoading]);

  useEffect(() => {
    console.log(ordersData);
  }, [ordersData]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
  };

  const assignAuto = (sOrder) => {
    ApiService({
      method: "POST",
      route: "order/delivery/auto-assign/" + sOrder?.id,
    }).then((response) => {
      console.log("r" + response.data);
      if (response.data?.status_code == 0) {
        openSnackbar(response.data.message);
      } else {
        getordersList();
        openSnackbar(
          response.message || strings.s_request_sent_to_driver_successfully
        );
      }
    });
  };

  useEffect(() => {
    console.log(selectedOrder);
    if (selectedOrder?.action) {
      setAdvisible(true);
    }
  }, [selectedOrder]);

  function getRouteJson(o) {
    ApiLoginService(
      "GET",
      "c/order/directions/" +
        o?.id +
        "?key=" +
        process.env.REACT_APP_GOOGLE_DIRECTION_KEY
    ).then((response) => {
      console.log(response);
      if (response?.routes) {
        props.onClick(o, response);
      } else {
        props.onClick(o);
        openSnackbar(response.message);
      }
    });
  }

  return (
    <div>
      {isLoading == true ? (
        <>
          <br />
          <br />
          <GSingleShimmer theme="dark" />
          <GTaskShimmer theme="dark" />
          <br />
          <GTaskShimmer theme="dark" />
        </>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GSearch
              placeholder={strings.s_search_by_id}
              value={searchWord}
              handleChange={(val) => {
                setSearchWord(val);
                setCurrentPage(1);
              }}
              style={{
                width: "170px",
                border: "0px",
                padding: "5px 8px",
                background: "#222326",
                margin: "5px",
              }}
              inputStyle={{ color: "#e2e2e2" }}
              iconStyle={{ color: "#999" }}
            />
            <GPagination
              style={{ paddingTop: "5px", color: "#aaa", marginLeft: "auto" }}
              theme={"dark"}
              count={ordersData.total}
              from={ordersData.from}
              to={ordersData.to}
              onChangeNextPage={() =>
                handleChangePage(ordersData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(ordersData.prev_page_url)
              }
              rowsPerPage={ordersData.per_page}
              prevDisabled={ordersData.prev_page_url ? false : true}
              nextDisabled={ordersData.next_page_url ? false : true}
            ></GPagination>
          </div>
          <div
            style={{
              background: "transparent",
              height: "calc(100vh - 185px)",
              overflow: "overlay",
              paddingBottom: "50px",
            }}
          >
            {ordersData?.data?.length == 0 ? (
              <GText
                text={strings.s_no_data_found}
                style={{ color: "#555", textAlign: "center", margin: "30px 0" }}
              />
            ) : (
              ordersData?.data?.map((o, i) => {
                return (
                  <TaskListView key={i}>
                    <div
                      className="topStatus"
                      onClick={() => {
                        console.log(o);
                        getRouteJson(o);
                      }}
                    >
                      <TooltipComponent
                        title={o.status_display?.title}
                        position="bottom"
                        id={o.id}
                        divStyle={{
                          verticalAlign: "unset",
                          margin: "0",
                          height: "fit-content",
                        }}
                        tooltipStyle={{
                          minWidth: "60px",
                          padding: "2px 0",
                          background: "#333",
                          top: "30px",
                          left: "65%",
                        }}
                        arrowStyle={{
                          borderBottom: "10px solid #333",
                          left: "calc(50% - 20px)",
                        }}
                      >
                        <HexagonIcon
                          style={{
                            fill: "#" + o.status_display?.color1,
                            width: "22px",
                            height: "22px",
                          }}
                          // title={o.status?.title}
                        />
                      </TooltipComponent>
                      <div
                        style={{
                          width: "calc(100% - 40px)",
                          marginLeft: "5px",
                        }}
                      >
                        <h4>
                          {"#" + o.order_id}
                          {o.delivery_type == "pickup" && (
                            <span
                              style={{
                                marginLeft: "10px",
                                fontSize: "14px",
                                color: "#999",
                                background: "#555",
                                padding: "0px 5px",
                              }}
                            >
                              P
                            </span>
                          )}
                        </h4>

                        <TaskDiv>
                          <PickupDiv>
                            <div>
                              <h4>{strings.w_pickup}</h4>
                              <ReadMore>{o?.pickup_address || "-"}</ReadMore>
                            </div>
                          </PickupDiv>
                          <DeliveryDiv>
                            <div>
                              <h4>{strings.w_dropoff}</h4>
                              <ReadMore>{o?.delivery_address || "-"}</ReadMore>
                            </div>
                          </DeliveryDiv>
                        </TaskDiv>
                      </div>

                      <div
                        style={{
                          width: "40px",
                          // alignSelf: "center",
                          marginLeft: "20px",
                          position: "relative",
                          minHeight: "50px",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {o.agent?.id && (
                          <TooltipComponent
                            title={o.agent?.title}
                            position="left"
                            id={o.agent?.id}
                            divStyle={{
                              verticalAlign: "unset",
                              margin: "0",
                              height: "fit-content",
                            }}
                            tooltipStyle={{
                              minWidth: "60px",
                              padding: "2px 5px",
                              background: "#333",
                              // top: "50px",
                              right: "calc(100% + 12px)",
                            }}
                            arrowStyle={{
                              borderLeft: "10px solid #333",
                            }}
                          >
                            <Avatar
                              alt={o.agent?.title}
                              src={ImgUrl("driver") + "/" + o.agent?.photo}
                            />
                          </TooltipComponent>
                        )}
                        <div
                          style={{
                            alignSelf: "center",
                            flex: "50%",
                            display: "flex",
                            position: "relative",
                          }}
                        >
                          <MoreHoriz
                            className={"actionIcon"}
                            style={{
                              fill: "#555",
                              margin: "0",
                              fontSize: "1.5rem",
                              cursor: "pointer",
                              display: "inline-block",
                              margin: "auto",
                              textAlign: "left",
                              borderRadius: "20px",
                              padding: "2px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              ordersData.data.map((o) => {
                                o.showPopover = false;
                              });
                              ordersData.data[i].showPopover = true;
                              setOrdersData({ ...ordersData });
                            }}
                          />
                          {o.showPopover == true && (
                            <GPopover
                              placement={"right"}
                              buttons={[
                                // {
                                //   title: "Show on map",
                                //   icon: <MyLocation style={{ width: "22px" }} />,
                                //   link: false,
                                //   show: true,
                                //   action: () => props.onClick(o),
                                // },
                                {
                                  title: strings.w_autoassign_task,
                                  icon: <AutoIcon />,
                                  show:
                                    o.status != "completed" &&
                                    o.status != "cancelled"
                                      ? !o.agent?.id
                                        ? true
                                        : o.agent?.id && o.status == "pending"
                                        ? true
                                        : false
                                      : false,
                                  action: () => assignAuto(o),
                                },
                                {
                                  title: strings.w_assign_task_manually,
                                  icon: <SearchIcon />,
                                  show: !o.agent?.id ? true : false,
                                  action: () => {
                                    o.action = "assign";
                                    setSelectedOrder(o);
                                    setAdvisible(true);
                                  },
                                },
                                {
                                  title: strings.w_re_assign_driver,
                                  icon: <SearchIcon />,
                                  show:
                                    o.agent?.id && o.status == "pending"
                                      ? true
                                      : false,
                                  action: () => {
                                    o.action = "reassign";
                                    setSelectedOrder(o);
                                    setAdvisible(true);
                                  },
                                },
                                {
                                  title: strings.w_view_order_details,
                                  icon: <OrderIcon />,
                                  route: "/order/",
                                  state: "order",
                                  show: true,
                                  action: () => {
                                    if (o.class === "courier") {
                                      window.open(
                                        process.env.REACT_APP_BASE_URL +
                                          "/courier-order/" +
                                          o.order_id,
                                        "_blank" // <- This is what makes it open in a new window.
                                      );
                                    } else if (o.class === "atm") {
                                      window.open(
                                        process.env.REACT_APP_BASE_URL +
                                          "/atm-order/" +
                                          o.order_id,
                                        "_blank" // <- This is what makes it open in a new window.
                                      );
                                    } else {
                                      window.open(
                                        process.env.REACT_APP_BASE_URL +
                                          "/order/" +
                                          o.order_id,
                                        "_blank" // <- This is what makes it open in a new window.
                                      );
                                      // history.push({
                                      //   pathname: "/order/" + o.id,
                                      //   state: "order",
                                      // });
                                    }
                                  },
                                },
                              ]}
                              show={o.showPopover}
                              onPress={() => {
                                ordersData.data[i].showPopover = false;
                                setOrdersData({ ...ordersData });
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                props.onClick(o?.tasks || {});
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </TaskListView>
                );
              })
            )}
          </div>
        </>
      )}

      {advisible === true && (
        <AssignDriverPopup
          advisible={advisible}
          setAdvisible={setAdvisible}
          linkedId={selectedOrder?.id || ""}
          onSubmit={() => getordersList()}
        />
      )}
    </div>
  );
}
