import React, { useContext } from "react";
import { MoreVert, Edit, Delete, FileCopy, Block } from "@material-ui/icons";
import { ReactComponent as Unblock } from "../assets/images/unlock.svg";
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { StringsContext } from "../DataFactory/useStrings";

const ToggleButton = styled.button`
  display: inline-block;
  min-width: auto;
  padding: 5px 6px 1px;
  background: #fff;
  box-shadow: none;
  border: 0px;
  border-radius: 50px;
  outline: 0;
  & svg {
    height: 0.8em;
    width: 0.8em;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition(props) {
  const [strings] = useContext(StringsContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} style={props.style}>
      <div>
        <ToggleButton
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVert />
        </ToggleButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: "99", border: "1px solid #f2f2f2" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    style={{ padding: "5px 0" }}
                  >
                    {props.edit ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onEdit();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        <Edit fontSize="small" /> {strings.w_edit}
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    {props.duplicate ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onDuplicate();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        <FileCopy fontSize="small" /> {strings.w_duplicate}
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    {props.delete ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onDelete();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        <Delete fontSize="small" /> {strings.w_delete}
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    {props.onSelect ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onSelect();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        {props.menuTitle}
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    {props.block ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onBlock();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        <Block fontSize="small" /> {strings.w_block}
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    {props.unblock ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onUnblock();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        <Unblock
                          fontSize="small"
                          style={{ width: 20, height: 20, fill: "#777779" }}
                        />{" "}
                        {strings.w_unblock}
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
