import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService, getUserType, isPermissionExist } from "../services";
import { Redeem, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GSwitch from "../gComponents/gSwitch";
import PromotionForm from "../forms/promotionForm";
import GSelectbox from "../gComponents/gSelectbox";
import QueryWith from "../Query";
import { StoreFilter } from "../components/storeFilterComponent";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function Promotions() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [couponData, setCouponData] = useState([]);
  const [couponPaginationData, setCouponPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCouponData, setSelectedCouponData] = useState({
    index: "",
    data: {},
  });
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [ccpnvisible, setCcpnvisible] = useState(false);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "",
    store_id: params.get("store_id") ? params.get("store_id") : "",
  });

  const statusFilters = [
    {
      title: strings.w_active,
      value: "1",
    },
    {
      title: strings.w_inactive,
      value: "0",
    },
  ];

  const promotionTypes = [
    {
      title: strings.w_free_delivery,
      value: "free_delivery",
    },
    { title: strings.w_free_product, value: "free_product" },
    {
      title: strings.w_fixed_discount,
      value: "fixed_discount",
    },
    {
      title: strings.w_percentage_discount,
      value: "percentage_discount",
    },
  ];

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 130 },
    { id: "promotion_type", label: strings.w_promotion_type, minWidth: 50 },
    { id: "coupon_code", label: strings.w_coupon_code, minWidth: 120 },
    { id: "store", label: strings.w_store, minWidth: 120 },
    {
      id: "total_usage_count",
      label: strings.w_total_used_count,
      minWidth: 100,
      align: "center",
    },
    { id: "time_schedule", label: strings.w_time_schedule, minWidth: 150 },
    { id: "status", label: strings.w_status, minWidth: 50 },
    {
      id: "added_by",
      label: strings.w_added_by,
      minWidth: 80,
      align: "center",
    },
    getUserType() ||
    isPermissionExist("coupon_update") ||
    isPermissionExist("coupon_destroy")
      ? {
          id: "actions",
          label: strings.w_actions,
          minWidth: 60,
          align: "center",
        }
      : "",
  ];

  const getPromotions = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/promotions",
        state: "coupon",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "coupons", body: filters })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setCouponData(response.data.data.data);
          setCouponPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPromotions();
  }, [history, filters]);

  const deleteCoupon = () => {
    ApiService({ method: "DELETE", route: "coupons/" + selectedCouponId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getPromotions();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleValue = (newValue, valueId, i) => {
    ApiService({
      method: "PUT",
      route: "coupons/" + valueId,
      body: { status: newValue },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          couponData[i].status = newValue;
          setCouponData([...couponData]);
        } else {
          getPromotions();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <Redeem /> {strings.w_promotions}
      </h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <StoreFilter
          value={filters.store_id}
          handleStoreChange={(value) => {
            setFilters({ ...filters, store_id: value || "", page: 1 });
          }}
        />
        <GSelectbox
          placeholder={strings.w_select_status}
          defaultvalue={filters.status}
          data={statusFilters}
          id="item_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        {couponData.length > 0 && (
          <>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={couponPaginationData.total}
              from={couponPaginationData.from || 0}
              to={couponPaginationData.to || 0}
              onChangeNextPage={() =>
                handleChangePage(couponPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(couponPaginationData.prev_page_url)
              }
              rowsPerPage={couponPaginationData.per_page}
              prevDisabled={couponPaginationData.prev_page_url ? false : true}
              nextDisabled={couponPaginationData.next_page_url ? false : true}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={couponPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </>
        )}
      </div>

      <div className="listPaper">
        {!couponPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {couponData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {couponData.map((c, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData>
                          {" "}
                          {c.promotion_type
                            ? promotionTypes
                                .filter(
                                  (type) => type.value == c.promotion_type
                                )
                                .map((t) => {
                                  return t.title;
                                })
                            : "-"}{" "}
                        </TableRowData>
                        <TableRowData>{c.code || "-"}</TableRowData>
                        <TableRowData>
                          {c.store_id ? (
                            <Link
                              to={{
                                pathname: "/store/" + c.store_id + "/orders",
                                state: "store",
                              }}
                            >
                              {c.store_name}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c?.total_used_count}
                        </TableRowData>
                        <TableRowData>
                          {strings.w_valid_from} :{" "}
                          {c.valid_from ? (
                            c.valid_from
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                          <br />
                          {strings.w_valid_upto} :{" "}
                          {c.valid_upto ? (
                            c.valid_upto
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                        </TableRowData>
                        <TableRowData>
                          <GSwitch
                            id={c.id}
                            uId={c.id}
                            disabled={
                              getUserType() ||
                              isPermissionExist("coupon_update")
                                ? false
                                : true
                            }
                            currentValue={
                              c.status === "true" || c.status == "1" ? 1 : 0
                            }
                            onSwitchChange={(newVal, id) =>
                              toggleValue(newVal, id, index)
                            }
                          />
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          {c.created_by || "-"}
                        </TableRowData>
                        {getUserType() ||
                        isPermissionExist("coupon_update") ||
                        isPermissionExist("coupon_destroy") ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {getUserType() ||
                            isPermissionExist("coupon_update") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCouponData({
                                    index: index,
                                    data: c,
                                  });
                                  setCcpnvisible(true);
                                }}
                              >
                                <Edit fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}

                            {getUserType() ||
                            isPermissionExist("coupon_destroy") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCouponId(c.id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_coupon_title}
          text={strings.s_delete_coupon_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCoupon();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={ccpnvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcpnvisible(false)}
        style={{
          content: {
            width: "620px",
          },
        }}
        contentLabel="Coupon Form Modal"
      >
        <PromotionForm
          isEmpty={false}
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
          data={selectedCouponData.data}
          onSubmit={(data) => {
            console.log(data);
            if (data) {
              couponData[selectedCouponData.index] = data;
            }
          }}
        />
      </Modal>
    </div>
  );
}
