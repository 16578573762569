import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function SubscriptionForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    price: !props.isEmpty ? props.data.price : "",
    validity: !props.isEmpty ? props.data.validity : "",
    delivery_price: !props.isEmpty ? props.data.delivery_price : "",
    delivery_limit: !props.isEmpty ? props.data.delivery_limit : "",
    additional_cashback: !props.isEmpty ? props.data.additional_cashback : "",
    details: !props.isEmpty ? props.data.details : "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    let sendData = { ...values };
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "subscriptions/" + props.data.id
      : "subscriptions";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setSubFormvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    price: Yup.string().required(strings.w_required),
    validity: Yup.string().required(strings.w_required),
    delivery_price: Yup.string().required(strings.w_required),
    delivery_limit: Yup.string().required(strings.w_required),
    additional_cashback: Yup.string().required(strings.w_required),
  });

  if (props.subFormvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_subscription
          : strings.w_create_subscription}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />

              <FormikControl
                control="input"
                as="textarea"
                label={strings.w_description}
                name="details"
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_price}
                name="price"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("price", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label={`${strings.w_validity} (${strings.w_days})`}
                name="validity"
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("validity", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <div style={{ margin: "0 15px" }}>
                <hr />
                <GText g3 med semi text={strings.w_benefits} />
              </div>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_delivery_price}
                name="delivery_price"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("delivery_price", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_delivery_limit}
                name="delivery_limit"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("delivery_limit", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label={`${strings.w_additional_cashback} (%)`}
                name="additional_cashback"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("additional_cashback", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ width: "calc(100% - 30px)", margin: "15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
