import React, { useEffect, useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderProductVariants(props) {
  const [strings] = useContext(StringsContext);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    ApiService({ method: "GET", route: "products/" + props.item.id }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setSelectedProduct(response.data.data);
        } else {
          openSnackbar(response.data.message);
        }
      }
    );
  }, []);

  const validateData = () => {
    let validated = true;
    console.log(selectedProduct.variants);
    selectedProduct.variants.every((element) => {
      console.log(element);
      //Maximum Minimum Check
      if (element.options.length > 0) {
        let count = element.options.filter(
          (e) => e.id === element.selected
        ).length;

        if (element.maximum < count) {
          openSnackbar(
            `Maximum Selection for ${element.title} is ${element.maximum}`
          );
          validated = false;
          return false;
        }
        if (element.minimum > count) {
          openSnackbar(`${element.title} selection is required.`);
          validated = false;
          return false;
        }
        return true;
      } else {
        return true;
      }
    });
    return validated;
  };

  const onAddVariants = () => {
    let validate = validateData();
    console.log(validate);

    if (validate === true) {
      let newArray = [];
      selectedProduct.variants.map((element) => {
        console.log(element);
        if (element.selected) {
          let v = element.options.filter((e) => e.id === element.selected);
          console.log(v);
          if (v && v.length > 0) {
            newArray = newArray.concat(v);
            console.log(newArray);
          }
        } else {
          return false;
        }
      });
      let newItem = { ...props.item };
      newItem.variants = newArray;
      console.log(newItem);

      props.onSubmitVariants(newItem, newArray);
      props.setOrderVarVisible(false);
    }
  };

  if (props.orderVarVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_choose_variants}</div>
      <div className="modal-body">
        {selectedProduct.variants && selectedProduct.variants.length > 0
          ? selectedProduct?.variants.map((v, i) => {
              return (
                <React.Fragment key={i}>
                  {v.options.length > 0 ? (
                    <React.Fragment>
                      <br />
                      <h4 style={{ margin: "10px" }}>{v.title}</h4>
                    </React.Fragment>
                  ) : (
                    ""
                  )}

                  {v.options.map((option, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "block",
                          padding: "5px",
                          color: "#555",
                        }}
                        onClick={() =>
                          (selectedProduct.variants[i].selected = option.id)
                        }
                      >
                        <input
                          name={"option_" + v.title}
                          type="radio"
                          id={"v_option_" + option.title}
                          value={option.id}
                          // checked={option.id}
                        />{" "}
                        &nbsp;&nbsp;
                        <label htmlFor={"v_option_" + option.title}>
                          {option.title} ({option.price})
                        </label>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })
          : ""}
        <GButton
          type="button"
          onClick={onAddVariants}
          variant="condensed"
          children={strings.w_add_to_cart}
          style={{ margin: "20px 15px 0" }}
        />
      </div>
    </>
  );
}
