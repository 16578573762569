import React, { useState, useEffect, useRef, useContext } from "react";
import {
  ApiService,
  getUserType,
  isPermissionExist,
  ImgUrl,
} from "../services";
import CustomSeparator from "../gComponents/gBreadCrumbs";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GTableContent } from "../gComponents/gContent";
import {
  AccountBalanceWallet,
  Info,
  Send,
  WorkOutlineOutlined,
  PinDropOutlined,
} from "@material-ui/icons";
import { NoteDiv } from "../styledComponents/notesStyles";
import { MessageBox } from "../styledComponents/messageStyles";
import TooltipComponent from "../gComponents/gTooltip";
import OrderTransactionPopup from "../components/OrderTransactionReceives";
import OrderDeliveryInfo from "../components/orderDeliveryInfo";
import TaskView from "../gComponents/gTaskView";
import TaskStatusTiming from "../gComponents/gPackageTaskStatusView";
import CancelReasonsView from "../components/orderCancelReasons";
import CancelBox from "../gComponents/gCancelBox";
import GAgentView from "../gComponents/gAssignAgentView";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import { GContent } from "../gComponents/gContent";
import { useParams, useHistory } from "react-router-dom";
import GText from "../gComponents/gText";
import { createSocketConnection } from "../useLaravelPusher";
import styled from "styled-components";
import OrderTrack from "./courierOrderMap";
import Modal from "react-modal";
import OrderTaskPackageInfo from "../components/orderTaskCourierInfo";
import { StringsContext } from "../DataFactory/useStrings";

const TaskViewDiv = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding: 20px 10px;

  &:last-child {
    border: 0;
  }

  & .taskIcon {
    vertical-align: top;
  }
`;

const fixedDivStyle = {
  display: "flex",
  bottom: "5px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "9",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function AtmOrderDetail(props) {
  let { orderId } = useParams();
  let history = useHistory();
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [ocrvisible, setOcrvisible] = useState(false);
  const [odInfovisible, setOdInfovisible] = useState(false);
  const [transModalVisible, setTransModalVisible] = useState(false);
  const [noteVal, setNoteValue] = useState("");
  const [messageVal, setMessageValue] = useState("");
  const [selectedTaskInfo, setSelectedTaskInfo] = useState({});
  const [orderData, setOrderData] = useState({
    customer: {},
    store: {},
    order_products: [],
    delivery: {},
    pickup: {},
    payment_summary: {
      data: [],
    },
    cancelled_log: {},
    order_transactions: {},
    order_fields: {},
  });
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [ordernotes, setOrderNotes] = useState([]);
  const [orderMessages, setOrderMessages] = useState([]);
  const [msgBtnDisabled, setMsgBtnDisabled] = useState(false);
  const [noteBtnDisabled, setNoteBtnDisabled] = useState(false);
  const [orderAgent, setOrderAgent] = useState({});
  const settings = useSettings(true);
  const [selectedDeliveryPackage, setSelectedDeliveryPackage] = useState({});
  const [dPackageInfovisible, setdPackageInfovisible] = useState(false);

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "orders/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderAgent(response.data?.data?.agent || {});

          setOrderData(response.data.data);
          setOrderStatus(response.data.data.status.title);
          setOrderNotes(response.data.data.order_notes);
          setOrderMessages(response.data.data.order_messages);
          setOrderStatuses([
            {
              title: strings.w_pending,
              identifier: "pending",
            },
            {
              title: strings.w_accepted,
              identifier: "accepted",
            },
            {
              title: strings.w_started,
              identifier: "started",
            },
            {
              title: strings.w_completed,
              identifier: "completed",
            },
            {
              title: strings.w_cancelled,
              identifier: "cancelled",
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("order-updated." + orderId).listen(
      "BCOrderUpdated",
      function (e) {
        console.log(e);
        setOrderData({ ...e?.order });
        setOrderStatus(e.order.status.title);
        setOrderNotes(e.order.order_notes);
        setOrderMessages(e.order.order_messages);
      }
    );

    history.push({
      pathname: "/atm-order/" + orderId,
      state: "order",
    });

    getOrderDetail();
    return () => {
      window.Echo.leaveChannel(`order-updated.${orderId}`);
    };
  }, [orderId]);

  useEffect(() => {
    if (orderStatuses && orderStatuses.length > 0 && orderStatus) {
      var keepGoing = true;
      console.log(orderStatuses);
      const listCopy = [...orderStatuses];
      //Logic to update your list here
      console.log(listCopy);

      listCopy.forEach((user, index) => {
        if (keepGoing === true) {
          user["color"] = "colored";
          console.log(user.title + "--" + orderStatus);
          if (user.title === orderStatus) {
            console.log(user.title);
            keepGoing = false;
          }
        } else {
          user["color"] = "";
        }
      });
      setOrderStatusList(listCopy);
    }
  }, [orderStatuses, orderStatus]);

  function updateField(selectedFieldId, newValue) {
    if (selectedFieldId === "notes") {
      setNoteBtnDisabled(true);
    }
    if (selectedFieldId === "messages") {
      setMsgBtnDisabled(true);
    }
    var post_note = {
      order_id: orderId,
      user_type: "4",
      user_id: "1",
      text:
        selectedFieldId === "notes"
          ? noteVal
          : selectedFieldId === "messages"
          ? messageVal
          : newValue,
    };
    ApiService({
      method: "POST",
      route: "order/" + selectedFieldId,
      body: post_note,
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          if (selectedFieldId === "notes") {
            setNoteValue("");
            setOrderNotes([...ordernotes, response.data.data]);
          } else {
            setMessageValue("");
            setOrderMessages([...orderMessages, response.data.data]);
          }
        }
        openSnackbar(response.data.message);
        setNoteBtnDisabled(false);
        setMsgBtnDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCompleteOrder() {
    ApiService({ method: "POST", route: "atm/order/complete/f/" + orderId })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          getOrderDetail();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateOrderStatus(status) {
    ApiService({
      method: "POST",
      route: "atm/order/" + status + "/" + orderId,
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          getOrderDetail();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="mainInnerView" style={{ padding: "20px 30px" }}>
      <h1 style={{ display: "inline-block" }}>
        #{orderId} &nbsp;&nbsp;
        {orderStatus !== "Cancelled" && (
          <a href={"/atm-order-invoice/" + orderId} target="_blank">
            <GButton variant="linkable"> {strings.w_view_inoice}</GButton>
          </a>
        )}
      </h1>

      {orderStatus &&
      (orderStatus === "Pending" ||
        orderStatus === "Accepted" ||
        orderStatus === "Started") ? (
        getUserType() || isPermissionExist("order_update_status") ? (
          <CancelBox
            handleClick={() => setOcrvisible(true)}
            type={orderData.class}
            handleCompleteForcefully={() => handleCompleteOrder()}
          />
        ) : (
          ""
        )
      ) : orderStatus === "Cancelled" ? (
        <CancelBox data={orderData.cancelled_log} />
      ) : (
        ""
      )}

      <CustomSeparator
        statusList={orderStatusList}
        class={orderStatus}
        updateStatus={true}
        onUpdateStatus={(status) => updateOrderStatus(status)}
        orderClass={orderData.class}
      />

      <div
        style={{
          width: "67%",
          display: "inline-block",
          paddingRight: "15px",
          boxSizing: "border-box",
        }}
      >
        {orderData.tasks && orderData.tasks.length > 0 && (
          <>
            <Card>
              <OrderTrack taskData={orderData.tasks} agent={orderAgent} />
            </Card>

            <Card>
              <CardHead>
                {strings.w_tasks} ({orderData.tasks.length})
              </CardHead>
              {orderData.tasks.map((task, i) => {
                return (
                  <TaskViewDiv key={i}>
                    {task.type === "pickup" ? (
                      <WorkOutlineOutlined className="taskIcon" />
                    ) : (
                      <PinDropOutlined className="taskIcon" />
                    )}
                    <div
                      style={{
                        width: "calc(100% - 45px)",
                        display: "inline-block",
                        paddingLeft: "15px",
                      }}
                    >
                      <CardHead
                        style={{ fontSize: "16px", padding: "3px 0 5px" }}
                      >
                        {task.delivery_type === "pickup"
                          ? strings.w_pickup
                          : strings.w_delivery}{" "}
                        {strings.w_task} &nbsp;&nbsp;
                        <span
                          onClick={() => {
                            setSelectedTaskInfo(task);
                            setOdInfovisible(true);
                          }}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "#2d4961",
                            fontSize: "14px",
                          }}
                        >
                          {strings.w_more_info}
                        </span>
                        <span
                          style={{
                            float: "right",
                            fontSize: "14px",
                            textTransform: "uppercase",
                            padding: "4px 8px 2px",
                            background: "#" + task.status_display.color2,
                            color: "#" + task.status_display.color1,
                          }}
                        >
                          {task.status_display?.title}
                        </span>
                      </CardHead>
                      <TaskStatusTiming
                        data={task}
                        type="atm"
                        onStatusUpdate={getOrderDetail}
                        orderStatus={orderStatus}
                      />
                      <div
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <TaskView task={task} orderClass={orderData.class} />
                      </div>
                    </div>
                    {/* <br /> */}
                  </TaskViewDiv>
                );
              })}
            </Card>
          </>
        )}

        {orderData.vehicle_type_title ||
          (orderData.order_fields.length > 0 && (
            <div
              style={{
                width: "50%",
                display: "inline-block",
                boxSizing: "border-box",
                verticalAlign: "top",
              }}
            >
              <Card>
                <GText g4 semi text={strings.w_order_summary} />

                {orderData.vehicle_type_title && (
                  <GContent
                    title={strings.w_vehicle_type}
                    value={orderData.vehicle_type_title}
                  />
                )}
                {orderData.order_fields.length > 0 && (
                  <>
                    {orderData.order_fields.map((p) => {
                      return (
                        <>
                          {p.type == "image" ? (
                            <div style={{ margin: "10px 0" }}>
                              <GText
                                semi
                                text={p.title}
                                style={{ margin: "5px 0" }}
                              />
                              {p.value ? (
                                <img
                                  src={`${ImgUrl("form")}/${p.value}`}
                                  style={{ maxWidth: "100%", height: "100px" }}
                                />
                              ) : (
                                "-"
                              )}
                            </div>
                          ) : p.type == "document" ? (
                            <div style={{ margin: "10px 0" }}>
                              <GText
                                semi
                                text={p.title}
                                style={{ margin: "5px 0" }}
                              />
                              <a
                                target="_blank"
                                href={`${ImgUrl("form")}/${p.value?.path}`}
                                style={{ wordBreak: "break-word" }}
                              >
                                {ImgUrl("form")}/{p.value?.path}
                              </a>
                            </div>
                          ) : (
                            <GContent
                              key={p.id}
                              title={p.title}
                              value={p.value}
                              type="inline"
                            />
                          )}
                        </>
                      );
                    })}
                  </>
                )}

                {orderData.ip_address && (
                  <GContent
                    title={strings.w_ip_address}
                    value={orderData.ip_address}
                  />
                )}
                <br />
              </Card>
            </div>
          ))}
        <div
          style={{
            width: "calc(50% - 20px)",
            display: "inline-block",
            boxSizing: "border-box",
            verticalAlign: "top",
            marginLeft:
              orderData.vehicle_type_title || orderData.order_fields.length > 0
                ? "20px"
                : "0",
          }}
        >
          <Card>
            <GText g4 semi text={strings.w_payment_summary} />

            {orderData.payment_summary.data.length > 0 &&
              orderData.payment_summary.data.map((p) => {
                return (
                  <GTableContent
                    key={p.title}
                    title={p.title}
                    value={settings.currency_symbol + p.value}
                    align="right"
                    lAlign="right"
                    color="#777"
                  />
                );
              })}
            <hr />

            <GTableContent
              title={strings.w_total}
              value={
                settings.currency_symbol +
                (orderData.payment_summary?.total_display || "")
              }
              align="right"
              lAlign="right"
              bold="550"
            />
          </Card>
        </div>
      </div>
      <div
        style={{
          width: "33%",
          display: "inline-block",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ padding: "20px 15px 25px" }}>
          <GText g4 semi text={strings.w_customer_details} />
          <GInfo
            title={orderData.customer?.title}
            key={orderData.customer?.id}
            subtitle={orderData.customer?.phone}
            photo={orderData.customer?.thumb_photo}
            imgType="user"
            link={"/customer/" + orderData.customer?.id + "/orders"}
          />

          <>
            {" "}
            <hr style={{ margin: "18px 0" }} />
            <GText g4 semi text={strings.w_driver_details} />
            <GAgentView
              class={orderData.class}
              orderStatus={orderStatus}
              orderId={orderId}
              taskLog={orderData.assign_log}
              isReAssign={orderStatus === "Pending" ? true : false}
              assign={
                orderData.status?.title != "Completed" &&
                orderData.status?.title != "Cancelled"
                  ? true
                  : false
              }
              isAssign={!orderAgent?.id ? false : true}
              driverData={orderAgent}
              onAssignTask={() => getOrderDetail()}
              deliveryId={orderData?.delivery?.id}
              style={{ marginBottom: 0 }}
            />
          </>

          <hr style={{ margin: "18px 0" }} />

          {orderData.pending_payment > 0 && orderStatus !== "Cancelled" ? (
            getUserType() || isPermissionExist("order_payments") ? (
              <GButton
                variant="linkable"
                onClick={() => setTransModalVisible(true)}
                style={{
                  float: "right",
                  textDecoration: "underline",
                  marginTop: "-3px",
                  color: orderStatus === "Cancelled" ? "#a2a2a2" : "#f23938",
                }}
              >
                {strings.w_receive_payment}
              </GButton>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <GText g4 semi text={strings.w_payment_details} />

          {orderData.order_transactions.length > 0 ? (
            orderData.order_transactions.map((s) => {
              return (
                <div key={s.id} style={{ borderBottom: "1px solid #e2e2e2" }}>
                  <AccountBalanceWallet
                    style={{
                      float: "right",
                      color: "#777",
                    }}
                  />
                  <p
                    style={{
                      textTransform: "capitalize",
                      margin: "15px 0 10px",
                    }}
                  >
                    {settings.currency_symbol +
                      s.amount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                    {s.gateway && (
                      <span style={{ color: "#b2b2b2", fontStyle: "italic" }}>
                        ({s.gateway})
                      </span>
                    )}
                  </p>

                  <span
                    style={{
                      background: "#" + s.background,
                      padding: "2px 5px",
                      fontSize: "14px",
                    }}
                  >
                    {s.status_label}
                  </span>
                  <p
                    style={{
                      color: s.status === "pending" ? "#a2a2a2" : "#333",
                      fontSize: "14px",
                      margin: "12px 0 15px 0",
                    }}
                  >
                    {s.label}
                  </p>
                </div>
              );
            })
          ) : (
            <React.Fragment>
              <p
                style={{
                  margin: "20px 0 5px",
                }}
              >
                {strings.w_pending_amount} -{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0",
                    color: "#777",
                  }}
                >
                  {settings.currency_symbol}
                  {orderData?.pending_payment?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}

                  <span style={{ fontStyle: "italic" }}>
                    ({orderData.gateway})
                  </span>
                </span>
              </p>
              <GText light text={strings.s_no_transaction_yet} />
            </React.Fragment>
          )}
        </Card>
        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              {strings.w_notes} ({ordernotes.length})
            </CardHead>
          </div>
          {ordernotes && ordernotes.length >= 0 ? (
            <div
              style={{
                padding: "5px 15px",
                maxHeight: "340px",
                overflow: "overlay",
                overflow: "auto",
                marginBottom:
                  getUserType() || isPermissionExist("order_notes")
                    ? "40px"
                    : "10px",
              }}
            >
              {ordernotes.map((p) => {
                return (
                  <NoteDiv key={p.id}>
                    <h5>{p.text}</h5>

                    <p>{p.created}</p>
                  </NoteDiv>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {getUserType() || isPermissionExist("order_notes") ? (
            <div style={fixedDivStyle}>
              <input
                type="text"
                value={noteVal}
                id="notes"
                placeholder={strings.w_add_a_note}
                onChange={(e) => setNoteValue(e.target.value)}
                style={formInputStyle}
              />
              <GButton
                variant="linkable"
                disabled={noteBtnDisabled}
                onClick={() => {
                  updateField("notes");
                }}
                style={{
                  borderRadius: "50%",
                  color: "#fff",
                  background: "#f23938",
                  marginTop: "7px",
                  height: "30px",
                  padding: "3px 5px 0 7px",
                }}
              >
                <Send style={{ fontSize: "1.2rem" }} />
              </GButton>
            </div>
          ) : !ordernotes || ordernotes.length <= 0 ? (
            <p style={{ textAlign: "center", color: "#a2a2a2" }}>
              {strings.s_no_notes_added_yet}
            </p>
          ) : (
            ""
          )}
        </Card>

        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
            marginBottom: "50px",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              {strings.w_messages} ({orderMessages.length})
            </CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px 15px",
              maxHeight: "350px",
              overflow: "overlay",
              overflow: "auto",
              marginBottom:
                getUserType() || isPermissionExist("order_chat")
                  ? "40px"
                  : "10px",
            }}
          >
            {orderMessages.map((p) => {
              return (
                <div
                  key={p.id}
                  style={{ textAlign: p.user_type === 4 ? "right" : "left" }}
                >
                  <MessageBox
                    className={p.user_type === 4 ? "send" : "received"}
                  >
                    <h5>{p.text}</h5>

                    <p>
                      {p.user_type === 4 ? "" : p.user_title + ", "}
                      {p.created}
                    </p>
                  </MessageBox>
                </div>
              );
            })}
          </div>
          {getUserType() || isPermissionExist("order_chat") ? (
            <div style={fixedDivStyle}>
              <input
                type="text"
                value={messageVal}
                id="messages"
                placeholder={strings.w_add_a_message}
                onChange={(e) => setMessageValue(e.target.value)}
                style={formInputStyle}
              />
              <GButton
                variant="linkable"
                disabled={msgBtnDisabled}
                onClick={() => {
                  updateField("messages");
                }}
                style={{
                  borderRadius: "50%",
                  color: "#fff",
                  background: "#f23938",
                  marginTop: "7px",
                  height: "30px",
                  padding: "3px 5px 0 7px",
                }}
              >
                <Send style={{ fontSize: "1.2rem" }} />
              </GButton>
            </div>
          ) : !orderMessages || orderMessages.length <= 0 ? (
            <p style={{ textAlign: "center", color: "#a2a2a2" }}>
              {strings.s_no_messages_added_yet}
            </p>
          ) : (
            ""
          )}
        </Card>
      </div>

      {ocrvisible === true && (
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrvisible={setOcrvisible}
          type={orderData.class}
          linkedId={orderId}
          onSubmit={getOrderDetail}
        />
      )}

      {transModalVisible === true && (
        <OrderTransactionPopup
          transModalVisible={transModalVisible}
          setTransModalVisible={setTransModalVisible}
          linkedId={orderId}
          amount={
            settings.currency_symbol +
            orderData.pending_payment.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          }
          pendingAmount={orderData.pending_payment}
          onSubmit={getOrderDetail}
        />
      )}

      {odInfovisible === true && (
        <OrderDeliveryInfo
          odInfovisible={odInfovisible}
          setOdInfovisible={setOdInfovisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
          notes={selectedTaskInfo?.delivery_note}
          images={selectedTaskInfo?.delivery_images}
          signatures={selectedTaskInfo?.delivery_signatures}
        />
      )}

      <Modal
        isOpen={dPackageInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setdPackageInfovisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Task Package Info Modal"
      >
        <OrderTaskPackageInfo
          dPackageInfovisible={dPackageInfovisible}
          setdPackageInfovisible={setdPackageInfovisible}
          data={selectedDeliveryPackage}
          settings={settings}
        />
      </Modal>
    </div>
  );
}
