import React, { useState, useEffect } from "react";
import { ApiService, getUserType, isPermissionExist } from "../services";
import GTabs from "../gComponents/gTabs";
import Orders from "./tasks";
import Drivers from "./drivers";
import styled from "styled-components";

const Div = styled.div`
  margin-right: 0;
  vertical-align: top;
  width: 420px;
  background: #000;
  display: inline-block;
  transition: margin-right 0.5s;
  height: calc(100vh - 65px);
  &.show {
    display: inline-block;
    margin-right: -420px;
  }
`;

const Listview = (props) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const tabRoutes = getUserType()
    ? ["tasks", "drivers"]
    : isPermissionExist("order_index")
    ? isPermissionExist("agent_index")
      ? ["tasks", "drivers"]
      : ["tasks"]
    : isPermissionExist("agent_index")
    ? ["drivers"]
    : [];
  const [typeValue, setTypeValue] = useState(tabRoutes[0]);
  const [ordersData, setOrdersData] = useState({});

  const getordersList = () => {
    var hitUrl = "order/deliveries?status=active&page=1";
    ApiService({ method: "GET", route: hitUrl })
      .then((response) => {
        console.log(response.data);

        response.data.data.data.map((o) => {
          o.showPopover = false;
        });
        setOrdersData({ ...response.data.data } || {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getordersList();
  }, []);
  return (
    <Div className={props.viewTaskList === true ? "show" : ""}>
      <GTabs
        routes={tabRoutes}
        active={typeValue}
        type="single"
        onClick={(newVal) => setTypeValue(newVal)}
        style={{
          background: "#222326",
          border: "1px solid #101010",
          padding: "0 10px",
        }}
        ulStyle={{ height: "39px" }}
        spanStyle={{ padding: "9px 20px", lineHeight: "40px", color: "#ccc" }}
        spanActiveStyle={{ color: "#bbb", borderBottom: "2px solid #999" }}
      ></GTabs>

      <div
        style={{
          background: "#151515",
          minHeight: "calc(100vh - 100px)",
          borderLeft: "1px solid #101010",
        }}
      >
        {typeValue == "tasks" ? (
          <Orders
            onClick={(task, taskJson) => props.onViewRoute(task, taskJson)}
            filters={props.filters}
            settings={props.settings}
            setInitialLoading={setInitialLoading}
            initialLoading={initialLoading}
            taskData={ordersData}
          />
        ) : typeValue == "drivers" ? (
          <Drivers
            onClick={(d) => props.onLocateDriver(d)}
            filters={props.filters}
            initialLoading={initialLoading}
            driversData={props.driversData}
            updatedDriver={props.updatedDriver}
          />
        ) : (
          ""
        )}
      </div>
    </Div>
  );
};

export default Listview;
