import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Edit, Delete, Add } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import GSelectbox from "../gComponents/gSelectbox";
import CancelReasonForm from "../forms/CancelReasonForm";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function Child(props) {
  const [strings] = useContext(StringsContext);
  let { type } = useParams();
  var typeId = type === "customer" ? "1" : type === "manager" ? "2" : "3";

  const classTypes = [
    { title: strings.w_regular, value: "regular" },
    { title: strings.w_courier, value: "courier" },
    { title: strings.w_atm, value: "atm" },
  ];

  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState({});
  const [selectedReasonId, setSelectedReasonId] = useState("");
  const [crvisible, setCrvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [filters, setFilters] = useState({
    reason_type: "cancel",
    class_type: classTypes[0].value || "",
    user_type: typeId || "",
  });
  const {
    loading,
    Placeholder,
    data: cancelReasonsData,
    TableShimmer,
    refreshData,
  } = useDataFctory("cancel_reasons", false, filters);

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 200 },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "center" },
  ];

  useEffect(() => {
    if (initialLoading == false) {
      console.log(filters);
      refreshData({ ...filters, user_type: typeId });
      props.onChange(type);
    }

    setInitialLoading(false);
  }, [type, filters]);

  const deleteReason = () => {
    ApiService({
      method: "DELETE",
      route: "cancel-reasons/" + selectedReasonId,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          let selectedIndex = cancelReasonsData.findIndex(
            (p) => p.id == selectedReasonId
          );
          if (selectedIndex > -1) {
            cancelReasonsData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div
        style={{
          float: "right",
          marginTop: "-125px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <GSelectbox
          placeholder={strings.w_choose_class_type}
          defaultvalue={filters.class_type}
          display="inline-flex"
          data={classTypes}
          id={"class_type"}
          onChange={(val) => {
            console.log(val);
            setFilters({ ...filters, class_type: val || "" });
          }}
          option_key="title"
          option_value="value"
          style={{ marginRight: "10px" }}
          showSelectOption={false}
        />
      </div>
      <GButton
        variant="linkable"
        onClick={() => {
          setSelectedReason({});
          setIsEmpty(true);
          setCrvisible(true);
        }}
        style={{
          margin: "-70px -15px 0px",
          float: "right",
        }}
      >
        <Add fontSize="large" />
      </GButton>

      {loading === true ? (
        <TableShimmer />
      ) : (
        <TableContainer style={{ marginTop: "10px" }}>
          {cancelReasonsData.length === 0 ? (
            <Placeholder />
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cancelReasonsData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{
                          BorderBottom: "1px solid #f4f5f9",
                        }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          children="Add"
                          onClick={() => {
                            setSelectedReason(c);
                            setIsEmpty(false);
                            setCrvisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedReasonId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_cancel_reason_title}
          text={strings.s_delete_cancel_reason_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteReason();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={crvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCrvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Cancel Reason Form Modal"
      >
        <CancelReasonForm
          crvisible={crvisible}
          setCrvisible={setCrvisible}
          type={typeId}
          filters={filters}
          data={selectedReason}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = cancelReasonsData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              cancelReasonsData[selectedIndex] = { ...data };
            } else {
              cancelReasonsData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
