import React from "react";
import styled from "styled-components";
import { Edit, Close } from "@material-ui/icons";

const TagDiv = styled.div`
  background: #cde8ff;
  border-radius: 20px;
  padding: 7px 15px;
  display: inline-flex;
  margin: 5px;
  p {
    font-size: 14px;
    margin: 0px;
    color: #333;
  }
  svg.MuiSvgIcon-root {
    font-size: 12px;
    padding: 1px;
    margin: 1px 0px 0 5px;
    display: none;
    background: #fff;
    color: #d8ae36;
    font-weight: 700;
    cursor: pointer;
    border-radius: 20px;
  }
  &:hover svg.MuiSvgIcon-root {
    display: inline-block;
  }
`;
export default function gTags(props) {
  return (
    <TagDiv>
      <p>{props.data.title}</p>
      {props.onDelete ? <Close onClick={props.onDelete} /> : ""}
    </TagDiv>
  );
}
