import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import { Info } from "@material-ui/icons";
import GEditable from "../gComponents/gEditable";
import GSwitch from "../gComponents/gSwitch";
import TooltipComponent from "../gComponents/gTooltip";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderSettings() {
  const [strings, language] = useContext(StringsContext);
  const [orderSettingsData, setOrderSettingsData] = useState({
    min_order: {},
    free_delivery: {},
    product_stock: {},
    delivery_otp: {},
    max_cash_order: {},
  });
  const [openSnackbar] = useSnackbar();
  const [minOrderSwitch, setMinOrderSwitchVal] = useState();
  const [maxOrderSwitch, setMaxOrderSwitchVal] = useState();
  const [freeDeliverySwitch, setFreeDeliverySwitchVal] = useState();
  const [otpDeliverySwitch, setOtpDeliverySwitchVal] = useState();
  const [stockSwitch, setStockSwitchVal] = useState();

  const getOrderSettings = () => {
    ApiService({ method: "GET", route: "setting/order" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderSettingsData(response.data.data);
          setMinOrderSwitchVal(response.data.data.min_order?.key_value);
          setMaxOrderSwitchVal(response.data.data.max_cash_order?.key_value);
          setFreeDeliverySwitchVal(response.data.data.free_delivery?.key_value);
          setOtpDeliverySwitchVal(response.data.data.delivery_otp?.key_value);
          setStockSwitchVal(response.data.data.product_stock?.key_value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderSettings();
  }, [language]);

  const switchActionValue = (newValue, sId, keyTitle) => {
    if (keyTitle !== "product_stock") {
      if (!(newValue === "1" || newValue === 1)) {
        //setMinOrderVal(newValue);
        updateSetting(newValue, sId, keyTitle);
      }
    } else {
      updateSetting(newValue, sId, keyTitle);
    }
  };

  function updateField(newValue, selectedFieldId, keyTitle) {
    console.log(newValue);
    console.log(selectedFieldId);
    updateSetting(newValue, selectedFieldId, keyTitle);
  }
  function updateSetting(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            orderSettingsData[keyTitle] = response.data.data;
            setOrderSettingsData({ ...orderSettingsData });
          }
        } else {
          getOrderSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_order_settings}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        <Table>
          <TableBody>
            {orderSettingsData.min_order?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {orderSettingsData.min_order.key_display_title}
                  <TooltipComponent
                    title={strings.s_min_order_tooltip}
                    position="bottom"
                    id="min_order"
                  >
                    <Info fontSize="small" />
                  </TooltipComponent>
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.min_order.id}
                    uId={orderSettingsData.min_order.id}
                    currentValue={minOrderSwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setMinOrderSwitchVal(newVal);
                      switchActionValue(newVal, id, "min_order");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {minOrderSwitch != "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={orderSettingsData.min_order.key_value}
                      id={orderSettingsData.min_order.id}
                      onSubmit={(newVal, id) => {
                        orderSettingsData.min_order.key_value = newVal;
                        setOrderSettingsData(orderSettingsData);
                        updateField(newVal, id, "min_order");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}

            {orderSettingsData.free_delivery?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {orderSettingsData.free_delivery.key_display_title}
                  <TooltipComponent
                    title={strings.s_free_delivery_tooltip}
                    position="bottom"
                    id={orderSettingsData.free_delivery.id}
                  >
                    <Info fontSize="small" />
                  </TooltipComponent>
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.free_delivery.id}
                    uId={orderSettingsData.free_delivery.id}
                    currentValue={freeDeliverySwitch !== "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setFreeDeliverySwitchVal(newVal);
                      switchActionValue(newVal, id, "free_delivery");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {freeDeliverySwitch !== "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={orderSettingsData.free_delivery.key_value}
                      id={orderSettingsData.free_delivery.id}
                      onSubmit={(newVal, id) => {
                        orderSettingsData.free_delivery.key_value = newVal;
                        setOrderSettingsData(orderSettingsData);
                        updateField(newVal, id, "free_delivery");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}
            {orderSettingsData.product_stock?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {orderSettingsData.product_stock.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.product_stock.id}
                    uId={orderSettingsData.product_stock.id}
                    currentValue={
                      orderSettingsData.product_stock.key_value !== "0"
                        ? "1"
                        : "0"
                    }
                    onSwitchChange={(newVal, id) => {
                      setStockSwitchVal(newVal);
                      switchActionValue(newVal, id, "product_stock");
                    }}
                  />
                </TableRowData>
                <TableRowData
                  style={{ width: "200px", padding: "0" }}
                ></TableRowData>
              </TableRow>
            )}

            {orderSettingsData.max_cash_order?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {orderSettingsData.max_cash_order.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.max_cash_order.id}
                    uId={orderSettingsData.max_cash_order.id}
                    currentValue={maxOrderSwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setMaxOrderSwitchVal(newVal);
                      switchActionValue(newVal, id, "max_cash_order");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {maxOrderSwitch != "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={orderSettingsData.max_cash_order.key_value}
                      id={orderSettingsData.max_cash_order.id}
                      onSubmit={(newVal, id) => {
                        orderSettingsData.max_cash_order.key_value = newVal;
                        setOrderSettingsData(orderSettingsData);
                        updateField(newVal, id, "max_cash_order");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}

            {orderSettingsData.delivery_otp?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {orderSettingsData.delivery_otp.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.delivery_otp.id}
                    uId={orderSettingsData.delivery_otp.id}
                    currentValue={otpDeliverySwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setOtpDeliverySwitchVal(newVal);
                      updateField(newVal, id, "delivery_otp");
                    }}
                  />
                </TableRowData>
                <TableRowData
                  style={{ width: "200px", padding: "0" }}
                ></TableRowData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
