import React, {useState, useEffect, useRef} from "react";
import {TableContainer} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {ApiService} from "../services";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GSelectbox from "../gComponents/gSelectbox";
import QueryWith from "../Query";
import {StorefrontSharp, Delete} from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import {useSnackbar} from "react-simple-snackbar";
import {Map, HeatMap, GoogleApiWrapper} from "google-maps-react";
import MapStyles from "../mapView/mapStyles.json";

export default function Stores() {
  const [openSnackbar] = useSnackbar();
  const [storesData, setStoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [storePaginationData, setStorePaginationData] = useState("");
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
  });

  const viewOptions = [
    {title: "List", value: "list"},
    {title: "Heatmap", value: "heatmap"},
  ];
  const [view, setView] = useState("list");

  const columns = [
    {id: "id", label: "Date", minWidth: 30},
    {id: "location", label: "Location", minWidth: 40},
    {id: "customer_title", label: "Customer", minWidth: 100},
    {id: "query", label: "Raw Query", minWidth: 100},
    {id: "actions", label: "Actions", minWidth: 60},
  ];

  const getLogs = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/store-logs",
        state: "store",
        search: params[0],
      });
    }
    ApiService({method: "GET", route: "store/searchlog-index", body: filters})
      .then(response => {
        console.log("gh" + response);
        if (response.data.status_code === 1) {
          setStoreData(response.data.data.data);
          setStorePaginationData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const deleteLog = id => {
    ApiService({method: "DELETE", route: `store/searchlog/${id}`})
      .then(response => {
        console.log("gh" + JSON.stringify(response));
        if (response.data.status_code === 1) {
          getLogs();
        }
        openSnackbar(response.data.message);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLogs();
  }, [filters]);

  const handleChangePage = pageUrl => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({...filters, page: NewPageNo});
  };

  return (
    <div className="mainInnerView">
      <h1>
        <StorefrontSharp /> Search Logs (With No Availability)
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <GSelectbox
          showSelectOption={false}
          placeholder="Choose View"
          defaultvalue={view}
          data={viewOptions}
          id="view_options"
          option_key="title"
          option_value="value"
          onChange={v => setView(v)}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />

        {storesData.length > 0 && view == "list" && (
          <React.Fragment>
            <GPagination
              style={{marginLeft: "auto"}}
              count={storePaginationData.total}
              from={storePaginationData.from}
              to={storePaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(storePaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(storePaginationData.prev_page_url)
              }
              rowsPerPage={storePaginationData.per_page}
              prevDisabled={storePaginationData.prev_page_url ? false : true}
              nextDisabled={storePaginationData.next_page_url ? false : true}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={storePaginationData.last_page}
              onChangePage={NewPageNo =>
                setFilters({...filters, page: NewPageNo})
              }
            />
          </React.Fragment>
        )}
      </div>

      {view == "list" ? (
        <div className="listPaper" style={{marginTop: "15px"}}>
          {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (
            <TableContainer>
              {storesData.length === 0 ? (
                <GEmpty></GEmpty>
              ) : (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storesData.map((s, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={s.id}
                        >
                          <TableRowData>{s.created}</TableRowData>
                          <TableRowData>{`${s.latitude},${s.longitude}`}</TableRowData>
                          <TableRowData>
                            <Link
                              to={{
                                pathname:
                                  "/customer/" + s.customer_id + "/orders",
                                state: "customer",
                              }}
                            >
                              {s.customer_title}
                            </Link>
                          </TableRowData>

                          <TableRowData> {s.query} </TableRowData>

                          <TableRowData>
                            <GButton
                              variant="linkable"
                              onClick={() => deleteLog(s.id)}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}
        </div>
      ) : (
        <MapHere />
      )}
    </div>
  );
}

const MapContainer = props => {
  const [storesData, setStoreData] = useState(null);

  const getLogs = () => {
    ApiService({method: "GET", route: "store/searchlog-heatmap"}).then(
      response => {
        if (response.data.status_code === 1) {
          setStoreData(response.data.data);
        }
      }
    );
  };

  useEffect(() => {
    getLogs();
  }, []);

  if (!storesData) {
    return <div style={{marginTop: 20}}>Loading Data..</div>;
  }

  return (
    <Map
      google={props.google}
      className={"map"}
      zoom={2.5}
      initialCenter={{
        lat: process.env.REACT_APP_DEFAULT_LATITUDE,
        lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
      }}
      mapTypeControl={false}
      streetViewControl={false}
      zoomControl={false}
      fullscreenControl={false}
      styles={props.mapStyle}
      style={{
        height: "calc(100vh - 220px)",
        minHeight: "200px",
        position: "relative",
        marginTop: 20,
        marginRight: 40,
        borderRadius: 5,
      }}
    >
      <HeatMap
        positions={storesData.map(s => {
          return {lat: s.latitude, lng: s.longitude};
        })}
        opacity={0.6}
        radius={20}
      />
    </Map>
  );
};

MapContainer.defaultProps = {mapStyle: MapStyles};

const MapHere = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_KEY,
  libraries: ["visualization"],
})(MapContainer);
