import React, { useContext, useEffect } from "react";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GSwitch from "../gComponents/gSwitch";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function TaskActions() {
  const [strings, language] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const {
    loading,
    data: taskActionData,
    TableShimmer,
    refreshData,
  } = useDataFctory("task_actions", false);

  const columns = [
    { id: "actions", label: strings.w_action, minWidth: 200 },
    { id: "enabled", label: strings.w_enabled, minWidth: 150 },
    { id: "required", label: strings.w_required, minWidth: 60 },
  ];
  useEffect(() => {
    refreshData();
  }, [language]);

  const toggleValue = (newValue, valueId, keyType, index) => {
    var hitUrl = "task/actions/" + valueId;
    var obj = {};
    obj[keyType] = newValue;
    ApiService({ method: "PUT", route: hitUrl, body: obj })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          taskActionData[index] = response.data.data;
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_task_actions}
        style={{ marginBottom: "15px" }}
      />
      <div className="listPaper">
        <GText text={strings.s_action_a_driver_can_in_a_task} />
        <hr />
        <TableContainer>
          {loading == true ? (
            <TableShimmer />
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {taskActionData.map((t, ind) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={t.id}>
                      <TableRowData
                        style={{
                          BorderBottom: "1px solid #f4f5f9",
                        }}
                      >
                        {t.title}
                      </TableRowData>
                      <TableRowData>
                        <GSwitch
                          id={"enable" + t.id}
                          uId={t.id}
                          currentValue={t.enable}
                          onSwitchChange={(newValue, valueId, keyType) =>
                            toggleValue(newValue, valueId, keyType, ind)
                          }
                          keyType="enable"
                        />
                      </TableRowData>
                      <TableRowData>
                        {t.enable === 1 ? (
                          <GSwitch
                            id={"requird" + t.id}
                            uId={t.id}
                            currentValue={t.required}
                            keyType="required"
                            onSwitchChange={(newValue, valueId, keyType) =>
                              toggleValue(newValue, valueId, keyType, ind)
                            }
                          />
                        ) : (
                          "-"
                        )}
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  );
}
