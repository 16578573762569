import React from "react";
import styled from "styled-components";
import Loader from "../assets/images/loader.svg";

const Button = styled.button`
  padding: 0.6em 2em;
  font-size: 15px;
  font-weight: 550;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  box-shadow: none;
  outline: 0;

  &.condensed {
    background: ${(props) => (props.primary ? "palevioletred" : "white")};
    color: ${(props) => (props.primary ? "white" : "palevioletred")};
    color: white;
    background: #d8ae36;
    border: 0;
  }

  &[disabled] {
    // color: ${(props) => (props.primary ? "white" : "palevioletred")};
    cursor: not-allowed;
    background: #d2d2d2;
    border: 0px;

    &:hover {
      // color: rgba(0, 0, 0, 0.66);
    }
  }
  &.outlined {
    color: #d8ae36;
    background: transparent;
    border: 1px solid #d8ae36;
  }

  &.linkable {
    color: #d8ae36;
    background: transparent;
    border: 0px;
    padding: 5px;
  }

  &.text {
    background: transparent;
    padding: 6px 12px;
    border: 0px;
    color: #d8ae36;

    &:hover {
      background: rgba(25, 118, 210, 0.04);
    }
  }
`;

function GButton(props) {
  return (
    <Button
      type={props.type || "button"}
      className={props.variant}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled || props.loading}
    >
      {props.children}&nbsp;
      {props.loading && (
        <img
          src={Loader}
          alt="loading"
          style={{ verticalAlign: "middle", float: "right" }}
          width="auto"
          height="auto"
        />
        // "..loading"
      )}
    </Button>
  );
}

export default GButton;
