import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { Search, Close, KeyboardArrowDown, Check } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import useLocalStorage from "../useAsyncStorage";
import useDebounce from "../useDebounce";
import { ReactComponent as CustomerIcon } from "../assets/images/sidebar/customers.svg";
import { StringsContext } from "../DataFactory/useStrings";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 13.5px;
  display: flex;
  cursor: pointer;
  &:hover {
    background: #d8ae36;
    color: #fff;
  }
`;
const DIV = styled.div`
  background: transparent;
  border-radius: 4px;

  &:hover {
    background: #e6e7eb;
  }
  &.active {
    background: #f0d3c8;
    &:hover {
      background: #f0d3c8;
    }
  }
`;

const Input = styled.input`
  width: calc(100% - 45px);
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const CustomerFilter = ({
  cvalue,
  handleCustomerChange,
  handleBlur,
  handleFocus,
  style,
}) => {
  const [strings] = useContext(StringsContext);
  const [mainInitialloading, setMainInitialloading] = useState(true);
  const [searchCusWord, setSearchCusWord] = useState("");
  const debouncedSearchWord = useDebounce(searchCusWord, 500);
  const wrapperCustomerRef = useRef(null);
  const [viewFilterList, setViewFilterList] = useDetectOutsideClick(
    wrapperCustomerRef,
    false
  );
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customersFilterList, setCustomers] = useState([]);
  const [selectedIdValues, setSelectedIdValues] = useState([]);

  useEffect(() => {
    // console.log(cvalue);
    if (mainInitialloading == true) {
      if (cvalue.length == 0) {
        // setSelectedCustomers([])
        setSelectedIdValues([]);
      }
      setMainInitialloading(false);
    }
  }, [cvalue]);

  const getCustomers = () => {
    ApiService({
      method: "GET",
      route: "customers?per_page=30&search=" + searchCusWord,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setCustomers(response.data.data.data);
          //console.log(selectedIdValues);
          let newArray = [];
          response.data.data.data.map((values) => {
            // console.log(cvalue.length);
            // console.log(selectedIdValues.length);
            if (cvalue.length == selectedIdValues.length) {
              if (selectedIdValues.length > 0) {
                handleCustomerChange(selectedIdValues);
                return;
              }
            } else {
              cvalue.map((s) => {
                if (values.id == s) {
                  let findIndx = newArray.findIndex((n) => n.id == s);
                  if (findIndx == -1) {
                    newArray.push(values);
                  }
                }
              });
            }
          });

          if (newArray.length < cvalue.length) {
            cvalue.map((s) => {
              let findInd = newArray.findIndex((n) => n.id != s);
              if (findInd == -1) {
                let findIndxx = newArray.findIndex((n) => n.id == s);
                if (findIndxx == -1) {
                  newArray.push({ id: s });
                }
              }
            });
          }
          setSelectedIdValues(newArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (mainInitialloading == false) {
      ApiService({
        method: "GET",
        route: "customers?per_page=30&search=" + searchCusWord,
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setCustomers(response.data.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [debouncedSearchWord]);

  useEffect(() => {
    if (mainInitialloading == false) {
      let selectedCustomersIds = [];
      console.log(selectedCustomers);
      setSelectedIdValues([...selectedCustomers]);
      if (selectedCustomers.length > 0) {
        selectedCustomers.map((s) => {
          selectedCustomersIds.push(s.id);
        });
      }
      handleCustomerChange(selectedCustomersIds);
    }
  }, [selectedCustomers]);

  return (
    <div
      id="customerFilter"
      ref={wrapperCustomerRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
          maxWidth: "300px",
        },
        style
      )}
    >
      <DIV
        style={{
          borderRadius: "4px",
          minHeight: "30px",
          fontSize: "14px",
          color: "#555",
          boxSizing: "border-box",
          padding: "1px 5px 3px 4px",
          fontWeight: "500",
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
        }}
        onClick={() => {
          setViewFilterList(true);
          setSearchCusWord("");
        }}
        className={cvalue.length > 0 ? "active" : ""}
      >
        <CustomerIcon
          style={{
            fill: "#4D4D4D",
            margin: "0px 8px 0 2px",
            width: "13px",
            height: "13px",
          }}
        />
        {cvalue.length > 0 ? (
          <>
            <span
              style={{
                color: "#555",
                padding: "1px 3px 1px 0",
                fontWeight: "550",
                userSelect: "none",
              }}
            >
              {cvalue.length}{" "}
              {cvalue.length > 1 ? strings.w_customers : strings.w_customer}
            </span>
            <Close
              onClick={() => {
                setSelectedCustomers([]);
                setViewFilterList(false);
              }}
              style={{
                fontSize: "14px",
                marginLeft: "2px",
                marginTop: "1px",
                cursor: "pointer",
              }}
            />
          </>
        ) : (
          <span
            style={{
              color: "#555",
              padding: "1px 3px 1px 0",
              fontWeight: "550",
              userSelect: "none",
            }}
          >
            {strings.w_customers}
          </span>
        )}
      </DIV>
      {viewFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "100%",
            minWidth: "180px",
            padding: "0 2px 5px",
            border: "1px solid rgb(226, 226, 226)",
            marginTop: "5px",
          }}
        >
          <React.Fragment>
            <Input
              cvalue={searchCusWord}
              onChange={(e) => setSearchCusWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder={strings.w_search}
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              height: "220px",
              overflow: "auto",
            }}
          >
            {customersFilterList.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    let findInd = selectedIdValues.findIndex(
                      (s) => s.id == c.id
                    );
                    if (findInd == -1) {
                      selectedIdValues.push(c);
                      setSelectedCustomers([...selectedIdValues]);
                    } else {
                      selectedIdValues.splice(findInd, 1);
                      setSelectedCustomers([...selectedIdValues]);
                    }
                  }}
                >
                  {c.title}
                  {selectedIdValues
                    .filter((s) => s.id == c.id)
                    .map((c) => {
                      return (
                        <Check
                          style={{
                            float: "right",
                            marginLeft: "auto",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      );
                    })}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
