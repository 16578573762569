import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { Search, Close, KeyboardArrowDown } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import useLocalStorage from "../useAsyncStorage";
import useDebounce from "../useDebounce";
import { StringsContext } from "../DataFactory/useStrings";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #d8ae36;
    color: #fff;
  }
`;

const Input = styled.input`
  width: calc(100% - 45px);
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const StoreFilter = ({
  value,
  handleStoreChange,
  handleBlur,
  handleFocus,
  handleClick,
  style,
  isStored,
  innerStyle,
  placeholder,
}) => {
  const [strings] = useContext(StringsContext);
  const [searchWord, setSearchWord] = useState("");
  const debouncedSearchTerm = useDebounce(searchWord, 500);
  const wrapperStoreRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperStoreRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [storesFilterList, setStores] = useState([]);
  const [selectedValue, setSelectedValue] = useLocalStorage(
    "product_store_id",
    value
  );

  const getStores = () => {
    ApiService({
      method: "GET",
      route: "stores?per_page=30&search=" + searchWord,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setStores(response.data.data.data);
          console.log(selectedValue + "--" + isStored);
          response.data.data.data.map((values) => {
            if (values.id === parseInt(value)) {
              setSelectedTitle(values.title);
            }
            if (isStored === true) {
              if (selectedValue) {
                handleStoreChange(selectedValue);
                return;
              }
              if (values.id === parseInt(selectedValue)) {
                setSelectedTitle(values.title);
                return;
              }
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStores();
  }, [debouncedSearchTerm]);

  return (
    <div
      ref={wrapperStoreRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
          // verticalAlign: "middle",
        },
        style
      )}
    >
      <div
        style={Object.assign(
          {
            background: "#fff",
            border: "1px solid rgb(226, 226, 226)",
            height: "40px",
            fontSize: "0.97rem",
            color: "#555",
            boxSizing: "border-box",
            padding: "10px 5px 10px 10px",
            fontWeight: "500",
          },
          innerStyle
        )}
        onClick={() => {
          setShowFilterList(true);
          setSearchWord("");
        }}
      >
        {selectedTitle ? (
          <span
            style={{
              width: "calc(100% - 45px)",
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: "20px",
              verticalAlign: "top",
            }}
          >
            {selectedTitle}
          </span>
        ) : (
          <span style={{ color: "#555" }}>
            {placeholder || strings.w_all_stores}
          </span>
        )}
        <KeyboardArrowDown
          style={{
            float: "right",
            fontSize: "19px",
            color: "#555",
          }}
        />
        {selectedTitle ? (
          <Close
            fontSize="small"
            style={{
              float: "right",
              fontSize: "18px",
              color: "#777",
              cursor: "pointer",
              margin: "0",
            }}
            onClick={() => {
              if (isStored) {
                setSelectedValue("");
              }
              handleStoreChange();
              setSelectedTitle();
              setSearchWord("");
            }}
          />
        ) : (
          ""
        )}
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "100%",
            padding: "0 5px 5px",
            border: "1px solid rgb(226, 226, 226)",
          }}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder={strings.w_search}
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              maxHeight: "220px",
              minHeight: "50px",
              overflow: "auto",
            }}
          >
            {storesFilterList && storesFilterList.length > 0 ? (
              storesFilterList.map((c, i) => {
                return (
                  <LI
                    key={i}
                    onClick={() => {
                      if (isStored) {
                        setSelectedValue(c.id);
                      }
                      setSelectedTitle(c.title);
                      setShowFilterList(false);
                      handleStoreChange(c.id, c);
                      setSearchWord("");
                      if (handleClick) {
                        handleClick(c.id);
                      }
                    }}
                  >
                    {c.title}
                  </LI>
                );
              })
            ) : (
              <p style={{ fontSize: "14px", padding: "0 10px", color: "#777" }}>
                {strings.s_no_store_found}
              </p>
            )}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
