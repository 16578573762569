import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import GEmpty from "../gComponents/gEmpty";
import { StringsContext } from "../DataFactory/useStrings";

export default function AssignRoleForm(props) {
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [strings] = useContext(StringsContext);

  const initialValues = {
    admin_id: props.staffData.id,
    role_id: props.staffData?.role?.id || "",
  };

  useEffect(() => {
    ApiService({ method: "GET", route: "roles" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setRolesData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    ApiService({ method: "PUT", route: "role/assign", body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code == 1) {
          props.setAssignRoleVisible(false);
          props.onSubmit(response.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    role_id: Yup.string().required(strings.w_required),
  });

  if (props.assignRoleVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{strings.w_assign_role}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {rolesData.length > 0 ? (
                <>
                  <FormikControl
                    control="radio"
                    label={strings.w_choose_role}
                    name="role_id"
                    options={rolesData}
                    key_title="name"
                    key_value="id"
                  />
                  <br />
                  <GButton
                    variant="condensed"
                    disabled={isDisabled}
                    children={strings.w_assign}
                    type="submit"
                    style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
                  />
                </>
              ) : (
                // <GEmpty subtitle={"No roles have been added yet"} />
                <GText
                  med
                  text={strings.s_no_roles_added_yet}
                  style={{ padding: "10px 15px" }}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
