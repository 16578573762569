import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import DriverCommissionCatForm from "../forms/driverCommissionCatForm";
import { StringsContext } from "../DataFactory/useStrings";

export default function DriverCommissionCategories() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState({});
  const [selectedEventId, setSelectedEventId] = useState("");
  const [cCatVisible, setCcatVisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const {
    loading,
    data: commissionCategoriesData,
    Placeholder,
    TableShimmer,
  } = useDataFctory("driver_commission_categories", false);

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 150 },
    {
      id: "percentage",
      label: strings.w_percentage,
      minWidth: 100,
      align: "center",
    },
    {
      id: "min_weekly_rating",
      label: strings.w_min_weekly_rating,
      minWidth: 100,
      align: "center",
    },
    {
      id: "min_weekly_orders",
      label: strings.w_min_weekly_orders,
      minWidth: 100,
      align: "center",
    },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "center" },
  ];

  const deleteEvent = () => {
    ApiService({
      method: "DELETE",
      route: "driver-commission-categories/" + selectedEventId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = commissionCategoriesData.findIndex(
            (p) => p.id == selectedEventId
          );
          if (selectedIndex > -1) {
            commissionCategoriesData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedEventData({});
          setIsEmpty(true);
          setCcatVisible(true);
        }}
        style={{
          float: "right",
        }}
      />
      <GText
        g2
        bold
        text={strings.w_driver + " " + strings.w_commission_categories}
        style={{ marginBottom: "15px" }}
      />
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer />
        ) : commissionCategoriesData.length === 0 ? (
          <Placeholder />
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {commissionCategoriesData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{ BorderBottom: "1px solid #f4f5f9" }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.percentage}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.min_weekly_rating}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.min_weekly_orders}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedEventData(c);
                            setIsEmpty(false);
                            setCcatVisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedEventId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_commission_category_title}
          text={strings.s_delete_commission_category_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteEvent();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={cCatVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcatVisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Commission Category Form Modal"
      >
        <DriverCommissionCatForm
          cCatVisible={cCatVisible}
          setCcatVisible={setCcatVisible}
          data={selectedEventData}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = commissionCategoriesData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              commissionCategoriesData[selectedIndex] = { ...data };
            } else {
              commissionCategoriesData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
