import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function EventForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    date: !props.isEmpty ? new Date(props.data.date) : null,
  };

  const handleChange = (d) => {
    // var date = new Date(d).getDate(); //Current Date
    var date = (new Date(d).getDate() < 10 ? "0" : "") + new Date(d).getDate();
    // var month = new Date(d).getMonth() + 1; //Current Month
    var month =
      (new Date(d).getMonth() + 1 < 10 ? "0" : "") +
      (new Date(d).getMonth() + 1);
    var year = new Date(d).getFullYear(); //Current Year
    console.log(year + "-" + month + "-" + date);
    return year + "-" + month + "-" + date;
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    values.date = handleChange(values.date);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "events/" + props.data.id : "events";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setCevisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    date: Yup.string().nullable().required(strings.w_required),
  });

  if (props.cevisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_event : strings.w_create_event}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              type="text"
              label={strings.w_title}
              name="title"
            />

            <FormikControl
              control="date"
              label={strings.w_date}
              name="date"
              format="yyyy-MM-dd"
              onKeyDown={(e) => e.preventDefault()}
            />
            <br />

            <GButton
              type="submit"
              disabled={isDisabled}
              variant="condensed"
              children={!props.isEmpty ? strings.w_update : strings.w_create}
              style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
            />
          </Form>
        </Formik>
      </div>
    </>
  );
}
