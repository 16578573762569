import React, { useContext } from "react";
import { StringsContext } from "../DataFactory/useStrings";

const GNoFound = () => {
  const [strings] = useContext(StringsContext);
  return (
    <div>
      <h1
        style={{
          fontSize: "40px",
          color: "#a2a2a2",
          display: "flex",
          height: "calc(100vh - 250px)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {strings.w_not_found}
      </h1>
    </div>
  );
};

export default GNoFound;
