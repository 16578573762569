import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import styled from "styled-components";

const Div = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 5px 10px;
  label {
    font-size: 12px;
    margin: 0;
    padding: 0 0 5px;
    color: #aaa;
  }
  textarea {
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: inherit;
    font-weight: 400;
  }
`;

function formikTextarea(props) {
  const { label, name, ...rest } = props;
  return (
    <div className="form-control">
      <Div>
        <label htmlFor={name} style={props.labelStyle}>
          {label}
        </label>
        <Field as="textarea" id={name} name={name} {...rest} />
      </Div>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default formikTextarea;
