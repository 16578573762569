import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import styled from "styled-components";

const Div = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 5px 10px;
  label {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #aaa;
  }
  input[type="text"] {
    border: 0;
    margin: 0;
    padding: 0;
    height: 26px;
    font-size: 14px;
    font-family: inherit;
    font-weight: 400;
    color: #000;
  }
`;

function formikInput(props) {
  const { label, name, spanLabel, ...rest } = props;
  return (
    <div className="form-control" style={props.divstyle}>
      <Div style={props.innerDivStyle}>
        {label ? (
          <label htmlFor={name} style={props.labelStyle}>
            {label}
            {spanLabel && (
              <span style={{ fontSize: "13px", color: "#aaa" }}>
                {spanLabel}
              </span>
            )}
          </label>
        ) : (
          ""
        )}
        <div style={{ display: "flex" }}>
          <Field id={name} name={name} {...rest} />
          <span style={{ color: "#ccc" }}>
            {props.inputRightSymbol ? props.inputRightSymbol : ""}
          </span>
        </div>
      </Div>

      {props.errorstyle ? (
        <div style={props.errorstyle}>
          <ErrorMessage name={name} component={TextErrors} />
        </div>
      ) : (
        <ErrorMessage name={name} component={TextErrors} />
      )}
    </div>
  );
}

export default formikInput;
