import React, { useContext, useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function AtmOptionForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    photo: !props.isEmpty ? props.data.photo : "",
    amount: !props.isEmpty ? props.data.amount : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "atm-options/" + props.data.id
      : "atm-options";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setAtmOpVisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required(strings.w_required),
  });

  if (props.atmOpVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_update_atm_option
          : strings.w_create_atm_option}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  values.photo = newValue;
                }}
                id="atm_photo"
                image={values.photo ? ImgUrl("atm") + "/" + values.photo : ""}
                type="atm"
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{ margin: "0 auto", display: "block" }}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_amount}
                name="amount"
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("amount", event.target.value);
                  }
                }}
              />

              <br />
              <GButton
                type="submit"
                variant="condensed"
                disabled={isBtnDisabled}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
