import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GText from "./gText";

const ListCard = styled.div`
  padding: 0.9em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  background: #fff;
  vertical-align: top;
  margin: 10px 0;
  height: auto;
  overflow: overlay;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  p {
    margin: 0px;
  }
`;

export default function GAddressList(props) {
  const [addressData, setAddressData] = useState({});
  useEffect(() => {
    setAddressData(props.addressData);
  }, [props]);

  return (
    <React.Fragment>
      <ListCard>
        {props.default === true ? (
          <div
            className="radio"
            style={{
              cursor: "pointer",
              display: "inline-block",
              width: "35px",
              verticalAlign: "top",
            }}
            onClick={() => {
              props.onSelectDefault(addressData);
            }}
          >
            <label>
              <input
                type="radio"
                value="cash"
                checked={addressData.id === props.defaultAddress?.id}
                style={{
                  cursor: "pointer",
                  height: "17px",
                  width: "17px",
                }}
              />
            </label>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            display: props.default ? "inline-block" : "block",
            width: props.default ? "calc(100% - 35px)" : "100%",
          }}
        >
          <GText
            g4
            text={addressData.title}
            style={{ textTransform: "uppercase",fontSize:'16px' }}
          />
          <div
            style={{
              color: "#555",
              fontWeight: "400",
              display: "inline-flex",
              width: "100%",
            }}
          >
            <GText
              med
              text={addressData.formatted}
              style={{
                color: "#777",
                textOverflow: "ellipsis",
                overflow: "hidden",
                webkitBoxOrient: "vertical",
                display: "-webkit-box",
                webkitLineClamp: "4",
              }}
            />
          </div>
        </div>
      </ListCard>
    </React.Fragment>
  );
}
