import React, { useEffect, useState, useContext } from "react";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderTimeslots(props) {
  const [strings] = useContext(StringsContext);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate || "");
  const { data: timeslotsList } = useDataFctory("order/timeslots", false, {
    from: props.fromDate,
  });

  useEffect(() => {
    setSelectedDate(props.selectedDate || timeslotsList[0]?.display);
  }, [timeslotsList]);

  if (props.otslotsvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_choose_timeslot}</div>
      <div className="modal-body">
        <div
          style={{
            width: "140px",
            display: "inline-block",
            padding: "5px 15px 5px 5px",
            borderRight: "1px solid #e2e2e2",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
        >
          {timeslotsList &&
            timeslotsList.map((slots, i) => {
              return (
                <GText
                  key={i}
                  med
                  text={slots.display}
                  onClick={() => setSelectedDate(slots.display)}
                  style={{
                    margin: "15px 0",
                    textAlign: "center",
                    border:
                      slots.display === selectedDate
                        ? "1px solid #D8AE36"
                        : "1px solid #e2e2e2",
                    borderRadius: "20px",
                    padding: "7px 12px",
                    width: "auto",
                    cursor: "pointer",
                    background:
                      slots.display === selectedDate
                        ? "#D8AE36"
                        : "transparent",
                    color: slots.display === selectedDate ? "#fff" : "#777",
                  }}
                />
              );
            })}
        </div>

        <div
          style={{
            width: "calc(100% - 140px)",
            display: "inline-block",
            boxSizing: "border-box",
            padding: "5px",
            verticalAlign: "top",
          }}
        >
          {timeslotsList.map((slots, i) => {
            return (
              <React.Fragment key={i}>
                {slots.display === selectedDate ? (
                  <div>
                    {slots.slots.map((t, ind) => {
                      return (
                        <GText
                          med
                          text={t.from_time + " - " + t.to_time}
                          key={ind}
                          onClick={() => {
                            props.onChooseTimeslot(slots, t);
                            props.setOTslotsVisible(false);
                          }}
                          style={{
                            width: "auto",
                            display: "inline-block",
                            cursor: "pointer",
                            background: "#f4f5f9",
                            padding: "10px",
                            margin: "8px",
                          }}
                        />
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
}
