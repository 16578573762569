import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { Delete, CheckCircle } from "@material-ui/icons";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import useSettings from "../useSettings";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

const lArray = [
  {
    title: "English",
    value: "en",
  },
  {
    title: "Spanish",
    value: "es",
  },
];

export default function LocalizationForm(props) {
  const [strings] = useContext(StringsContext);
  const settings = useSettings(true);
  const [selectionStart, setSelectionStart] = useState(0);
  const [openSnackbar] = useSnackbar();
  let localeArray = settings?.locales || lArray || [];
  const initialValues = {
    options:
      props.data.length > 0 ? props.data : [{ key: "", locale: "", value: "" }],
  };

  const onSubmit = (values) => {
    console.log(values);
    props.onSubmit(values.options);
    props.setLocalizationVisible(false);
  };

  const validationSchema = Yup.object({
    options: Yup.array()
      .of(
        Yup.object({
          key: Yup.string().required(strings.w_required), // these constraints take precedence
          locale: Yup.string().required(strings.w_required), // these constraints take precedence
          value: Yup.string().required(strings.w_required),
        })
      )
      .required(strings.w_required) // these constraints are shown if and only if inner constraints are satisfied
      .min(1, strings.s_minimum_of_1_option),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setLocalizationVisible(false);
  };

  if (props.localizationVisible === false) {
    return null;
  }

  return (
    <>
      <div className="modal-header">{strings.w_manage_localizations}</div>
      <div className="modal-body" style={{ padding: "10px 0" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, resetForm }) => (
            <Form>
              <div className="form-control" style={{ padding: 0 }}>
                <FieldArray name="options">
                  {(fieldArrayProps) => {
                    // console.log(fieldArrayProps);
                    const { push, remove, form } = fieldArrayProps;
                    const { values, setFieldValue } = form;
                    const { options } = values;

                    const handleSelectChange = (newVal, fieldName, ind) => {
                      if (!newVal) {
                        return;
                      }
                      let cursorPos2 = selectionStart;
                      var v2 = options[ind][fieldName];
                      var var2Val = "**" + newVal + "**";
                      var textBefore2 = v2.substring(0, cursorPos2);
                      var textAfter2 = v2.substring(cursorPos2, v2.length);
                      setFieldValue(
                        `options[${ind}][${fieldName}]`,
                        textBefore2 + var2Val + textAfter2
                      );
                    };

                    const handleDelete = (ind) => {
                      ApiService({
                        method: "DELETE",
                        route: "localizations/" + values.options[ind].id,
                      }).then((response) => {
                        console.log(response.data.status_code);
                        if (response.data.status_code === 1) {
                          props.onLocalizationRemove(ind);
                          // remove(ind);
                        }
                        openSnackbar(response.data.message);
                        return;
                      });
                    };

                    const handleUpdate = (ind) => {
                      ApiService({
                        method: "PUT",
                        route: "localizations/" + values.options[ind].id,
                        body: values.options[ind],
                      }).then((response) => {
                        console.log("r" + response.data.status_code);
                        if (response.data.status_code === 1) {
                          props.onLocalizationUpdate(ind, response.data.data);
                        }
                        openSnackbar(response.data.message);
                        return;
                      });
                    };

                    return (
                      <>
                        <div
                          style={{
                            maxHeight: "calc(100vh - 180px)",
                            overflow: "overlay",
                            padding: "0 30px",
                          }}
                        >
                          {options.map((o, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  margin: "12px 0",
                                  // padding: "10px 0 ",
                                  display: "flex",
                                  alignItems: "start",
                                  gap: "10px",
                                }}
                                className="arrayFields"
                              >
                                <FormikControl
                                  control="select"
                                  as="select"
                                  label={strings.w_key}
                                  name={`options[${index}].key`}
                                  options={props.keyArray || []}
                                  key_title="title"
                                  key_value="value"
                                  placeholder="Choose"
                                  disabled={options[index].id ? true : false}
                                  divstyle={{
                                    padding: "0",
                                    verticalAlign: "top",
                                    flex: 1,
                                  }}
                                  innerDivStyle={{
                                    boxSizing: "border-box",
                                    border: "1px solid #e2e2e2",
                                    borderRadius: "4px",
                                    padding: "2px 4px",
                                    background: options[index].id
                                      ? "#f4f5f9"
                                      : "transparent",
                                  }}
                                  style={{
                                    height: "auto",
                                    border: 0,
                                    padding: "2px 0",
                                    color: "#000",
                                    background: "transparent",
                                  }}
                                  labelStyle={{
                                    fontSize: "13px",
                                    margin: 0,
                                    padding: "0 0 0 5px",
                                    color: "#a2a2a2",
                                  }}
                                />
                                <FormikControl
                                  control="select"
                                  as="select"
                                  label={strings.w_locale}
                                  placeholder={strings.w_choose}
                                  name={`options[${index}].locale`}
                                  disabled={options[index].id ? true : false}
                                  options={localeArray}
                                  key_title="title"
                                  key_value="value"
                                  divstyle={{
                                    padding: "0",
                                    verticalAlign: "top",
                                    flex: 1,
                                  }}
                                  innerDivStyle={{
                                    boxSizing: "border-box",
                                    border: "1px solid #e2e2e2",
                                    borderRadius: "4px",
                                    padding: "2px 4px",
                                    background: options[index].id
                                      ? "#f4f5f9"
                                      : "transparent",
                                  }}
                                  style={{
                                    height: "auto",
                                    border: 0,
                                    padding: "2px 0",
                                    color: "#000",
                                    background: "transparent",
                                  }}
                                  labelStyle={{
                                    fontSize: "13px",
                                    margin: 0,
                                    padding: "0 0 0 5px",
                                    color: "#a2a2a2",
                                  }}
                                />

                                {props.variables.length > 0 ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      flex: 3,
                                    }}
                                  >
                                    <FormikControl
                                      control="select"
                                      as="select"
                                      name="variable"
                                      options={props.variables}
                                      placeholder={strings.w_insert_variable}
                                      key_title="title"
                                      key_value="identifier"
                                      onChange={(e) =>
                                        handleSelectChange(
                                          e.target.value,
                                          "value",
                                          index
                                        )
                                      }
                                      style={{
                                        width: "auto",
                                        border: 0,
                                        background: "transparent",
                                        padding: 0,
                                        height: "auto",
                                        fontSize: "13px",
                                        color: "#999",
                                      }}
                                      divstyle={{
                                        margin: 0,
                                        padding: "0",
                                        flex: "none",
                                        position: "absolute",
                                        right: 0,
                                        top: "-1px",
                                      }}
                                    />

                                    <FormikControl
                                      control="input"
                                      as="textarea"
                                      type="text"
                                      label={strings.w_value}
                                      rows={3}
                                      name={`options[${index}].value`}
                                      onBlur={(e) =>
                                        setSelectionStart(
                                          e.target.selectionStart
                                        )
                                      }
                                      divstyle={{
                                        padding: "0",
                                        verticalAlign: "top",
                                        flex: 1,
                                      }}
                                      innerDivStyle={{
                                        boxSizing: "border-box",
                                        border: "1px solid #e2e2e2",
                                        borderRadius: "4px",
                                        padding: "2px 4px",
                                        background: "transparent",
                                      }}
                                      style={{
                                        height: "auto",
                                        border: 0,
                                        padding: "2px 5px 0",
                                        margin: 0,
                                        color: "#000",
                                        background: "transparent",
                                        fontFamily: "sans-serif",
                                        fontSize: "14px",
                                      }}
                                      labelStyle={{
                                        fontSize: "13px",
                                        margin: 0,
                                        padding: "0 0 0 5px",
                                        color: "#a2a2a2",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <FormikControl
                                    control="input"
                                    as="textarea"
                                    type="text"
                                    label={strings.w_value}
                                    rows={2}
                                    name={`options[${index}].value`}
                                    divstyle={{
                                      padding: "0",
                                      verticalAlign: "top",
                                      flex: 2,
                                    }}
                                    innerDivStyle={{
                                      boxSizing: "border-box",
                                      border: "1px solid #e2e2e2",
                                      borderRadius: "4px",
                                      padding: "2px 4px",
                                      background: "transparent",
                                    }}
                                    style={{
                                      height: "auto",
                                      border: 0,
                                      padding: "2px 5px 0",
                                      margin: 0,
                                      color: "#000",
                                      background: "transparent",
                                      fontFamily: "sans-serif",
                                      fontSize: "14px",
                                    }}
                                    labelStyle={{
                                      fontSize: "13px",
                                      margin: 0,
                                      padding: "0 0 0 5px",
                                      color: "#a2a2a2",
                                    }}
                                  />
                                )}

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    padding: "2px 0 0",
                                    width: "77px",
                                  }}
                                >
                                  {o.id ? (
                                    <>
                                      <GButton
                                        variant="linkable"
                                        type="button"
                                        onClick={() => handleDelete(index)}
                                        style={{ alignSelf: "stretch" }}
                                      >
                                        <Delete />
                                      </GButton>
                                      <GButton
                                        variant="linkable"
                                        type="button"
                                        onClick={() => handleUpdate(index)}
                                        style={{ alignSelf: "stretch" }}
                                      >
                                        <CheckCircle />
                                      </GButton>
                                    </>
                                  ) : (
                                    <>
                                      <GButton
                                        variant="linkable"
                                        type="button"
                                        style={{ alignSelf: "stretch" }}
                                        onClick={(e) => {
                                          if (options.length >= 1) {
                                            remove(index);
                                          }
                                        }}
                                      >
                                        <Delete />
                                      </GButton>
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <GButton
                          type="button"
                          variant="linkable"
                          onClick={(e) => {
                            push({ key: "", locale: "", value: "" });
                          }}
                          style={{ margin: "8px 25px 0" }}
                        >
                          {strings.w_add_localization}
                        </GButton>
                      </>
                    );
                  }}
                </FieldArray>
              </div>

              <GButton
                type="submit"
                disabled={
                  values.options && values.options.length <= 0 ? true : false
                }
                variant="condensed"
                children={strings.w_submit}
                style={{ margin: "10px 25px", width: "calc(100% - 50px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
