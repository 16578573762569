import React, { useState, useEffect } from "react";
import { ApiService, ApiLoginService } from "../services";
import { StoreFilter } from "../components/storeFilterComponent";
import { ProductFilter } from "../components/productCustomerFilterComponent";
import GEmpty from "../gComponents/gEmpty";
import GInfo from "../gComponents/gInfo";
import useSettings from "../useSettings";
import { Card, CardHead } from "../styledComponents/cardStyles";
import GItemTable from "../gComponents/gCartItems";
import { GTableContent } from "../gComponents/gContent";
import useCart from "../useCart";
import OrderProductVariants from "../components/orderProductVariants";
import CheckoutForm from "./checkout";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";

export default function Orders(props) {
  const [openSnackbar] = useSnackbar();
  const [itemsData, setItemsData] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const {
    cart,
    setCart,
    cartStore,
    setCartStore,
    addProduct,
    removeProduct,
    decreaseQuantity,
  } = useCart();
  const [newOrderData, setNewOrderData] = useState({
    products: [],
    summary: {
      data: [],
    },
  });
  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const settings = useSettings(true);

  useEffect(() => {
    const getCartProducts = () => {
      ApiLoginService("POST", "c/order/cart", {
        store_id: cartStore,
        products: cart,
      }).then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setNewOrderData(response.data);
        } else {
          setNewOrderData(response.data);
          openSnackbar(response.message);
        }
      });
    };
    if (cartStore) {
      getCartProducts();
    }
  }, [cart]);

  const orderPlaced = (formData, customerId) => {
    const placedData = {
      store_id: selectedStore.id,
      customer_id: customerId,
      products: newOrderData.products,
      order_type: formData.order_type,
      pickup_address: formData?.pickup_address,
      delivery_address: formData.delivery_address,
      delivery_type: formData.delivery_type,
      pickup_from: formData?.pickup_from,
      pickup_to: formData?.pickup_to,
      delivery_from: formData.delivery_from,
      delivery_to: formData.delivery_to,
      instructions: formData.instructions,
      coupon: "",
      points: false,
      gateway: { identifier: "cash" },
    };
    console.log(placedData);
    ApiService({ method: "POST", route: "orders", body: placedData }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          window.location = "/order/" + response.data.data.id;
        }
        openSnackbar(response.message);
        return;
      }
    );
  };

  return (
    <div className="mainInnerView">
      <h1>Create Order</h1>

      <StoreFilter
        value={selectedStore ? selectedStore.id : ""}
        style={{
          marginBottom: "10px",
          position: "relative",
        }}
        handleStoreChange={(id, value) => {
          console.log(value);
          if (newOrderData && newOrderData.products.length > 0) {
            setNewOrderData({ products: [] });
            setSelectedProduct({});
          }
          setSelectedStore(value ? value : "");
          // setCartStore(value ? value : "");
        }}
      />

      {!selectedStore ? (
        <div className="listPaper" style={{ marginTop: "10px" }}>
          <GEmpty subtitle="Choose store to get Products"></GEmpty>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "65%",
              boxSizing: "border-box",
              paddingRight: "10px",
            }}
          >
            <Card>
              <CardHead>Store Details</CardHead>
              <GInfo
                title={selectedStore.title}
                subtitle={selectedStore.address}
                subtitle2={selectedStore.phone}
                photo={selectedStore.thumb_photo}
                imgType="store"
              />
            </Card>

            <Card>
              <CardHead>Search Items</CardHead>
              <ProductFilter
                value={selectedProduct ? selectedProduct.id : ""}
                style={{
                  marginBottom: "10px",
                }}
                storeId={selectedStore?.id}
                status={"1"}
                handleProductChange={(id, value) => {
                  setSelectedProduct(value ? value : "");
                  setItemsData([...itemsData, value]);
                  if (!value) {
                    return;
                  }
                  if (value.variants_available === false) {
                    value.variants = [];
                    addProduct(value);
                  } else {
                    setSelectedProduct(value);
                    setOrderVarVisible(true);
                  }
                }}
              />

              {cart && newOrderData ? (
                newOrderData?.products?.length > 0 ? (
                  <React.Fragment>
                    <br />
                    <br />
                    <CardHead>Items ({cart.length})</CardHead>
                    <GItemTable
                      items={cart}
                      currencySymbol={settings.currency_symbol}
                      editable={true}
                      onRemove={(item) => removeProduct(item)}
                      onIncQuantity={(item) => addProduct(item, item.variants)}
                      onDecQuantity={(item) => decreaseQuantity(item)}
                    />
                    {/* <ul>
                    {cart.map((c, i) => {
                      return (
                        <li>
                          <GInfo
                            title={c.title}
                            subtitle={c.price}
                            photo={c.thumb_photo}
                            imgType="item"
                          />
                        </li>
                      );
                    })}
                  </ul> */}

                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                      <div style={{ width: "250px", display: "inline-block" }}>
                        {newOrderData?.summary?.data.map((p) => {
                          return (
                            <GTableContent
                              key={p.title}
                              title={p.title}
                              value={settings.currency_symbol + "" + p.value}
                              align="right"
                              lAlign="right"
                              color="#777"
                            />
                          );
                        })}{" "}
                        <hr />
                        <GTableContent
                          title="Total"
                          value={
                            settings.currency_symbol +
                            "" +
                            newOrderData?.summary?.total_display
                          }
                          align="right"
                          lAlign="right"
                          bold="550"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <GEmpty
                    title="Your Cart is empty"
                    subtitle="Search items to add in cart"
                  />
                )
              ) : (
                ""
              )}
            </Card>
          </div>
          <div
            style={{
              width: "35%",
              boxSizing: "border-box",
              paddingLeft: "10px",
            }}
          >
            <Card>
              <CardHead>Checkout Form</CardHead>

              <CheckoutForm
                store={selectedStore}
                settings={settings}
                onOrderPlace={(formData, customerId) =>
                  orderPlaced(formData, customerId)
                }
              />
            </Card>
          </div>
        </div>
      )}

      <Modal
        isOpen={orderVarVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order Variants Form Modal"
      >
        <OrderProductVariants
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(items, itemVariants) => {
            console.log(itemVariants);
            addProduct(items, itemVariants);
          }}
        />
      </Modal>
    </div>
  );
}
