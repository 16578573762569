import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import { Info } from "@material-ui/icons";
import GEditable from "../gComponents/gEditable";
import GSwitch from "../gComponents/gSwitch";
import TooltipComponent from "../gComponents/gTooltip";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function DriverBasicSettings() {
  const [strings, language] = useContext(StringsContext);
  const [driverSettingsData, setDriverSettingsData] = useState({
    default_driver_commission: {},
  });
  const [openSnackbar] = useSnackbar();
  const [defaultCommissionSwitch, setDefaultCommissionSwitchVal] = useState();

  const getOrderSettings = () => {
    ApiService({ method: "GET", route: "setting/driver" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDriverSettingsData(response.data.data);
          setDefaultCommissionSwitchVal(
            response.data.data.default_driver_commission?.key_value
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderSettings();
  }, [language]);

  const switchActionValue = (newValue, sId, keyTitle) => {
    if (!(newValue === "1" || newValue === 1)) {
      //setMinOrderVal(newValue);
      updateSetting(newValue, sId, keyTitle);
    }
  };

  function updateField(newValue, selectedFieldId, keyTitle) {
    console.log(newValue);
    console.log(selectedFieldId);
    updateSetting(newValue, selectedFieldId, keyTitle);
  }
  function updateSetting(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            driverSettingsData[keyTitle] = response.data.data;
            setDriverSettingsData({ ...driverSettingsData });
          }
        } else {
          getOrderSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_driver_settings}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        <Table>
          <TableBody>
            {driverSettingsData.default_driver_commission?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {
                    driverSettingsData.default_driver_commission
                      .key_display_title
                  }
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={driverSettingsData.default_driver_commission.id}
                    uId={driverSettingsData.default_driver_commission.id}
                    currentValue={defaultCommissionSwitch !== "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setDefaultCommissionSwitchVal(newVal);
                      switchActionValue(
                        newVal,
                        id,
                        "default_driver_commission"
                      );
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {defaultCommissionSwitch !== "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={
                        driverSettingsData.default_driver_commission.key_value
                      }
                      id={driverSettingsData.default_driver_commission.id}
                      onSubmit={(newVal, id) => {
                        driverSettingsData.default_driver_commission.key_value =
                          newVal;
                        setDriverSettingsData(driverSettingsData);
                        updateField(newVal, id, "default_driver_commission");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
