import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function FeedbackTemplateForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    star: !props.isEmpty ? props.data.star : "",
    type: !props.isEmpty ? props.data.type : "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "feedback-templates/" + props.data.id
      : "feedback-templates";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setFeedbackTempVisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    star: Yup.string().required(strings.w_required),
    type: Yup.string().required(strings.w_required),
  });

  if (props.feedbackTempVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_feedback_template
          : strings.w_create_feedback_template}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                as="textarea"
                type="text"
                label={strings.w_title}
                name="title"
                rows="2"
              />
              <FormikControl
                control="select"
                as="select"
                label={strings.w_star}
                name="star"
                options={[
                  { title: 1, value: 1 },
                  { title: 2, value: 2 },
                  { title: 3, value: 3 },
                  { title: 4, value: 4 },
                  { title: 5, value: 5 },
                ]}
                key_title="title"
                key_value="value"
              />
              <FormikControl
                control="select"
                as="select"
                label={strings.w_type}
                name="type"
                options={[
                  { title: strings.w_store, value: "store" },
                  { title: strings.w_driver, value: "driver" },
                ]}
                key_title="title"
                key_value="value"
              />
              <br />
              <GButton
                variant="condensed"
                disabled={isDisabled}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
