import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import GButton from "../gComponents/gButton";
import GHtmlEditor from "../gComponents/gHtmlEditor";
import { StringsContext } from "../DataFactory/useStrings";

export default function AutomatedPromotionForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    weekday: props.weekday,
    title: !props.isEmpty ? props.data.title : "",
    time: !props.isEmpty ? props.data.time : "",
    inactive_days: !props.isEmpty ? props.data.inactive_days : "",
    content: !props.isEmpty ? props.data.content : "<p></p>",
  };

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "automated-promotions/" + props.data.id
      : "automated-promotions";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setAutoPromoVisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    time: Yup.string().nullable().required(strings.w_required),
  });

  if (props.autoPromoVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_automated_promotions
          : strings.w_add_automated_promotions}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />
              <div
                className="form-control"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              >
                <label>{strings.w_time}</label>
                <TimePicker
                  defaultValue={
                    values.time ? moment(values.time, "HH:mm:ss") : undefined
                  }
                  defaultOpenValue={
                    values.time ? moment(values.time, "HH:mm:ss") : moment()
                  }
                  onChange={(value) => {
                    values.time = value && value.format(str);
                    setFieldValue("time", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="time"
                  style={{ display: "none" }}
                  divstyle={{ padding: 0, margin: 0 }}
                />
              </div>

              <FormikControl
                control="input"
                type="text"
                label={strings.w_inactive_days}
                name="inactive_days"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("inactive_days", event.target.value);
                  }
                }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_weekday}
                name="weekday"
                placeholder={strings.w_all}
                options={props.weekdays}
                key_title="title"
                key_value="value"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              />

              <div className="form-control">
                <label>{strings.w_content}</label>
                <GHtmlEditor
                  value={values.content}
                  onChangeEditor={(val) => {
                    setFieldValue("content", val);
                  }}
                  toolbarOptions={{
                    options: ["inline", "list"],
                    inline: { options: ["bold", "italic", "underline"] },
                    list: { options: ["unordered", "ordered"] },
                  }}
                />
              </div>

              <br />
              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
