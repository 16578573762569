import React, { useState, useEffect, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import LocalizationForm from "../forms/localizationForm";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { StringsContext } from "../DataFactory/useStrings";

export default function ProductCategoryForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { data: categoriesList } = useDataFctory("product/group", false, {
    store_id: props.storeId,
  });
  const [openSnackbar] = useSnackbar();
  const [localizationsArray, setLocalizationsArray] = useState(
    props.data?.localizations || []
  );
  const [localizationVisible, setLocalizationVisible] = useState(false);
  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    photo: !props.isEmpty && props.categoryId ? props.data.photo : "",
    thumb_photo:
      !props.isEmpty && props.categoryId ? props.data.thumb_photo : "",
    // description: !props.isEmpty ? props.data.description : "",
    store_id: props.storeId,
    index: !props.isEmpty ? props.data.index : "",
    open_at: !props.isEmpty ? props.data.open_at : "",
    close_at: !props.isEmpty ? props.data.close_at : "",
  };

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };
    sendData.localizations = localizationsArray;
    sendData.open_at = sendData?.open_at || "";
    sendData.close_at = sendData?.close_at || "";
    console.log(sendData);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "product/categories/" + props.data.id
      : "product/categories";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setCatFormvisible(false);
          props.onSubmit();
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    // description: Yup.string().required(strings.w_required)
  });

  if (props.catFormvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit : strings.w_add} {strings.w_category}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  values.photo = newValue;
                  values.thumb_photo = thumb_val;
                }}
                id="thumb_photo"
                image={
                  values.photo ? ImgUrl("category") + "/" + values.photo : ""
                }
                type="category"
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{ margin: "0 auto", display: "block" }}
              />

              <br />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />

              {/* <FormikControl
                  control="input"
                  as="textarea"
                  label="Description"
                  name="description"
                /> */}

              <FormikControl
                control="input"
                type="text"
                label={strings.w_sort_index}
                name="index"
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("index", event.target.value);
                  }
                }}
              />

              <div
                style={{
                  padding: "15px 15px 0px",
                }}
              >
                <label>{strings.w_open_at}</label>
                <TimePicker
                  defaultValue={
                    values.open_at
                      ? moment(values.open_at, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.open_at
                      ? moment(values.open_at, "HH:mm:ss")
                      : moment()
                  }
                  onChange={(value) => {
                    values.open_at = value && value.format(str);
                    setFieldValue("open_at", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="open_at"
                style={{ display: "none" }}
              />

              <div
                style={{
                  margin: "10px 15px 0px",
                }}
              >
                <label>{strings.w_close_at}</label>
                <TimePicker
                  defaultValue={
                    values.close_at
                      ? moment(values.close_at, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.close_at
                      ? moment(values.close_at, "HH:mm:ss")
                      : moment()
                  }
                  onChange={(value) => {
                    values.close_at = value && value.format(str);
                    setFieldValue("close_at", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="close_at"
                style={{ display: "none" }}
              />

              {props.settings?.locales &&
                props.settings?.locales.length > 0 && (
                  <div style={{ margin: "15px 15px 0" }}>
                    <label>{strings.w_localizations}</label>

                    {localizationsArray.length > 0 ? (
                      <GButton
                        variant="linkable"
                        onClick={() => setLocalizationVisible(true)}
                      >
                        {localizationsArray.length}{" "}
                        {strings.w_localizations_added}
                      </GButton>
                    ) : (
                      <GButton
                        variant="linkable"
                        children={strings.w_add_localization}
                        onClick={() => setLocalizationVisible(true)}
                      />
                    )}
                  </div>
                )}
              <br />
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>

      <Modal
        isOpen={localizationVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setLocalizationVisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
        contentLabel="Localization Form Modal"
      >
        <LocalizationForm
          localizationVisible={localizationVisible}
          setLocalizationVisible={setLocalizationVisible}
          data={localizationsArray}
          onSubmit={(data) => {
            setLocalizationsArray([...data]);
          }}
          keyArray={[{ title: strings.w_title, value: "title" }]}
          variables={[]}
          onLocalizationRemove={(ind) => {
            props.onLocalizationRemove(ind);
          }}
          onLocalizationUpdate={(ind, data) => {
            props.onLocalizationUpdate(ind, data);
          }}
        />
      </Modal>
    </>
  );
}
