import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { ReactComponent as InfoIcon } from "../assets/images/info-icon.svg";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import GSwitch from "../gComponents/gSwitch";
import TooltipComponent from "../gComponents/gTooltip";
import TaxTypes from "./taxTypes";
import TaxClasses from "./taxClasses";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function TaxSettings() {
  const [strings, language] = useContext(StringsContext);
  const settings = useSettings();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const {
    loading,
    data: taxData,
    Placeholder,
    TableShimmer,
    refreshData,
    extra,
  } = useDataFctory("taxes", false);

  const [taxSettingsData, setTaxSettingsData] = useState(
    extra?.settings || {
      tax_class_enabled: {},
      tax_inclusive: {},
    }
  );
  const [taxClassesData, setTaxClassesData] = useState(extra?.classes || []);

  useEffect(() => {
    taxData.map((c) => {
      c.isDisabled = true;
    });
    getTaxList();
  }, [taxData]);

  useEffect(() => {
    if (loading == false) {
      refreshData();
    }
  }, [language]);

  useEffect(() => {
    setTaxSettingsData(extra?.settings || {});
    setTaxClassesData(extra?.classes || []);
  }, [extra]);

  function updateField(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            taxSettingsData[keyTitle] = response.data.data;
            setTaxSettingsData({ ...taxSettingsData });
          }
        } else {
          setTaxSettingsData({ ...taxSettingsData });
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const initialValues = {
    options: [],
  };

  const onSubmit = (values, { resetForm }) => {
    setIsBtnDisabled(true);
    ApiService({ method: "POST", route: "tax", body: values.options[0] })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          response.data.data.isDisabled = true;
          taxData.push(response.data.data);
          resetForm(initialValues);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTaxList = () => {};

  return (
    <div className="rightInnerView" style={{ padding: "20px 20px 0" }}>
      <GText
        g2
        bold
        text={`${strings.w_tax} ${strings.w_settings}`}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        {loading === true ? (
          <TableShimmer />
        ) : (
          <>
            <div
              style={{
                padding: "0 15px",
                boxSizing: "border-box",
              }}
            >
              {taxSettingsData.tax_class_enabled?.id && (
                <div style={{ display: "flex", padding: "12px 0" }}>
                  <label
                    style={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {taxSettingsData.tax_class_enabled?.key_display_title}
                    &nbsp;&nbsp;
                    <TooltipComponent
                      title={strings.s_tax_class_enabled_tooltip}
                      position="top"
                      id="tax_class_enabled"
                      theme={"white"}
                      divStyle={{
                        verticalAlign: "unset",
                        margin: "0",
                      }}
                      tooltipStyle={{
                        minWidth: "235px",
                        padding: "5px",
                        border: "1px solid #e2e2e2",
                      }}
                    >
                      <InfoIcon fontSize="small" style={{ color: "#000" }} />
                    </TooltipComponent>
                  </label>
                  <div style={{ width: "calc(100% - 320px)" }}>
                    <GSwitch
                      id={taxSettingsData.tax_class_enabled?.id}
                      uId={taxSettingsData.tax_class_enabled?.id}
                      currentValue={
                        taxSettingsData.tax_class_enabled?.key_value
                      }
                      style={{ margin: "0 20px" }}
                      onSwitchChange={(newVal, id) =>
                        updateField(newVal, id, "tax_class_enabled")
                      }
                    />
                  </div>
                </div>
              )}

              {taxSettingsData.tax_inclusive?.id && (
                <div style={{ display: "flex", padding: "12px 0" }}>
                  <label style={{ width: "200px" }}>
                    {taxSettingsData.tax_inclusive?.key_display_title}
                  </label>
                  <div style={{ width: "calc(100% - 320px)" }}>
                    <GSwitch
                      id={taxSettingsData.tax_inclusive?.id}
                      uId={taxSettingsData.tax_inclusive?.id}
                      currentValue={taxSettingsData.tax_inclusive?.key_value}
                      style={{ margin: "0 20px" }}
                      onSwitchChange={(newVal, id) =>
                        updateField(newVal, id, "tax_inclusive")
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <hr />

            <div style={{ padding: "10px 15px" }}>
              <GText
                g3
                semi
                text={strings.w_tax_types}
                style={{ margin: "0 0 15px" }}
              />

              <TaxTypes
                taxData={taxData}
                onSubmit={(data) => {
                  taxData.push(data);
                }}
                onTaxRemove={(id) => {
                  let selectedIndex = taxData.findIndex((p) => p.id == id);
                  if (selectedIndex > -1) {
                    taxData.splice(selectedIndex, 1);
                  }
                }}
                onTaxUpdate={(data) => {
                  let selectedIndex = taxData.findIndex((p) => p.id == data.id);
                  if (selectedIndex > -1) {
                    taxData[selectedIndex] = { ...data, isDisabled: true };
                  }
                }}
                settings={settings}
              />
            </div>

            {taxSettingsData.tax_class_enabled?.key_value == 1 && (
              <>
                <hr />
                <div style={{ padding: "10px 15px" }}>
                  <GText
                    g3
                    semi
                    text={strings.w_tax_classes}
                    style={{ margin: "0 0 15px" }}
                  />
                  <TaxClasses
                    taxData={taxData}
                    taxClassesData={taxClassesData}
                    settings={settings}
                    onSubmit={(data) => {
                      let findInd = taxClassesData.findIndex(
                        (t) => t.id == data.id
                      );
                      if (findInd > -1) {
                        taxClassesData[findInd] = {
                          ...taxClassesData[findInd],
                          ...data,
                        };
                      } else {
                        taxClassesData.push({ ...data, values: [] });
                      }
                      console.log(taxClassesData);
                      setTaxClassesData([...taxClassesData]);
                    }}
                    onClassValueSubmit={(data, classId) => {
                      let findInd = taxClassesData.findIndex(
                        (t) => t.id == data.tax_class_id
                      );
                      if (findInd > -1) {
                        let findCInd = taxClassesData[findInd].values.findIndex(
                          (t) => t.id == data.id
                        );
                        if (findCInd > -1) {
                          taxClassesData[findInd].values[findCInd] = data;
                        } else {
                          taxClassesData[findInd].values.push(data);
                        }
                      }

                      setTaxClassesData([...taxClassesData]);
                    }}
                    onDelete={(cId) => {
                      let findInd = taxClassesData.findIndex(
                        (t) => t.id == cId
                      );
                      if (findInd > -1) {
                        taxClassesData.splice(findInd, 1);
                      }
                      setTaxClassesData([...taxClassesData]);
                    }}
                    onClassValueDelete={(valueId, classId) => {
                      let findInd = taxClassesData.findIndex(
                        (t) => t.id == classId
                      );
                      if (findInd > -1) {
                        let findCInd = taxClassesData[findInd].values.findIndex(
                          (t) => t.id == valueId
                        );
                        if (findCInd > -1) {
                          taxClassesData[findInd].values.splice(findCInd, 1);
                        }
                      }

                      setTaxClassesData([...taxClassesData]);
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
