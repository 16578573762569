import React, { useState, useEffect, useContext } from "react";
import "../css/tags.css";
import { Close } from "@material-ui/icons";
import { StringsContext } from "../DataFactory/useStrings";

const GTagsInput = (props) => {
  const [strings] = useContext(StringsContext);
  const [selectedTags, setSelectedTags] = useState(props.data || []);
  useEffect(() => {
    setSelectedTags(props.data || []);
  }, [props]);
  return (
    <div className="react-tags">
      <ul className="react-tags-list">
        {selectedTags.map((t, i) => {
          return (
            <li className="react-tags__selected-tag" key={i}>
              {t}
              <Close
                onClick={() => {
                  selectedTags.splice(i, 1);
                  setSelectedTags([...selectedTags]);
                  props.onChange([...selectedTags]);
                }}
              />
            </li>
          );
        })}
      </ul>
      <input
        type="text"
        className="react-tags-input"
        placeholder={strings.w_add_a_keyword}
        onKeyPress={(e) => {
          console.log(e.target);
          if (e.key === "Enter") {
            e.preventDefault();
            setSelectedTags([...selectedTags, e.target.value]);
            props.onChange([...selectedTags, e.target.value]);
            e.target.value = "";
          }
        }}
      />
    </div>
  );
};

export default GTagsInput;
