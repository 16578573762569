import React, { useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import GListCard from "../gComponents/gListCards";
import VariantTypeForm from "../forms/variantTypesForm";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function VariantTypes() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [vtvisible, setVtvisible] = useState(false);
  const [selectedVariantType, setSelectedVariantType] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const {
    loading,
    data: variantTypeData,
    Placeholder,
    CardShimmer,
  } = useDataFctory("variant_types", false);

  function deleteVariantType(selectedId) {
    ApiService({
      method: "DELETE",
      route: "product/variant/types/" + selectedId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = variantTypeData.findIndex(
            (p) => p.id == selectedId
          );
          if (selectedIndex > -1) {
            variantTypeData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSwitch = (newValue, sId, ind) => {
    ApiService({
      method: "PUT",
      route: "product/variant/types/" + sId,
      body: {
        status: newValue,
      },
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          variantTypeData[ind] = response.data.data;
        } else {
          variantTypeData[ind].status = !variantTypeData[ind].status;
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedVariantType({});
          setVtvisible(true);
          setIsEmpty(true);
        }}
        style={{
          margin: "10px 5px",
          float: "right",
        }}
      />
      <GText
        g2
        bold
        text={strings.w_variant_types}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        {loading === true ? (
          <CardShimmer />
        ) : variantTypeData.length === 0 ? (
          <Placeholder />
        ) : (
          <Grid container spacing={1}>
            {variantTypeData.map((v, i) => {
              return (
                <Grid item xl={3} lg={3} md={4} xs={6}>
                  <GListCard
                    setting={strings.w_variant_type}
                    key={v.id}
                    id={v.id}
                    title={v.title}
                    switch={true}
                    switchValue={v.status}
                    editAction={true}
                    onEdit={(selectedRecord) => {
                      setSelectedVariantType(selectedRecord);
                      setIsEmpty(false);
                      setVtvisible(true);
                    }}
                    onDelete={deleteVariantType}
                    data={v}
                    onSwitchToggle={(value, id) => handleSwitch(value, id, i)}
                    style={{ minWidth: "calc(100% - 20px)" }}
                  ></GListCard>
                </Grid>
              );
            })}
          </Grid>
        )}
      </div>

      <Modal
        isOpen={vtvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVtvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Variant Type Form Modal"
      >
        <VariantTypeForm
          vtvisible={vtvisible}
          setVtvisible={setVtvisible}
          variantData={selectedVariantType}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = variantTypeData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              variantTypeData[selectedIndex] = { ...data };
            } else {
              variantTypeData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
