import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import NoteTemplateForm from "../forms/noteTemplateForm";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Edit, Delete, Add } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import Modal from "react-modal";
import useDataFctory from "../useDataFactory";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { StringsContext } from "../DataFactory/useStrings";

export default function NoteTemplates() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isEmpty, setIsEmpty] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedNoteTemplate, setSelectedNoteTemplate] = useState({});
  const [selectedNoteTemplateId, setSelectedNoteTemplateId] = useState({});
  const [noteTempVisible, setNoteTempVisible] = useState(false);
  const {
    loading,
    data: noteTemplatesData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("note_templates", false);

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 200 },
    { id: "actions", label: strings.w_actions, minWidth: 70, align: "center" },
  ];

  function deleteNoteTemplate(selectedId) {
    ApiService({ method: "DELETE", route: "note-templates/" + selectedId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = noteTemplatesData.findIndex(
            (p) => p.id == selectedId
          );
          if (selectedIndex > -1) {
            noteTemplatesData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>
        {strings.w_note_templates}
        <GButton
          variant="condensed"
          children={strings.w_add}
          onClick={() => {
            setSelectedNoteTemplate({});
            setIsEmpty(true);
            setNoteTempVisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      </h2>
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : (
          <TableContainer>
            {noteTemplatesData && noteTemplatesData?.length > 0 ? (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {noteTemplatesData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            children="Edit"
                            onClick={() => {
                              setSelectedNoteTemplate(c);
                              setIsEmpty(false);
                              setNoteTempVisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>

                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedNoteTemplateId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Placeholder />
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_note_template_title}
          text={strings.s_delete_note_template_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteNoteTemplate();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={noteTempVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setNoteTempVisible(false)}
        style={{
          content: {
            width: "380px",
          },
        }}
        contentLabel="Note Template Form Modal"
      >
        <NoteTemplateForm
          noteTempVisible={noteTempVisible}
          setNoteTempVisible={setNoteTempVisible}
          data={selectedNoteTemplate}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = noteTemplatesData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              noteTemplatesData[selectedIndex] = { ...data };
            } else {
              noteTemplatesData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
