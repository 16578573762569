import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function BsicSettings(props) {
  const [strings, language] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [linksData, setLinksData] = useState({
    currency_symbol: {},
    admin_phone: {},
    admin_email: {},
    admin_logo: {},
    email_logo: {},
    project_name: {},
    country_code: {},
    invitation_url: {},
    admin_whatsapp: {},
    distance_unit: {},
  });
  const [pSettings, setPSettings] = useState({
    invite_string: {},
  });

  useEffect(() => {
    getBasicSettings();
    getPromotionSettings();
  }, [language]);

  const getBasicSettings = () => {
    ApiService({ method: "GET", route: "setting/basic" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPromotionSettings = () => {
    ApiService({ method: "GET", route: "setting/promotion" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setPSettings(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, selectedKey) {
    console.log(newValue + selectedFieldId);
    // return;
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          linksData[selectedKey] = response.data.data;
          setLinksData({ ...linksData });
          //getPromotionSettings();
        } else {
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_basic_settings}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "225px",
            verticalAlign: "top",
            boxSizing: "border-box",
            padding: "20px",
          }}
        >
          <GText
            text={strings.w_project_logo}
            style={{ marginBottom: "10px" }}
          />
          <div
            style={{
              background: "#f4f5f9",
              padding: "10px",
              display: "inline-block",
            }}
          >
            <GImageEditor
              image={ImgUrl("logo") + "/" + linksData.admin_logo.key_value}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "admin_logo");
              }}
              id={linksData.admin_logo.id}
              type="logo"
              isPreviewImg={true}
            />
          </div>

          <hr style={{ margin: "20px 0" }} />

          <GText text={strings.w_email_logo} style={{ marginBottom: "10px" }} />
          <div
            style={{
              background: "#f4f5f9",
              padding: "10px",
              display: "inline-block",
            }}
          >
            {/* <GImageEditor
                image={ImgUrl("logo") + "/" + linksData.email_logo.key_value}
                onSubmit={(newVal, fieldId) => {
                  updateField(newVal, fieldId, "email_logo");
                }}
                id={linksData.email_logo.id}
                type="logo"
                isPreviewImg={true}
              /> */}

            {linksData.email_logo.id ? (
              <GImagePicker
                type="logo"
                alt={linksData.project_name.key_value}
                height={"auto"}
                width={100}
                radius={1}
                //editor
                editorProps={{
                  height: 200,
                  width: 200,
                  radius: 100,
                }}
                serverProps={{
                  size: 200,
                }}
                onSuccess={(v) => {
                  console.log(v);
                  updateField(v.image, linksData.email_logo.id, "email_logo");
                }}
                isPreviewImg={true}
                default={ImgUrl("logo") + "/" + linksData.email_logo.key_value}
                style={{
                  minHeight: linksData.email_logo.key_value ? "50px" : "100px",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          style={{
            display: "inline-block",
            width: "calc(100% - 500px)",
            minWidth: "350px",
            maxWidth: "600px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={linksData.currency_symbol.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.currency_symbol.key_value}
            className={props.display}
            placeholder={linksData.currency_symbol.key_display_title}
            id={linksData.currency_symbol.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "currency_symbol");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.admin_phone.id}
            type="number"
            maxLength={15}
            title={true}
            editable={false}
            defaultValue={linksData.admin_phone.key_value}
            className={props.display}
            placeholder={linksData.admin_phone.key_display_title}
            id={linksData.admin_phone.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "admin_phone");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.admin_email.id}
            type="email"
            title={true}
            editable={false}
            required={true}
            defaultValue={linksData.admin_email.key_value}
            className={props.display}
            placeholder={linksData.admin_email.key_display_title}
            id={linksData.admin_email.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "admin_email");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.project_name.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.project_name.key_value}
            className={props.display}
            placeholder={linksData.project_name.key_display_title}
            id={linksData.project_name.id}
            onSubmit={(newVal, fieldId) => {
              linksData.project_name.key_value = newVal;
              setLinksData(linksData);
              updateField(newVal, fieldId, "project_name");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          {linksData.country_code?.id && (
            <GEditable
              key={linksData.country_code?.id}
              type="number"
              title={true}
              editable={false}
              defaultValue={linksData.country_code?.key_value}
              className={props.display}
              placeholder={linksData.country_code?.key_display_title}
              id={linksData.country_code?.id}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "country_code");
              }}
              style={{ margin: "10px 0 20px" }}
            />
          )}
          {linksData.invitation_url?.id && (
            <GEditable
              key={linksData.invitation_url?.id}
              type="text"
              title={true}
              editable={false}
              defaultValue={linksData.invitation_url?.key_value}
              className={props.display}
              placeholder={linksData.invitation_url?.key_display_title}
              id={linksData.invitation_url?.id}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "country_code");
              }}
              style={{ margin: "10px 0 20px" }}
            />
          )}
          {linksData.admin_whatsapp?.id && (
            <GEditable
              key={linksData.admin_whatsapp?.id}
              type="number"
              maxLength={15}
              title={true}
              editable={false}
              defaultValue={linksData.admin_whatsapp?.key_value}
              className={props.display}
              placeholder={linksData.admin_whatsapp?.key_display_title}
              id={linksData.admin_whatsapp?.id}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "admin_whatsapp");
              }}
              style={{ margin: "10px 0 20px" }}
            />
          )}
          {linksData.distance_unit?.id && (
            <GEditable
              key={linksData.distance_unit?.id}
              type="alpha"
              title={true}
              editable={false}
              defaultValue={linksData.distance_unit?.key_value}
              className={props.display}
              placeholder={linksData.distance_unit?.key_display_title}
              id={linksData.distance_unit?.id}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "distance_unit");
              }}
              style={{ margin: "10px 0 20px" }}
            />
          )}
        </div>
        <hr />
        <br />
        <div style={{ width: "90%", maxWidth: "750px" }}>
          <label>{pSettings.invite_string.key_display_title}</label>
          <GText
            g5
            med
            text={strings.s_use_referral_code_for_referral_code}
            style={{ fontStyle: "italic", letterSpacing: "0.3px" }}
          />

          <GEditable
            key={pSettings.invite_string?.id}
            type="textarea"
            title={false}
            editable={false}
            defaultValue={pSettings.invite_string?.key_value}
            className={props.display}
            id={pSettings.invite_string?.id}
            onSubmit={updateField}
            style={{ margin: "10px 0 20px" }}
          />
        </div>
      </div>
    </div>
  );
}
