import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function CreateWallet(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues = {
    customer_id: props.id,
    amount: "",
    wallet_type: "",
    description: "",
  };

  const WalletType = [
    {
      title: strings.w_debit,
      value: "debit",
    },
    {
      title: strings.w_credit,
      value: "credit",
    },
  ];

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    ApiService({
      method: "POST",
      route: "customer/wallet",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setWalletvisible(false);
        props.onSubmit();
      }
      setIsDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required(strings.w_required),
    wallet_type: Yup.string().required(strings.w_required),
    description: Yup.string().required(strings.w_required),
  });

  if (props.walletvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_adjust_wallet} </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_amount}
                name="amount"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("amount", event.target.value);
                  }
                }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_wallet_type}
                name="wallet_type"
                options={WalletType}
                key_title="title"
                key_value="value"
              />
              <FormikControl
                control="input"
                type="text"
                as="textarea"
                label={strings.w_description}
                name="description"
              />

              <br />

              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={strings.w_add}
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
