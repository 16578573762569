import React, { useContext } from "react";
import AddressView from "../gComponents/gAddressView";
import useDataFctory from "../useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderCustomerAddress(props) {
  const [strings] = useContext(StringsContext);
  const {
    loading,
    data: addressList,
    Placeholder,
    TableShimmer,
  } = useDataFctory("addresses", true, { customer_id: props.customerId });

  if (props.ocAddvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_delivery_address}</div>
      <div className="modal-body">
        {loading == true ? (
          <TableShimmer></TableShimmer>
        ) : addressList.length === 0 ? (
          <Placeholder />
        ) : (
          addressList &&
          addressList.map((v, i) => {
            return (
              <AddressView
                key={i}
                dropdownActions={false}
                addressData={v}
                default={true}
                defaultAddress={props.defaultAddress}
                onSelectDefault={(address) => {
                  props.onSelectAddress(address);
                  props.setOcAddVisible(false);
                }}
              />
            );
          })
        )}
      </div>
    </>
  );
}
