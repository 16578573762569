import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import GEditable from "../gComponents/gEditable";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function AtmSettings() {
  const [strings, language] = useContext(StringsContext);
  const [atmSettingsData, setAtmSettingsData] = useState({
    max_atm_order: {},
    atm_fee: {},
  });
  const [openSnackbar] = useSnackbar();

  const getOtherSettings = () => {
    ApiService({ method: "GET", route: "setting/atm" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setAtmSettingsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOtherSettings();
  }, [language]);

  function updateField(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            atmSettingsData[keyTitle] = response.data.data;
            setAtmSettingsData({ ...atmSettingsData });
          }
        } else {
          getOtherSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_atm_settings}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            minWidth: "200px",
            padding: "10px 15px",
          }}
        >
          <GEditable
            key={atmSettingsData.max_atm_order.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={atmSettingsData.max_atm_order.key_value}
            placeholder={atmSettingsData.max_atm_order.key_display_title}
            id={atmSettingsData.max_atm_order.id}
            onSubmit={(val, id) => updateField(val, id, "max_atm_order")}
            style={{ margin: "10px 0 20px" }}
          />

          <GEditable
            key={atmSettingsData.max_atm_order.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={atmSettingsData.atm_fee.key_value}
            placeholder={atmSettingsData.atm_fee.key_display_title}
            id={atmSettingsData.atm_fee.id}
            onSubmit={(val, id) => updateField(val, id, "atm_fee")}
            style={{ margin: "10px 0 20px" }}
          />
        </div>
      </div>
    </div>
  );
}
