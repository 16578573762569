import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import styled from "styled-components";

const Div = styled.div`
  .DraftEditor-root {
    max-height: 150px;
    border: 1px solid #ccc;
    overflow: auto;
    padding: 0 15px;
    min-height: 100px;
  }

  .rdw-toolbar-absolute {
    right: 5px;
    position: absolute;
    top: -30px;
    z-index: 9;
  }
  .rdw-wrapper-margined {
    margin-top: 5px;
    position: relative;
  }
`;

function GHtmlEditor(props) {
  const [initialLoading, setInitialloading] = useState(true);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    // console.log(props);
    if (props.onAdd == true) {
      setInitialloading(false);
    } else {
      if (props.value) {
        if (initialLoading == true) {
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(htmlBackEntities(props.value)).contentBlocks
              )
            )
          );
        }
        setInitialloading(false);
      }
    }
  }, [props.value]);

  const onEditorStateChange = (editorState) => {
    // console.log(editorState);
    return setEditorState(editorState);
  };

  function htmlBackEntities(str) {
    const regex = /\<u\>(.*)\<\/u\>/g;
    // let rNewString = str.replace(regex, "<ins>$1</ins>");
    let rNewString = str.replace(/<u\>/g, "<ins>").replace(/<\/u>/g, "</ins>");
    return rNewString;
  }

  function htmlEntities(str) {
    const regex = /\<ins\>(.*)\<\/ins\>/g;
    // let rString = str.replace(regex, "<u>$1</u>");
    let rString = str.replace(/<ins[^>]*>/g, "<u>").replace(/<\/ins>/g, "</u>");
    return rString;
  }

  useEffect(() => {
    if (initialLoading == false) {
      var text = getHtml(editorState);
      let newText = htmlEntities(text);

      props.onChangeEditor(newText);
    }
  }, [editorState]);

  const getHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  return (
    <Div>
      <Editor
        toolbarOnFocus
        editorState={editorState}
        initialContentState={editorState}
        editorContent={editorState}
        wrapperClassName="rdw-wrapper-margined"
        // wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        toolbarClassName="rdw-toolbar-absolute"
        onEditorStateChange={onEditorStateChange}
        toolbar={
          props.toolbarOptions || {
            options: ["list"],
            list: { options: ["unordered", "ordered"] },
          }
        }
      />
    </Div>
  );
}

export default GHtmlEditor;
