import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { ProductFilter } from "./productFilterComponent";
import GText from "../gComponents/gText";
import { Close } from "@material-ui/icons";
import GImageEditor from "../gComponents/gFormImageEditor";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function EmailNewsletter() {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const settings = useSettings();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [productId, setProductId] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const initialValues = {
    subject: "",
    content: "",
    header_image: "",
    header_image_link: "",
    products: [],
  };

  const onSubmit = (values, { setFieldValue, resetForm }) => {
    let assignedProducts = [];

    selectedProducts.map((item) => {
      assignedProducts.push(item.id);
    });

    values.products = assignedProducts;
    console.log(values);
    setIsBtnDisabled(true);
    ApiService({
      method: "POST",
      route: "promotion/newsletter",
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        setFieldValue("subject", "");
        setFieldValue("content", "");
        setFieldValue("header_image", "");
        setFieldValue("header_image_link", "");
        resetForm(initialValues);
        setSelectedProducts([]);
        setProductId("");
      }
      setIsBtnDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    // subject: Yup.string().required(strings.w_required),
    // content: Yup.string().required(strings.w_required),
  });

  return (
    <div className="mainInnerView">
      <h1>{strings.w_email_newsletter}</h1>
      <div className="listPaper">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ width: "90%" }}>
              <GText
                g5
                med
                text={`${strings.w_note} : Use {{name}} for Customer Name`}
                style={{
                  fontStyle: "italic",
                  letterSpacing: "0.3px",
                  padding: "0 15px",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_subject}
                name="subject"
                divstyle={{ width: "500px" }}
              />
              <FormikControl
                control="input"
                as="textarea"
                type="text"
                label={strings.w_content}
                name="content"
                rows="5"
                divstyle={{ width: "500px" }}
              />

              <div style={{ margin: "0 15px", width: "550px" }}>
                <label>{strings.w_products}</label>
                <ProductFilter
                  status={1}
                  value={productId}
                  style={{
                    minWidth: "300px",
                    margin: 0,
                  }}
                  storeId={""}
                  handleProductChange={(id, value) => {
                    setProductId(id || "");
                    if (id) {
                      let selectedInd = selectedProducts.findIndex(
                        (s) => s.id == id
                      );
                      if (selectedInd == -1) {
                        selectedProducts.push({
                          id: id,
                          title: value.title,
                          price: value.price,
                        });
                        setSelectedProducts([...selectedProducts]);
                      }
                    }
                  }}
                />

                {selectedProducts.length > 0 && (
                  <>
                    <GText
                      theme
                      semi
                      text={`${selectedProducts.length} ${strings.w_products_selected}`}
                    />
                    <div
                      style={{
                        border: "1px solid #e2e2e2",
                        padding: "0",
                        marginTop: "10px",
                        maxHeight: "220px",
                        overflow: "overlay",
                        overflow: "auto",
                      }}
                    >
                      {selectedProducts.map((c, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              // background: "#FCD7D7",
                              // borderRadius: "20px",
                              borderBottom: "1px solid #e2e2e2",
                              padding: "5px 10px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <GText
                                semi
                                text={c.title}
                                style={{ margin: "2px 5px", fontSize: "14px" }}
                              />
                              <GText
                                med
                                text={`${settings.currency_symbol}${c?.price}`}
                                style={{ margin: "2px 5px", fontSize: "14px" }}
                              />
                            </div>
                            <Close
                              onClick={() => {
                                selectedProducts.splice(i, 1);
                                setSelectedProducts([...selectedProducts]);
                              }}
                              style={{ width: "17px", marginLeft: "auto" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <div style={{ padding: "10px 15px", width: "500px" }}>
                <GText
                  text={strings.w_header_image}
                  med
                  style={{
                    margin: "3px 0 10px",
                    fontSize: "14px",
                  }}
                />
                <GImageEditor
                  title={strings.w_header_image}
                  onSubmit={(newValue, id, thumb_val) => {
                    values.header_image = newValue;
                    setFieldValue("header_image", newValue);
                  }}
                  id={`header_image`}
                  image={
                    values.header_image
                      ? ImgUrl("newsletter") + "/" + values.header_image
                      : ""
                  }
                  type="newsletter"
                  style={{ height: "155px", paddingTop: "60px" }}
                  divstyle={{ margin: "0", display: "block" }}
                />
              </div>

              <FormikControl
                control="input"
                type="text"
                label={strings.w_header_image_link}
                name="header_image_link"
                divstyle={{ width: "500px" }}
              />
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={strings.w_send_newsletter}
                style={{ display: "flex", margin: "25px 15px 20px 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
