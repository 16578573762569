import React, { useEffect, useState, useContext } from "react";
import { ApiService, isFeatureEnabled } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import ProductImages from "../products/productImages";
import ProductVariants from "../products/productVariants";
import useDataFctory from "../useDataFactory";
import GTagsInput from "../gComponents/gTagsInput";
import Modal from "react-modal";
import LocalizationForm from "../forms/localizationForm";
import { StringsContext } from "../DataFactory/useStrings";

var formTitle = {
  fontWeight: "400",
  margin: "20px 15px",
};

export default function ProductEditForm(props) {
  const [strings] = useContext(StringsContext);
  const [productBasicInfo, setProductBasicInfo] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [productImages, setProductImgaes] = useState([]);
  const [variantTypeIndex, setVariantTypeIndex] = useState(0);
  const { data: categoriesList } = useDataFctory("product/group", false, {
    store_id: props.storeId,
  });
  const { data: classesData } = useDataFctory("tax_classes", false);
  const [openSnackbar] = useSnackbar();
  const [localizationsArray, setLocalizationsArray] = useState(
    props.data?.localizations || []
  );
  const [localizationVisible, setLocalizationVisible] = useState(false);
  const initialValues = {
    title: "",
    price: "",
    unit: "",
    categories: "",
    store_id: props.storeId,
    stock: 0,
    unlimited: 0,
    discount: 0,
    description: "",
    keywords: "",
    index: 0,
    barcode: "",
    age_restricted: 0,
  };

  const getProductDetail = () => {
    ApiService({ method: "GET", route: "products/" + props.data.id }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setProductBasicInfo(response.data);
          initialValues.title = response.data.data.title;
          initialValues.price = response.data.data.price;
          initialValues.unit = response.data.data.unit;
          initialValues.categories = response.data.data.categories;
          initialValues.unlimited = response.data.data.unlimited;
          initialValues.stock = response.data.data.stock;
          initialValues.discount = response.data.data.discount;
          initialValues.description = response.data.data.description;
          initialValues.index = response.data.data.index;
          initialValues.barcode = response.data.data?.barcode || "";
          initialValues.tax_class_id = response.data.data?.tax_class_id || "";
          initialValues.keywords = response.data.data.keywords
            ? response.data.data.keywords.split(",")
            : [];
          initialValues.age_restricted =
            response.data.data?.age_restricted || 0;
          setProductVariants(response.data.data.variants);
          setProductImgaes(response.data.data.images);
        }
      }
    );
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  const onSubmit = (values) => {
    console.log(values);
    values.stock = values.stock ? values.stock : "0";
    values.keywords =
      values.keywords.length > 0 ? values.keywords.toString() : "";
    values.localizations = localizationsArray;
    // return;
    ApiService({
      method: "PUT",
      route: "products/" + props.data.id,
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setPevisible(false);
        props.onSubmit();
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    price: Yup.string().required(strings.w_required),
    unit: Yup.string().required(strings.w_required),
    categories: Yup.string().required(strings.w_required),
  });

  if (props.pevisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_edit_product}</div>
      <div className="modal-body">
        <h2 style={formTitle}>{strings.w_basic_information}</h2>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              {categoriesList.length >= 0 ? (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_category}
                  name="categories"
                  options={categoriesList}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
              ) : (
                ""
              )}

              <FormikControl
                control="input"
                type="text"
                label={strings.w_unit}
                name="unit"
                divstyle={{
                  display: "inline-block",
                  width: "33.3%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_price}
                name="price"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("price", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <div
                style={{
                  display: "inline-block",
                  width: "33%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              >
                <FormikControl
                  control="input"
                  type="text"
                  label={`${strings.w_discount} (${strings.w_in} %)`}
                  name="discount"
                  maxLength={3}
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("discount", event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "100%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                    margin: "0",
                  }}
                />
              </div>

              <FormikControl
                control="input"
                type="text"
                label={strings.w_index}
                name="index"
                divstyle={{
                  display: "inline-block",
                  width: "40%",
                  boxSizing: "border-box",
                  verticleAlign: "top",
                }}
              />

              {props.isBarcodeShow == 1 && (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_barcode}
                  name="barcode"
                  divstyle={{
                    display: "inline-block",
                    width: "60%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
              )}
              <FormikControl
                control="input"
                as="textarea"
                label={strings.w_description}
                name="description"
              />

              <FormikControl
                control="input"
                type="text"
                label={`${strings.w_keywords} (${strings.s_used_in_product_search})`}
                name="keywords"
              />
              {/* <div style={{ margin: "0 15px" }}>
                <label>Keywords (Used In Product Search)</label>
                <GTagsInput
                  data={keywords}
                  onChange={(data) => {
                    console.log(data);
                    values.keywords = data;
                    setFieldValue("keywords", data);
                    setKeywords(data);
                  }}
                />
                <br />
              </div> */}

              {props.isStockShow === "1" && (
                <>
                  <FormikControl
                    control="toggle"
                    label={strings.w_unlimited}
                    isToggled={values.unlimited == "1" ? 1 : 0}
                    name="unlimited"
                    onToggle={() => {
                      let unlimitedVal =
                        values.unlimited === "1" ? true : false;
                      unlimitedVal = !unlimitedVal;
                      unlimitedVal = unlimitedVal === true ? "1" : "0";
                      setFieldValue("unlimited", unlimitedVal);
                    }}
                    style={{
                      width: "25%",
                      boxSizing: "border-box",
                      verticleAlign: "top",
                    }}
                  />
                  {values.unlimited != "1" ? (
                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_stock}
                      name="stock"
                      divstyle={{
                        display: "inline-block",
                        width: "35%",
                        boxSizing: "border-box",
                        verticleAlign: "top",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              {isFeatureEnabled("tax_class_enabled") ? (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_tax_class}
                  name="tax_class_id"
                  placeholder={"Choose Tax Class"}
                  options={classesData}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    verticalAlign: "top",
                    boxSizing: "border-box",
                    width: "40%",
                  }}
                />
              ) : (
                ""
              )}

              <FormikControl
                control="toggle"
                label={strings.w_age_restricted}
                isToggled={values.age_restricted == "1" ? 1 : 0}
                name="age_restricted"
                onToggle={() => {
                  let ageRestricted =
                    values.age_restricted === "1" ? true : false;
                  ageRestricted = !ageRestricted;
                  ageRestricted = ageRestricted === true ? "1" : "0";
                  setFieldValue("age_restricted", ageRestricted);
                }}
                style={{ width: "30%", boxSizing: "border-box" }}
              />

              {props.settings?.locales &&
                props.settings?.locales.length > 0 && (
                  <div style={{ margin: "15px 15px" }}>
                    <label>{strings.w_localizations}</label>

                    {localizationsArray.length > 0 ? (
                      <GButton
                        variant="linkable"
                        onClick={() => setLocalizationVisible(true)}
                      >
                        {localizationsArray.length}{" "}
                        {strings.w_localizations_added}
                      </GButton>
                    ) : (
                      <GButton
                        variant="linkable"
                        children={strings.w_add_localization}
                        onClick={() => setLocalizationVisible(true)}
                      />
                    )}
                  </div>
                )}

              <h2 style={formTitle}>{strings.w_product_images}</h2>

              <div style={{ margin: "15px" }}>
                <ProductImages
                  onUpload={(data) => {
                    setProductImgaes([...productImages, data]);
                  }}
                  onRemove={(id) => {
                    setProductImgaes(
                      productImages.filter((item) => item.id !== id)
                    );
                  }}
                  productImages={productImages}
                  add={true}
                  productId={props.data.id}
                />
              </div>

              {productVariants && productVariants.length > 0 ? (
                <React.Fragment>
                  <h2 style={formTitle}>{strings.w_product_variants}</h2>
                  <ProductVariants
                    productVariants={productVariants}
                    productId={props.data.id}
                    setVariantTypeIndex={setVariantTypeIndex}
                    selectedIndex={variantTypeIndex}
                    handleVariantAdd={(index, data) => {
                      setVariantTypeIndex(index);
                      productVariants[index].options.push(data);
                      // setProductVariants(productVariants);
                    }}
                    handleVariantRemove={(index, i) => {
                      setVariantTypeIndex(index);
                      console.log(i);
                      productVariants[index].options.splice(i, 1);
                      setProductVariants(productVariants);
                    }}
                    isVarStockShow={props.isStockShow}
                    onVariantTypeAdd={() => getProductDetail()}
                  />
                </React.Fragment>
              ) : (
                ""
              )}

              <br />
              <GButton
                variant="condensed"
                style={{
                  margin: "0 15px",
                  width: "95%",
                }}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>

      <Modal
        isOpen={localizationVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setLocalizationVisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
        contentLabel="Localization Form Modal"
      >
        <LocalizationForm
          localizationVisible={localizationVisible}
          setLocalizationVisible={setLocalizationVisible}
          data={localizationsArray}
          onSubmit={(data) => {
            setLocalizationsArray([...data]);
          }}
          keyArray={
            props.localizableKeys || [
              { title: strings.w_title, value: "title" },
              { title: strings.w_unit, value: "unit" },
            ]
          }
          variables={[]}
          onLocalizationRemove={(ind) => {
            props.onLocalizationRemove(ind);
          }}
          onLocalizationUpdate={(ind, data) => {
            props.onLocalizationUpdate(ind, data);
          }}
        />
      </Modal>
    </>
  );
}
