import React, { useState, useEffect, useContext } from "react";
import { Edit } from "@material-ui/icons";
import { ApiService, getUserType, isPermissionExist } from "../services";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent, GTableContent } from "../gComponents/gContent";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Orders from "../orders/ordersList";
import Addresses from "../components/addressList";
import WalletPoints from "../components/walletPointsList";
import Documents from "../components/documentsList";
import { useSnackbar } from "react-simple-snackbar";
import CustomerForm from "../forms/customerForm";
import useSettings from "../useSettings";
import GRouting from "../gComponents/gRouteBreadCrumbs";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function CustomerDetail(props) {
  const [strings] = useContext(StringsContext);
  let { customerId } = useParams();
  const [customerData, setCustomerData] = useState([]);
  const [customerInsights, setCustomerInsights] = useState({});
  const [typeValue, setTypeValue] = useState("orders");
  const [ccvisible, setCcvisible] = useState(false);
  const settings = useSettings(true);
  const [openSnackbar] = useSnackbar();

  const RoutLinks = [
    {
      title: strings.w_customers,
      link: "/customers",
      state: "customer",
    },
  ];

  const customerRoutes = [
    getUserType() || isPermissionExist("order_index")
      ? {
          title: strings.w_orders,
          identifier: "orders",
          link: "/customer/" + customerId + "/orders",
          state: "customer",
        }
      : "",
    {
      title: strings.w_address,
      identifier: "address",
      link: "/customer/" + customerId + "/address",
      state: "customer",
    },
    getUserType() || isPermissionExist("customer_wallet")
      ? {
          title: strings.w_wallet,
          identifier: "wallet",
          link: "/customer/" + customerId + "/wallet",
          state: "customer",
        }
      : "",
    getUserType()
      ? {
          title: strings.w_documents,
          identifier: "documents",
          link: "/customer/" + customerId + "/documents",
          state: "customer",
        }
      : "",
  ];

  const getCustomerDetail = () => {
    ApiService({ method: "GET", route: "customers/" + customerId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setCustomerData(response.data.data);
          setCustomerInsights(response.data.data?.insights);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomerDetail();
  }, [props, customerId]);

  function handleChange(newValue) {
    console.log("n" + newValue);
    setTypeValue(newValue);
  }

  function handleUpdateImage(imgValue, id, thumbValue) {
    console.log(imgValue);
    var data = { photo: imgValue, thumb_photo: thumbValue };
    ApiService({
      method: "PUT",
      route: "customers/" + customerId,
      body: data,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setCustomerData(response.data.data);
      }
      openSnackbar(response.data.message);
      return;
    });
  }

  return (
    <div className="mainInnerView" style={{ padding: "20px 20px 0" }}>
      <GRouting links={RoutLinks} currentPage={customerData.title} />
      <GInfo
        id={customerData.id}
        title={customerData.title}
        key={customerData.id}
        className="headerInfo"
        imgType="user"
        imgEdit={
          getUserType() || isPermissionExist("customer_update") ? true : false
        }
        photo={customerData.photo}
        uploadImage={handleUpdateImage}
        style={{ width: "auto" }}
      />

      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ minHeight: "200px" }}>
          <CardHead>
            {strings.w_contact_details}
            {getUserType() || isPermissionExist("customer_update") ? (
              <Edit onClick={() => setCcvisible(true)} />
            ) : (
              ""
            )}
          </CardHead>
          <GContent title={strings.w_email} value={customerData.email} />
          <GContent title={strings.w_phone} value={customerData.phone || "-"} />
        </Card>

        <Card style={{ minHeight: "200px" }}>
          <CardHead>{strings.w_smart_insights}</CardHead>
          <GTableContent
            title={strings.w_last_order}
            value={
              customerInsights.last_ordered
                ? customerInsights.last_ordered
                : "-"
            }
            align="right"
          />
          <GTableContent
            title={strings.w_open_orders}
            value={customerInsights.open_orders}
            innerValue={
              settings.currency_symbol +
              "" +
              customerInsights.open_orders_amount
            }
            align="right"
          />
          <GTableContent
            title={strings.w_orders_completed}
            value={customerInsights.completed_orders}
            innerValue={
              settings.currency_symbol +
              "" +
              customerInsights.completed_order_amount
            }
            align="right"
          />
          <GTableContent
            title={strings.w_balance}
            value={customerData.wallet}
            innerValue={settings.currency_symbol + customerData.balance}
            align="right"
          />
          <GTableContent
            title={strings.w_subscription}
            value={customerInsights?.subscription?.title || "-"}
            innerValue={customerInsights?.subscription?.expiry || ""}
            align="right"
          />
        </Card>
        <Modal
          isOpen={ccvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setCcvisible(false)}
          style={{
            content: {
              width: "420px",
            },
          }}
          contentLabel="Customer Form Modal"
        >
          <CustomerForm
            ccvisible={ccvisible}
            setCcvisible={setCcvisible}
            data={customerData}
            onSubmit={getCustomerDetail}
          />
        </Modal>
      </div>

      <div
        style={{
          width: "calc(100% - 30%)",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <GTabs routes={customerRoutes} active={typeValue}></GTabs>

        <Switch>
          {getUserType() || isPermissionExist("order_index") ? (
            <Route exact path={"/customer/" + customerId + "/orders"}>
              <Orders
                onChange={handleChange}
                type="customer"
                value={customerId}
                title="customer_id"
              />
            </Route>
          ) : (
            ""
          )}
          <Route exact path={"/customer/" + customerId + "/address"}>
            <Addresses
              onChange={handleChange}
              type="customer"
              value={customerId}
              title="customer_id"
            />
          </Route>
          {getUserType() || isPermissionExist("customer_wallet") ? (
            <Route exact path={"/customer/" + customerId + "/wallet"}>
              <WalletPoints
                onChange={handleChange}
                type="customer"
                value={customerId}
                title="customer_id"
              />
            </Route>
          ) : (
            ""
          )}
          {getUserType() ? (
            <Route exact path={"/customer/" + customerId + "/documents"}>
              <Documents
                onChange={handleChange}
                type="customer"
                value={customerId}
                title="customer_id"
              />
            </Route>
          ) : (
            ""
          )}
          <Redirect
            to={"/customer/" + customerId + "/address"}
            from={"/customer/" + customerId + "/orders"}
          />
          <Redirect
            to={"/customer/" + customerId + "/orders"}
            from={"/customer/" + customerId + "/wallet"}
          />
          <Redirect
            to={"/customer/" + customerId + "/orders"}
            from={"/customer/" + customerId + "/documents"}
          />
        </Switch>
      </div>
    </div>
  );
}
