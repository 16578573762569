import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useContext,
} from "react";
import GTabs from "../gComponents/gTabs";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useSnackbar } from "react-simple-snackbar";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

const emailTypes = ["system", "mailgun"];

export default function EmailSettings() {
  const [strings] = useContext(StringsContext);
  const testEmail = useRef(null);
  const [typeValue, setTypeValue] = useState();
  const [emailSettingsData, setEmailSettingsData] = useState({});
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResponse, setTestResponse] = useState("");
  const [systemData] = useState({ default: "system" });
  const [mailgunData, setMailgunData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      default: "mailgun",
      mailgun: {
        service_url: "",
        api_secret: "",
        from: "",
      },
    }
  );

  const fieldStyle = {
    display: "block",
    width: "300px",
    padding: "10px 15px 10px 0",
  };
  const lableStyle = {
    fontSize: "0.9rem",
    lineHeight: "1.5rem",
    padding: "3px",
    textTransform: "capitalize",
  };

  function getEmailSettings() {
    ApiService({ method: "GET", route: "email-settings" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setEmailSettingsData(response.data.data);
          setTypeValue(response.data.data.default);
          setMailgunData({
            mailgun: {
              service_url: response.data.data.mailgun.service_url,
              api_secret: response.data.data.mailgun.api_secret,
              from: response.data.data.mailgun.from,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(typeValue);
    getEmailSettings();
  }, []);

  function updateMailgunSettings() {
    ApiService({
      method: "PUT",
      route: "email-settings",
      body: typeValue === "system" ? systemData : mailgunData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getEmailSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function testEmailSettings() {
    ApiService({
      method: "POST",
      route: "email-settings",
      body: {
        email: testEmail.current.value,
        driver: typeValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          testEmail.current.value = "";
          setTestResponse(
            response.data.data
              ? JSON.stringify(response.data.data)
              : response.data.data
          );
          setTimeout(function () {
            setDialogOpen(true);
          }, 800);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <div
        style={{
          float: "right",
          marginTop: "20px",
          fontStyle: "italic",
          display: "flex",
        }}
      >
        <GText
          med
          g4
          text={strings.w_default_method + " :"}
          style={{ margin: "2px 0", display: "inline" }}
        />
        &nbsp;
        <GText
          theme
          g4
          text={emailSettingsData?.default?.replace("_", " ") || ""}
          style={{ margin: "2px 0", textTransform: "capitalize", flex: "1" }}
        />
      </div>

      <GText
        bold
        g2
        text={strings.w_email + " " + strings.w_notification_settings}
        style={{ margin: "15px 0" }}
      />

      <GTabs
        routes={emailTypes}
        active={typeValue}
        type="single"
        onClick={(newVal) => {
          setTypeValue(newVal);
          if (testEmail.current) {
            testEmail.current.value = "";
          }
        }}
      ></GTabs>

      <div className="listPaper" style={{ padding: "30px" }}>
        {typeValue === "system" ? (
          <div>
            <p>{strings.s_email_settings_are_set_by}</p>
            <GButton
              variant="condensed"
              children={strings.w_set_as_default}
              onClick={updateMailgunSettings}
              disabled={emailSettingsData.default === "system" ? true : false}
            ></GButton>
          </div>
        ) : typeValue === "mailgun" ? (
          <div>
            <div style={fieldStyle}>
              <label style={lableStyle}>{strings.w_service_url}</label>
              <GInput
                type="text"
                placeholder=""
                name="service_url"
                id="service_url"
                value={mailgunData.mailgun.service_url}
                display="block"
                onChange={(e) => {
                  mailgunData.mailgun.service_url = e.target.value;
                  setMailgunData({ ...mailgunData });
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>{strings.w_secret_api}</label>
              <GInput
                type="text"
                placeholder=""
                name="api_secret"
                id="api_secret"
                value={mailgunData.mailgun.api_secret}
                display="block"
                onChange={(e) => {
                  mailgunData.mailgun.api_secret = e.target.value;
                  setMailgunData({ ...mailgunData });
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>{strings.w_email}</label>
              <GInput
                type="text"
                placeholder=""
                name="from"
                id="from"
                value={mailgunData.mailgun.from}
                display="block"
                onChange={(e) => {
                  mailgunData.mailgun.from = e.target.value;
                  setMailgunData({ ...mailgunData });
                }}
              />
            </div>
            <br />
            <GButton
              variant="condensed"
              children={strings.w_update}
              onClick={updateMailgunSettings}
            ></GButton>
          </div>
        ) : (
          ""
        )}

        {typeValue === "mailgun" && emailSettingsData.default === "mailgun" && (
          <React.Fragment>
            <hr style={{ margin: "20px 0" }} />
            <GText
              text={
                strings.w_check + " " + typeValue + " " + strings.w_integration
              }
              style={{ textTransform: "capitalize", margin: "5px" }}
            />
            <div
              style={{
                display: "inline-flex",
                verticalAlign: "middle",
              }}
            >
              <input
                type="text"
                ref={testEmail}
                placeholder="test@example.com"
                name="testNo"
                id="testNo"
                display="block"
                style={{
                  padding: "10px",
                  margin: "4px",
                  outline: "0",
                  border: "1px solid #ccc",
                }}
              />
              <GButton
                variant="condensed"
                children={strings.w_send_test_email}
                onClick={testEmailSettings}
                style={{
                  height: "38px",
                  marginTop: "4px",
                }}
              ></GButton>
            </div>
          </React.Fragment>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={typeValue + " Gateway Response"}
          text={testResponse}
          handleClose={() => setDialogOpen(false)}
          handleCloseText="Okay"
        ></GConfirmDialog>
      )}
    </div>
  );
}
