import axios from "axios";
import placeholder from "./assets/images/placeholders/list-placeholder.png";
import categoryPlaceholder from "./assets/images/placeholders/p-category.png";
import itemPlaceholder from "./assets/images/placeholders/p-item.png";
import storePlaceholder from "./assets/images/placeholders/p-store.png";
import userPlaceholder from "./assets/images/placeholders/f-placeholder.png";
import AppLogo from "./assets/images/logo.png";
import moment from "moment-timezone";
import Axios from "./Axios";

export function addDefaultSrc(e, type) {
  // e.target.src = placeholder;

  e.target.src =
    type && type === "item"
      ? itemPlaceholder
      : type === "store"
      ? storePlaceholder
      : type === "category"
      ? categoryPlaceholder
      : type === "user" || type === "admin"
      ? userPlaceholder
      : placeholder;
  // return e.target.src;
}
export function addDefaultLogo(e) {
  e.target.src = AppLogo;
  // return e.target.src;
}
export function ImgUrl(imgType) {
  if (imgType) {
    var ImageUrl = process.env.REACT_APP_IMAGE_URL + "/" + imgType;

    return ImageUrl;
  }
}

export function isPermissionExist(per) {
  let s = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : localStorage.getItem("settings");
  if (s) {
    let exist = s?.permissions?.includes(per);

    return exist;
  }
}

export function isFeatureEnabled(feature) {
  let s = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : localStorage.getItem("settings");
  return s?.[feature] == 1 || s?.[feature] == true;
}

export function getUserType() {
  let s = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : localStorage.getItem("settings");
  if (s) {
    let uType =
      s?.user?.user_type == "sa"
        ? true
        : s?.user?.user_type == "admin"
        ? true
        : false;
    // console.log(uType);
    return uType;
  }
}

export async function ApiService(service) {
  const getParams = () => {
    console.log(service.body);
    var formBody = [];
    for (var property in service.body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(service.body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    console.log(formBody);
    formBody = formBody.join("&");
    return "?" + formBody;
  };
  // console.log(Object.keys(service.body).length)
  let urlParam =
    service.method === "GET"
      ? service.body && Object.keys(service.body).length > 0
        ? getParams()
        : ""
      : "";
  let postData = service.method === "GET" ? "" : service.body;

  try {
    let response1 = await axios({
      method: service.method,
      url:
        process.env.REACT_APP_API_URL + "/a/" + service.route + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
        timezone: moment.tz.guess(),
        locale: localStorage.getItem("language") || "en",
      },
    });
    console.log(response1);
    // if (response1.data.status_code === 417) {
    //   localStorage.clear();
    // }
    return response1;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function ApiDownloadService(method, hUrl, param) {
  let urlParam = method === "GET" ? (param ? param : "") : "";
  let postData = method === "GET" ? "" : param;

  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/a/" + hUrl + "" + urlParam,
      data: postData,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
}
export async function csvFileApiService(method, hUrl, param) {
  try {
    var fd = new FormData();
    fd.append("csv", param.csv);
    fd.append("store_id", param.store_id);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/a/" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}
export async function FileApiService(method, hUrl, param, skipThumb) {
  try {
    var fd = new FormData();
    fd.append("image", param);

    let url =
      process.env.REACT_APP_API_URL +
      "/a/upload?type=" +
      hUrl +
      "&skip_thumb=" +
      skipThumb;
    console.log(url);

    let response1 = await axios({
      method: method,
      url: url,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function ApiLoginService(method, hUrl, param) {
  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: param,
      headers: {
        Accept: "application/json",
        Authorization: "",
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function LogoutService(hUrl) {
  try {
    let response1 = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: "",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}
