import React, { useEffect, useState, useContext } from "react";
import { ReactComponent as Check } from "../assets/images/untick.svg";
import GText from "../gComponents/gText";
import { Brightness1 } from "@material-ui/icons";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderTaskPackageInfo(props) {
  const [strings] = useContext(StringsContext);
  const [packageInfo, setPackageInfo] = useState({});

  useEffect(() => {
    console.log(props);
    let abc = getOptionsValue(props.data);
    console.log(abc);

    setPackageInfo({ ...props.data, additional_services: abc || [] });
  }, [props]);

  const getOptionsValue = (data) => {
    let available = [];
    if (data?.is_cod == 1) {
      if (data.cod_amount) {
        available.push(
          "Cash on delivery (" +
            data.cod_amount +
            props.settings?.currency_symbol +
            ")"
        );
      } else {
        available.push("Cash on delivery");
      }
    }
    if (data?.is_fragile == 1) {
      if (data.price_fragile) {
        available.push(
          "Fragile (" +
            data.price_fragile +
            props.settings?.currency_symbol +
            ")"
        );
      } else {
        available.push("Fragile");
      }
    }
    if (data?.is_express_delivery == 1) {
      if (data.price_express_delivery) {
        available.push(
          "Express delivery (" +
            data.price_express_delivery +
            props.settings?.currency_symbol +
            ")"
        );
      } else {
        available.push("Express delivery");
      }
    }
    if (data?.is_return_documentation == 1) {
      if (data.price_return_documentation) {
        available.push(
          "Return documentation (" +
            data.price_return_documentation +
            props.settings?.currency_symbol +
            ")"
        );
      } else {
        available.push("Return documentation");
      }
    }
    if (data?.notes) {
      available.push(data?.notes);
    }
    return available;
  };

  if (props.dPackageInfovisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{strings.w_package_details}</div>
      <div className="modal-body">
        <div style={{ display: "flex", padding: "0 10px" }}>
          <div style={{ flex: 1 }}>
            <GText g4 text={packageInfo.title} />
          </div>
        </div>

        {packageInfo?.additional_services &&
        packageInfo?.additional_services.length > 0 ? (
          <div style={{ padding: 10 }}>
            <hr />
            <GText
              g4
              semi
              text={strings.w_additional_services}
              style={{ margin: "20px 0 5px" }}
            />
            <GText
              med
              text={strings.s_overview_of_additional_options}
              style={{ margin: "5px 0" }}
            />
            <div style={{ padding: "10px 0" }}>
              {packageInfo?.additional_services.map((s, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "6px 0",
                    }}
                  >
                    <Brightness1
                      style={{
                        width: 15,
                        height: 15,
                        marginRight: 10,
                        fill: "#000",
                      }}
                    />
                    {s}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
