import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function TaxClassValForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const Types = [
    {
      title: strings.w_fixed,
      value: "fixed",
    },
    {
      title: strings.w_percentage,
      value: "percentage",
    },
  ];

  const initialValues = {
    tax_class_id: !props.isEmpty ? props.data.tax_class_id : props.classId,
    tax_id: !props.isEmpty ? props.data.tax_id : "",
    type: !props.isEmpty ? props.data.type : "",
    fixed: !props.isEmpty ? props.data.fixed : "",
    percentage: !props.isEmpty ? props.data.percentage : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };
    if (sendData.type == "fixed") {
      delete sendData.percentage;
    }
    if (sendData.type == "percentage") {
      delete sendData.fixed;
    }
    console.log(sendData);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "tax-class-values/" + props.data.id
      : "tax-class-values";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setTaxClassValFormvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    tax_id: Yup.string().required(strings.w_required),
    type: Yup.string().required(strings.w_required),
    fixed: Yup.string().when("type", {
      is: "fixed",
      then: Yup.string().required(strings.w_required),
    }),
    percentage: Yup.string().when("type", {
      is: "percentage",
      then: Yup.string().required(strings.w_required),
    }),
  });

  if (props.taxClassValFormvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_tax_class_value
          : strings.w_create_tax_class_value}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="select"
                as="select"
                label={strings.w_tax}
                name="tax_id"
                options={props.taxData}
                key_title="title"
                key_value="id"
                onChange={(e) => {
                  setFieldValue("tax_id", e.target.value);
                }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_type}
                name={`type`}
                options={Types}
                key_title="title"
                key_value="value"
                onChange={(e) => {
                  setFieldValue(`type`, e.target.value || "");
                  setFieldValue(`fixed`, "");
                  setFieldValue(`percentage`, "");
                }}
              />

              {values.type == "percentage" && (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_percentage}
                  name={`percentage`}
                  maxLength={3}
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue(`percentage`, event.target.value);
                    }
                  }}
                />
              )}
              {values.type == "fixed" && (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_fixed_amount}
                  name={`fixed`}
                  maxLength={6}
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue(`fixed`, event.target.value);
                    }
                  }}
                />
              )}
              <br />

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
