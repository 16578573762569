import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import SubscriptionForm from "../forms/subscriptionForm";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function Subscriptions() {
  const [strings] = useContext(StringsContext);
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSubscriptionData, setSelectedSubscriptionData] = useState({});
  const [selectedSubscriptionId, sstSelectedSubscriptionId] = useState("");
  const [subFormvisible, setSubFormvisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const {
    loading,
    data: subscriptionData,
    Placeholder,
    TableShimmer,
  } = useDataFctory("subscriptions", false);

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 140 },
    { id: "price", label: strings.w_price, minWidth: 100, align: "center" },
    { id: "validity", label: "Validity", minWidth: 90, align: "center" },
    {
      id: "delivery_price",
      label: strings.w_delivery_price,
      minWidth: 110,
      align: "center",
    },
    {
      id: "delivery_limit",
      label: strings.w_delivery_limit,
      minWidth: 110,
      align: "center",
    },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "center" },
  ];

  const deleteEvent = () => {
    ApiService({
      method: "DELETE",
      route: "subscriptions/" + selectedSubscriptionId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = subscriptionData.findIndex(
            (p) => p.id == selectedSubscriptionId
          );
          if (selectedIndex > -1) {
            subscriptionData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setSelectedSubscriptionData({});
          setIsEmpty(true);
          setSubFormvisible(true);
        }}
        style={{
          float: "right",
        }}
      />
      <GText
        g2
        bold
        text={strings.w_subscriptions}
        style={{ marginBottom: "15px" }}
      />
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer />
        ) : subscriptionData.length === 0 ? (
          <Placeholder />
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{ BorderBottom: "1px solid #f4f5f9" }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {settings.currency_symbol}
                        {c.price}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.validity ? c.validity + " Days" : "-"}
                      </TableRowData>{" "}
                      <TableRowData style={{ textAlign: "center" }}>
                        {settings.currency_symbol}
                        {c.delivery_price}
                      </TableRowData>{" "}
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.delivery_limit}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedSubscriptionData(c);
                            setIsEmpty(false);
                            setSubFormvisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            sstSelectedSubscriptionId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_subscription_title}
          text={strings.s_delete_subscription_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteEvent();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={subFormvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSubFormvisible(false)}
        style={{
          content: {
            width: "420px",
          },
        }}
        contentLabel="Subscription Form Modal"
      >
        <SubscriptionForm
          subFormvisible={subFormvisible}
          setSubFormvisible={setSubFormvisible}
          data={selectedSubscriptionData}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = subscriptionData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              subscriptionData[selectedIndex] = { ...data };
            } else {
              subscriptionData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
