import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function CancelReasonForm(props) {
  const [strings] = useContext(StringsContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    user_type: props.type ? props.type : "",
    title: !props.isEmpty ? props.data.title : "",
    reason_type: !props.isEmpty
      ? props.data.reason_type
      : props.filters.reason_type || "",
    class_type: !props.isEmpty
      ? props.data.class_type
      : props.filters.class_type || "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "cancel-reasons/" + props.data.id
      : "cancel-reasons";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setCrvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    reason_type: Yup.string().required(strings.w_required),
    class_type: Yup.string().required(strings.w_required),
  });

  if (props.crvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? props.filters.reason_type == "issue"
            ? strings.w_edit_order_issue
            : strings.w_edit_cancel_reason
          : props.filters.reason_type == "issue"
          ? strings.w_create_order_issue
          : strings.w_create_cancel_reason}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              as="textarea"
              type="text"
              label={strings.w_title}
              name="title"
              rows="4"
            />

            <FormikControl
              control="select"
              as="select"
              label={strings.w_class_type}
              name="class_type"
              placeholder={strings.w_choose_class_type}
              options={[
                { title: strings.w_regular, value: "regular" },
                { title: strings.w_courier, value: "courier" },
                { title: strings.w_atm, value: "atm" },
              ]}
              key_title="title"
              key_value="value"
            />

            <br />
            <GButton
              children={!props.isEmpty ? strings.w_update : strings.w_create}
              variant="condensed"
              type="submit"
              disabled={isDisabled}
              style={{ width: "calc(100% - 30px", margin: "0 15px" }}
            />
          </Form>
        </Formik>
      </div>
    </>
  );
}
