import React, { useContext, useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function EditProfile(props) {
  const [strings] = useContext(StringsContext);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    first_name: settings?.user?.first_name || "",
    last_name: settings?.user?.last_name || "",
    email: settings?.user?.email || "",
    photo: settings?.user?.photo || "",
  };
  const [openSnackbar] = useSnackbar();
  const [icon, setIcon] = useState("");

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (!values.password) {
      delete values.password;
    }
    console.log(values);

    ApiService({ method: "PUT", route: "me", body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          settings.user = response.data.data;
          localStorage.setItem("settings", JSON.stringify(settings));
          props.setEpvisible(false);
        }
        setBtnLoading(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings.w_required),
    email: Yup.string()
      .email(strings.s_invalid_email_address)
      .required(strings.w_required),
  });

  if (props.epvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{strings.w_edit_profile}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div style={{ margin: "0 15px 15px" }}>
                <GImagePicker
                  type="admin"
                  alt={settings.user.first_name}
                  height={100}
                  width={100}
                  radius={50}
                  editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  onSuccess={(v) => {
                    console.log(v);
                    setIcon(v.image);
                    values.photo = v.image;
                  }}
                  isPreviewImg={true}
                  default={ImgUrl("admin") + "/" + settings.user.photo}
                />
              </div>

              <FormikControl
                control="input"
                type="text"
                label={strings.w_first_name}
                name="first_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("first_name", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_last_name}
                name="last_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("last_name", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label={strings.w_email_id}
                name="email"
                disabled={true}
              />

              <GButton
                type="submit"
                display={isBtnLoading}
                variant="condensed"
                children={strings.w_update}
                style={{ margin: "15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
