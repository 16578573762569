import React, { useState, useContext } from "react";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import GImageEditor from "../gComponents/gImageEditor";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Modal from "react-modal";
import BannerForm from "../forms/bannersForm";
import { StringsContext } from "../DataFactory/useStrings";

const BannerDiv = styled.div`
  width: 33%;
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
  &:hover .hoverImg {
    display: block;
  }
  & img {
    width: 100%;
    object-fit: cover;
    height: 180px;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #e2e2e2;
  }
  & .hoverImg {
    display: none;
    height: 170px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    top: 0px;
    left: 15px;
    right: 15px;
    padding: 5px;
    margin: 15px 5px 0;
    position: absolute;
    box-sizing: border-box;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 50%;
  }
  @media (max-width: 959px) {
    width: 100%;
  }
`;

export default function Banners(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [banvisible, setBanvisible] = useState(false);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [selectedBanner, setSelectedBanner] = useState({});
  const [selectedBannerId, setSelectedBannerId] = useState("");
  const {
    data: bannerData,
    setData,
    refreshData,
    Placeholder,
  } = useDataFctory("banners", false, {
    type: "mobile",
  });

  const deleteBanner = () => {
    ApiService({
      method: "DELETE",
      route: "offer-banners/" + selectedBannerId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = bannerData.findIndex(
            (p) => p.id == selectedBannerId
          );
          if (selectedIndex > -1) {
            bannerData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDragEnd = (result) => {
    const { oldIndex, newIndex } = result;
    let banners = [...bannerData];
    let old = [...bannerData];
    let banner = banners[oldIndex];

    banners = banners.filter((b) => b.id != banners[oldIndex].id);
    banners.splice(newIndex, 0, banner);
    banners = banners.map((b, i) => {
      b.index = i;
      return b;
    });

    setData(banners);

    ApiService({
      method: "PUT",
      route: "sort/offer-banners",
      body: { data: banners },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          //
        } else {
          setData(old);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <h2 style={{ margin: "10px 0" }}>
        {strings.w_banners}
        <GButton
          variant="condensed"
          children={strings.w_add}
          onClick={() => {
            setIsEmpty(true);
            setSelectedBanner({});
            setBanvisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      </h2>

      <p style={{ fontSize: "15px", margin: "10px 0 25px" }}>
        {strings.s_use_images_with_size} 400(h) x 700(w).
      </p>
      <div className="listPaper">
        {bannerData && bannerData.length > 0 ? (
          <SortableList onSortEnd={onDragEnd} axis={"xy"}>
            {bannerData.map((b, i) => {
              return (
                <SortableItem
                  key={`item-${b.id}`}
                  index={i}
                  value={b}
                  setSelectedBannerId={setSelectedBannerId}
                  setSelectedBanner={setSelectedBanner}
                  setIsEmpty={setIsEmpty}
                  setBanvisible={setBanvisible}
                  setDialogOpen={setDialogOpen}
                  strings={strings}
                ></SortableItem>
              );
            })}
          </SortableList>
        ) : (
          <Placeholder />
        )}
      </div>
      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_banner_title}
          text={strings.s_delete_banner_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteBanner();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={banvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setBanvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Banner Form Modal"
      >
        <BannerForm
          banvisible={banvisible}
          isEmpty={isEmpty}
          type="mobile"
          imgType={"banners"}
          data={selectedBanner}
          setBanvisible={setBanvisible}
          onSubmit={() => refreshData({ type: "mobile" })}
        />
      </Modal>
    </div>
  );
}

const SortableItem = SortableElement(
  ({
    value,
    setSelectedBannerId,
    setDialogOpen,
    setIsEmpty,
    setSelectedBanner,
    setBanvisible,
    strings,
  }) => (
    <BannerDiv>
      <img
        alt=""
        src={ImgUrl("banners") + "/" + value.photo}
        onError={addDefaultSrc}
      />
      <div className="hoverImg">
        <GButton
          variant="linkable"
          children={strings.w_delete}
          style={{
            float: "right",
            color: "#fff",
            textDecoration: "underline",
            paddingTop: "135px",
          }}
          onClick={(e) => {
            setSelectedBannerId(value.id);
            setDialogOpen(true);
          }}
        />
        <GButton
          variant="linkable"
          children={strings.w_edit}
          style={{
            float: "right",
            color: "#fff",
            textDecoration: "underline",
            paddingTop: "135px",
          }}
          onClick={(e) => {
            setIsEmpty(false);
            setSelectedBanner(value);
            setBanvisible(true);
          }}
        />
      </div>
    </BannerDiv>
  )
);

const SortableList = SortableContainer(({ children }) => {
  return <div>{children}</div>;
});
