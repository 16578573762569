import React, { Component, useRef, useEffect } from "react";
import Pickup from "../assets/images/mark-p.png";
import Dropoff from "../assets/images/mark-d.png";
import driverIcon from "../assets/images/gms-marker.svg";
const GMap = (props) => {
  // console.log(props);
  const googleMapRef = useRef(null);
  let googleMap = null;
  // console.log(props.data);
  // list of icons

  // list of the marker object along with icon
  const markerList = [];
  // console.log(markerList);
  useEffect(() => {
    if (props.agent?.latitude) {
      markerList.push({
        lat: parseFloat(props.agent?.latitude),
        lng: parseFloat(props?.agent?.longitude),
        icon: driverIcon,
        title: props?.agent?.title,
        address: props.agent?.title,
      });
    }

    props.data.map((c) => {
      let coordinates = {
        lat: parseFloat(c.delivery_lat),
        lng: parseFloat(c.delivery_lng),
        icon: c.delivery_type === "pickup" ? Pickup : Dropoff,
        title: c.delivery_type,
        address: c.delivery_address,
      };
      markerList.push(coordinates);
    });

    googleMap = initGoogleMap();
    var bounds = new window.google.maps.LatLngBounds();
    // const directionsService = new window.google.maps.DirectionsService();
    // const directionsRenderer = new window.google.maps.DirectionsRenderer({
    //   suppressMarkers: true,
    //   polylineOptions: {
    //     strokeColor: "black",
    //   },
    // });

    // directionsRenderer.setMap(googleMap);
    // calculateAndDisplayRoute(directionsService, directionsRenderer);

    markerList.map((x) => {
      const marker = createMarker(x);
      const infoWindow = createInfoWindow(x);

      window.google.maps.event.addListener(marker, "click", function () {
        infoWindow.close(); // Close previously opened infowindow
        infoWindow.setContent(
          '<p class="infowindowstyle">' + x.address + "</p>"
        );
        infoWindow.open(googleMap, marker);
      });
      bounds.extend(marker.position);
    });
    console.log(markerList);
    if (markerList.length <= 1) {
      var latlng = new window.google.maps.LatLng(
        markerList[0].lat,
        markerList[0].lng
      );
      googleMap.setCenter(latlng);
      googleMap.setZoom(15);
    } else {
      googleMap.fitBounds(bounds); // the map to contain all markers
    }
  }, []);

  // function calculateAndDisplayRoute(directionsService, directionsRenderer) {
  //   directionsService
  //     .route({
  //       origin: props.data[0]?.lat + "," + props.data[0]?.lng,
  //       destination: props.data[1]?.lat + "," + props.data[1]?.lng,
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     })
  //     .then((response) => {
  //       directionsRenderer.setDirections(response);
  //     });
  //   // .catch((e) => window.alert("Directions request failed due to " + status));
  // }

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: {
        lat: parseFloat(props.data?.agent?.latitude),
        lng: parseFloat(props.data?.agent?.longitude),
      },
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) =>
    new window.google.maps.Marker({
      position: { lat: markerObj.lat, lng: markerObj.lng },
      map: googleMap,
      title: markerObj.title,
      icon: {
        url: markerObj.icon,
        // set marker width and height
        scaledSize: new window.google.maps.Size(40, 40),
      },
    });

  const createInfoWindow = (markerObj) =>
    new window.google.maps.InfoWindow({
      content: markerObj.address,
    });

  return <div ref={googleMapRef} style={{ width: "100%", height: 250 }} />;
};

export default GMap;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyAl8q9s5LY5qUCPybXG-FWIp717RMB8IqE'
// })(MapContainer)
