import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Tabs = styled.div`
  ul {
    display: inline-flex;
    list-style-type: none;
    padding-left: 0px;
    margin: 0px;

    li {
      float: left;
      margin-bottom: -1px;
      text-transform: capitalize;

      a,
      span {
        color: #000;
        border-bottom: 2px solid transparent;
        text-decoration: none;
        font-size: 15px;
        padding: 10px 25px;
        margin: 0px;
        line-height: 42px;

        &:hover {
          color: #d8ae36;
          cursor: pointer;
          border-bottom: 2px solid #d8ae36;
        }
      }
      a.active,
      span.active {
        color: #d8ae36;
        border-bottom: 2px solid #d8ae36;
      }
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.26);
    cursor: not-allowed;

    &:hover {
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &.condensed {
    background: ${(props) => (props.primary ? "palevioletred" : "white")};
    color: ${(props) => (props.primary ? "white" : "palevioletred")};
    color: white;
    background: #d8ae36;
    border: 0;
  }
  &.outlined {
    color: #d8ae36;
    background: transparent;
    border: 1px solid #d8ae36;
  }

  &.linkable {
    color: #d8ae36;
    background: transparent;
    border: 0px;
    padding: 5px;
  }

  &.text {
    background: transparent;
    padding: 6px 12px;
    border: 0px;
    color: #d8ae36;

    &:hover {
      background: rgba(25, 118, 210, 0.04);
    }
  }
`;

function GTabs(props) {
  return (
    <Tabs style={props.style}>
      {!props.type ? (
        <ul>
          {props.routes.map(
            (r, index) =>
              r.title && (
                <li className="gg" key={index}>
                  <Link
                    to={{ pathname: r.link, state: r.state }}
                    className={r.identifier === props.active ? "active" : ""}
                  >
                    {r.title.replace("-", " ")}
                  </Link>
                </li>
              )
            // <p>Hello, {person.name} from {person.country}!</p>
          )}
        </ul>
      ) : (
        <ul>
          {props.routes.map((r, index) => (
            <li className="gg" key={index}>
              <span
                onClick={(e) => {
                  props.onClick(r);
                }}
                className={r === props.active ? "active" : ""}
                style={Object.assign(
                  {},
                  props.spanStyle,
                  r === props.active ? props.spanActiveStyle : {}
                )}
              >
                {" "}
                {r.replace("_", " ")}
              </span>
            </li>
            // <p>Hello, {person.name} from {person.country}!</p>
          ))}
        </ul>
      )}
    </Tabs>
  );
}

export default GTabs;
