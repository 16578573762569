import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import GEditable from "../gComponents/gEditable";
import GSwitch from "../gComponents/gSwitch";
import GText from "../gComponents/gText";
import { StringsContext } from "../DataFactory/useStrings";

export default function OtherSettings() {
  const [strings, language] = useContext(StringsContext);
  const [otherSettingsData, setOtherSettingsData] = useState({
    sms_campaign_cost: {},
    email_campaign_cost: {},
    lapsed_customer_days: {},
  });
  const [openSnackbar] = useSnackbar();
  const [smsCostSwitch, setSmsCostSwitchVal] = useState();
  const [emailCostSwitch, setEmailCostSwitchVal] = useState();
  const [lapsedCusSwitch, setLapsedCuSwitchVal] = useState();

  const getOtherSettings = () => {
    ApiService({ method: "GET", route: "setting/other" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOtherSettingsData(response.data.data);
          setSmsCostSwitchVal(response.data.data.sms_campaign_cost?.key_value);
          setEmailCostSwitchVal(
            response.data.data.email_campaign_cost?.key_value
          );
          setLapsedCuSwitchVal(
            response.data.data.lapsed_customer_days?.key_value
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOtherSettings();
  }, [language]);

  const switchActionValue = (newValue, sId, keyTitle) => {
    if (!(newValue === "1" || newValue === 1)) {
      //setMinOrderVal(newValue);
      updateSetting(newValue, sId, keyTitle);
    }
  };

  function updateField(newValue, selectedFieldId, keyTitle) {
    console.log(newValue);
    console.log(selectedFieldId);
    updateSetting(newValue, selectedFieldId, keyTitle);
  }
  function updateSetting(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            otherSettingsData[keyTitle] = response.data.data;
            setOtherSettingsData({ ...otherSettingsData });
          }
        } else {
          getOtherSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_other_settings}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        <Table>
          <TableBody>
            {otherSettingsData.sms_campaign_cost?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {otherSettingsData.sms_campaign_cost.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={otherSettingsData.sms_campaign_cost.id}
                    uId={otherSettingsData.sms_campaign_cost.id}
                    currentValue={smsCostSwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setSmsCostSwitchVal(newVal);
                      switchActionValue(newVal, id, "sms_campaign_cost");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {smsCostSwitch != "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={
                        otherSettingsData.sms_campaign_cost.key_value
                      }
                      id={otherSettingsData.sms_campaign_cost.id}
                      onSubmit={(newVal, id) => {
                        otherSettingsData.sms_campaign_cost.key_value = newVal;
                        setOtherSettingsData(otherSettingsData);
                        updateField(newVal, id, "sms_campaign_cost");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}

            {otherSettingsData.email_campaign_cost?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {otherSettingsData.email_campaign_cost.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={otherSettingsData.email_campaign_cost.id}
                    uId={otherSettingsData.email_campaign_cost.id}
                    currentValue={emailCostSwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setEmailCostSwitchVal(newVal);
                      switchActionValue(newVal, id, "email_campaign_cost");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {emailCostSwitch != "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={
                        otherSettingsData.email_campaign_cost.key_value
                      }
                      id={otherSettingsData.email_campaign_cost.id}
                      onSubmit={(newVal, id) => {
                        otherSettingsData.email_campaign_cost.key_value =
                          newVal;
                        setOtherSettingsData(otherSettingsData);
                        updateField(newVal, id, "email_campaign_cost");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}

            {otherSettingsData.lapsed_customer_days?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {otherSettingsData.lapsed_customer_days.key_display_title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={otherSettingsData.lapsed_customer_days.id}
                    uId={otherSettingsData.lapsed_customer_days.id}
                    currentValue={lapsedCusSwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setLapsedCuSwitchVal(newVal);
                      switchActionValue(newVal, id, "lapsed_customer_days");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {lapsedCusSwitch != "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={
                        otherSettingsData.lapsed_customer_days.key_value
                      }
                      id={otherSettingsData.lapsed_customer_days.id}
                      onSubmit={(newVal, id) => {
                        otherSettingsData.lapsed_customer_days.key_value =
                          newVal;
                        setOtherSettingsData(otherSettingsData);
                        updateField(newVal, id, "lapsed_customer_days");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
