import React, { useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import { StringsContext } from "../DataFactory/useStrings";

export default function CreateCustomer(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const location = useLocation();

  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: "",
  };

  const onSubmit = (values) => {
    if (props.data) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "customers/" + props.data.id : "customers";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (props.onSubmit) {
            props.onSubmit();
          }
          if (!props.data) {
            if (location.pathname === "/customers") {
              window.location.reload(false);
            } else {
              window.location = "/customers";
            }
          }

          props.setCcvisible(false);
        }
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings.w_required),
    email: Yup.string()
      .email(strings.s_invalid_email_address)
      .required(strings.w_required),
    phone: Yup.string()
      .matches(
        /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
        strings.s_invalid_phone_number
      )
      .min(9, strings.s_min_length_is_10)
      .max(14, strings.w_too_long)
      .required(strings.w_required),
    password: props.data
      ? ""
      : Yup.string()
          .required(strings.w_required)
          .min(6, strings.s_password_is_too_short),
  });

  if (props.ccvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_customer : strings.w_create_customer}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_first_name}
                name="first_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("first_name", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_last_name}
                name="last_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("last_name", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label={strings.w_email_id}
                name="email"
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_password}
                name="password"
              />
              <FormikControl
                control="numberInput"
                type="text"
                label={strings.w_phone}
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9-+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <br />
              <GButton
                variant="condensed"
                // disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "calc(100% - 30px)",
                }}
                children={props.data ? strings.w_update : strings.w_create}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
