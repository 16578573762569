import React, { useState, useEffect, useContext } from "react";
import GItemTable from "../gComponents/gCartItems";
import { ProductFilter } from "../components/productFilterComponent";
import useCart from "../useCart";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { ApiLoginService, ApiService } from "../services";
import { GTableContent } from "../gComponents/gContent";
import OrderProductVariants from "../components/orderProductVariants";

import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderEdit(props) {
  const [openSnackbar] = useSnackbar();
  const [strings] = useContext(StringsContext);
  const [selectedProduct, setSelectedProduct] = useState({});
  const {
    cart,
    setCart,
    cartStore,
    addProduct,
    removeProduct,
    decreaseQuantity,
    decimalQuantity,
  } = useCart();
  const settings = useSettings(true);
  const [newOrderData, setNewOrderData] = useState({
    products: [],
    summary: {
      data: [],
    },
  });
  const [orderVarVisible, setOrderVarVisible] = useState(false);

  useEffect(() => {
    const getCartProducts = () => {
      ApiLoginService("POST", "c/order/cart", {
        store_id: props.cartStore,
        products: props.cart,
      }).then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setNewOrderData(response.data);
          setCart(response.data.products);
        } else {
          setNewOrderData(response.data);
          setCart(response.data.products || []);
          openSnackbar(response.message);
        }
      });
    };
    getCartProducts();
  }, []);

  useEffect(() => {
    const getCartProducts = () => {
      ApiLoginService("POST", "c/order/cart", {
        store_id: cartStore,
        products: cart,
      }).then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setNewOrderData(response.data);
        } else {
          setNewOrderData(response.data);
          openSnackbar(response.message);
        }
      });
    };
    if (cartStore) {
      getCartProducts();
    }
  }, [cart]);

  const UpdateItems = () => {
    console.log(cart);
    ApiService({
      method: "PUT",
      route: "order/products/" + props.orderId,
      body: {
        products: cart,
      },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.onUpdate();
      }
      openSnackbar(response.data.message);
    });
  };

  return (
    <div>
      <Card>
        <CardHead>
          {strings.w_search_items}
          <div style={{ float: "right" }}>
            <span
              style={{ cursor: "pointer", fontSize: "15px", color: "#D8AE36" }}
              onClick={() => UpdateItems()}
            >
              {strings.w_update}
            </span>{" "}
            &nbsp; &nbsp;
            <span
              style={{ cursor: "pointer", fontSize: "15px", color: "#D8AE36" }}
              onClick={() => props.onCancel()}
            >
              {strings.w_cancel}
            </span>
          </div>
        </CardHead>
        <ProductFilter
          status={1}
          value={selectedProduct ? selectedProduct.id : ""}
          style={{
            minWidth: "250px",
          }}
          storeId={cartStore ? cartStore : props.cartStore}
          handleProductChange={(id, value) => {
            setSelectedProduct(value ? value : "");
            if (value) {
              console.log(value);
              if (value.variants_available === false) {
                value.variants = [];
                addProduct(value);
              } else {
                setSelectedProduct(value);
                setOrderVarVisible(true);
              }
            }
          }}
        />

        {cart && newOrderData && newOrderData.products.length > 0 ? (
          <React.Fragment>
            <br />
            <br />
            <CardHead>
              {strings.w_items} ({newOrderData.products.length})
            </CardHead>
            <GItemTable
              items={newOrderData.products}
              currencySymbol={settings.currency_symbol}
              editable={true}
              onRemove={(item) => removeProduct(item)}
              onIncQuantity={(item) => addProduct(item, item.variants)}
              onDecQuantity={(item) => decreaseQuantity(item)}
              decimalQuantity={(item) => decimalQuantity(item)}
            />
          </React.Fragment>
        ) : (
          ""
        )}

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <div style={{ width: "250px", display: "inline-block" }}>
            {cart && newOrderData ? (
              <React.Fragment>
                {newOrderData?.summary?.data.map((p) => {
                  return (
                    <GTableContent
                      key={p.title}
                      title={p.title}
                      value={settings.currency_symbol + "" + p.value}
                      align="right"
                      lAlign="right"
                      color="#777"
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              ""
            )}
            <hr />

            <GTableContent
              title={strings.w_total}
              value={
                settings.currency_symbol +
                "" +
                newOrderData?.summary?.total_display
              }
              align="right"
              lAlign="right"
              bold="550"
            />
          </div>
        </div>
      </Card>

      <Modal
        isOpen={orderVarVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={{
          content: {
            width: "450px",
            maxWidth: "100%",
          },
        }}
        contentLabel="Order Variant Form Modal"
      >
        <OrderProductVariants
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(item, itemVariants) => {
            console.log(itemVariants);
            addProduct(item, itemVariants);

            // setCart([...cart, item]);
          }}
        />
      </Modal>
    </div>
  );
}
