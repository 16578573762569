import React, { useState, useContext } from "react";
import { SettingsContext } from "./DataFactory/useSettings";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import Dashboard from "./dashboard/dashboard";
import Orders from "./orders/ordersList";
import OrderDetail from "./orders/orderDetails";
import OrderCreate from "./orders/orderCreate";
import OrderInvoice from "./orders/orderInvoice";
import OrderThermalInvoice from "./orders/orderThermalInvoice";
import OrderChatSupport from "./orderChatSupport/users";
import Customers from "./customers/customersList";
import CustomerDetail from "./customers/customerDetails";
import Drivers from "./drivers/driversList";
import DriverDetail from "./drivers/driverDetails";
import MapView from "./mapView/index";
import Stores from "./stores/storesList";
import StoreLogs from "./stores/storeLogs";
import StoreDetail from "./stores/storeDetails";
import Reviews from "./components/reviewsList";
import Products from "./products/productsList";
import productCategories from "./products/categoriesList";
import Promotions from "./components/promotionsList";
import BulkNotifications from "./components/bulkPushNotifications";
import AutomatedPromotions from "./components/automatedPromotions";
import EmailNewsletter from "./components/emailNewsletter";
import Reports from "./reports/reports";
import ReportDetails from "./reports/reportDetails";
import Withdrawals from "./components/withdrawals";
import Transactions from "./components/transactions";
import TransactionsFailed from "./components/transactionsFailed";
import SettingView from "./settings/settings";
import ChatSupport from "./chatSupport/users";
import Login from "./Login";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
import Modal from "react-modal";
import GButton from "./gComponents/gButton";
import PromotionCampaign from "./components/couponCampaign";
import CourierOrderDetail from "./orders/courierOrderDetails";
import CourierOrderInvoice from "./orders/courierOrderInvoice";
import AtmOrderDetail from "./orders/atmOrderDetails";
import VariantTypes from "./settings/variantTypes";

const mainStyle = {
  display: "inline-flex",
  width: "100%",
};
const contentStyle = {
  background: "#f4f5f9",
  width: "82%",
  height: "100vh",
  overflow: "auto",
};

Modal.setAppElement("#root");
export default function App() {
  const location = useLocation();
  // const setting = useSettings(true);
  const [toogleSidebar, setToggleSidebar] = useState(false);

  let match = useRouteMatch("/order-invoice/:id");
  let match2 = useRouteMatch("/order-thermal-invoice/:id");
  let match3 = useRouteMatch("/courier-order-invoice/:id");
  let match4 = useRouteMatch("/atm-order-invoice/:id");
  let url = match ? match.url : "";
  let tUrl = match2 ? match2.url : "";
  let cUrl = match3 ? match3.url : "";
  let aUrl = match4 ? match4.url : "";
  // let settings = localStorage.getItem("settings")
  //   ? JSON.parse(localStorage.getItem("settings"))
  //   : localStorage.getItem("settings");

  const { settings } = useContext(SettingsContext);
  // console.log(settings);

  const isLoggedIn = () => {
    if (
      localStorage.getItem("aAccessToken") &&
      localStorage.getItem("aExpiresIn") > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isExpired = () => {
    let s = localStorage.getItem("settings");
    s = s ? JSON.parse(s) : s;
    // console.log(s?.expired);
    if (s?.expired === true) {
      return true;
    } else {
      return false;
    }
  };

  function isPermissionExist(per) {
    if (settings?.currency_symbol) {
      let exist = settings?.permissions?.includes(per);
      // console.log(exist);
      return exist;
    } else {
      let exist = settings?.permissions?.includes(per);
      // console.log(exist);
      return exist;
    }
  }

  return (
    <div>
      {isLoggedIn() &&
      !(
        url === location.pathname ||
        tUrl === location.pathname ||
        cUrl === location.pathname ||
        aUrl === location.pathname
      ) ? (
        <React.Fragment>
          <div style={mainStyle}>
            <Sidebar
              style={{
                width: toogleSidebar == true ? "0px" : "18%",
                minWidth: toogleSidebar == true ? "0px" : "165px",
                transition: "width 0.5s ,min-width 0.5s",
              }}
              toggle={toogleSidebar}
            />
            <div
              style={Object.assign({}, contentStyle, {
                width: toogleSidebar == true ? "100%" : "82%",
                transition: "width 0.5s",
              })}
            >
              <Header />

              <div
                className="mainInnerStyles"
                style={{
                  height: "calc(100vh - 54px)",
                  width: "100%",
                  overflow: "overlay",
                  overflow: "auto",
                  position: "relative",
                }}
              >
                {location.pathname == "/map-view" && (
                  <>
                    {toogleSidebar == true ? (
                      <GButton
                        variant="condensed"
                        style={{
                          position: "absolute",
                          zIndex: "8",
                          top: "0px",
                          padding: "0.2em",
                          left: "0px",
                          background: "#222326",
                          color: "#555",
                          border: "1px solid #101010",
                          borderRadius: "0",
                        }}
                        onClick={() => setToggleSidebar(false)}
                      >
                        <KeyboardArrowRight
                          style={{
                            width: "30px",
                            height: "30px",
                            marginleft: "3px",
                          }}
                        />
                      </GButton>
                    ) : (
                      <GButton
                        variant="condensed"
                        style={{
                          position: "absolute",
                          zIndex: "8",
                          top: "0px",
                          left: "0px",
                          padding: "0.2em",
                          background: "#222326",
                          color: "#777",
                          border: "1px solid #101010",
                          borderRadius: "0",
                        }}
                        onClick={() => setToggleSidebar(true)}
                      >
                        <KeyboardArrowLeft
                          style={{
                            width: "30px",
                            height: "30px",
                            marginleft: "3px",
                          }}
                        />
                      </GButton>
                    )}
                  </>
                )}

                {settings?.user?.user_type === "sa" ||
                settings?.user?.user_type === "admin" ? (
                  <Switch>
                    <Route path="/dashboard" component={Dashboard}></Route>
                    <Route path="/orders" component={Orders}></Route>
                    <Route
                      path="/order/:orderId"
                      component={OrderDetail}
                    ></Route>
                    <Route path="/OrderCreate" component={OrderCreate}></Route>
                    <Route
                      path="/courier-order/:orderId"
                      component={CourierOrderDetail}
                    ></Route>
                    <Route
                      path="/atm-order/:orderId"
                      component={AtmOrderDetail}
                    ></Route>
                    <Route
                      path="/order-chat-support"
                      component={OrderChatSupport}
                    ></Route>
                    <Route
                      path="/order-chat-support/:uId"
                      component={OrderChatSupport}
                    ></Route>

                    <Route path="/customers" component={Customers}></Route>
                    <Route
                      path="/customer/:customerId/:detailType"
                      component={CustomerDetail}
                    ></Route>
                    <Route
                      path="/driver/:driverId/:detailType"
                      state="driver"
                      component={DriverDetail}
                    ></Route>
                    <Route
                      path="/drivers"
                      state="driver"
                      component={Drivers}
                    ></Route>
                    <Route
                      path="/map-view"
                      state="map"
                      component={MapView}
                    ></Route>
                    <Route path="/stores" component={Stores}></Route>
                    <Route
                      path="/store/:storeId/:detailType"
                      component={StoreDetail}
                    ></Route>
                    <Route path="/reviews" component={Reviews}></Route>
                    <Route path="/store-logs" component={StoreLogs}></Route>

                    <Route path="/products" component={Products}></Route>
                    <Route
                      path="/categories"
                      component={productCategories}
                    ></Route>
                    <Route
                      path="/variant-types"
                      component={VariantTypes}
                    ></Route>
                    <Route
                      path="/bulk-notifications/:type"
                      component={BulkNotifications}
                    ></Route>
                    <Route
                      path="/promotion-campaign"
                      component={PromotionCampaign}
                    ></Route>
                    {settings?.newsletter == true && (
                      <Route
                        path="/email-newsletter"
                        component={EmailNewsletter}
                      ></Route>
                    )}
                    <Route path="/promotions" component={Promotions}></Route>
                    <Route
                      path="/automated-promotions"
                      component={AutomatedPromotions}
                    ></Route>
                    <Route path="/reports" component={Reports}></Route>
                    <Route
                      path="/report/details/:identifier"
                      component={ReportDetails}
                    ></Route>

                    <Route
                      path="/transactions"
                      component={Transactions}
                    ></Route>
                    <Route
                      path="/failed-payments/:type"
                      component={TransactionsFailed}
                    ></Route>
                    <Route
                      path="/settings/:settingId"
                      component={SettingView}
                    />
                    <Route
                      path="/withdrawals/:withdrawId"
                      component={Withdrawals}
                    />

                    {settings?.support_chat == true && (
                      <Route path="/chat-support" component={ChatSupport} />
                    )}
                    {settings?.support_chat == true && (
                      <Route
                        path="/chat-support/:uId"
                        component={ChatSupport}
                      />
                    )}

                    <Redirect to="/dashboard" from="/" />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/dashboard" component={Dashboard}></Route>
                    {isPermissionExist("order_index") && (
                      <Route path="/orders" component={Orders}></Route>
                    )}
                    {isPermissionExist("order_index") && (
                      <Route
                        path="/order/:orderId"
                        component={OrderDetail}
                      ></Route>
                    )}
                    {isPermissionExist("order_index") && (
                      <Route
                        path="/courier-order/:orderId"
                        component={CourierOrderDetail}
                      ></Route>
                    )}
                    {isPermissionExist("order_index") && (
                      <Route
                        path="/atm-order/:orderId"
                        component={AtmOrderDetail}
                      ></Route>
                    )}
                    {isPermissionExist("order_index") &&
                      isPermissionExist("order_store") && (
                        <Route
                          path="/OrderCreate"
                          component={OrderCreate}
                        ></Route>
                      )}

                    <Route
                      path="/order-chat-support"
                      component={OrderChatSupport}
                    ></Route>
                    <Route
                      path="/order-chat-support/:uId"
                      component={OrderChatSupport}
                    ></Route>
                    {isPermissionExist("customer_index") && (
                      <Route path="/customers" component={Customers}></Route>
                    )}
                    {isPermissionExist("customer_index") && (
                      <Route
                        path="/customer/:customerId/:detailType"
                        component={CustomerDetail}
                      ></Route>
                    )}

                    {isPermissionExist("agent_index") && (
                      <Route
                        path="/drivers"
                        state="driver"
                        component={Drivers}
                      ></Route>
                    )}

                    {isPermissionExist("agent_index") && (
                      <Route
                        path="/driver/:driverId/:detailType"
                        state="driver"
                        component={DriverDetail}
                      ></Route>
                    )}
                    {isPermissionExist("agent_index") && (
                      <Route
                        path="/map-view"
                        state="map"
                        component={MapView}
                      ></Route>
                    )}
                    {isPermissionExist("store_index") && (
                      <Route path="/stores" component={Stores}></Route>
                    )}
                    {isPermissionExist("store_index") && (
                      <Route
                        path="/store/:storeId/:detailType"
                        component={StoreDetail}
                      ></Route>
                    )}
                    {(isPermissionExist("store_reviews") ||
                      isPermissionExist("agent_reviews")) && (
                      <Route path="/reviews" component={Reviews}></Route>
                    )}
                    {isPermissionExist("store_index") && (
                      <Route path="/store-logs" component={StoreLogs}></Route>
                    )}
                    {isPermissionExist("product_index") && (
                      <Route path="/products" component={Products}></Route>
                    )}
                    {isPermissionExist("product_index") && (
                      <Route
                        path="/categories"
                        component={productCategories}
                      ></Route>
                    )}
                    {isPermissionExist("variant_type_index") && (
                      <Route
                        path="/variant-types"
                        component={VariantTypes}
                      ></Route>
                    )}

                    {isPermissionExist("coupon_index") &&
                      isPermissionExist("promotion_bulk") && (
                        <Route
                          path="/bulk-notifications/:type"
                          component={BulkNotifications}
                        ></Route>
                      )}

                    {isPermissionExist("coupon_index") &&
                      isPermissionExist("newsletter") &&
                      settings?.newsletter == true && (
                        <Route
                          path="/email-newsletter"
                          component={EmailNewsletter}
                        ></Route>
                      )}
                    {isPermissionExist("coupon_index") && (
                      <Route path="/promotions" component={Promotions}></Route>
                    )}

                    {isPermissionExist("report_index") && (
                      <Route path="/reports" component={Reports}></Route>
                    )}
                    {isPermissionExist("report_index") && (
                      <Route
                        path="/report/details/:identifier"
                        component={ReportDetails}
                      ></Route>
                    )}

                    {isPermissionExist("transaction_index") && (
                      <Route
                        path="/transactions"
                        component={Transactions}
                      ></Route>
                    )}

                    {isPermissionExist("support_chat") &&
                      settings?.support_chat == true && (
                        <Route path="/chat-support" component={ChatSupport} />
                      )}
                    {isPermissionExist("support_chat") &&
                      settings?.support_chat == true && (
                        <Route
                          path="/chat-support/:uId"
                          component={ChatSupport}
                        />
                      )}
                    <Redirect to="/dashboard" from="/" />
                    <Redirect to="/expires" from="/" />
                  </Switch>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Switch>
          {location?.pathname !== "/" && <Redirect to="/" from={"/dasboard"} />}
          <Redirect to="/" from="/expires" />
          <Route exact path="/" component={Login}></Route>
          <Route
            path="/order-invoice/:orderId"
            component={OrderInvoice}
          ></Route>
          <Route
            path="/order-thermal-invoice/:orderId"
            component={OrderThermalInvoice}
          ></Route>
          <Route
            path="/courier-order-invoice/:orderId"
            component={CourierOrderInvoice}
          ></Route>
          <Route
            path="/atm-order-invoice/:orderId"
            component={CourierOrderInvoice}
          ></Route>
        </Switch>
      )}
    </div>
  );
}
