import React, { useState, useEffect, useContext } from "react";
import { ApiService, getUserType, isPermissionExist } from "../services";
import { Add, Edit } from "@material-ui/icons";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent } from "../gComponents/gContent";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Orders from "../orders/ordersList";
import WalletPoints from "../components/walletPointsList";
import Documents from "../components/documentsList";
import GMap from "../gComponents/gMaps";
import StoreEditForm from "../forms/storeEditForm";
import StoreManagerForm from "../forms/storeManagerForm";
import { useSnackbar } from "react-simple-snackbar";
import GTagsSearchInput from "../gComponents/gTagsSearchInput";
import GButton from "../gComponents/gButton";
import useSettings from "../useSettings";
import GRouting from "../gComponents/gRouteBreadCrumbs";
import GText from "../gComponents/gText";
import Modal from "react-modal";
import GNoFound from "../gComponents/gNoFound";
import { StringsContext } from "../DataFactory/useStrings";

export default function StoreDetail(props) {
  const [strings] = useContext(StringsContext);
  let { storeId, detailType } = useParams();
  const [storeData, setStoreData] = useState({});
  const [openSnackbar] = useSnackbar();
  const [storeLocationData, setStoreLocationData] = useState({
    lat: "",
    lon: "",
  });
  const [storeManagerData, setStoreManagerData] = useState({});
  const [csmvisible, setCsmvisible] = useState(false);
  const [sevisible, setSevisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [locEdit, setLocEdit] = useState(false);
  const [tagsEdit, setTagsEdit] = useState(false);
  const settings = useSettings(false);
  const [storeLocation, setLocation] = useState({
    latitude: "",
    longitude: "",
    address: "",
  });

  const RoutLinks = [
    {
      title: strings.w_stores,
      identifier: "stores",
      link: "/stores",
      state: "store",
    },
  ];

  const storeRoutes = [
    getUserType() || isPermissionExist("order_index")
      ? {
          title: strings.w_orders,
          identifier: "orders",
          link: "/store/" + storeId + "/orders",
          state: "store",
        }
      : "",
    getUserType() || isPermissionExist("store_wallet")
      ? {
          title: strings.w_wallet,
          identifier: "wallet",
          link: "/store/" + storeId + "/wallet",
          state: "store",
        }
      : "",
    getUserType()
      ? {
          title: strings.w_documents,
          identifier: "documents",
          link: "/store/" + storeId + "/documents",
          state: "store",
        }
      : "",
    getUserType()
      ? {
          title: strings.w_manager_documents,
          identifier: "manager-documents",
          link: "/store/" + storeId + "/manager-documents",
          state: "store",
        }
      : "",
  ];

  const getStoreDetail = () => {
    ApiService({ method: "GET", route: "stores/" + storeId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setStoreData(response.data.data);
          setStoreManagerData(response.data.data.manager);
          setStoreLocationData({
            lat: response.data.data.latitude,
            lon: response.data.data.longitude,
            address: response.data.data.address,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStoreDetail();
  }, [storeId]);

  function handleUpdateImage(imgValue, id, thumbImg) {
    console.log(imgValue);
    var data = { photo: imgValue, thumb_photo: thumbImg };
    ApiService({
      method: "PUT",
      route: "stores/" + storeId,
      body: data,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setStoreData(response.data.data);
      }
      openSnackbar(response.data.message);
      return;
    });
  }
  const handleLocation = (address, loc) => {
    var send_loc_json = {
      latitude: storeLocationData.lat,
      longitude: storeLocationData.lon,
      address: storeLocationData.address,
    };
    console.log(storeLocation);
    var sendData = storeLocation.latitude ? storeLocation : send_loc_json;
    console.log(sendData);

    ApiService({
      method: "PUT",
      route: "stores/" + storeId,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        getStoreDetail();
        setLocEdit(false);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const handleTagsUpdate = (tags, titlekey) => {
    let obj = {};
    var send_tag_json = [];
    send_tag_json = tags.map((s) => {
      return s;
    });
    obj[titlekey] = send_tag_json;
    ApiService({
      method: "PUT",
      route: "stores/" + storeId,
      body: obj,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setTagsEdit(false);
        getStoreDetail();
      }
      openSnackbar(response.data.message);
      return;
    });
  };
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div className="mainInnerView">
      <GRouting links={RoutLinks} currentPage={storeData.title} />
      <div style={{ width: "50%", display: "inline-block" }}>
        <GInfo
          id={storeData.id}
          title={storeData.title}
          subtitle={storeData.phone}
          key={storeData.id}
          className="headerInfo"
          imgType="store"
          imgEdit={
            getUserType() || isPermissionExist("store_update") ? true : false
          }
          photo={storeData.thumb_photo}
          uploadImage={handleUpdateImage}
        />
      </div>
      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card
          style={{
            borderBottom: "1px solid #e2e2e2",
            margin: "0",
            padding: "20px",
          }}
        >
          <CardHead>
            {strings.w_manager}
            {getUserType() || isPermissionExist("store_update") ? (
              storeManagerData.id ? (
                <Edit
                  onClick={() => {
                    setIsEmpty(false);
                    setCsmvisible(true);
                  }}
                />
              ) : (
                <Add
                  onClick={() => {
                    setIsEmpty(true);
                    setCsmvisible(true);
                  }}
                />
              )
            ) : (
              ""
            )}
          </CardHead>

          {storeManagerData.id ? (
            <GInfo
              title={storeManagerData.first_name}
              subtitle={storeManagerData.phone}
              key={storeManagerData.id}
              imgType="user"
              photo={storeManagerData.photo}
            />
          ) : (
            <>
              <GText
                med
                text={strings.w_not_available}
                style={{ padding: "10px 0 5px", margin: "0" }}
              />
            </>
          )}
        </Card>
        <Card style={{ minHeight: "200px", padding: "20px" }}>
          <CardHead>
            {strings.w_store_details}
            {getUserType() || isPermissionExist("store_update") ? (
              <Edit
                onClick={() => {
                  setSevisible(true);
                }}
              />
            ) : (
              ""
            )}
          </CardHead>
          <GContent
            title={strings.w_commission}
            value={storeData.commission + "%"}
          />
          <GContent
            title={strings.w_store_availability}
            value={
              storeData.store_status === 1 ? strings.w_open : strings.w_closed
            }
          />
          <GContent
            title={strings.w_balance}
            value={settings.currency_symbol + "" + storeData.balance}
          />
          <GContent
            title={strings.w_featured}
            value={storeData.featured === 1 ? strings.w_true : strings.w_false}
            tooltip={true}
            tContent={strings.s_featured_stores_shown_on_top_of_stores_list}
          />
        </Card>
        <Card style={{ minHeight: "130px", padding: "20px" }}>
          <CardHead>
            {strings.w_tags}
            {tagsEdit === true ? (
              ""
            ) : getUserType() || isPermissionExist("store_update") ? (
              <Edit
                onClick={() => {
                  setTagsEdit(true);
                }}
              />
            ) : (
              ""
            )}
          </CardHead>

          {storeData && storeData.tags ? (
            <GTagsSearchInput
              data={storeData.tags}
              tagsEdit={tagsEdit}
              setTagsEdit={setTagsEdit}
              onSubmit={(tags) => handleTagsUpdate(tags, "tags")}
            />
          ) : (
            ""
          )}
        </Card>

        {/* <Card style={{ minHeight: "130px", padding: "20px" }}>
          <div>
            <CardHead>Geofences</CardHead>
            {storeData.geofences ? (
              <GGeoTagsInput
                data={storeData.geofences}
                onSubmit={(tags) => handleTagsUpdate(tags, "geofences")}
              />
            ) : (
              ""
            )}
          </div>
        </Card> */}

        <Card style={{ minHeight: "200px" }}>
          <CardHead>
            {strings.w_location}
            {locEdit === true ? (
              <React.Fragment>
                <GButton
                  variant="linkable"
                  children={strings.w_cancel}
                  style={{
                    float: "right",
                    padding: "3px 3px 20px",
                    fontSize: "15px",
                    color: "#fb1d04",
                  }}
                  onClick={() => {
                    setLocation({
                      lat: "",
                      lng: "",
                      address: "",
                    });
                    setLocEdit(false);
                  }}
                ></GButton>
                <GButton
                  variant="linkable"
                  children={strings.w_update}
                  style={{
                    float: "right",
                    padding: "3px 3px 20px",
                    fontSize: "15px",
                  }}
                  onClick={handleLocation}
                ></GButton>
              </React.Fragment>
            ) : getUserType() || isPermissionExist("store_update") ? (
              <Edit
                onClick={() => {
                  setLocEdit(true);
                }}
              />
            ) : (
              ""
            )}
          </CardHead>
          {locEdit === true ? (
            ""
          ) : (
            <GContent title="" value={storeData.address} />
          )}
          {storeLocationData.lat ? (
            <GMap
              centerLocation={{
                lat: storeLocationData.lat,
                lng: storeLocationData.lon,
              }}
              onChange={(address, loc) =>
                setLocation({
                  latitude: loc.lat(),
                  longitude: loc.lng(),
                  address: address.address,
                })
              }
              search={locEdit}
              height="200px"
              type="viewStoreLoc"
              address={storeData.address}
            />
          ) : (
            ""
          )}
        </Card>

        <Modal
          isOpen={csmvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setCsmvisible(false)}
          style={{
            content: {
              width: "400px",
            },
          }}
          contentLabel="Store Manager Form Modal"
        >
          <StoreManagerForm
            csmvisible={csmvisible}
            storeId={storeId}
            setCsmvisible={setCsmvisible}
            data={storeManagerData}
            isEmpty={isEmpty}
            onSubmit={getStoreDetail}
          />
        </Modal>

        <Modal
          isOpen={sevisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setSevisible(false)}
          style={{
            content: {
              width: "480px",
            },
          }}
          contentLabel="Store Edit Form Modal"
        >
          <StoreEditForm
            sevisible={sevisible}
            setSevisible={setSevisible}
            data={storeData}
            onSubmit={getStoreDetail}
            settings={settings}
          />
        </Modal>
      </div>

      <div
        style={{
          width: "calc(100% - 30%)",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <GTabs routes={storeRoutes} active={detailType} key={storeId}></GTabs>

        <Switch>
          {getUserType() || isPermissionExist("order_index") ? (
            <Route exact path={"/store/" + storeId + "/orders"}>
              <Orders type="store" value={storeId} title="store_id" />
            </Route>
          ) : (
            ""
          )}
          {getUserType() || isPermissionExist("store_wallet") ? (
            <Route exact path={"/store/" + storeId + "/wallet"}>
              <WalletPoints type="store" value={storeId} title="store_id" />
            </Route>
          ) : (
            ""
          )}
          {getUserType() ? (
            <Route exact path={"/store/" + storeId + "/documents"}>
              <Documents type="store" value={storeId} title="store_id" />
            </Route>
          ) : (
            ""
          )}
          {getUserType() ? (
            <Route exact path={"/store/" + storeId + "/manager-documents"}>
              <Documents
                type="manager"
                value={storeManagerData.id}
                title="user_id"
              />
            </Route>
          ) : (
            ""
          )}

          <Route exact path={"/store/" + storeId + "/not"}>
            <GNoFound />
          </Route>

          <Redirect
            to={"/store/" + storeId + "/orders"}
            from={"/store/" + storeId + "/wallet"}
          />
          <Redirect
            to={"/store/" + storeId + "/not"}
            from={"/store/" + storeId + "/orders"}
          />
        </Switch>
      </div>
    </div>
  );
}
