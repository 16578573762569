import React from "react";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import styled from "styled-components";

const Div = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 5px 10px;
  label {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #aaa;
  }
  input[type="text"] {
    border: 0;
    margin: 0;
    padding: 0;
    height: 26px;
    font-size: 14px;
    font-family: inherit;
    font-weight: 400;
    color: #000;
  }
`;

function datePicker(props) {
  const { label, name, format, ...rest } = props;

  return (
    <div className="form-control" style={props.divstyle}>
      <Div>
        {label ? <label htmlFor={name}>{label}</label> : ""}
        <Field name={name} {...rest}>
          {({ form, field }) => {
            const { setFieldValue } = form;
            const { value } = field;

            return (
              <DateView
                id={name}
                {...field}
                {...rest}
                selected={value}
                onChange={(val) => {
                  setFieldValue(name, val);
                }}
                dateFormat={format}
                showYearDropdown
                showTimeSelect={props.showTimeSelect || false}
              />
            );
          }}
        </Field>
      </Div>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default datePicker;
