import React, { useState, useEffect, useContext } from "react";
import GMap from "../gComponents/gMaps";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GGeoTagsInput from "../gComponents/gGeofencesInput";
import { useLocation } from "react-router-dom";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";
import useSettings from "../useSettings";
import GTagsInput from "../gComponents/gClassesSearchInput";
import GImageEditor from "../gComponents/gImageEditor";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { StringsContext } from "../DataFactory/useStrings";

const PriceRangeValues = [
  { title: "$", value: 1 },
  { title: "$$", value: 2 },
  { title: "$$$", value: 3 },
];

export default function CreateStore(props) {
  const [strings] = useContext(StringsContext);
  const settings = useSettings(true);
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const { data: branchesData } = useDataFctory("branches", false);
  const { data: brandsData } = useDataFctory("brands", false);
  const { data: classesData } = useDataFctory("store/classes", false);
  const { data: groupsData } = useDataFctory("store_groups", false);
  const [subClassesData, setSubClassesData] = useState([]);
  const [geofencesList, setGeofencesList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (classesData.length > 0) {
      let subData = [];
      classesData.map((sub, i) => {
        sub.childs.map((ch, ind) => {
          subData.push(ch);
        });
      });
      setSubClassesData([...subClassesData, ...subData]);
    }
  }, [classesData]);

  const initialValues = {
    cover_photo: "",
    title: props.data ? props.data.title : "",
    phone: props.data ? props.data.phone : "",
    address: props.data ? props.data.address : "",
    photo: "",
    latitude: "",
    longitude: "",
    commission: "",
    pack_time: "",
    order_type: "",
    delivery_type: "",
    geofences: [],
    branch_id: "",
    aerial_distance: "",
    brand_id: "",
    aerialDistance: 0,
    delivery_otp: 0,
    classes: [],
    sub_classes: [],
    price_range: "",
    open_till: "",
    allow_inventory: 0,
    groups: [],
    theme: "",
    lapsed_customer_days: "",
  };

  const deliveryTypes = [
    {
      title: strings.w_both,
      value: "",
    },
    {
      title: strings.w_asap_only,
      value: "asap",
    },
    {
      title: strings.w_scheduled_only,
      value: "scheduled",
    },
  ];

  const orderTypes = [
    {
      title: strings.w_both,
      value: "",
    },
    {
      title: strings.w_home_delivery,
      value: "home_delivery",
    },
    {
      title: strings.w_store_pickup,
      value: "store_pickup",
    },
  ];

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  const onSubmit = (values) => {
    setIsDisabled(true);
    let sendData = { ...values };
    sendData.aerial_distance = sendData.aerial_distance || 0;
    console.log(sendData);
    //return;
    ApiService({ method: "POST", route: "stores", body: sendData }).then(
      (response) => {
        console.log(response.data);
        openSnackbar(response.data.message);
        if (response.data.status_code === 1) {
          if (location.pathname === "/stores") {
            window.location.reload(false);
          } else {
            window.location = "/stores";
          }
          props.setCsvisible(false);
        }
        setIsDisabled(false);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    phone: Yup.string().required(strings.w_required),
    address: Yup.string().required(strings.w_required),
    commission: Yup.string().required(strings.w_required),
  });

  if (props.csvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_create_store} </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div
                style={{
                  padding: "0 15px 10px",
                }}
              >
                <label
                  style={{
                    fontSize: "15px",
                    fontWeight: "450",
                  }}
                >
                  {strings.w_cover_photo}
                </label>
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.cover_photo = newValue;
                    setFieldValue("cover_photo", newValue);
                  }}
                  id="cover_photo"
                  image={
                    values.cover_photo
                      ? ImgUrl("store") + "/" + values.cover_photo
                      : ""
                  }
                  type="store"
                  style={{ height: "155px", paddingTop: "60px" }}
                  divstyle={{
                    margin: "0",
                    display: "block",
                    minWidth: "95%",
                    padding: "5px 0",
                  }}
                />
              </div>
              <div style={{ display: "inline-flex" }}>
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_title}
                  name="title"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_phone}
                  name="phone"
                  onChange={(event) => {
                    const re = /^[0-9-+\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("phone", event.target.value);
                    }
                  }}
                />
              </div>

              <GMap
                centerLocation={{ lat: 30.7046, lng: 76.7179 }}
                search={true}
                height="30vh"
                type="createStore"
                onChange={(address, loc) => {
                  values.latitude = loc.lat();
                  values.longitude = loc.lng();
                  setFieldValue("address", address.address);
                }}
                label="Location"
                padding={true}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_address}
                name="address"
              />

              <h4 style={{ margin: "15px 15px 10px" }}>
                {strings.w_additional_information}
              </h4>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_commission_in_percentage}
                name="commission"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              {branchesData && (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_branches}
                  name="branch_id"
                  options={branchesData}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
              )}

              {brandsData &&
                brandsData.length >= 0 &&
                settings?.brands_available == 1 && (
                  <FormikControl
                    control="select"
                    as="select"
                    label={strings.w_brand}
                    name="brand_id"
                    options={brandsData}
                    key_title="title"
                    key_value="id"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                )}

              <div
                style={{
                  display: "flex",
                  margin: "0 15px",
                  minHeight: "55px",
                  alignItems: "center",
                }}
              >
                <label style={{ width: "140px" }}>
                  {" "}
                  {strings.w_max_listing_distance}
                </label>
                <FormikControl
                  control="toggle"
                  label=""
                  isToggled={values.aerialDistance}
                  name="serial_distance"
                  iconType="google"
                  onToggle={() => {
                    let unlimitedVal =
                      values.aerialDistance == 1 ? true : false;
                    unlimitedVal = !unlimitedVal;
                    unlimitedVal = unlimitedVal === true ? 1 : 0;
                    setFieldValue("aerialDistance", unlimitedVal);
                  }}
                />
                {values.aerialDistance === "1" ||
                values.aerialDistance === 1 ? (
                  <FormikControl
                    control="input"
                    type="text"
                    name="aerial_distance"
                    className="noBorder noMargin"
                    style={{ width: "150px" }}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("aerial_distance", event.target.value);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  margin: "0 15px",
                  minHeight: "55px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "0.98rem",
                    paddingRight: "15px",
                  }}
                >
                  {strings.w_delivery_otp}
                </label>
                <FormikControl
                  control="toggle"
                  label=""
                  isToggled={values.delivery_otp}
                  name="delivery_otp"
                  iconType="google"
                  onToggle={() => {
                    let otpConfirmation =
                      values.delivery_otp == 1 ? true : false;
                    otpConfirmation = !otpConfirmation;
                    otpConfirmation = otpConfirmation === true ? 1 : 0;
                    setFieldValue("delivery_otp", otpConfirmation);
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  margin: "0 15px",
                  minHeight: "55px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "0.98rem",
                    paddingRight: "15px",
                  }}
                >
                  {strings.w_allow_inventory}
                </label>
                <FormikControl
                  control="toggle"
                  label=""
                  isToggled={values.allow_inventory}
                  name="allow_inventory"
                  iconType="google"
                  onToggle={() => {
                    let allowInventory =
                      values.allow_inventory == 1 ? true : false;
                    allowInventory = !allowInventory;
                    allowInventory = allowInventory === true ? 1 : 0;
                    setFieldValue("allow_inventory", allowInventory);
                  }}
                />
              </div>

              <div style={{ margin: "15px" }}>
                <label>{strings.w_geofences}</label>
                <GGeoTagsInput
                  data={geofencesList}
                  onSubmit={(tags) => {
                    console.log(tags);
                    values.geofences = tags.map((d) => d.id);
                  }}
                  showButton={false}
                />
              </div>

              <div style={{ margin: "15px 15px 25px" }}>
                <label>{strings.w_classes}</label>
                <GTagsInput
                  selectedData={values.classes}
                  data={classesData}
                  placeholder={strings.w_select_classes}
                  onSubmit={(data) => {
                    console.log(data);
                    values.classes = data;
                    setFieldValue("classes", data);
                  }}
                  showButton={false}
                />
              </div>

              {subClassesData && subClassesData.length > 0 && (
                <div style={{ margin: "15px 15px 25px" }}>
                  <label>{strings.w_subclasses}</label>
                  <GTagsInput
                    selectedData={values.sub_classes}
                    data={subClassesData || []}
                    placeholder={strings.w_select_subclasses}
                    onSubmit={(data) => {
                      console.log(data);
                      values.sub_classes = data;
                      setFieldValue("sub_classes", data);
                    }}
                    showButton={false}
                  />
                </div>
              )}

              <div style={{ margin: "15px 15px 25px" }}>
                <label>{strings.w_groups}</label>
                <GTagsInput
                  selectedData={values.groups}
                  data={groupsData}
                  placeholder={strings.w_select_groups}
                  onSubmit={(data) => {
                    console.log(data);
                    values.groups = data;
                    setFieldValue("groups", data);
                  }}
                  showButton={false}
                />
              </div>

              <FormikControl
                control="radio"
                label={strings.w_delivery_types}
                name="delivery_type"
                options={deliveryTypes}
                key_title="title"
                key_value="value"
              />
              <FormikControl
                control="radio"
                label={strings.w_order_type}
                name="order_type"
                options={orderTypes}
                key_title="title"
                key_value="value"
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.s_packaging_time_in_min}
                name="pack_time"
                maxLength={4}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("pack_time", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_theme}
                name="theme"
                options={[
                  { title: strings.w_restaurant, value: "restaurant" },
                  { title: strings.w_grocery, value: "grocery" },
                ]}
                key_title="title"
                key_value="value"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_price_range}
                name="price_range"
                options={PriceRangeValues}
                key_title="title"
                key_value="value"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_lapsed_customer_days}
                name="lapsed_customer_days"
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("lapsed_customer_days", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              />

              <div
                style={{
                  margin: "5px 15px 0px",
                }}
              >
                <label>{strings.w_open_till}</label>
                <TimePicker
                  defaultValue={
                    values.open_till
                      ? moment(values.open_till, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.open_till
                      ? moment(values.open_till, "HH:mm:ss")
                      : moment()
                  }
                  onChange={(value) => {
                    values.open_till = value && value.format(str);
                    setFieldValue("open_till", value && value.format(str));
                  }}
                  showSecond={showSecond}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="open_till"
                style={{ display: "none" }}
              />

              <br />

              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
