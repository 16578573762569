import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { Assignment } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEmpty from "../gComponents/gEmpty";
import OrderStatuses from "../gComponents/gOrderStatus";
import QueryWith from "../Query";
import { DriverFilter } from "../components/driverFilterComponent";
import GSorting from "../gComponents/gSorting";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function Tasks(props) {
  const [strings] = useContext(StringsContext);
  const settings = useSettings(true);
  const [tasksData, setTaskData] = useState([]);
  const [taskPaginationData, setTaskPaginationData] = useState("");
  const [taskStatusData, setTaskStatusData] = useState([]);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    task_status: params.get("task_status")
      ? params.get("task_status")
      : "pending",
    agent_id: params.get("agent_id") ? params.get("agent_id") : "",
  });

  const columns = [
    { id: "order_id", label: strings.w_order, minWidth: 30, type: "task" },
    {
      id: "driver",
      label: strings.w_driver,
      minWidth: 100,
      type: "agent_id",
      align: "center",
    },
    {
      id: "commission",
      label: strings.w_commission,
      minWidth: 80,
      // format: (value) => value.toLocaleString('en-US'),
      //format: (value) => value.toFixed(2),
      type: "task",
      align: "center",
    },
    {
      id: "time_spent",
      label: strings.w_time_spent,
      minWidth: 80,
      type: "task",
      align: "center",
    },

    {
      id: "status",
      label: strings.w_status,
      minWidth: 120,
      type: "task",
      align: "center",
    },
    {
      id: "created_at_formatted",
      label: strings.w_created,
      minWidth: 130,
      type: "task",
      align: "center",
    },
    {
      id: "delivery_time",
      label: strings.w_delivered,
      minWidth: 130,
      type: "task",
      align: "center",
    },
  ];

  const orderSorting = [
    {
      title: strings.w_created_at,
      value: "created_at",
    },
    {
      title: strings.w_delivery_time,
      value: "delivery_to",
    },
  ];

  useEffect(() => {
    console.log(props);

    if (props.type) {
      if (props.onChange) {
        props.onChange("tasks");
      }
      var obj = {};
      obj["page"] = filters.page;
      obj[props.title] = props.value;

      //  var obj = "?page=" + filters.page + "&" + props.title + "=" + props.value;
    } else {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/tasks",
          search: params[0],
        });
      }
    }
    var query = props.type ? obj : filters;
    console.log(query);

    ApiService({ method: "GET", route: "order/deliveries", body: query })
      .then((response) => {
        console.log("gh" + response.data);
        if (response.data.status_code === 1) {
          setTaskData(response.data.data.data);
          setTaskPaginationData(response.data.data);
          setTaskStatusData(response.data.statuses);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [history, filters]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({
      page: NewPageNo,
    });
  };
  const handleChangeStatus = (oStatus) => {
    console.log(oStatus);
    setFilters({
      page: "1",
      order_status: oStatus,
    });
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {!props.type ? (
        <React.Fragment>
          <h1>
            <Assignment /> {strings.w_tasks}
          </h1>
        </React.Fragment>
      ) : (
        ""
      )}

      {!props.type ? (
        <React.Fragment>
          <OrderStatuses
            statusList={taskStatusData}
            onClickStatus={handleChangeStatus}
            defaultStatus={filters.order_status}
            currencySymbol={settings.currency_symbol}
          />

          <div>
            <DriverFilter
              value={filters.agent_id}
              style={{
                marginRight: "10px",
              }}
              handleCustomerChange={(value) => {
                setFilters({
                  agent_id: value,
                  order_status: filters.order_status,
                });
              }}
            />
            <GSorting
              defaultvalue={filters.orderby}
              defaultOrdervalue={filters.order}
              data={orderSorting}
              id="sortBy"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  orderby: newVal,
                  order_status: filters.order_status,
                });
              }}
              style={{
                height: "40px",
                marginRight: "10px",
                minWidth: "150px",
              }}
              onOrderChange={(newVal) => {
                setFilters({
                  order: newVal,
                  order_status: filters.order_status,
                });
              }}
            />
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      {tasksData.length !== 0 && !props.type ? (
        <div style={{ textAlign: "right" }}>
          <GPagination
            style={{ display: "inline-block" }}
            count={taskPaginationData.total}
            from={taskPaginationData.from}
            to={taskPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(taskPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(taskPaginationData.prev_page_url)
            }
            rowsPerPage={taskPaginationData.per_page}
            prevDisabled={taskPaginationData.prev_page_url ? false : true}
            nextDisabled={taskPaginationData.next_page_url ? false : true}
          ></GPagination>
          {taskPaginationData && (
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={taskPaginationData.last_page}
              onChangePage={(NewPageNo) => setFilters({ page: NewPageNo })}
            />
          )}
        </div>
      ) : (
        ""
      )}

      <div
        className="listPaper"
        style={{ marginTop: props.type ? "" : "10px" }}
      >
        {tasksData.length !== 0 && props.type ? (
          <GPagination
            count={taskPaginationData.total}
            from={taskPaginationData.from}
            to={taskPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(taskPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(taskPaginationData.prev_page_url)
            }
            rowsPerPage={taskPaginationData.per_page}
            prevDisabled={taskPaginationData.prev_page_url ? false : true}
            nextDisabled={taskPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}

        {!taskPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {tasksData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => column.type != props.title)
                      .map((c) => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              width: c.width,
                              textAlign: c.align,
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasksData.map((s) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/order/" + s.order_id,
                              state: "order",
                            }}
                          >
                            #{s.order_id}
                          </Link>
                        </TableRowData>
                        {props.title !== "agent_id" && (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.agent ? (
                              <Link
                                to={{
                                  pathname: "/driver/" + s.agent.id + "/tasks",
                                  state: "driver",
                                }}
                              >
                                {s.agent.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.agent_commission
                            ? settings.currency_symbol + s.agent_commission
                            : "-"}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.time_spent || "-"}
                        </TableRowData>

                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            className="orderStatus"
                            style={{
                              color: "#" + s.status_display.color1,
                              background: "#" + s.status_display.color2,
                            }}
                          >
                            {s.status_display.title}
                          </span>
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.created}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.completed ? s.completed : "-"}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>
    </div>
  );
}
