import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function CompensationCouponForm(props) {
  const [strings] = useContext(StringsContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    order_id: props.orderId || "",
    amount: "",
    deduct_from: "",
    reason: "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    ApiService({
      method: "POST",
      route: "order-compensation-coupon",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setCompCouponVisible(false);
        props.onSubmit(response.data.data);
      }
      setIsDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required(strings.w_required),
    deduct_from: Yup.string().required(strings.w_required),
    reason: Yup.string().required(strings.w_required),
  });

  if (props.compCouponVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{strings.w_add_compensation_coupon}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_amount}
                name="amount"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("amount", event.target.value);
                  }
                }}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_deduct_from}
                name="deduct_from"
                placeholder={strings.w_choose + " " + strings.w_deduct_from}
                options={[
                  { title: strings.w_admin, value: "admin" },
                  { title: strings.w_store, value: "store" },
                  { title: strings.w_driver, value: "driver" },
                ]}
                key_title="title"
                key_value="value"
              />

              <FormikControl
                control="input"
                as="textarea"
                label={strings.w_reason}
                name="reason"
                rows="4"
              />

              <br />
              <GButton
                children={strings.w_submit}
                variant="condensed"
                type="submit"
                disabled={isDisabled}
                style={{ width: "calc(100% - 30px", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
