import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import { StoreFilter } from "../components/storeFilterComponent";
import { ProductFilter } from "../components/productFilterComponent";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";
import GText from "../gComponents/gText";
import { Cancel } from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";

export default function BannerForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [storeId, setStoreId] = useState("");
  const [selectedStores, setSelectedStores] = useState(
    props.data?.linked_type == "stores" ? props.data?.value || [] : []
  );

  const BannerTypes = [
    { title: strings.w_store, value: "store" },
    { title: strings.w_product, value: "product" },
    { title: strings.w_url, value: "url" },
    { title: strings.w_stores, value: "stores" },
  ];

  const initialValues = {
    photo: !props.isEmpty ? props.data?.photo : "",
    index: !props.isEmpty ? props.data?.index : "0",
    type: props.type,
    linked_value: !props.isEmpty ? props.data?.linked_value : "",
    linked_type: props.data?.linked_type || "",
    store_id:
      props.data?.linked_type == "product" ? props.data?.value?.store_id : "",
    stores: !props.isEmpty ? props.data?.stores || [] : [],
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };

    let assignedStores = [];
    selectedStores.map((s) => {
      assignedStores.push(s.id);
    });
    sendData.stores = assignedStores;
    console.log(sendData);
    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "offer-banners/" + props.data.id
      : "offer-banners";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setBanvisible(false);
          props.onSubmit();
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    photo: Yup.string().required(strings.w_required),
  });

  if (props.banvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_banner : strings.w_add_banner} Banner
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GImageEditor
                onSubmit={(newValue, id) => {
                  values.photo = newValue;
                }}
                id="photo"
                image={
                  values.photo ? `${ImgUrl(props.imgType)}/${values.photo}` : ""
                }
                type={props.imgType}
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{ margin: "0 auto", display: "block" }}
                icoStyle={{ marginRight: "10px" }}
                imageStyle={{ width: "95%" }}
              />

              <FormikControl
                control="input"
                type="text"
                name="photo"
                divstyle={{ margin: "0", padding: "0", textAlign: "center" }}
                style={{ display: "none" }}
              />

              <br />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_sort_index}
                name="index"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              />

              <FormikControl
                control="radio"
                label={strings.w_banner_type}
                name="linked_type"
                options={BannerTypes}
                key_title="title"
                key_value="value"
                onChange={(e) => {
                  values.linked_type = e.target.value;
                  setFieldValue("linked_type", e.target.value);

                  values.linked_value = "";
                  setFieldValue("linked_value", "");
                }}
              />

              {values.linked_type == "store" && (
                <div style={{ padding: "4px 15px" }}>
                  <label>{strings.w_store}</label>
                  <StoreFilter
                    value={values.linked_value}
                    title={props.data?.value?.title || ""}
                    storeType={"regular"}
                    style={{
                      marginBottom: "10px",
                      display: "block",
                      width: "100%",
                    }}
                    handleStoreChange={(value) => {
                      console.log(value);
                      setFieldValue("linked_value", value ? value : "");
                    }}
                  />
                </div>
              )}

              {values.linked_type == "product" && (
                <>
                  <div style={{ padding: "4px 15px" }}>
                    <label>{strings.w_store}</label>
                    <StoreFilter
                      value={values?.store_id | ""}
                      title={props.data?.value?.title || ""}
                      storeType={"regular"}
                      style={{
                        marginBottom: "10px",
                        display: "block",
                        width: "100%",
                      }}
                      handleStoreChange={(value) => {
                        console.log(value);
                        setFieldValue("store_id", value || "");
                        setFieldValue("linked_value", "");
                      }}
                    />
                  </div>
                  {values.store_id && values.linked_type == "product" ? (
                    <div style={{ padding: "4px 15px" }}>
                      <label>{strings.w_product}</label>
                      <ProductFilter
                        value={values.linked_value}
                        productTitle={props.data?.value?.title || ""}
                        style={{
                          margin: "0px",
                          width: "100%",
                        }}
                        status={1}
                        containerStyle={{ margin: "0", width: "100%" }}
                        storeId={values.store_id}
                        isStored={values.linked_value ? true : false}
                        perPage={50}
                        handleProductChange={(id, value) => {
                          setFieldValue("linked_value", id ? id : "");
                        }}
                      />{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}

              {values.linked_type == "stores" && (
                <div style={{ padding: "4px 15px" }}>
                  <div>
                    <label>{strings.w_stores}</label>
                    <StoreFilter
                      value={storeId}
                      handleStoreChange={(value, data) => {
                        setStoreId(value || "");
                        if (value) {
                          let selectedInd = selectedStores.findIndex(
                            (s) => s.id == value
                          );
                          if (selectedInd == -1) {
                            selectedStores.push({
                              id: value,
                              title: data.title,
                              phone: data.phone,
                            });
                            setSelectedStores([...selectedStores]);
                          }
                        }
                      }}
                      style={{ width: "300px" }}
                    />
                  </div>
                  {selectedStores.length > 0 && (
                    <div
                      style={{
                        border: "1px solid #e2e2e2",
                        padding: "7px 5px",
                        marginTop: "15px",
                      }}
                    >
                      {selectedStores.map((c, i) => {
                        return (
                          <TooltipComponent
                            key={i}
                            title={c.title}
                            position="top"
                            id={c.id}
                            subtitle={`${c.phone || ""}`}
                            theme={"white"}
                            tooltipStyle={{
                              textAlign: "left",
                              minWidth: "auto",
                              borderRadius: "4px",
                              left: "60%",
                            }}
                            divStyle={{ margin: 0 }}
                          >
                            <div
                              style={{
                                margin: "5px",
                                display: "inline-flex",
                                background: "#f4f5f9",
                                borderRadius: "4px",
                                padding: "2px 10px",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <div>
                                <GText
                                  semi
                                  text={c.title}
                                  style={{
                                    margin: "2px 5px",
                                    fontSize: "14px",
                                  }}
                                />
                              </div>
                              <Cancel
                                onClick={() => {
                                  selectedStores.splice(i, 1);
                                  setSelectedStores([...selectedStores]);
                                }}
                                style={{ width: "18px", marginLeft: 10 }}
                              />
                            </div>
                          </TooltipComponent>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}

              {values.linked_type == "url" && (
                <FormikControl
                  control="input"
                  type="text"
                  name="linked_value"
                  label={strings.w_external_url}
                />
              )}

              <br />
              <GButton
                disabled={isBtnDisabled}
                variant="condensed"
                type="submit"
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              >
                {!props.isEmpty ? strings.w_update : strings.w_create}
              </GButton>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
